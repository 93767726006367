import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Body4 } from '../Typography/Fonts';
import CalendarLayout from './CalendarLayout';
import { Context } from '../Context/globalContextProvider';
import { SettingsContext } from '../Context/SettingsContext';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;
const Wrapper = styled.div`
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  @media (max-width: 780px) {
    justify-content: flex-start;
    gap: 14px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  @media (max-width: 780px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: row;
`;
const LittleBox = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  @media (max-width: 780px) {
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
  }
  ${(props) => {
    const backgroundColor = `background: ${props.activeColor};`;
    switch (props.variant) {
      // case 'past':
      //   return `background: ${props.activeColor}; border: 0`;
      // case 'present':
      //   return `background: ${props.activeColor}; filter: brightness(75%); border: 0`;
      // default:
      //   return 'background: transparent; border: 1px solid #ADADAD; box-sizing: border-box;';

      case 'present':
        return `${backgroundColor} animation: current 4s infinite; border: 0`;
      case 'past':
        return `${backgroundColor} border: 0`;
      default:
        return `${backgroundColor} border: 1px solid #ADADAD; box-sizing: border-box;`;
    }
  }}

  @keyframes current {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }
`;
const Label = styled(Body4)`
  margin: 0;
  width: 135px;
  font-size: 16px;
  line-height: 13px;
  padding: 0px 10px 0px 0px;
  @media (max-width: 780px) {
    letter-spacing: 1px;
    line-height: 17px;
    font-weight: 500;
    margin-top: 25px;
    font-size: 14px;
    color: #505050;
    width: 100%;
  }
`;

// eslint-disable-next-line object-curly-newline
function WeeksContainer({ myRef, year, age, user, pagelink }) {
  const boxesArray = [];
  const boxCallback = React.useCallback(
    (key, week) => {
      if (user.paintOpen) {
        const newArray = user.yearsInWeeksColors.filter((l) => l.key !== key);
        user.setYearsInWeeksColors([
          ...newArray,
          { key, color: user.activeColor },
        ]);

        const newArray2 = [
          ...user.recentColors.filter((rc) => rc !== user.activeColor),
          user.activeColor,
        ];
        if (newArray2.length > 10) newArray2.shift();
        user.setRecentColors(newArray2);
      }
      user.setScrollYear(year);
      user.setScrollWeek(week);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.paintOpen, user.yearsInWeeksColors, user.activeColor],
  );

  const littleBoxCreator = (week, key, variant, activeColor) => {
    let referenceForScroll;
    if (year === user.scrollYear) referenceForScroll = myRef;
    return (
      <LittleBox
        key={`LittleBox-${key}`}
        onClick={() => {
          //console.log(moment().year() - age + year);
          user.setRenderYear(moment().year() - age + year);
          boxCallback(key, week);
        }}
        variant={variant}
        activeColor={activeColor}
      >
        <Link
          key={`Link-${key}`}
          ref={referenceForScroll}
          onClick={() => {
            // console.log('click Link: ', year, week);
            // user.setScrollYear(year);
            // user.setScrollWeek(week);
          }}
          to={pagelink}
        />
      </LittleBox>
    );
  };

  for (let j = 1; j <= 52; j += 1) {
    const key = `${year}-${j}`;
    const actualColor = user.yearsInWeeksColors.find(
      (c) => c.key === key,
    )?.color;
    const inRangeColor = actualColor ?? user.defaultColor;
    const notInRangeColor = actualColor ?? 'transparent';
    if (year < age) {
      boxesArray.push(littleBoxCreator(j, key, 'past', inRangeColor));
    } else if (year === age) {
      if (j === moment().week()) {
        boxesArray.push(littleBoxCreator(j, key, 'present', inRangeColor));
      } else if (j < moment().week()) {
        boxesArray.push(littleBoxCreator(j, key, 'past', inRangeColor));
      } else {
        boxesArray.push(littleBoxCreator(j, key, undefined, notInRangeColor));
      }
    } else {
      boxesArray.push(littleBoxCreator(j, key, undefined, notInRangeColor));
    }
  }
  return <>{boxesArray}</>;
}

const translations = [
  {
    English: 'Year',
    Russian: 'Год',
    Spanish: 'Año',
  },
];
function YearsInWeeks() {
  const pagelink = '/calendar/days';
  const user = React.useContext(Context);
  const { language } = React.useContext(SettingsContext);
  const age = moment().year() - moment(user.birthday).year() ?? 1800;
  const [componentArray, setComponentArray] = React.useState([]);
  const [scrolledToRef, setScrolledToRef] = React.useState(false);

  const myRef = React.useRef(null);
  // const { body } = document;
  // const html = document.documentElement;
  // const documentHeight = Math.max(
  //   body.scrollHeight,
  //   body.offsetHeight,
  //   html.clientHeight,
  //   html.scrollHeight,
  //   html.offsetHeight,
  // );

  /** Component's autoscroll */
  const executeScroll = React.useCallback(() => {
    // console.log('executeScroll', user.scrollYear, myRef.current);
    // const percentage = (num, per) => (num / 100) * per;
    const validRange = user.lifeExpectency - user.scrollYear > 11;
    if (user.scrollYear > 3 && myRef.current) {
      myRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      // window.scrollBy(0, -percentage(documentHeight, 22));
      if (validRange) window.scrollBy(0, -50);
    }
  }, [user.lifeExpectency, user.scrollYear]);

  const calculateYearsInWeeksMatrix = React.useCallback(() => {
    const localComponentArray = [];
    const l = user.lifeExpectency === 0 ? 90 : user.lifeExpectency;
    for (let i = 1; i <= l; i += 1) {
      localComponentArray.push(
        <div id={i} key={`Div-${i}`}>
          <Row key={`Row-${i}`}>
            <Column key={`Column-${i}`}>
              <Label key={`Label-${i}`}>
                {`${i} ${translations[0][language]}, ${
                  moment(user.birthday).year() + i || 2000 + i
                }`}
              </Label>
            </Column>
            <Wrapper key={`Wrapper-${i}`}>
              <WeeksContainer
                myRef={myRef}
                year={i}
                age={age}
                user={user}
                pagelink={pagelink}
              />
            </Wrapper>
          </Row>
        </div>,
      );
    }
    setComponentArray(localComponentArray);
  }, [age, language, user]);

  React.useEffect(() => {
    calculateYearsInWeeksMatrix();
  }, [
    calculateYearsInWeeksMatrix,
    user.user,
    user.yearsInWeeksColors,
    language,
  ]);

  // It has to be triggerd only one time
  React.useEffect(() => {
    if (!scrolledToRef && myRef.current && user.birthday) {
      //console.log('useEffectB');
      // if (user.scrollYear > 0) executeScroll();
      setScrolledToRef(true);
    }
  }, [executeScroll, scrolledToRef, user.birthday, user.scrollYear]);

  return (
    <CalendarLayout>
      <Container>{componentArray}</Container>
    </CalendarLayout>
  );
}

export default YearsInWeeks;
