import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Body2 } from '../Typography/Fonts';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  @media (min-width: 780px) {
    display: none;
  }
`;
// right: 0px;
const AvatarImageContainer = styled.div`
  @media (min-width: 780px) {
    display: none;
  }
`;
const AvatarImage = styled.img`
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

const AvatarContainer = styled.div`
  @media (min-width: 780px) {
    display: none;
  }
`;

const AvatarWrapper = styled.div`
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #dfebfd;
  margin: auto;
  width: 20px;
  height: 20px;
  z-index: 1;
`;

const Avatar = styled.svg``;

const NotLoggedAvatar = styled.svg`
  @media (min-width: 780px) {
    display: none;
  }
`;

const UsernameText = styled(Body2)`
  color: ${(props) => (props.focused ? '#2A75BA' : '#8B9583')};
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 10px;
    line-height: 0px;
    letter-spacing: 1px;
  }
`;

const UserIcon = ({ userDetails }) => {
  const history = useHistory();
  const redirect = () => {
    if (userDetails) {
      history.push('/settings');
    } else {
      history.push('/login');
    }
  };
  return (
    <IconWrapper onClick={redirect}>
      {userDetails ? (
        userDetails?.photoURL ? (
          <AvatarImageContainer>
            <AvatarImage src={userDetails?.photoURL} />
            <UsernameText>
              {userDetails?.displayName?.split(' ')[0]}
            </UsernameText>
          </AvatarImageContainer>
        ) : (
          <AvatarContainer>
            <AvatarWrapper>
              <Avatar
                width="15"
                height="15"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.1422 25.8578C31.9638 23.6795 29.371 22.067 26.5421 21.0908C29.572 19.004 31.5625 15.5116 31.5625 11.5625C31.5625 5.18695 26.3755 0 20 0C13.6245 0 8.4375 5.18695 8.4375 11.5625C8.4375 15.5116 10.428 19.004 13.458 21.0908C10.6291 22.067 8.03625 23.6795 5.85789 25.8578C2.08039 29.6354 0 34.6578 0 40H3.125C3.125 30.6951 10.6951 23.125 20 23.125C29.3049 23.125 36.875 30.6951 36.875 40H40C40 34.6578 37.9196 29.6354 34.1422 25.8578ZM20 20C15.3476 20 11.5625 16.215 11.5625 11.5625C11.5625 6.91 15.3476 3.125 20 3.125C24.6524 3.125 28.4375 6.91 28.4375 11.5625C28.4375 16.215 24.6524 20 20 20Z"
                  fill="#2A75BA"
                />
              </Avatar>
            </AvatarWrapper>
            <UsernameText>
              {userDetails?.displayName?.split(' ')[0]}
            </UsernameText>
          </AvatarContainer>
        )
      ) : (
        <NotLoggedAvatar
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.92863 8.80948C5.49982 8.80948 3.5238 6.83363 3.5238 4.40482C3.5238 1.97602 5.49982 0 7.92863 0C10.3574 0 12.3334 1.97602 12.3334 4.40482C12.3334 6.83363 10.3574 8.80948 7.92863 8.80948ZM7.92863 1.32143C6.22845 1.32143 4.84523 2.70448 4.84523 4.40482C4.84523 6.105 6.22845 7.48805 7.92863 7.48805C9.62881 7.48805 11.012 6.105 11.012 4.40482C11.012 2.70448 9.62881 1.32143 7.92863 1.32143Z"
            fill="#797979"
          />
          <path
            d="M15.1965 18.5007H0.660715C0.295999 18.5007 0 18.2047 0 17.84V14.7566C0 12.4494 1.8773 10.5721 4.18448 10.5721H11.6727C13.9799 10.5721 15.8572 12.4494 15.8572 14.7566V17.84C15.8572 18.2047 15.5612 18.5007 15.1965 18.5007ZM1.32143 17.1793H14.5357V14.7566C14.5357 13.1781 13.2512 11.8936 11.6727 11.8936H4.18448C2.60592 11.8936 1.32143 13.1781 1.32143 14.7566V17.1793Z"
            fill="#797979"
          />
        </NotLoggedAvatar>
      )}
    </IconWrapper>
  );
};

export default UserIcon;
