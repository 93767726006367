const data = [
  {
    id: 1,
    icon: 'insurance-icon-1.png',
    heading: 'Family with children',
    paragraph: "Ensure your children have comfortable living conditions, good education and a safe home. Protect your family's goals and fulfill your plans, even if your life circumstances have changed and one of the spouses becomes seriously ill or dies as a result of an accident.",
  },
  {
    id: 2,
    icon: 'insurance-icon-2.png',
    heading: 'Young family',
    paragraph: 'Prevent solving financial issues in a difficult life situation associated with loss of health or death of the spouse, covering unpredictable costs of treatment, loan payments and running expenses.',
  },
  {
    id: 3,
    icon: 'insurance-icon-3.png',
    heading: 'Single-parent family',
    paragraph: 'When there is only one parent in a family, then the entire burden of responsibility for the present and future of children lies entirely with him. A life insurance policy allows you to preserve the well-being of the family in case of loss of health of the breadwinner and take care of the future of the family, no matter what happens.',
  },
  {
    id: 4,
    icon: 'insurance-icon-4.png',
    heading: 'A housewife',
    paragraph: "If something happens to a housewife's health, will her husband be able to pay not only for her treatment, but also for the maintenance of her life - taking care of children, preparing meals, cleaning the house, and other household chores? With life insurance, the family can maintain the quality of their life in any situation.",
  },
  {
    id: 5,
    icon: 'insurance-icon-5.png',
    heading: 'A family where children have grown up',
    paragraph: "Ensure your children have comfortable living conditions, good education and a safe home. Protect your family's goals and fulfill your plans, even if your life circumstances have changed and one of the spouses becomes seriously ill or dies as a result of an accident.",
  },
  {
    id: 6,
    icon: 'insurance-icon-6.png',
    heading: 'Single person',
    paragraph: "Single people often think that if they don't have dependants, then there is no need to open a life insurance program. However, an accident or illness can happen to anyone, and it will take some time for treatment and recovery. Who can you count on for help in such a situation? The insurance company will pay funds that will allow you to receive quality treatment and maintain a decent standard of living during the period of disability.",
  },
];

export default data;
