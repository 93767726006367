import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import AuthenticationLayout from './AuthenticationLayout';
import 'firebase/auth';
import 'firebase/firestore';
import { SettingsContext } from '../Context/SettingsContext';

const Input = styled.input`
  background: #dfebfd;
  border-radius: 6px;
  padding-left: 4%;
  padding-right: 4%;
  box-sizing: border-box;
  color: ${(props) => (props.filled ? '#2A75BA' : '#8B9583')};
  width: 100%;
  outline: 0;
  height: 48px;
  border: 3px solid transparent;
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  transition: 0.3s;
  white-space: normal !important;
  &:focus {
    color: #262626;
    background: #ffffff;
    border: 3px solid #dfebfd;
  }
  @media (max-width: 780px) {
    height: 47px;
    max-width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 10px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    padding: 16px 21px 16px 21px;
  }
`;
const Form = styled.form`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 45px;
  @media (max-width: 780px) {
    gap: 20px;
    width: 80%;
    margin: 0% 10% 0% 10%;
  }
`;
const Button = styled.button`
  height: 42px;
  width: 100%;
  min-width: 360px;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  transition: 0.3s;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  white-space: normal !important;
  color: #ffffff;
  background: #57b010;
  border: 2px solid #57b010;
  &:hover {
    border: 2px solid #79c040;
    cursor: pointer;
    background: #79c040;
    color: #ffffff;
  }
  &:focus {
    outline: 0;
  }
  @media (max-width: 780px) {
    height: 50px;
    min-width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    border-radius: 10px;
    text-transform: none;
    letter-spacing: 0.5px;
  }
`;

// const signInWithEmailAndPasswordHandler = (event, email, password) => {
//   event.preventDefault();
//   firebaseLogin(email, password);
// };

const translations = [
  {
    English: 'Enter the e-mail associated with your account',
    Russian: 'Введите имейл, с которым Вы зарегистрированы',
    Spanish: 'Ingresa el email asociado a tu cuenta',
  },
  {
    English: 'Reset password',
    Russian: 'Восстановить пароль',
    Spanish: 'Cambiar contraseña',
  },
  {
    English: 'Email',
    Russian: 'Имейл',
    Spanish: 'Email',
  },
];

const ForgotPassword = () => {
  const { language } = React.useContext(SettingsContext);
  const [email, setEmail] = React.useState('');
  const [focusedInput, setFocusedInput] = React.useState(false);
  const sendPasswordResetEmail = (userEmail) => {
    firebase
      .auth()
      .sendPasswordResetEmail(userEmail)
      .then(() => {
        // Password reset email sent!
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <AuthenticationLayout
      pageType={translations[1].English}
      pageHeader={translations[1][language]}
      pageText={translations[0][language]}
    >
      <Form>
        <Input
          type="email"
          name="userEmail"
          value={email}
          placeholder={translations[2][language]}
          id="userEmail"
          filled={email !== '' || email !== null}
          focused={focusedInput}
          onFocus={() => setFocusedInput(true)}
          onBlur={() => setFocusedInput(false)}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Link to="verify-email">
          <Button
            type="submit"
            onClick={() => {
              sendPasswordResetEmail(email);
            }}
          >
            {translations[1][language]}
          </Button>
        </Link>
      </Form>
    </AuthenticationLayout>
  );
};

export default ForgotPassword;
