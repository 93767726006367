import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// const environment = process.env;
const firebaseConfig = {
  apiKey: 'AIzaSyCkbR34Bccvpiw6zHJ57azgWkpym93IOR4',
  authDomain: 'act-meaningful-app-22582.firebaseapp.com',
  databaseURL: 'https://act-meaningful-app-22582.firebaseio.com',
  projectId: 'act-meaningful-app-22582',
  storageBucket: 'act-meaningful-app-22582.appspot.com',
  messagingSenderId: '655216814057',
  appId: '1:655216814057:web:18b088ce1f0f41caf98d78',
  measurementId: 'G-XLHEDT8QPG',
};

firebase.initializeApp(firebaseConfig);

const handleAfterAuth = async (calendarData, history) => {
  // const userData = (
  //   await firebase.firestore().collection('users').doc(uid).get()
  // ).data();
  // if (userData?.lifeExpectency && userData?.birthday) {
  // }

  history.push({
    pathname: calendarData ? '/calendar' : '/birthdayinfo',
    search: '?saveSettings=true&saveGlobal=true',
  });
};

export const firebaseSignup = async (
  displayName,
  email,
  password,
  setEmailValidation,
  setPasswordValidation,
  setErrorMessage,
  calendarData,
  history,
) => {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((credential) => {
      const { user } = credential;
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({
          displayName,
          email,
          id: user.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          console.log('User Signup Stored!');
          const u = firebase.auth().currentUser;
          u.updateProfile({
            displayName,
          }).then((e) => {
            handleAfterAuth(calendarData, history);
          });
        })
        .catch((error) => {
          console.error('Error writing User Signup: ', error);
        });
    })
    .catch((error) => {
      console.log(error);
      setErrorMessage(error?.message);
      if (error?.code === 'auth/email-already-in-use') setEmailValidation(true);
      if (error?.code === 'auth/weak-password') setPasswordValidation(true);
    });
};

export const firebaseLogin = (
  email,
  password,
  setPasswordValidation,
  setErrorMessage,
  calendarData,
  history,
) => {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async () => {
      //credential
      //const { user } = credential;
      console.log('User logged in');
      handleAfterAuth(calendarData, history);
    })
    .catch((error) => {
      console.log(error);
      if (error?.code === 'auth/wrong-password')
        setErrorMessage(error?.message);
      setPasswordValidation(true);
    });
};

const firebaseLoginWithProvider = (provider, calendarData, history) => {
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      const { user } = result;
      user
        .linkWithCredential(result.credential)
        .then((usercred) => {
          console.log('Anonymous account successfully upgraded', user);
        })
        .catch((error) => {
          console.log('Error upgrading anonymous account', error);
        });
      if (result.additionalUserInfo.isNewUser === true) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .set(
            {
              displayName: user.displayName,
              email: user.email,
              id: user.uid,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true },
          )
          .then(() => {
            console.log('Logged in!');
            handleAfterAuth(calendarData, history);
          })
          .catch((error) => {
            console.error('Error writing active color: ', error);
          });
      } else {
        handleAfterAuth(calendarData, history);
      }
    });
};

export const firebaseLoginGoogle = (history, calendarData) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebaseLoginWithProvider(provider, calendarData, history);
};

export const firebaseLoginFacebook = (history, calendarData) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  firebaseLoginWithProvider(provider, calendarData, history);
};

export const firebaseLoginTwitter = (history, calendarData) => {
  const provider = new firebase.auth.TwitterAuthProvider();
  firebaseLoginWithProvider(provider, calendarData, history);
};

export const firebaseLoginApple = (history, calendarData) => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  firebaseLoginWithProvider(provider, calendarData, history);
};

export const firebaseLogout = (history) => {
  firebase
    .auth()
    .signOut()
    .then(
      setTimeout(() => {
        //window.location = '/login';
        history.push({
          pathname: '/login',
        });
      }, 1000),
    );
};

// FUNCTIONS FOR UPLOADING OF DATA TO FIRESTORE
export const FirebaseUploadJournalEntries = async (user, answers) => {
  // firebase
  //   .firestore()
  //   .collection('users')
  //   .doc(user)
  //   .collection('journals')
  //   .doc(date)
  //   .set(data, { merge: true })
  //   .then(() => {
  //     console.log('Journal Entries Stored!');
  //   })
  //   .catch((error) => {
  //     console.error('Error writing Journal Entries: ', error);
  //   });

  firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .set(
      {
        answers,
      },
      { merge: true },
    )
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('Answers stored!');
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error writing answers: ', error);
    });
};

export const FirebaseSaveBucketsLists = async (user, bucketsLists) => {
  // firebase
  //   .firestore()
  //   .collection('users')
  //   .doc(user)
  //   .collection('buckets')
  //   .doc(bucketId)
  //   .set(data, { merge: true })
  //   .then(() => {
  //     console.log('Bucket List Written Successfully!');
  //   })
  //   .catch((error) => {
  //     console.error('Error writing Bucket List: ', error);
  //   });

  firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .set(
      {
        bucketsLists,
      },
      { merge: true },
    )
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('Buckets stored!');
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error writing buckets: ', error);
    });
};

export const FirebaseSaveColors = async (
  user,
  recentColors,
  lifeInYearsColors,
  lifeInWeeksColors,
  yearsInWeeksColors,
) => {
  firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .set(
      {
        recentColors,
        lifeInYearsColors,
        lifeInWeeksColors,
        yearsInWeeksColors,
      },
      { merge: true },
    )
    .then(() => {
      // eslint-disable-next-line no-console
      console.log('Colors stored!');
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error writing active color: ', error);
    });
};

// FUNCTIONS FOR PULLING DATA FROM FIRESTORE
export function FirebaseFetchJournalEntries(user, date) {
  const [value] = useCollection(
    firebase
      .firestore()
      .collection('users')
      .doc(user)
      .collection('journals')
      .doc(date),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  if (value !== undefined) return value.data();
}

export function FirebaseFetchBucketListEntries(user) {
  const [value] = useCollection(
    firebase.firestore().collection('users').doc(user).collection('buckets'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  if (value !== undefined) return value.data();
}

export function FirebaseGetLifeExpectencyQuery(params) {
  const collection = firebase.firestore().collection('lifeExpectency');

  const query = collection
    .where('alcohol', '==', params.alcohol)
    .where('age', '==', params.age)
    .where('diabetes', '==', params.diabetes)
    .where('education', '==', params.education)
    .where('exercise', '==', params.exercise)
    .where('gender', '==', params.gender)
    .where('generalHealth', '==', params.generalHealth)
    .where('height', '==', params.height)
    .where('annualIncome', '==', params.annualIncome)
    .where('workStatus', '==', params.workStatus)
    .where('maritalStatus', '==', params.maritalStatus)
    .where('race', '==', params.race)
    .where('weight', '==', params.weight)
    .where('smoking', '==', params.smoking)
    .where('smokingAmount', '==', params.smokingAmount)
    .where('smokingQuit', '==', params.smokingQuit)
    .where('smokingStatus', '==', params.smokingStatus)
    .limit(1)
    .get();

  return query;
}

export function FirestoreSaveUserLifeExpectency(uid, lifeExpectency, birthday) {
  return firebase.firestore().collection('users').doc(uid).set(
    {
      lifeExpectency,
      birthday,
    },
    { merge: true },
  );
  // .then(() => {
  //   console.log('Life expectency stored!');
  // })
  // .catch((error) => {
  //   console.error('Error writing Life expectency: ', error);
  // });
}

export function FirestoreSaveLifeExpectency(params) {
  return firebase.firestore().collection('lifeExpectency').doc().set(params);
  // .then(() => {
  //   console.log('Longevity stored!');
  // })
  // .catch((error) => {
  //   console.error('Error writing Longevity: ', error);
  // });
}

export const provider = new firebase.auth.GoogleAuthProvider();

export const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const auth = firebase.auth();
  // useEffect(() => {
  //   if (
  //     user === undefined &&
  //     user?.isAnonymous === false &&
  //     window.location.pathname !== "/login" &&
  //     window.location.pathname !== "/register"
  //   ) {
  //     if (typeof window !== "undefined") {
  //       window.location = "/login";
  //     }
  //   }
  // }, [user]);
  // useEffect(()=>{return()=>{
  //   firebaseLogout();
  // }},[])
  // console.log(environment);
  return (
    <FirebaseContext.Provider
      value={{
        auth,
        user,
        loading,
        error,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
