import React from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
//import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { H1, Body4 } from '../Typography/Fonts';
import BackArrow from '../ReusableItems/BackArrow';
import NavigationBar from '../ReusableItems/NavigationBar';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import ShareModalComponent from '../Modals/ShareModal';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.div`
  padding: 0% 5% 0% 5%;
  margin-left: 200px;
  @media (max-width: 780px) {
    padding: 0%;
    margin-left: 0;
    padding-top: 20px;
  }
`;
const Heading = styled(H1)`
  margin: 0;
  line-height: 72px;
  background: transparent;
  text-transform: uppercase;
`;
const HeadingArea = styled.div`
  display: flex;
  width: 100%;
  padding: 3% 0% 0% 0%;
  justify-content: space-between;
  @media (max-width: 780px) {
    width: 90%;
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const SubHeading = styled(Heading)`
  font-size: 32px;
  line-height: 40px;
  margin: 40px 0px 40px 0px;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #262626;
    margin: 32px 0px 32px 0px;
    padding: 0% 5% 0% 5%;
  }
`;
const SubHeading2 = styled(Heading)`
  font-size: 24px;
  line-height: 32px;
  margin: 24px 0px 24px 0px;
  @media (max-width: 780px) {
    font-weight: bold;
    color: #262626;
    font-size: 12px;
    line-height: 15px;
    padding: 0% 5% 0% 5%;
    letter-spacing: 0.5px;
    margin: 24px 0px 16px 0px;
    text-transform: capitalize;
  }
`;
const BodyText = styled(Body4)`
  @media (max-width: 780px) {
    letter-spacing: 0.5px;
    margin: 0% 5% 0% 5%;
    line-height: 15px;
    font-size: 11px;
    color: #262626;
  }
`;
const List = styled.ul`
  padding: 0px 0px 0px 18px;
`;
const ListItem = styled.li`
  color: #57b010;
  @media (max-width: 780px) {
    margin: 0% 5% 0% 5%;
  }
`;
const BackgroundIllustration1 = styled.svg`
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  @media (max-width: 780px) {
    max-width: 90%;
  }
`;
const BackgroundIllustration2 = styled.svg`
  right: 0;
  z-index: -1;
  position: absolute;
  transform: translateY(150px);
  @media (max-width: 780px) {
    transform: translateY(70px);
    max-width: 90%;
  }
`;
const Icon = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  outline: 0;
  border: 0;
  border-radius: 10px;
  background: transparent;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  &:foucs {
    outline: 0;
  }
  @media (max-width: 780px) {
    transform: scale(0.6);
    position: absolute;
    right: 0;
    top: 30px;
    &:hover {
      transform: scale(0.7);
    }
  }
`;

const translations = [
  {
    English: 'About Us',
    Russian: 'О нас',
    Spanish: 'Sobre nosotros',
  },
  {
    English:
      'LIVEWEEKS is a free web application which helps you to live a meaningful life.',
    Russian:
      'LIVEWEEKS - это бесплатное веб-приложение, которое поможет вам жить осознанной жизнью.',
    Spanish:
      'LIVEWEEKS es una aplicación web gratuita que te ayuda a vivir una vida significativa.',
  },
  {
    English: 'Our application will help you:',
    Russian: 'Наше приложение поможет вам:',
    Spanish: 'Nuestra aplicación te ayudará:',
  },
  {
    English:
      'Enjoy life today, build your future and remember the joyful moments of the past',
    Russian:
      'Наслаждаться жизнью сегодня, строить своё будущее и вспоминать радостные моменты прошлого',
    Spanish:
      'Disfruta la vida hoy, construye tu futuro y recuerda los momentos felices del pasado.',
  },
  {
    English: 'Stay positive and emotionally healthy',
    Russian: 'Оставаться позитивными и эмоционально здоровыми',
    Spanish: 'Mantente positiva y emocionalmente sano',
  },
  {
    English: 'Visualize your life week by week, day by day',
    Russian: 'Визуализировать свою жизнь неделю за неделей, день за днем',
    Spanish: 'Visualiza tu vida semana a semana, día a día',
  },
  {
    English: 'Add notes every day to capture your life events',
    Russian:
      'Добавлять заметки каждый день, чтобы запечатлеть свои жизненные события',
    Spanish: 'Agrega notas todos los días para capturar los eventos de tu vida',
  },
  {
    English: 'Help to focus on the positive sides of your life in an easy way',
    Russian: 'Легко сосредоточиться на положительных сторонах вашей жизни',
    Spanish:
      'Ayuda a concentrarte en los aspectos positivos de tu vida de una manera fácil',
  },
  {
    English: 'Appreciate life more',
    Russian: 'Ценить жизнь больше',
    Spanish: 'Aprecia más la vida',
  },
  {
    English:
      'We developed 3 parts of the application that may be useful for you: calendar, journal and protection.',
    Russian:
      'Мы разработали 3 части приложения, которые могут быть вам полезны: календарь, дневник и защита.',
    Spanish:
      'Desarrollamos 3 partes de la aplicación que pueden serte útiles: calendario, diario y protección.',
  },
  {
    English: 'Calendar', //10
    Russian: 'Календарь',
    Spanish: 'Calendario',
  },
  {
    English:
      'Calendar is made up to overview your life and see your life in a different perspective. We display life in 90 years, 1 year = 52 weeks. Sometimes you feel that time passes so quickly - day by day, week by week. And now you have an opportunity to overview your life in weeks, years and days. For this we created 4 different views of the Calendar: Life in weeks, Life in Years, Years in Weeks and Life in Days.',
    Russian:
      'Календарь создан для того, чтобы посмотреть на свою жизнь с другой стороны. Мы отображаем жизнь в 90 годах, 1 год = 52 недели. Иногда кажется, что время летит так быстро - день за днем, неделя за неделей. И теперь у вас есть возможность просмотреть свою жизнь в неделях, годах и днях. Для этого мы создали 4 разных вида календаря: жизнь в неделях, жизнь в годах, годы в неделях и жизнь в днях.',
    Spanish:
      'El calendario está hecho para revisar tu vida y verla desde una perspectiva diferente. Mostramos la vida en 90 años, 1 año = 52 semanas. A veces sientes que el tiempo pasa muy rápido, día a día, semana a semana. Y ahora tiene la oportunidad de revisar su vida en semanas, años y días. Para ello creamos 4 vistas diferentes del Calendario: Vida en semanas, Vida en años, Años en semanas y Vida en días.',
  },
  {
    English:
      'Calendar shows you how many years you lived through and how many of them are left! Don’t lose your time and plan effectively to live all future years devoted to implementing your cherished dreams.',
    Russian:
      'Календарь покажет вам, сколько лет вы прожили и сколько из них осталось! Не теряйте время и эффективно планируйте как прожить все будущие годы, посвящая их воплощению заветной мечты. ',
    Spanish:
      '¡El calendario te muestra cuántos años has vivido y cuántos te quedan! No pierda su tiempo y planifique eficazmente vivir todos los años futuros dedicados a implementar sus preciados sueños.',
  },
  {
    English:
      'With the Brush you can paint your life in periods (like school, university years etc) and highlight some important events of your life like marriage, giving a birth to a child, some unforgettable trip or anything that happened during your life.',
    Russian:
      'С помощью кисточки вы можете разукрасить свою жизнь по периодам (например, детсад, школа, университетские годы и т. д.) и выделить некоторые важные события вашей жизни, такие как брак, рождение ребенка, незабываемое путешествие или все, что угодно, что произошло в вашей жизни.',
    Spanish:
      'Con el Pincel puedes pintar tu vida en períodos (como la escuela, los años universitarios, etc.) y resaltar algunos eventos importantes de tu vida como el matrimonio, el nacimiento de un hijo, algún viaje inolvidable o cualquier cosa que sucedió durante tu vida.',
  },
  {
    English:
      "Calendar can help to be self-aware and conscious about the deeds you do and time you spend and change the direction if something went wrong. It's never too late change the course of your life.",
    Russian:
      'Календарь может помочь вам осознанно посмотреть на свои поступки и время, которое вы тратите, и изменить направление, если что-то идёт не так. Никогда не поздно изменить ход своей жизни.',
    Spanish:
      'El calendario puede ayudarlo a ser consciente de sí mismo, a ser consciente de las acciones que realiza y del tiempo que dedica para luego cambiar la dirección si algo salió mal. Nunca es tarde para cambiar el curso de tu vida.',
  },
  {
    English: 'Journal', //15
    Russian: 'Дневник',
    Spanish: 'Diario',
  },
  {
    English:
      'Journal is a good practice to start your day by talking to yourself and focusing your attention on pleasant things in your life.',
    Russian:
      'Ведение дневника - это хорошая привычка, чтобы начать свой день с разговора с самим собой и сосредоточения внимания на приятных вещах в вашей жизни.',
    Spanish:
      'El diario es una buena práctica para comenzar el día hablando contigo mismo enfocando su atención en las cosas agradables de su vida.',
  },
  {
    English: 'Journal has 3 tabs: Now, Past and Future.',
    Russian: 'В дневнике есть 3 вкладки: «Сейчас», «Прошлое» и «Будущее».',
    Spanish: 'El diario tiene 3 pestañas: Ahora, Pasado y Futuro.',
  },
  {
    English:
      'In the Now tab you are to fill morning and evening powerful questions and fill free form notes “Your thoughts”. Morning questions can help you to feel motivated in the morning, practice gratefulness, give you a chance to remind what are the most important things in your life now, what are the current goals and what you are committed to. Evening powerful questions help you to concentrate and notice more positive things around you, analyze your day and understand how it adds to the quality of your life. In the “Your thoughts” section feel free to write anything, note pleasant memories, write the phrase of the day or your cherishing dreams.',
    Russian: `Во вкладке «Сейчас» вы можете заполнять утренние и вечерние вопросы или просто записывать ваши. 

    Утренние вопросы могут помочь вам почувствовать мотивацию утром, проявить благодарность, напомнить себе, что является самым важным в вашей жизни сейчас, каковы текущие цели и чему вы себя посвящаете. 
    
    Вечерние вопросы помогут вам сосредотачиваться и замечать больше положительных вещей вокруг вас, анализировать свой день и понять, как он улучшает качество вашей жизни. 
    
    В разделе «Ваши мысли» смело пишите что угодно, отметьте приятные воспоминания, напишите фразу дня или свои заветные мечты.
    `,
    Spanish:
      'En la pestaña Ahora, debe completar preguntas poderosas por la mañana y por la noche y completar las notas de formulario libre "Sus pensamientos". Las preguntas matutinas pueden ayudarte a sentirte motivado por la mañana, practicar el agradecimiento, darte la oportunidad de recordar cuáles son las cosas más importantes de tu vida ahora, cuáles son las metas actuales y con qué estás comprometido. Las preguntas poderosas de la noche lo ayudan a concentrarse y a notar más cosas positivas a su alrededor, analizar su día y comprender cómo se suma a la calidad de su vida. En la sección "Tus pensamientos", siéntete libre de escribir cualquier cosa, anotar recuerdos agradables, escribir la frase del día o tus preciados sueños.',
  },
  {
    English:
      'In the Past tab you can review your previous days notes, recollect how colorful your life actually is. And if it’s not that colorful, give you the power to take a brush and remind you that you are the painter of your life.',
    Russian:
      'Во вкладке «Прошлое» вы можете просмотреть свои записи за предыдущие дни, вспомнить, насколько красочна ваша жизнь на самом деле. А если она не такая уж и красочная, возьмите в руки кисточку и вспомните, что вы художник своей жизни.',
    Spanish:
      'En la pestaña Pasado puede revisar sus notas de días anteriores, recordar cuán colorida es su vida en realidad. Y si no es tan colorida, date el poder de tomar un pincel y recordarte que eres el pintor de tu vida.',
  },
  {
    //20
    English:
      'In the Future tab there is a place to fill your Bucket list (the dreams and goals you want to achieve during your whole life) and create any lists you need. It may be shopping lists, daily/weekly or monthly things to do. You just give the title to your list and write down all the things you need to accomplish.',
    Russian:
      'Во вкладке «Будущее» есть место, где можно заполнить список желаний (мечты и цели, которых вы хотите достичь на протяжении всей жизни) и создать любые списки, которые вам нужны. Это могут быть списки покупок, ежедневные/ еженедельные или ежемесячные дела. Вы просто даете название своему списку и записываете все, что планируете сделать. ',
    Spanish:
      'En la pestaña Futuro hay un lugar para completar su lista de deseos (los sueños y metas que desea lograr durante toda su vida) y crear las listas que necesite. Pueden ser listas de compras, actividades diarias / semanales o mensuales. Simplemente dé el título a su lista y escriba todas las cosas que necesita lograr.',
  },
  {
    English: 'Protect',
    Russian: 'Защита',
    Spanish: 'Proteger',
  },
  {
    English:
      'In the Protect tab you will find good pieces of advice about healthy lifestyle, effectiveness methods and information about life insurance to protect your loved ones from the worse.',
    Russian:
      'На вкладке «Защита» вы найдете полезные советы о здоровом образе жизни, методах эффективности и информацию о страховании жизни, чтобы защитить своих близких от худшего.',
    Spanish:
      'En la pestaña Proteger encontrará buenos consejos sobre estilos de vida saludables, métodos efectivos e información sobre seguros de vida para proteger a sus seres queridos de lo peor.',
  },
];

const About = () => {
  //const isMobile = useMediaQuery({ query: '(max-width: 780px)' });
  const { language } = React.useContext(SettingsContext);
  const openShareModal = () => {
    toast(<ShareModalComponent />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <div>
      <ToastContainer />
      <MetaTags>
        <title>Life Calendar and Journal | Liveweeks</title>
        <meta
          name="description"
          content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
        />
      </MetaTags>
      <Wrapper>
        <BackArrow />
        <BackgroundIllustration1
          width="389"
          height="827"
          viewBox="0 0 389 827"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M314.604 246.453C-99.2418 400.268 317.662 749.06 132.152 812.106C-53.3585 875.152 -272.443 724.12 -357.187 474.765C-441.932 225.41 -360.245 -27.8417 -174.735 -90.8881C10.7747 -153.935 591.467 143.551 314.604 246.453Z"
            fill="#E8F2EA"
          />
        </BackgroundIllustration1>

        <HeadingArea>
          <Heading>{translations[0][language]}</Heading>
          <Icon variant="share" onClick={openShareModal}>
            <img src="share-icon.png" alt="share-icon" />
          </Icon>
        </HeadingArea>
        <BodyText>{translations[1][language]}</BodyText>
        <SubHeading>{translations[2][language]}</SubHeading>
        <List>
          <ListItem>
            <BodyText>{translations[3][language]}</BodyText>
          </ListItem>
          <ListItem>
            <BodyText>{translations[4][language]}</BodyText>
          </ListItem>
          <ListItem>
            <BodyText>{translations[5][language]}</BodyText>
          </ListItem>
          <ListItem>
            <BodyText>{translations[6][language]}</BodyText>
          </ListItem>
          <ListItem>
            <BodyText>{translations[7][language]}</BodyText>
          </ListItem>
          <ListItem>
            <BodyText>{translations[8][language]}</BodyText>
          </ListItem>
        </List>

        <SubHeading>{translations[9][language]}</SubHeading>

        <BackgroundIllustration2
          width="565"
          height="990"
          viewBox="0 0 565 990"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M460.927 185.914C619.74 929.44 -71.0606 592.349 6.34805 920.815C83.7567 1249.28 412.783 1452.8 741.249 1375.39C1069.71 1297.99 1273.24 968.959 1195.83 640.493C1118.42 312.027 354.682 -311.506 460.927 185.914Z"
            fill="#FCF2E6"
          />
        </BackgroundIllustration2>

        <SubHeading2>{translations[10][language]}</SubHeading2>
        <BodyText>{translations[11][language]}</BodyText>
        <BodyText>{translations[12][language]}</BodyText>
        <BodyText>{translations[13][language]}</BodyText>
        <BodyText>{translations[14][language]}</BodyText>

        <SubHeading2>{translations[15][language]}</SubHeading2>
        <BodyText>{translations[16][language]}</BodyText>
        <BodyText>{translations[17][language]}</BodyText>
        <BodyText>{translations[18][language]}</BodyText>
        <BodyText>{translations[19][language]}</BodyText>
        <BodyText>{translations[20][language]}</BodyText>

        <SubHeading2>{translations[21][language]}</SubHeading2>
        <BodyText>{translations[22][language]}</BodyText>
      </Wrapper>
      <NavigationBar />
      <VerticalNavigationBar />
    </div>
  );
};

export default About;
