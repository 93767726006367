import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { SliderPicker } from 'react-color';
import styled from 'styled-components';
import { Context } from '../Context/globalContextProvider';
import { H1 } from '../Typography/Fonts';
import { FirebaseSaveColors } from '../Context/FirebaseContext';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #ffffff;
  height: 165px;
  box-sizing: border-box;
  padding: 30px 26px 40px 26px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
  @media (min-width: 900px) {
    display: none;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;
const Heading = styled(H1)`
  line-height: 22px;
  margin: 0;
  font-size: 16px;
  text-align: center;
  background: transparent;
  text-transform: uppercase;
  @media (max-width: 780px) {
    padding-left: 0;
  }
`;
// const Heading2 = styled(Heading)`
//   text-transform: none;
//   font-size: 16px;
//   line-height: 20px;
//   text-align: left;
// `;
const Recents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
const Color = styled.div`
  background: ${(props) => props.shade};
  border-radius: 50%;
  height: 23px;
  width: 23px;
`;

const translations = [
  {
    English: 'Color your life',
    Russian: 'Pаскрась свою жизнь',
    Spanish: 'Colorea tu vida',
  },
];

const MobilePaintSelection = () => {
  const {
    activeColor,
    recentColors,
    togglePaint,
    setActiveColor,
    user,
    lifeInYearsColors,
    lifeInWeeksColors,
    yearsInWeeksColors,
  } = React.useContext(Context);
  const { language } = React.useContext(SettingsContext);
  const [circleColor, setCircleColor] = React.useState(activeColor);

  const saveColors = React.useCallback(() => {
    if (user?.uid) {
      FirebaseSaveColors(
        user,
        recentColors,
        lifeInYearsColors,
        lifeInWeeksColors,
        yearsInWeeksColors,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeInWeeksColors, lifeInYearsColors, recentColors, yearsInWeeksColors]);

  return (
    <Wrapper>
      <HeadingArea>
        <svg
          style={{ opacity: 0.7 }}
          width="30"
          height="28"
          viewBox="0 0 30 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30 3.4798C30 2.80469 29.7373 2.16971 29.2594 1.69105C28.7893 1.22137 28.1646 0.958573 27.5002 0.951073C27.4904 0.950956 27.4808 0.950897 27.4711 0.950897C26.8174 0.950897 26.1981 1.19904 25.7244 1.65174L10.6468 16.0687C10.5882 16.064 10.5294 16.0601 10.4702 16.0577C9.1759 16.0036 7.85461 16.4121 6.74818 17.2082C5.91621 17.8068 5.44148 18.5464 4.9824 19.2617C4.77574 19.5837 4.58057 19.8877 4.35428 20.1779C3.49922 21.2742 2.07281 21.7835 0.925723 22.1047L0 22.3639L0.340195 23.263C1.28953 25.772 4.57125 26.9829 7.21869 27.0462C7.29996 27.0481 7.38164 27.0491 7.46408 27.0491C10.0359 27.0491 13.1597 26.0912 14.4884 23.3792C14.9637 22.4091 15.1121 21.3043 14.9425 20.2414L29.2994 5.22648C29.7512 4.75387 30 4.13348 30 3.4798ZM12.9085 22.6051C11.9016 24.6604 9.23631 25.3352 7.26076 25.2873C5.86318 25.2539 3.59496 24.7189 2.48467 23.4552C3.63574 23.0378 4.85801 22.3928 5.74166 21.2598C6.01764 20.906 6.2441 20.5532 6.46313 20.212C6.86707 19.5827 7.21588 19.0391 7.7758 18.6362C8.56389 18.0692 9.49395 17.7776 10.3967 17.8155C11.7324 17.8714 12.4455 18.7429 12.7516 19.2575C13.3594 20.2793 13.4196 21.562 12.9085 22.6051ZM28.0277 4.01078L14.2806 18.3878C14.2748 18.3779 14.2695 18.3679 14.2635 18.358C13.8319 17.6324 13.2507 17.0578 12.5703 16.6637L26.9401 2.92351C27.2449 2.63213 27.7176 2.63746 28.0151 2.93488C28.1605 3.08049 28.2406 3.27408 28.2406 3.4798C28.2407 3.67855 28.165 3.86711 28.0277 4.01078Z"
            fill="#2A75BA"
          />
        </svg>
        <Heading>{translations[0][language]}</Heading>
        <svg
          onClick={() => {
            togglePaint(false);
            saveColors();
          }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="#2A75BA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="#2A75BA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </HeadingArea>
      <div style={{ marginTop: '5px' }}>
        <SliderPicker
          color={circleColor}
          onChange={(color) => {
            setCircleColor(color.hex);
            setActiveColor(color.hex);
          }}
        />
      </div>
      <Recents style={{ marginTop: '5px' }}>
        {recentColors.map((color) => (
          <Color
            shade={color}
            key={color}
            onClick={() => {
              setCircleColor(color);
              setActiveColor(color);
            }}
          />
        ))}
      </Recents>
    </Wrapper>
  );
};

export default MobilePaintSelection;
