import React from 'react';

export const CalculateYearsContext = React.createContext();
export const CalculateYearsProvider = ({ children }) => {
  // Personal Info
  const [birth, setBirth] = React.useState(0);
  const [age, setAge] = React.useState(0);
  const [residenceCountry, setResidenceCountry] = React.useState(0);
  const [Gender, setGender] = React.useState('Male');
  const [race, setRace] = React.useState('White');
  // Physique
  const [usUnits, setUsUnits] = React.useState(false);
  const [weight, setWeight] = React.useState(0);
  const [Height, setHeight] = React.useState(0);
  // Life Events
  const [education, setEducation] = React.useState('HIGH_SCHOOL');
  const [maritalStatus, setMaritalStatus] = React.useState('NEVER_MARRIED');
  const [workStatus, setWorkStatus] = React.useState('WORKING');
  const [annualIncome, setAnnualIncome] = React.useState('40MINUS');
  // Fitness
  const [exercise, setExercise] = React.useState(0);
  const [generalHealth, setGeneralHealth] = React.useState(0);
  const [diabetes, setDiabetes] = React.useState(false);
  // Lifestyle
  const [alcohol, setAlcohol] = React.useState(0);
  const [smokingNow, setSmokingNow] = React.useState(0);
  const [smoking, setSmoking] = React.useState(0);
  const [smokingQuit, setSmokingQuit] = React.useState(false);
  const [smokingWhenQuit, setSmokingWhenQuit] = React.useState(0);
  const [smokingAmount, setSmokingAmount] = React.useState(0);
  const [smokingStatus, setSmokingStatus] = React.useState('NON_SMOKER');

  return (
    <CalculateYearsContext.Provider
      value={{
        birth,
        setBirth,
        age,
        setAge,
        residenceCountry,
        setResidenceCountry,
        Gender,
        setGender,
        race,
        setRace,
        weight,
        setWeight,
        Height,
        setHeight,
        education,
        setEducation,
        maritalStatus,
        setMaritalStatus,
        workStatus,
        setWorkStatus,
        annualIncome,
        setAnnualIncome,
        exercise,
        setExercise,
        generalHealth,
        setGeneralHealth,
        diabetes,
        setDiabetes,
        alcohol,
        setAlcohol,
        smoking,
        setSmoking,
        smokingQuit,
        setSmokingQuit,
        smokingNow,
        setSmokingNow,
        smokingWhenQuit,
        setSmokingWhenQuit,
        smokingAmount,
        setSmokingAmount,
        smokingStatus,
        setSmokingStatus,
        usUnits,
        setUsUnits,
      }}
    >
      {children}
    </CalculateYearsContext.Provider>
  );
};
