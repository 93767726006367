import React from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
// import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
import Section2Data from './Section2Data';
import Section3Data from './Section3Data';
import Section4Data from './Section4Data';
import CompaniesData from './CompaniesData';
import { H1, Body4 } from '../Typography/Fonts';
import BackArrow from '../ReusableItems/BackArrow';
import InsuranceCard from '../ReusableItems/InsuranceCard';
import NavigationBar from '../ReusableItems/NavigationBar';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import ShareModalComponent from '../Modals/ShareModal';

const Wrapper = styled.div`
  padding: 0% 5% 8% 5%;
  margin-left: 200px;
  @media (max-width: 780px) {
    padding: 0%;
    margin-left: 0;
    padding-top: 20px;
    padding-bottom: 100px;
  }
`;
const Heading = styled(H1)`
  margin: 0;
  line-height: 72px;
  background: transparent;
  text-transform: uppercase;
`;
const HeadingArea = styled.div`
  display: flex;
  width: 100%;
  padding: 3% 0% 0% 0%;
  justify-content: space-between;
  @media (max-width: 780px) {
    width: 90%;
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const SubHeading = styled(Heading)`
  font-size: 32px;
  line-height: 40px;
  margin: 64px 0px 32px 0px;
  @media (max-width: 780px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #262626;
    margin: 32px 0px 32px 0px;
    padding: 0% 5% 0% 5%;
  }
`;
const SubHeading2 = styled(Heading)`
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 780px) {
    font-weight: bold;
    color: #262626;
    font-size: 12px;
    line-height: 15px;
    padding: 0% 5% 0% 5%;
    letter-spacing: 0.5px;
    margin: 24px 0px 16px 0px;
    text-transform: capitalize;
  }
`;
const BodyText = styled(Body4)`
  @media (max-width: 780px) {
    letter-spacing: 0.5px;
    margin: 0% 5% 0% 5%;
    line-height: 15px;
    font-size: 11px;
    color: #262626;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 780px) {
    flex-direction: column;
    width: 100%;
  }
`;
const SubSection = styled.div`
  width: 50%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const CardSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 780px) {
    display: none;
  }
`;
const MobileCardSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: -30px;
  flex-wrap: nowrap;
  @media (min-width: 780px) {
    display: none;
  }
`;
const MobileIconArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0% 5% 5% 5%;
  overflow-x: scroll;
  position: relative;
  z-index: 1000;
  @media (min-width: 780px) {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  ${(props) =>
    props.variant === 'purple'
      ? 'justify-content: center; margin-left: 18px;'
      : 'align-items: center;'}
`;
const MobileIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 104px;
  height: 104px;
  margin: 9px;
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  background: #ffffff;
  ${(props) => {
    switch (props.variant) {
      case 'green':
        return 'color: #57B010;';
      case 'purple':
        return 'color: #7C40A9;';
      default:
        return 'color: #2A75BA;';
    }
  }}
  box-shadow: ${(props) =>
    props.active
      ? '0px 6px 34px rgba(0, 0, 0, 0.1)'
      : '0px 6px 34px rgba(0, 0, 0, 0.1)'};
  @media (min-width: 780px) {
    display: none;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 17px;
  }
`;
const MobileIconImg = styled.img`
  opacity: ${(props) => (props.active ? '1' : '0.5')};
`;
const MobileIconText = styled.h5`
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  opacity: ${(props) => (props.active ? '1' : '0.5')};
`;
const BackgroundIllustration1 = styled.svg`
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  @media (max-width: 780px) {
    max-width: 90%;
  }
`;
const BackgroundIllustration2 = styled.svg`
  left: 0;
  z-index: -1;
  position: absolute;
  transform: translateY(-50%);
  @media (max-width: 780px) {
    max-width: 90%;
    display: none;
  }
`;
const BackgroundIllustration3 = styled.svg`
  right: 0;
  z-index: -1;
  position: absolute;
  @media (max-width: 780px) {
    max-width: 90%;
  }
`;
const Illustration = styled.img`
  width: 45%;
  height: 100%;
  max-width: 680px;
  @media (max-width: 780px) {
    box-sizing: border-box;
    width: 100%;
    padding: 10% 5% 5% 5%;
  }
`;
const Icon = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  outline: 0;
  border: 0;
  border-radius: 10px;
  background: transparent;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  &:foucs {
    outline: 0;
  }
  @media (max-width: 780px) {
    transform: scale(0.6);
    position: absolute;
    right: 0;
    top: 30px;
    &:hover {
      transform: scale(0.7);
    }
  }
`;

const LifeInsurance = () => {
  const [activeCard1, setActiveCard1] = React.useState(0);
  const [activeCard2, setActiveCard2] = React.useState(0);
  const [activeCard3, setActiveCard3] = React.useState(0);
  //const isMobile = useMediaQuery({ query: '(max-width: 780px)' });

  const openShareModal = () => {
    toast(<ShareModalComponent />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <div>
      <ToastContainer />
      <MetaTags>
        <title>Life Calendar and Journal | Liveweeks</title>
        <meta
          name="description"
          content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
        />
      </MetaTags>
      <Wrapper>
        <BackArrow />
        <BackgroundIllustration1
          width="389"
          height="827"
          viewBox="0 0 389 827"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M314.604 246.453C-99.2418 400.268 317.662 749.06 132.152 812.106C-53.3585 875.152 -272.443 724.12 -357.187 474.765C-441.932 225.41 -360.245 -27.8417 -174.735 -90.8881C10.7747 -153.935 591.467 143.551 314.604 246.453Z"
            fill="#E8F2EA"
          />
        </BackgroundIllustration1>
        <HeadingArea>
          <Heading>Life Insurance</Heading>
          <Icon variant="share" onClick={openShareModal}>
            <img src="share-icon.png" alt="share-icon" />
          </Icon>
        </HeadingArea>
        <BodyText>Small commitment to protection from the worse</BodyText>
        <Section>
          <SubSection>
            <SubHeading>What is life insurance</SubHeading>
            <BodyText>
              Each person in his life sooner or later asks questions related to
              planning the future, for example: How to save money for education
              for a child? Where to get money if you need serious treatment? How
              to ensure the financial well-being of the family in the event of
              the loss of the breadwinner?
            </BodyText>
            <BodyText>
              Life insurance programs provide answers to these and other
              questions, helping to prepare for happy events (weddings,
              children&#39;s education, etc.) and create a financial cushion in
              case of sudden unpleasant events. Life insurance is adjusted to
              the goals, needs and budget of each individual person at a certain
              point in his life and allows you to solve a whole range of
              financial issues.
            </BodyText>
            <BodyText>
              So, life insurance is a type of insurance that helps to compensate
              for sudden losses in difficult life situations arising from a
              disease or an accident. Life insurance is a long-term investment
              with your protection and a guarantee of help to your family,
              relatives and friends in case of an emergency.
            </BodyText>
          </SubSection>
          <Illustration src="/insurance-image-1.png" alt="life-insurance" />
        </Section>

        <SubHeading>Who needs life insurance</SubHeading>
        <CardSection>
          {Section2Data.map((cardData) => (
            <InsuranceCard
              key={cardData.id}
              variant="top"
              cardData={cardData}
            />
          ))}
        </CardSection>
        <MobileCardSection>
          <InsuranceCard variant="top" cardData={Section2Data[activeCard1]} />
        </MobileCardSection>
        <MobileIconArea>
          {Section2Data.map((cardData, index) => (
            <MobileIcon
              key={cardData.heading}
              active={index === activeCard1}
              onClick={() => setActiveCard1(index)}
            >
              <MobileIconImg
                active={index === activeCard1}
                src={cardData.icon}
                width="35px"
                alt="card-illustration"
              />
              <MobileIconText active={index === activeCard1}>
                {cardData.heading}
              </MobileIconText>
            </MobileIcon>
          ))}
        </MobileIconArea>

        <BackgroundIllustration2
          width="648"
          height="1392"
          viewBox="0 0 648 1392"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-104.073 185.914C54.7398 929.44 -636.061 592.349 -558.652 920.815C-481.243 1249.28 -152.217 1452.8 176.249 1375.39C504.715 1297.99 708.237 968.959 630.828 640.493C553.42 312.027 -210.318 -311.506 -104.073 185.914Z"
            fill="#57B010"
            fillOpacity="0.2"
          />
        </BackgroundIllustration2>

        <SubHeading>Types of life insurance</SubHeading>
        <BodyText style={{ marginBottom: '40px' }}>
          Having insurance gives you confidence in the future, financial
          protection, from everything, no matter what happens in life.
        </BodyText>

        <CardSection>
          {Section3Data.map((cardData, index) => (
            <InsuranceCard
              key={cardData.id}
              size={index < 3 ? 'normal' : 'small'}
              cardData={cardData}
            />
          ))}
        </CardSection>
        <MobileCardSection>
          <InsuranceCard cardData={Section3Data[activeCard2]} />
        </MobileCardSection>
        <MobileIconArea>
          {Section3Data.map((cardData, index) => (
            <MobileIcon
              key={cardData.heading}
              active={index === activeCard2}
              variant="green"
              onClick={() => setActiveCard2(index)}
            >
              <MobileIconImg
                active={index === activeCard2}
                src={cardData.icon}
                width="30px"
                alt="card-illustration"
              />
              <MobileIconText active={index === activeCard2}>
                {cardData.heading}
              </MobileIconText>
            </MobileIcon>
          ))}
        </MobileIconArea>

        <BackgroundIllustration3
          width="276"
          height="495"
          viewBox="0 0 276 495"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M489.232 157.885C191.853 256.888 298.432 -41.5227 167.391 5.18387C36.3496 51.8905 -32.0171 195.984 14.6895 327.025C61.3961 458.066 205.489 526.433 336.53 479.726C467.572 433.02 688.178 91.6524 489.232 157.885Z"
            fill="#7CA9D2"
            fillOpacity="0.4"
          />
        </BackgroundIllustration3>
        <SubHeading>
          Two single policies or a joint policy for a couple
        </SubHeading>
        <BodyText style={{ marginBottom: '40px' }}>
          If you are a married couple and you&#39;re thinking together about
          taking out life insurance, which option will you choose - single or
          joint policy?
        </BodyText>

        <CardSection>
          {Section4Data.map((cardData) => (
            <InsuranceCard key={cardData.id} size="large" cardData={cardData} />
          ))}
        </CardSection>
        <MobileCardSection>
          <InsuranceCard cardData={Section4Data[activeCard3]} />
        </MobileCardSection>
        <MobileIconArea variant="purple">
          {Section4Data.map((cardData, index) => (
            <MobileIcon
              key={cardData.heading}
              active={index === activeCard3}
              variant="purple"
              onClick={() => setActiveCard3(index)}
            >
              <MobileIconImg
                active={index === activeCard3}
                src={cardData.icon}
                width="30px"
                alt="card-illustration"
              />
              <MobileIconText active={index === activeCard3}>
                {cardData.heading}
              </MobileIconText>
            </MobileIcon>
          ))}
        </MobileIconArea>

        <Section>
          <SubSection>
            <SubHeading>Stay healthy to have cheaper cover</SubHeading>
            <BodyText>
              The price of premiums you will pay to an insurance company depends
              on your age, occupation, habits, health conditions and being a
              smoker. So if you are 92-years-old smoking granny who likes hiking
              and surfing on waves you probably will never receive a low price
              for your policy as the risk of your death is high.
            </BodyText>
            <BodyText>
              It’s important to reveal all your health history to the insurer.
              It may happen that you hide some facts from them during your
              application and then it appears not to be the truth, your family
              may not receive compensation at all. So be honest when filling an
              life insurance application.
            </BodyText>
            <BodyText>
              The price of the policy is really jumping higher if you are a
              smoker, so not smoking is a good decision and not only to reduce
              the price of the policy but also to keep your health on a good
              level and live longer and happier. If you give up smoking during
              the existing policy it’s better to break up your old policy and
              start a new one as it would be cheaper for you (usually after one
              year of not smoking).
            </BodyText>
            <BodyText>
              Having regular training, eating fresh fruits and vegetables and
              drinking alcohol moderately is also important and is tracked by
              insurance companies. So stay healthy and get cheaper cover!
            </BodyText>
          </SubSection>
          <Illustration
            src="/insurance-image-2.png"
            alt="stay-healthy"
            width="45%"
            height="100%"
            style={{ maxWidth: '680px' }}
          />
        </Section>

        <SubHeading>Companies providing life insurance</SubHeading>
        <BodyText>Where can I get life insurance</BodyText>

        {CompaniesData.map((company) => (
          <div key={company.id}>
            <SubHeading2>
              {company.id} {company.heading}
            </SubHeading2>
            <BodyText>{company.paragraph}</BodyText>
          </div>
        ))}
      </Wrapper>
      <NavigationBar />
      <VerticalNavigationBar />
    </div>
  );
};
export default LifeInsurance;
