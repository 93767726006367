import React from 'react';
import styled from 'styled-components';
import AutoHeightTextarea from './TextArea';
import { Body4 } from '../Typography/Fonts';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 10px;
  padding: 18px 26px 18px 26px;
  transform: translateY(-10px);
  background: #e2f1ff;
  border-radius: 10px;
  @media (max-width: 780px) {
    padding: 20px;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  @media (max-width: 780px) {
    justify-content: space-between;
    padding-left: 0px;
  }
`;
const Arrow = styled.svg`
  justify-self: flex-end;
  margin-left: auto;
  cursor: pointer;
  padding: 12px;
  width: 15px;
  height: 8px;
  @media (max-width: 780px) {
    padding: 0;
  }
`;
const BodyText = styled(Body4)`
  @media (max-width: 780px) {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #333333;
  }
`;
const BodyHeading = styled(BodyText)`
  padding-left: 33px;
  cursor: pointer;
  @media (max-width: 780px) {
    padding: 0px 10px 0px 15px;
  }
`;
const Icon = styled.svg``;

const translations = [
  {
    English: 'What did I learn Today?',
    Russian: 'Что я сегодня узнал/а?',
    Spanish: '¿Qué aprendí hoy?',
  },
  {
    English: 'How has today added quality of my life?',
    Russian: 'Как сегодня улучшилось качество моей жизни?',
    Spanish: '¿Cómo hoy se ha agregado calidad a mi vida?',
  },
  {
    English: 'Three positive things that happened to me today',
    Russian: 'Три положительных вещи, которые произошли со мной сегодня',
    Spanish: 'Tres cosas positivas que me pasaron hoy',
  },
];

function EveningQuestions({ answers, saveJournal }) {
  const [expanded, toggleExpand] = React.useState(false);
  const { language } = React.useContext(SettingsContext);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const onChangeHandler = (text, name) => {
    saveJournal(name, text);
  };
  const optimisedHandleChange = debounce(onChangeHandler, 25);

  const onEnterKeyHandler = (event, name) => {
    if (event.key === 'Enter') {
      console.log('onEnterKeyHandler: ', name);
    }
  };

  let expandedContent = null;
  if (expanded) {
    expandedContent = (
      <div>
        <AutoHeightTextarea
          name="a4"
          fieldValue={answers?.a4 ?? ''}
          onChangeHandler={optimisedHandleChange}
          onEnterKeyHandler={onEnterKeyHandler}
          bgColor="#E2F1FF"
        />
        <BodyText>{translations[1][language]}</BodyText>
        <AutoHeightTextarea
          name="a5"
          fieldValue={answers?.a5 ?? ''}
          onChangeHandler={optimisedHandleChange}
          onEnterKeyHandler={onEnterKeyHandler}
          bgColor="#E2F1FF"
        />
        <BodyText>{translations[2][language]}</BodyText>
        <AutoHeightTextarea
          name="a6"
          fieldValue={answers?.a6 ?? ''}
          onChangeHandler={optimisedHandleChange}
          onEnterKeyHandler={onEnterKeyHandler}
          bgColor="#E2F1FF"
        />
      </div>
    );
  }

  return (
    <Wrapper>
      <HeadingArea>
        <Icon
          width="16px"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.97959 5.11136C3.97959 3.2207 4.53631 1.46281 5.49098 0C2.26321 1.40434 0 4.6764 0 8.48861C0 13.5814 4.03866 17.71 9.0205 17.71C12.1526 17.71 14.9121 16.0778 16.5293 13.6C15.445 14.0716 14.2525 14.3327 13.0001 14.3327C8.01825 14.3327 3.97959 10.2041 3.97959 5.11136Z"
            fill="#2A75BA"
          />
        </Icon>
        <BodyHeading onClick={() => toggleExpand(!expanded)}>
          {translations[0][language]}
        </BodyHeading>
        <Arrow
          onClick={() => toggleExpand(!expanded)}
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.514893 1.46495L8.99989 9.94995L17.4849 1.46495L16.0709 0.0499516L8.99989 7.12195L1.92889 0.0499516L0.514893 1.46495Z"
            fill="#57B010"
          />
        </Arrow>
      </HeadingArea>
      {expandedContent}
    </Wrapper>
  );
}

export default EveningQuestions;
