export const countriesList = [
  { English: 'Afghanistan', Spanish: 'Afganistán', Russian: 'Афганистан' },
  { English: 'Albania', Spanish: 'Albania', Russian: 'Албания' },
  { English: 'Algeria', Spanish: 'Argelia', Russian: 'Алжир' },
  { English: 'Andorra', Spanish: 'Andorra', Russian: 'Андорра' },
  { English: 'Angola', Spanish: 'Angola', Russian: 'Ангола' },
  {
    English: 'Antigua and Barbuda',
    Spanish: 'Antigua y Barbuda',
    Russian: 'Антигуа и Барбуда',
  },
  { English: 'Argentina', Spanish: 'Argentina', Russian: 'Аргентина' },
  { English: 'Armenia', Spanish: 'Armenia', Russian: 'Армения' },
  { English: 'Australia', Spanish: 'Australia', Russian: 'Австралия' },
  { English: 'Austria', Spanish: 'Austria', Russian: 'Австрия' },
  { English: 'Azerbaijan', Spanish: 'Azerbaiyán', Russian: 'Азербайджан' },
  { English: 'Bahamas', Spanish: 'Bahamas', Russian: 'Багамские Острова' },
  { English: 'Bahrain', Spanish: 'Baréin', Russian: 'Бахрейн' },
  { English: 'Bangladesh', Spanish: 'Bangladés', Russian: 'Бангладеш' },
  { English: 'Barbados', Spanish: 'Barbados', Russian: 'Барбадос' },
  { English: 'Belarus', Spanish: 'Bielorrusia', Russian: 'Белоруссия' },
  { English: 'Belgium', Spanish: 'Bélgica', Russian: 'Бельгия' },
  { English: 'Belize', Spanish: 'Belice', Russian: 'Белиз' },
  { English: 'Benin', Spanish: 'Benín', Russian: 'Бенин' },
  { English: 'Bhutan', Spanish: 'Bután', Russian: 'Бутан' },
  { English: 'Bolivia', Spanish: 'Bolivia', Russian: 'Боливия' },
  {
    English: 'Bosnia and Herzegovina',
    Spanish: 'Bosnia-Herzegovina',
    Russian: 'Босния и Герцеговина',
  },
  { English: 'Botswana', Spanish: 'Botsuana', Russian: 'Ботсвана' },
  { English: 'Brazil', Spanish: 'Brasil', Russian: 'Бразилия' },
  { English: 'Brunei', Spanish: 'Brunéi', Russian: 'Бруней' },
  { English: 'Bulgaria', Spanish: 'Bulgaria', Russian: 'Болгария' },
  { English: 'Burkina Faso', Spanish: 'Burkina Faso', Russian: 'Буркина-Фасо' },
  { English: 'Burundi', Spanish: 'Burundi', Russian: 'Бурунди' },
  { English: 'Cambodia', Spanish: 'Camboya', Russian: 'Камбоджа' },
  { English: 'Cameroon', Spanish: 'Camerún', Russian: 'Камерун' },
  { English: 'Canada', Spanish: 'Canadá', Russian: 'Канада' },
  { English: 'Cape Verde', Spanish: 'Cabo Verde', Russian: 'Кабо-Верде' },
  {
    English: 'Central African Republic',
    Spanish: 'República Centroafricana',
    Russian: 'Центральноафриканская Республика',
  },
  { English: 'Chad', Spanish: 'Chad', Russian: 'Чад' },
  { English: 'Chile', Spanish: 'Chile', Russian: 'Чили' },
  { English: 'China', Spanish: 'China', Russian: 'Китай' },
  { English: 'Colombia', Spanish: 'Colombia', Russian: 'Колумбия' },
  { English: 'Comoros', Spanish: 'Comoras', Russian: 'Коморские острова' },
  { English: 'Costa Rica', Spanish: 'Costa Rica', Russian: 'Коста-Рика' },
  {
    English: 'Côte d’Ivoire',
    Spanish: 'Costa de Marfil',
    Russian: 'Кот-д’Ивуар',
  },
  { English: 'Croatia', Spanish: 'Croacia', Russian: 'Хорватия' },
  { English: 'Cuba', Spanish: 'Cuba', Russian: 'Куба' },
  { English: 'Cyprus', Spanish: 'Chipre', Russian: 'Кипр' },
  { English: 'Czech Republic', Spanish: 'República Checa', Russian: 'Чехия' },
  {
    English: 'Democratic Republic of the Congo',
    Spanish: 'República Democrática del Congo',
    Russian: 'Демократическая Республика Конго',
  },
  { English: 'Denmark', Spanish: 'Dinamarca', Russian: 'Дания' },
  { English: 'Djibouti', Spanish: 'Yibuti', Russian: 'Джибути' },
  { English: 'Dominica', Spanish: 'Dominica', Russian: 'Доминика' },
  {
    English: 'Dominican Republic',
    Spanish: 'República Dominicana',
    Russian: 'Доминиканская Республика',
  },
  {
    English: 'East Timor',
    Spanish: 'Timor Oriental',
    Russian: 'Восточный Тимор',
  },
  { English: 'Ecuador', Spanish: 'Ecuador', Russian: 'Эквадор' },
  { English: 'Egypt', Spanish: 'Egipto', Russian: 'Египет' },
  { English: 'El Salvador', Spanish: 'El Salvador', Russian: 'Сальвадор' },
  {
    English: 'Equatorial Guinea',
    Spanish: 'Guinea Ecuatorial',
    Russian: 'Экваториальная Гвинея',
  },
  { English: 'Eritrea', Spanish: 'Eritrea', Russian: 'Эритрея' },
  { English: 'Estonia', Spanish: 'Estonia', Russian: 'Эстония' },
  { English: 'Ethiopia', Spanish: 'Etiopía', Russian: 'Эфиопия' },
  { English: 'Fiji', Spanish: 'Fiyi', Russian: 'Фиджи' },
  { English: 'Finland', Spanish: 'Finlandia', Russian: 'Финляндия' },
  { English: 'France', Spanish: 'Francia', Russian: 'Франция' },
  { English: 'Gabon', Spanish: 'Gabón', Russian: 'Габон' },
  { English: 'Gambia', Spanish: 'Gambia', Russian: 'Гамбия' },
  { English: 'Georgia', Spanish: 'Georgia', Russian: 'Грузия' },
  { English: 'Germany', Spanish: 'Alemania', Russian: 'Германия' },
  { English: 'Ghana', Spanish: 'Ghana', Russian: 'Гана' },
  { English: 'Greece', Spanish: 'Grecia', Russian: 'Греция' },
  { English: 'Grenada', Spanish: 'Granada', Russian: 'Гренада' },
  { English: 'Guatemala', Spanish: 'Guatemala', Russian: 'Гватемала' },
  { English: 'Guinea', Spanish: 'Guinea', Russian: 'Гвинея' },
  {
    English: 'Guinea-Bissau',
    Spanish: 'Guinea-Bisáu',
    Russian: 'Гвинея-Бисау',
  },
  { English: 'Guyana', Spanish: 'Guyana', Russian: 'Гайана' },
  { English: 'Haiti', Spanish: 'Haití', Russian: 'Гаити' },
  { English: 'Honduras', Spanish: 'Honduras', Russian: 'Гондурас' },
  { English: 'Hungary', Spanish: 'Hungría', Russian: 'Венгрия' },
  { English: 'Iceland', Spanish: 'Islandia', Russian: 'Исландия' },
  { English: 'India', Spanish: 'India', Russian: 'Индия' },
  { English: 'Indonesia', Spanish: 'Indonesia', Russian: 'Индонезия' },
  { English: 'Iran', Spanish: 'Irán', Russian: 'Иран' },
  { English: 'Iraq', Spanish: 'Irak', Russian: 'Ирак' },
  { English: 'Ireland', Spanish: 'Irlanda', Russian: 'Ирландия' },
  { English: 'Israel', Spanish: 'Israel', Russian: 'Израиль' },
  { English: 'Italy', Spanish: 'Italia', Russian: 'Италия' },
  { English: 'Jamaica', Spanish: 'Jamaica', Russian: 'Ямайка' },
  { English: 'Japan', Spanish: 'Japón', Russian: 'Япония' },
  { English: 'Jordan', Spanish: 'Jordania', Russian: 'Иордания' },
  { English: 'Kazakhstan', Spanish: 'Kazajistán', Russian: 'Казахстан' },
  { English: 'Kenya', Spanish: 'Kenia', Russian: 'Кения' },
  { English: 'Kiribati', Spanish: 'Kiribati', Russian: 'Кирибати' },
  { English: 'Kuwait', Spanish: 'Kuwait', Russian: 'Кувейт' },
  { English: 'Kyrgyzstan', Spanish: 'Kirguistán', Russian: 'Киргизия' },
  { English: 'Laos', Spanish: 'Laos', Russian: 'Лаос' },
  { English: 'Latvia', Spanish: 'Letonia', Russian: 'Латвия' },
  { English: 'Lebanon', Spanish: 'Líbano', Russian: 'Ливан' },
  { English: 'Lesotho', Spanish: 'Lesoto', Russian: 'Лесото' },
  { English: 'Liberia', Spanish: 'Liberia', Russian: 'Либерия' },
  { English: 'Libya', Spanish: 'Libia', Russian: 'Ливия' },
  {
    English: 'Liechtenstein',
    Spanish: 'Liechtenstein',
    Russian: 'Лихтенштейн',
  },
  { English: 'Lithuania', Spanish: 'Lituania', Russian: 'Литва' },
  { English: 'Luxembourg', Spanish: 'Luxemburgo', Russian: 'Люксембург' },
  { English: 'Madagascar', Spanish: 'Madagascar', Russian: 'Мадагаскар' },
  { English: 'Malawi', Spanish: 'Malaui', Russian: 'Малави' },
  { English: 'Malaysia', Spanish: 'Malasia', Russian: 'Малайзия' },
  { English: 'Maldives', Spanish: 'Maldivas', Russian: 'Мальдивы' },
  { English: 'Mali', Spanish: 'Malí', Russian: 'Мали' },
  { English: 'Malta', Spanish: 'Malta', Russian: 'Мальта' },
  {
    English: 'Marshall Islands',
    Spanish: 'Islas Marshall',
    Russian: 'Маршалловы Острова',
  },
  { English: 'Mauritania', Spanish: 'Mauritania', Russian: 'Мавритания' },
  { English: 'Mauritius', Spanish: 'Mauricio', Russian: 'Маврикий' },
  { English: 'Mexico', Spanish: 'México', Russian: 'Мексика' },
  { English: 'Micronesia', Spanish: 'Micronesia', Russian: 'Микронезия' },
  { English: 'Moldova', Spanish: 'Moldavia', Russian: 'Молдавия' },
  { English: 'Monaco', Spanish: 'Mónaco', Russian: 'Монако' },
  { English: 'Mongolia', Spanish: 'Mongolia', Russian: 'Монголия' },
  { English: 'Montenegro', Spanish: 'Montenegro', Russian: 'Черногория' },
  { English: 'Morocco', Spanish: 'Marruecos', Russian: 'Марокко' },
  { English: 'Mozambique', Spanish: 'Mozambique', Russian: 'Мозамбик' },
  { English: 'Myanmar', Spanish: 'Birmania', Russian: 'Мьянма' },
  { English: 'Namibia', Spanish: 'Namibia', Russian: 'Намибия' },
  { English: 'Nauru', Spanish: 'Nauru', Russian: 'Науру' },
  { English: 'Nepal', Spanish: 'Nepal', Russian: 'Непал' },
  { English: 'Netherlands', Spanish: 'Países Bajos', Russian: 'Нидерланды' },
  {
    English: 'New Zealand',
    Spanish: 'Nueva Zelanda',
    Russian: 'Новая Зеландия',
  },
  { English: 'Nicaragua', Spanish: 'Nicaragua', Russian: 'Никарагуа' },
  { English: 'Niger', Spanish: 'Níger', Russian: 'Нигер' },
  { English: 'Nigeria', Spanish: 'Nigeria', Russian: 'Нигерия' },
  {
    English: 'North Korea',
    Spanish: 'Corea del Norte',
    Russian: 'Северная Корея',
  },
  { English: 'Norway', Spanish: 'Noruega', Russian: 'Норвегия' },
  { English: 'Oman', Spanish: 'Omán', Russian: 'Оман' },
  { English: 'Pakistan', Spanish: 'Pakistán', Russian: 'Пакистан' },
  { English: 'Palau', Spanish: 'Palaos', Russian: 'Палау' },
  { English: 'Panama', Spanish: 'Panamá', Russian: 'Панама' },
  {
    English: 'Papua New Guinea',
    Spanish: 'Papúa Nueva Guinea',
    Russian: 'Папуа — Новая Гвинея',
  },
  { English: 'Paraguay', Spanish: 'Paraguay', Russian: 'Парагвай' },
  { English: 'Peru', Spanish: 'Perú', Russian: 'Перу' },
  { English: 'Philippines', Spanish: 'Filipinas', Russian: 'Филиппины' },
  { English: 'Poland', Spanish: 'Polonia', Russian: 'Польша' },
  { English: 'Portugal', Spanish: 'Portugal', Russian: 'Португалия' },
  { English: 'Qatar', Spanish: 'Catar', Russian: 'Катар' },
  {
    English: 'Republic of the Congo',
    Spanish: 'República del Congo',
    Russian: 'Республика Конго',
  },
  {
    English: 'Republic of Macedonia',
    Spanish: 'República de Macedonia',
    Russian: 'Республика Македония',
  },
  { English: 'Romania', Spanish: 'Rumania', Russian: 'Румыния' },
  { English: 'Russia', Spanish: 'Rusia', Russian: 'Россия' },
  { English: 'Rwanda', Spanish: 'Ruanda', Russian: 'Руанда' },
  {
    English: 'Saint Kitts and Nevis',
    Spanish: 'San Cristóbal y Nieves',
    Russian: 'Сент-Китс и Невис',
  },
  { English: 'Saint Lucia', Spanish: 'Santa Lucía', Russian: 'Сан-Марино' },
  {
    English: 'Saint Vincent and the Grenadines',
    Spanish: 'San Vicente y las Granadinas',
    Russian: 'Сент-Винсент и Гренадины',
  },
  { English: 'Samoa', Spanish: 'Samoa', Russian: 'Самоа' },
  { English: 'San Marino', Spanish: 'San Marino', Russian: 'Сан – Марино' },
  {
    English: 'Sao Tome and Principe',
    Spanish: 'Sao Tomé y Príncipe',
    Russian: 'Сан-Томе и Принсипи',
  },
  {
    English: 'Saudi Arabia',
    Spanish: 'Arabia Saudita',
    Russian: 'Саудовская Аравия',
  },
  { English: 'Senegal', Spanish: 'Senegal', Russian: 'Сенегал' },
  { English: 'Serbia', Spanish: 'Serbia', Russian: 'Сербия' },
  {
    English: 'Seychelles',
    Spanish: 'Seychelles',
    Russian: 'Сейшельские Острова',
  },
  { English: 'Sierra Leone', Spanish: 'Sierra Leona', Russian: 'Сьерра-Леоне' },
  { English: 'Singapore', Spanish: 'Singapur', Russian: 'Сингапур' },
  { English: 'Slovakia', Spanish: 'Eslovaquia', Russian: 'Словакия' },
  { English: 'Slovenia', Spanish: 'Eslovenia', Russian: 'Словения' },
  {
    English: 'Solomon Islands',
    Spanish: 'Islas Salomón',
    Russian: 'Соломоновы Острова',
  },
  { English: 'Somalia', Spanish: 'Somalia', Russian: 'Сомали' },
  { English: 'South Africa', Spanish: 'Sudáfrica', Russian: 'ЮАР' },
  { English: 'South Korea', Spanish: 'Corea del Sur', Russian: 'Южная Корея' },
  { English: 'South Sudan', Spanish: 'Sudán del Sur', Russian: 'Южный Судан' },
  { English: 'Spain', Spanish: 'España', Russian: 'Испания' },
  { English: 'Sri Lanka', Spanish: 'Sri Lanka', Russian: 'Шри-Ланка' },
  { English: 'Sudan', Spanish: 'Sudán', Russian: 'Судан' },
  { English: 'Suriname', Spanish: 'Surinam', Russian: 'Суринам' },
  { English: 'Swaziland', Spanish: 'Suazilandia', Russian: 'Свазиленд' },
  { English: 'Sweden', Spanish: 'Suecia', Russian: 'Швеция' },
  { English: 'Switzerland', Spanish: 'Suiza', Russian: 'Швейцария' },
  { English: 'Syria', Spanish: 'Siria', Russian: 'Сирия' },
  { English: 'Tajikistan', Spanish: 'Tayikistán', Russian: 'Таджикистан' },
  { English: 'Tanzania', Spanish: 'Tanzania', Russian: 'Танзания' },
  { English: 'Thailand', Spanish: 'Tailandia', Russian: 'Таиланд' },
  { English: 'Togo', Spanish: 'Togo', Russian: 'Того' },
  { English: 'Tonga', Spanish: 'Tonga', Russian: 'Тонга' },
  {
    English: 'Trinidad and Tobago',
    Spanish: 'Trinidad y Tobago',
    Russian: 'Тринидад и Тобаго',
  },
  { English: 'Tunisia', Spanish: 'Túnez', Russian: 'Тунис' },
  { English: 'Turkey', Spanish: 'Turquía', Russian: 'Турция' },
  { English: 'Turkmenistan', Spanish: 'Turkmenistán', Russian: 'Туркмения' },
  { English: 'Tuvalu', Spanish: 'Tuvalu', Russian: 'Тувалу' },
  { English: 'Uganda', Spanish: 'Uganda', Russian: 'Уганда' },
  { English: 'Ukraine', Spanish: 'Ucrania', Russian: 'Украина' },
  {
    English: 'United Arab Emirates',
    Spanish: 'Emiratos Árabes Unidos',
    Russian: 'Объединённые Арабские Эмираты',
  },
  {
    English: 'United Kingdom',
    Spanish: 'Reino Unido',
    Russian: 'Великобритания',
  },
  {
    English: 'United States of America',
    Spanish: 'Estados Unidos',
    Russian: 'Соединённые Штаты Америки',
  },
  { English: 'Uruguay', Spanish: 'Uruguay', Russian: 'Уругвай' },
  { English: 'Uzbekistan', Spanish: 'Uzbekistán', Russian: 'Узбекистан' },
  { English: 'Vanuatu', Spanish: 'Vanuatu', Russian: 'Вануату' },
  { English: 'Venezuela', Spanish: 'Venezuela', Russian: 'Венесуэла' },
  { English: 'Vietnam', Spanish: 'Vietnam', Russian: 'Вьетнам' },
  { English: 'Yemen', Spanish: 'Yemen', Russian: 'Йемен' },
  { English: 'Zambia', Spanish: 'Zambia', Russian: 'Замбия' },
  { English: 'Zimbabwe', Spanish: 'Zimbabue', Russian: 'Зимбабве' },
];

export default countriesList;
