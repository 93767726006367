import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Body4 } from '../Typography/Fonts';
import CalendarLayout from './CalendarLayout';
import { Context } from '../Context/globalContextProvider';
import { SettingsContext } from '../Context/SettingsContext';

import 'firebase/auth';
import 'firebase/firestore';
import ExpandableDayBlock from './ExpandableDayBlock';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 20px;
  @media (max-width: 780px) {
    box-sizing: border-box;
    margin: 5% 0% 5% 5%;
    width: 90%;
  }
`;
// const LabelDiv = styled.div`
//   padding: 0;
//   &:first-child {
//     margin-top: 32px;
//   }
//   @media (max-width: 780px) {
//     &:first-child {
//       margin-top: 8px;
//     }
//   }
// `;
// const Label = styled.label`
//   display: flex;
//   cursor: pointer;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   @media (max-width: 780px) {
//   }
// `;
// const DateDiv = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   width: 40%;
//   @media (max-width: 780px) {
//     justify-content: flex-start;
//     width: max-content;
//     gap: 15px;
//   }
// `;
const Hr = styled.hr`
  border: 0;
  border-top: 1px solid #e3e3e3;
  background: transparent;
  margin: 16px 0px 20px 0px;
  padding: 0;
  @media (max-width: 780px) {
    margin: 0;
    width: 100vw;
    margin-left: -10%;
  }
`;
// const DateTag = styled.h6`
//   font-family: Montserrat;
//   font-style: normal;
//   font-weight: 800;
//   display: inline;
//   font-size: 21px;
//   line-height: 100%;
//   letter-spacing: 1.2px;
//   color: ${(props) => (props.blue ? "#3E8FDA" : "#000000")};
//   @media (max-width: 780px) {
//     width: auto;
//     font-size: 26px;
//     letter-spacing: 1.5px;
//     text-transform: uppercase;
//   }
// `;
const BodyText = styled(Body4)`
  font-size: 15px;
  line-height: 16px;
  text-align: left;
  width: 170px;
  margin: 0;
  padding: 0;
  letter-spacing: 1.6px;
  @media (max-width: 780px) {
    width: auto;
    font-size: 10px;
    line-height: 100%;
    margin-bottom: 3px;
    letter-spacing: 1px;
    align-self: flex-end;
    text-transform: lowercase;
  }
`;
// const SVGArea = styled.div`
//   @media (max-width: 780px) {
//     display: none;
//   }
// `;
const Expanded = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0px 0px 0px;
  @media (max-width: 780px) {
    margin: 14px 0px 0px 0px;
  }
`;
const ExpandedText = styled(BodyText)`
  width: 70%;
  @media (max-width: 780px) {
  }
`;
// const ExpandedImage = styled.div`
//   width: 25%;
//   min-width: 200px;
//   height: 120px;
//   background: lightgrey;
//   @media (max-width: 780px) {
//     width: 25%;
//     height: 40px;
//     min-width: 70px;
//   }
// `;

const percentage = (num, per) => (num / 100) * per;

function compareDates(date) {
  const currentDate = new Date();
  let result;
  if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  ) {
    // console.log('NOW');
    result = 0;
  } else if (date.getFullYear() < currentDate.getFullYear()) {
    // console.log('Past Year');
    result = -1;
  } else if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() < currentDate.getMonth()
  ) {
    // console.log('Past Month');
    result = -1;
  } else if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() < currentDate.getDate()
  ) {
    // console.log('Past Date');
    result = -1;
  } else if (date.getFullYear() > currentDate.getFullYear()) {
    // console.log('Future Year');
    result = 1;
  } else if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() > currentDate.getMonth()
  ) {
    // console.log('Future Month');
    result = 1;
  } else if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() > currentDate.getDate()
  ) {
    // console.log('Future Date');
    result = 1;
  }
  return result;
}

const translations = [
  {
    English: 'y',
    Russian: 'л',
    Spanish: 'a',
  },
  {
    English: 'm',
    Russian: 'м',
    Spanish: 'm',
  },
  {
    English: 'd',
    Russian: 'д',
    Spanish: 'd',
  },
];

function DAYS(user, myRef, answers, language) {
  const days = [];
  const bound =
    moment([user.renderDaysOfYear]).isLeapYear() === true ? 366 : 365;

  const birthDayMoment = moment(user?.birthday);
  for (let i = 0; i < bound; i += 1) {
    const startDate = moment(new Date());
    const startDate2 = moment(new Date());
    const startDate3 = moment(new Date());
    const label = startDate.subtract(i, 'days');
    const label2 = startDate2.subtract(i, 'days').add(1, 'days');
    const label3 = startDate3.subtract(i, 'days');

    const actualDay = parseInt(label3.format('D'), 10);
    const birthdayDay = parseInt(birthDayMoment.format('D'), 10);
    let yearsDifference = '';
    let monthDifference = '';
    let daysDifference = '';

    if (label3.diff(birthDayMoment, 'days') <= 0) {
      yearsDifference = 0;
      monthDifference = 0;
      daysDifference = 0;
    } else {
      let last = moment();
      if (actualDay >= birthdayDay) {
        last = moment(
          new Date(
            `${label3.format('M')} ${birthDayMoment.format(
              'D',
            )},${label3.format('Y')}`,
          ),
        );
      } else {
        const lastMonthDate = label3.subtract(1, 'month');
        last = moment(
          new Date(
            `${lastMonthDate.format('M')} ${birthDayMoment.format(
              'D',
            )},${lastMonthDate.format('Y')}`,
          ),
        );
      }

      const y = birthDayMoment.diff(label, 'years');
      const m = birthDayMoment.diff(label, 'months');
      const d = last.diff(label, 'days');

      yearsDifference = Number.isNaN(y) ? '' : Math.abs(y);
      monthDifference = Number.isNaN(m) ? '' : Math.abs(m) % 12;
      daysDifference = Number.isNaN(d) ? '' : Math.abs(d);
    }

    const secondLabel = `
    ${yearsDifference}${translations[0][language]}
    ${monthDifference}${translations[1][language]}
    ${daysDifference}${translations[2][language]}
    `;

    let scrollReference;
    if (Math.ceil(i / 7) === user.scrollWeek && startDate.day() === 0) {
      // console.log('Scroll Enabled');
      scrollReference = myRef;
    }

    let linkForward;
    let linkIndex;
    if (compareDates(startDate.toDate()) === -1) {
      linkForward = '/journal/past';
      linkIndex = 0;
    } else {
      linkForward = '/journal';
      linkIndex = 1;
    }

    const filteredAnwsers = answers?.find(
      (value) => value.keyDate === label.format('D-MM-YYYY'),
    );
    const expandedText =
      filteredAnwsers?.a1 ??
      filteredAnwsers?.a2 ??
      filteredAnwsers?.a3 ??
      filteredAnwsers?.a4 ??
      filteredAnwsers?.a5 ??
      filteredAnwsers?.a6 ??
      filteredAnwsers?.a7;
    const expandedContent = (
      <Expanded>
        <ExpandedText>{expandedText}</ExpandedText>
        {/* <ExpandedImage /> */}
      </Expanded>
    );
    days.push(
      <ExpandableDayBlock
        scrollReference={scrollReference}
        label={label}
        secondLabel={secondLabel}
        expandedContent={expandedContent}
        startDate={startDate}
        linkForward={linkForward}
        linkIndex={linkIndex}
        dateToSet={label2}
      />,
      <Hr />,
    );
    // startDate.add(i, 'days');
  }
  return days;
}

function LifeInDays() {
  const { language } = React.useContext(SettingsContext);
  const { answers } = React.useContext(Context);
  const user = React.useContext(Context);
  const myRef = React.useRef(null);
  const daysArray = DAYS(user, myRef, answers, language);
  const { body } = document;
  const html = document.documentElement;
  const documentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  const executeScroll = () => {
    if (!(user.scrollWeek < 2)) {
      myRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      window.scrollBy(0, -percentage(documentHeight, 1));
    }
  };

  React.useEffect(() => {
    executeScroll();
    return () => {
      user.setScrollWeek(1);
    };
  });

  return (
    <CalendarLayout>
      <Container>{daysArray}</Container>
    </CalendarLayout>
  );
}

export default LifeInDays;
