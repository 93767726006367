import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { H1 } from '../Typography/Fonts';
import { Context } from '../Context/globalContextProvider';
import BackArrow from '../ReusableItems/BackArrow';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  margin-left: 200px;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  @media (max-width: 900px) {
    margin-left: 5%;
  }
  @media (max-width: 780px) {
    flex-direction: column;
    margin-left: 0;
    padding: 0%;
    text-align: center;
    align-items: center;
    gap: 0px;
    justify-content: space-evenly;
  }
`;
const PageHeading = styled(H1)`
  display: none;
  @media (max-width: 780px) {
    display: block;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    text-align: center;
  }
`;
const BackgroundImage1 = styled.img`
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 800px;
  position: absolute;
  @media (max-width: 780px) {
    min-width: 350px;
    margin-left: -10%;
  }
`;
const BackgroundImage2 = styled.img`
  top: 0;
  right: 0;
  z-index: -1;
  position: absolute;
  max-width: 1000px;
  @media (max-width: 780px) {
    bottom: 0;
    right: 0;
    top: auto;
    transform: scaleY(-1);
    min-width: 350px;
  }
`;
const Illustration = styled.img`
  width: 100%;
  max-width: 850px;
  @media (max-width: 780px) {
    margin: auto;
    width: 90%;
    min-width: 90%;
    max-width: 450px;
  }
`;
const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  max-width: 300px;
  @media (max-width: 780px) {
    width: 100%;
    max-width: 100%;
    margin-top: 90px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;
const ImageArea = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 50vw;
  display: flex;
  align-items: flex-end;
  @media (max-width: 900px) {
  }
  @media (max-width: 780px) {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-bottom: 100px;
  }
`;
const Heading = styled(H1)`
  text-align: left;
  text-transform: uppercase;
`;
const Subheading = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: 1.6px;
  @media (max-width: 780px) {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 2px;
    padding: 0% 15% 0% 15%;
    color: #262626;
  }
`;
const Button = styled(Link)`
  display: ${(props) => (props.mobile ? 'none' : 'flex')};
  height: 42px;
  width: 100%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  transition: 0.3s;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  white-space: normal !important;
  color: #ffffff;
  margin: 5% 5% 5% 0%;
  background: #57b010;
  border: 2px solid #57b010;
  &:hover {
    border: 2px solid #79c040;
    cursor: pointer;
    background: #79c040;
    color: #ffffff;
  }
  &:focus {
    outline: 0;
  }
  @media (max-width: 780px) {
    display: ${(props) => (props.desktop ? 'none' : 'flex')};
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.5px;
    position: absolute;
    left: auto;
    bottom: 50px;
    min-width: 100px;
    max-width: 312px;
    width: 80%;
    margin: 0% 10% 0% 10%;
    align-self: center;
    border-radius: 10px;
  }
`;
const Arrow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 0px 0px 10px;
  z-index: 9999;
`;

const translations = [
  {
    English: 'Congrats!',
    Russian: 'Поздравляем!',
    Spanish: 'Felicidades!',
  },
  {
    English: 'You will live happily',
    Russian: 'Вы проживете счастливо',
    Spanish: 'Vivirás felizmente',
  },
  {
    English: 'years',
    Russian: 'лет',
    Spanish: 'años',
  },
  {
    English: 'Continue',
    Russian: 'Продолжить',
    Spanish: 'Continuar',
  },
];

function Success() {
  const { language } = React.useContext(SettingsContext);
  const user = React.useContext(Context);
  return (
    <div>
      <MetaTags>
        <title>Life Calendar and Journal | Liveweeks</title>
        <meta
          name="description"
          content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
        />
      </MetaTags>
      <Wrapper>
        <div>
          <Arrow>
            <BackArrow link="/calculateyears" />
          </Arrow>
          <PageHeading>LIVEWEEKS</PageHeading>
          <BackgroundImage1
            src="birthday-info-illustration-1.png"
            alt="background-illustration1"
            width="30%"
          />
          <BackgroundImage2
            src="congratulations-illustration-2.png"
            alt="background-illustration1"
            width="50%"
          />
          <TextArea>
            <Heading>{translations[0][language]}</Heading>
            <Subheading>
              {translations[1][language]}&nbsp;
              <span style={{ color: '#57B010' }}>
                {user.lifeExpectency}
                &nbsp;{translations[2][language]}
              </span>
            </Subheading>
            <Button desktop="true" to="/calendar">
              {translations[3][language]}
            </Button>
          </TextArea>
        </div>
        <div>
          <ImageArea>
            <Illustration src="congratulations-image.png" alt="illustration" />
          </ImageArea>
          <Button mobile="true" to="/calendar">
            {translations[3][language]}
          </Button>
        </div>
      </Wrapper>
      <VerticalNavigationBar />
    </div>
  );
}

export default Success;
