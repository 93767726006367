import React from 'react';
import styled from 'styled-components';
import 'firebase/auth';
import 'firebase/firestore';
import BucketList from './BucketList';
import JournalLayout from './JournalLayout';
import BackArrow from '../ReusableItems/BackArrow';
import { H1 } from '../Typography/Fonts';
import UserIcon from '../ReusableItems/UserIcon';
import { Context } from '../Context/globalContextProvider';
import { FirebaseSaveBucketsLists } from '../Context/FirebaseContext';
import { SettingsContext } from '../Context/SettingsContext';

const UserIconWrapper = styled.div`
  right: 30px;
  position: absolute;
`;

const translations = [
  {
    English: 'Journal',
    Russian: 'Дневник',
    Spanish: 'Diario',
  },
  {
    English: 'Add new list',
    Russian: 'Добавить список',
    Spanish: 'Agregar nueva lista',
  },
];

const JournalFuture = () => {
  const { user, bucketsLists, setBucketsLists } = React.useContext(Context);
  const { language } = React.useContext(SettingsContext);

  const saveBucketsList = (newBucketsLists) => {
    setBucketsLists(newBucketsLists);
    if (user) {
      FirebaseSaveBucketsLists(user, newBucketsLists);
    }
  };

  const addBucketsList = () => {
    const newBucket = {
      id: Date.now(),
      listName: '',
      entries: [
        {
          id: Date.now(),
          text: '',
          checked: false,
        },
      ],
      checked: false,
    };
    const newBucketsLists = [newBucket, ...bucketsLists];
    saveBucketsList(newBucketsLists);
  };
  const deleteBucketsList = (id) => {
    const newBucketsLists = [...bucketsLists.filter((b) => b.id !== id)];
    saveBucketsList(newBucketsLists);
  };
  const editBucketsListName = (id, name) => {
    const bucketsList = bucketsLists.find((b) => b.id === id);
    if (bucketsList) {
      bucketsList.listName = name;
      const newBucketsLists = [...bucketsLists];
      const index = newBucketsLists.findIndex((b) => b.id === id);
      newBucketsLists.splice(index, 1, bucketsList);
      saveBucketsList(newBucketsLists);
    }
  };
  const editEntryChecked = (id, entryId, checked) => {
    const bucketsList = bucketsLists.find((b) => b.id === id);
    if (bucketsList) {
      const entry = bucketsList.entries.find((e) => e.id === entryId);
      if (entry) {
        entry.checked = checked;
        const newBucketsLists = [...bucketsLists];
        const index = newBucketsLists.findIndex((b) => b.id === id);
        newBucketsLists.splice(index, 1, bucketsList);
        saveBucketsList(newBucketsLists);
      }
    }
  };
  const editEntryText = (id, entryId, text) => {
    const bucketsList = bucketsLists.find((b) => b.id === id);
    if (bucketsList) {
      const entry = bucketsList.entries.find((e) => e.id === entryId);
      if (entry) {
        entry.text = text;
        const newBucketsLists = [...bucketsLists];
        const index = newBucketsLists.findIndex((b) => b.id === id);
        newBucketsLists.splice(index, 1, bucketsList);
        saveBucketsList(newBucketsLists);
      }
    }
  };
  const addEntry = (id, entry) => {
    const bucketsList = bucketsLists.find((b) => b.id === id);
    if (bucketsList) {
      bucketsList.entries.push(entry);
      const newBucketsLists = [...bucketsLists];
      const index = newBucketsLists.findIndex((b) => b.id === id);
      newBucketsLists.splice(index, 1, bucketsList);
      saveBucketsList(newBucketsLists);
    }
  };

  const saveEntries = (id, newEntriesArray) => {
    const bucketsList = bucketsLists.find((b) => b.id === id);
    if (bucketsList) {
      bucketsList.entries = newEntriesArray;
      const newBucketsLists = [...bucketsLists];
      const index = newBucketsLists.findIndex((b) => b.id === id);
      newBucketsLists.splice(index, 1, bucketsList);
      saveBucketsList(newBucketsLists);
    }
  };

  return (
    <Container key="ContainerKey">
      <BackgroundIllustration1
        viewBox="0 0 800 927"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.7"
          d="M554.604 246.453C140.758 400.268 557.662 749.06 372.152 812.106C186.642 875.152 -32.443 724.12 -117.187 474.765C-201.932 225.41 -120.245 -27.8417 65.2647 -90.8881C250.775 -153.935 831.467 143.551 554.604 246.453Z"
          fill="#FAEFFF"
        />
      </BackgroundIllustration1>
      <BackgroundIllustration2
        width="762"
        height="747"
        viewBox="0 0 762 747"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M467.478 223.672C584.745 772.692 74.658 523.783 131.817 766.323C188.975 1008.86 431.928 1159.14 674.467 1101.98C917.006 1044.83 1067.29 801.872 1010.13 559.333C952.97 316.794 389.026 -143.623 467.478 223.672Z"
          fill="#FCF2E6"
        />
      </BackgroundIllustration2>
      <Arrow>
        <BackArrow />
      </Arrow>
      <HeadingArea>
        <Heading>{translations[0][language]}</Heading>
        <UserIconWrapper>
          <UserIcon user={user} />
        </UserIconWrapper>
        <Button
          onClick={() => {
            addBucketsList();
          }}
        >
          {translations[1][language]}
        </Button>
      </HeadingArea>
      <JournalLayout futureTab key="JournalLayoutKey">
        <AddButton
          onClick={() => {
            addBucketsList();
          }}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 6.06641V21.9331"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.06665 14H21.9333"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </AddButton>
        <div key="JournalLayoutDivKey">
          {bucketsLists
            ? bucketsLists.map((bucket) => (
                // eslint-disable-next-line react/jsx-indent
                <Wrapper key={`WrapperKey-${bucket.id}`}>
                  <BucketList
                    key={`BucketListKey-${bucket.id}`}
                    listId={bucket.id}
                    listName={bucket.listName}
                    listValues={bucket.entries}
                    saveEntries={saveEntries}
                    deleteBucketsList={deleteBucketsList}
                    editBucketsListName={editBucketsListName}
                    addEntry={addEntry}
                    editEntryChecked={editEntryChecked}
                    editEntryText={editEntryText}
                  />
                </Wrapper>
              ))
            : null}
        </div>
        <JournalIllustration
          width="444"
          height="461"
          viewBox="0 0 444 461"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M425.385 309.063C380.406 414.011 261.676 459.09 168.649 451.403C143.25 449.306 92.1439 445.083 52.2402 407.414C-24.3923 335.072 -12.5914 183.859 60.7689 94.834C70.2162 83.3683 135.607 6.55609 237.504 7.58345C312.111 8.33587 364.475 50.4069 370.843 55.6475C378.887 62.2678 396.629 78.0026 412.617 102.962C450.189 161.61 453.492 243.481 425.385 309.063Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M45.0607 51.4586C45.8259 48.905 49.5138 49.0729 50.0383 51.6891C55.0391 76.9749 70.231 142.871 98.7263 179.452C145.122 239.004 171.791 331.879 135.579 376.049C135.575 376.043 -38.405 329.732 45.0607 51.4586Z"
            fill="#F9D7FF"
          />
          <path
            opacity="0.43"
            d="M47.1718 87.1432C47.1718 87.9566 47.2344 88.625 47.2707 89.3544L47.4356 91.4947L47.7936 95.7557C48.0525 98.5958 48.3527 101.429 48.6561 104.264C49.2796 109.93 49.9679 115.589 50.7211 121.239C52.2368 132.538 54.0577 143.795 56.0468 155.024C58.0358 166.252 60.3119 177.43 62.837 188.553C65.3621 199.676 68.0654 210.763 71.1232 221.758C77.1944 243.754 84.4695 265.443 93.2522 286.532C97.7532 297.026 102.531 307.41 107.901 317.494C113.344 327.538 119.217 337.355 125.78 346.7C125.847 346.799 125.95 346.868 126.067 346.89C126.185 346.913 126.306 346.889 126.405 346.822C126.504 346.756 126.573 346.652 126.596 346.535C126.619 346.418 126.594 346.297 126.527 346.198C120.367 336.644 114.703 326.779 109.559 316.643C104.38 306.529 99.7967 296.124 95.4903 285.615C91.1839 275.106 87.3113 264.415 83.6779 253.649C80.0444 242.883 76.8678 231.966 73.7489 221.039C67.6036 199.141 62.6325 176.939 58.275 154.617C56.1089 143.443 54.1671 132.232 52.4496 120.984C51.5765 115.363 50.7607 109.733 50.002 104.095L48.9415 95.6306L48.4467 91.3943L48.2273 89.2754C48.1663 88.5806 48.0888 87.8347 48.0624 87.2239V87.1235C48.0534 87.0099 48.0012 86.904 47.9165 86.8276C47.8318 86.7512 47.721 86.71 47.6069 86.7125C47.4928 86.715 47.3839 86.7611 47.3027 86.8412C47.2215 86.9213 47.174 87.0293 47.1701 87.1432H47.1718Z"
            fill="white"
          />
          <path
            d="M135.374 2.02488C136.775 -0.107243 140.118 1.05349 139.877 3.59227C137.551 28.1437 133.476 92.7115 149.608 134.012C175.882 201.26 172.481 297.352 127.197 327.994C127.2 327.994 -17.2145 234.487 135.374 2.02488Z"
            fill="#BDD0FB"
          />
          <path
            opacity="0.43"
            d="M127.545 35.3207C127.321 36.0682 127.195 36.7004 127.032 37.3754L126.59 39.3775L125.752 43.3832C125.213 46.057 124.715 48.7374 124.217 51.4194C123.24 56.7846 122.324 62.1629 121.469 67.5544C119.768 78.3302 118.361 89.1489 117.116 99.9889C115.871 110.829 114.9 121.7 114.175 132.588C113.451 143.476 112.901 154.38 112.691 165.296C112.247 187.123 112.995 208.994 115.285 230.725C116.547 241.575 118.089 252.4 120.265 263.112C122.513 273.802 125.213 284.405 128.686 294.761C128.721 294.872 128.798 294.964 128.901 295.018C129.004 295.073 129.124 295.083 129.235 295.049C129.347 295.014 129.439 294.937 129.493 294.835C129.548 294.732 129.559 294.612 129.524 294.501C126.489 284.059 123.995 273.467 122.051 262.769C120.072 252.084 118.708 241.293 117.632 230.484C116.557 219.676 115.927 208.816 115.538 197.956C115.149 187.096 115.219 176.223 115.345 165.345C115.69 143.599 117.202 121.88 119.303 100.221C120.377 89.3942 121.662 78.5887 123.156 67.8046C123.891 62.4131 124.679 57.0288 125.523 51.6515L126.86 43.5989L127.57 39.5816L127.947 37.5796C128.081 36.921 128.214 36.2196 128.355 35.6533L128.378 35.5578C128.408 35.4468 128.393 35.3284 128.336 35.2285C128.279 35.1287 128.185 35.0555 128.073 35.0251C127.962 34.9948 127.844 35.0097 127.744 35.0667C127.644 35.1237 127.57 35.218 127.54 35.3289L127.545 35.3207Z"
            fill="white"
          />
          <path
            d="M405.913 80.8801C405.913 77.1756 405.182 73.5075 403.762 70.085C402.342 66.6625 400.261 63.5528 397.637 60.9334C395.013 58.314 391.897 56.2362 388.469 54.8187C385.04 53.4011 381.366 52.6717 377.655 52.6719H137.349C130.966 52.6741 124.827 55.1226 120.2 59.5121C115.574 63.9016 112.812 69.8968 112.485 76.2602L94.7532 424.039C94.6278 426.493 94.9981 428.947 95.842 431.255C96.686 433.563 97.9863 435.679 99.6656 437.475C101.345 439.271 103.369 440.712 105.618 441.712C107.866 442.712 110.293 443.25 112.754 443.294L388.171 448.291C390.486 448.333 392.785 447.915 394.936 447.06C397.087 446.205 399.045 444.93 400.697 443.311C402.349 441.692 403.661 439.761 404.556 437.63C405.452 435.499 405.913 433.211 405.913 430.9V80.8801Z"
            fill="#9239C8"
          />
          <path
            d="M347.918 442.379H347.926L378.743 441.982C389.048 441.849 397.432 433.371 397.432 423.084V376.637C397.432 376.462 397.362 376.295 397.239 376.171C397.115 376.048 396.947 375.979 396.772 375.979C396.597 375.979 396.429 376.048 396.306 376.171C396.182 376.295 396.112 376.462 396.112 376.637V423.084C396.112 432.655 388.313 440.536 378.724 440.668L347.906 441.067C347.731 441.067 347.563 441.136 347.44 441.259C347.316 441.383 347.246 441.55 347.246 441.725C347.246 441.9 347.316 442.067 347.44 442.191C347.563 442.314 347.731 442.384 347.906 442.384L347.918 442.379Z"
            fill="#F9D7FF"
          />
          <path
            d="M396.774 362.906C396.949 362.906 397.117 362.836 397.24 362.713C397.364 362.589 397.434 362.422 397.434 362.247V354.726C397.434 354.552 397.364 354.384 397.24 354.261C397.117 354.137 396.949 354.068 396.774 354.068C396.599 354.068 396.431 354.137 396.307 354.261C396.184 354.384 396.114 354.552 396.114 354.726V362.247C396.114 362.422 396.184 362.589 396.307 362.713C396.431 362.836 396.599 362.906 396.774 362.906Z"
            fill="#F9D7FF"
          />
          <path
            d="M386.226 58.0247C386.234 56.8514 385.989 55.6902 385.508 54.6197C385.026 53.5492 384.321 52.5942 383.438 51.8195C382.555 51.0448 381.516 50.4684 380.391 50.1292C379.266 49.79 378.081 49.696 376.916 49.8535C344.094 54.2988 231.258 68.0349 137.96 60.8845C136.951 60.8048 135.936 60.9277 134.975 61.2458C134.014 61.5639 133.126 62.0709 132.364 62.7368C131.602 63.4027 130.982 64.2141 130.539 65.1232C130.096 66.0323 129.841 67.0206 129.786 68.03L111.187 417.368C111.066 419.668 111.86 421.923 113.397 423.64C114.934 425.358 117.089 426.399 119.392 426.537C158.633 428.855 320.232 437.817 377.873 431.789C379.566 431.609 381.133 430.812 382.275 429.551C383.418 428.29 384.054 426.653 384.064 424.953L386.226 58.0247Z"
            fill="#B985DE"
          />
          <path
            d="M382.36 66.7786C382.367 65.6501 382.132 64.5333 381.669 63.5037C381.207 62.474 380.528 61.5554 379.68 60.8099C378.831 60.0645 377.832 59.5096 376.75 59.1827C375.668 58.8559 374.528 58.7647 373.408 58.9153C341.828 63.196 233.234 76.4118 143.449 69.5314C142.477 69.4539 141.5 69.5716 140.574 69.8774C139.648 70.1832 138.793 70.671 138.059 71.3119C137.325 71.9529 136.728 72.7341 136.301 73.6094C135.874 74.4848 135.628 75.4365 135.576 76.4085L117.679 412.584C117.562 414.797 118.326 416.967 119.805 418.62C121.285 420.274 123.359 421.276 125.576 421.408C163.345 423.639 318.852 432.263 374.322 426.463C375.953 426.291 377.463 425.523 378.563 424.308C379.663 423.093 380.274 421.515 380.281 419.877L382.36 66.7786Z"
            fill="#F9D7FF"
          />
          <path
            d="M252.898 89.9899L249.214 162.062C249.092 164.459 246.964 166.366 244.329 166.433C229.664 166.81 183.963 167.825 158.168 166.598C155.249 166.46 152.94 164.311 152.842 161.659C152.348 148.142 151.274 108.808 154.657 92.3558C155.202 89.7001 157.711 87.754 160.68 87.6585L247.358 84.8991C250.49 84.7905 253.044 87.1465 252.898 89.9899Z"
            fill="#BDD0FB"
          />
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M264.337 98.9713C264.018 98.9763 263.701 98.917 263.406 98.7972C263.11 98.6773 262.842 98.4993 262.617 98.2738C262.392 98.0482 262.215 97.7797 262.096 97.4843C261.977 97.1889 261.919 96.8726 261.925 96.5544C261.958 95.8967 262.24 95.276 262.713 94.8176C263.187 94.3592 263.817 94.0973 264.477 94.0847L351.812 92.5322C353.177 92.5075 353.923 93.5398 353.469 94.824L352.833 96.635C352.379 97.9193 350.891 98.9713 349.526 98.9713H264.337Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M262.885 156.059C262.566 156.064 262.25 156.005 261.954 155.885C261.659 155.765 261.391 155.587 261.166 155.362C260.941 155.136 260.763 154.868 260.644 154.572C260.526 154.277 260.468 153.961 260.474 153.642C260.507 152.985 260.788 152.364 261.262 151.905C261.736 151.447 262.366 151.185 263.025 151.173L350.36 149.62C351.726 149.595 352.471 150.628 352.01 151.912L351.373 153.723C350.919 155.007 349.432 156.059 348.074 156.059H262.885Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M351.812 105.974C353.177 105.951 353.923 106.982 353.469 108.267L352.833 110.079C352.379 111.364 350.891 112.416 349.526 112.416H264.337C264.018 112.421 263.701 112.362 263.405 112.242C263.11 112.122 262.841 111.944 262.616 111.718C262.391 111.493 262.214 111.224 262.095 110.928C261.977 110.633 261.919 110.316 261.925 109.998C261.963 108.669 263.113 107.561 264.477 107.528L351.812 105.974Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M351.812 119.606C353.177 119.582 353.923 120.614 353.469 121.9L352.833 123.703C352.379 124.989 350.891 126.039 349.526 126.039H264.337C264.018 126.044 263.701 125.985 263.405 125.865C263.11 125.746 262.842 125.568 262.616 125.342C262.391 125.116 262.214 124.848 262.095 124.552C261.977 124.257 261.919 123.94 261.925 123.622C261.958 122.964 262.24 122.344 262.713 121.885C263.187 121.427 263.817 121.165 264.477 121.152L351.812 119.606Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M350.941 134.002C352.306 133.979 353.052 135.01 352.59 136.296L351.953 138.107C351.5 139.393 350.012 140.445 348.655 140.445H263.466C263.147 140.45 262.83 140.39 262.534 140.271C262.239 140.151 261.971 139.972 261.746 139.747C261.52 139.521 261.343 139.252 261.225 138.957C261.106 138.661 261.048 138.345 261.054 138.026C261.092 136.698 262.242 135.589 263.606 135.557L350.941 134.002Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M146.643 188.299C146.327 188.307 146.013 188.249 145.72 188.129C145.428 188.009 145.164 187.829 144.946 187.602C144.727 187.374 144.558 187.104 144.45 186.807C144.341 186.511 144.296 186.195 144.317 185.881C144.404 184.552 145.594 183.454 146.956 183.444L350.662 181.84C352.026 181.831 352.448 182.693 351.597 183.76L349.522 186.36C348.673 187.427 346.86 188.299 345.495 188.299H146.643Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M350.669 212.665C352.033 212.653 352.455 213.517 351.604 214.583L349.529 217.184C348.68 218.249 346.867 219.122 345.501 219.122H146.643C146.327 219.129 146.014 219.071 145.722 218.951C145.43 218.83 145.167 218.651 144.948 218.424C144.73 218.196 144.561 217.926 144.453 217.63C144.345 217.334 144.3 217.019 144.32 216.705C144.408 215.375 145.597 214.278 146.959 214.267L350.669 212.665Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M352.026 244.255C353.392 244.245 353.812 245.108 352.963 246.175L350.888 248.774C350.039 249.841 348.224 250.714 346.86 250.714H148.002C147.686 250.721 147.372 250.663 147.08 250.543C146.788 250.423 146.525 250.243 146.306 250.016C146.088 249.788 145.919 249.518 145.811 249.221C145.703 248.925 145.658 248.61 145.679 248.295C145.767 246.965 146.956 245.868 148.318 245.859L352.026 244.255Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M357.584 281.351C356.938 283.224 354.734 284.518 352.234 284.489L148.45 282.184C146.141 282.157 144.307 280.672 144.366 278.883C144.424 277.142 146.255 275.754 148.49 275.754L352.297 275.995C355.894 275.998 358.515 278.652 357.584 281.351Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M357.409 318.013C356.763 319.886 354.559 321.179 352.059 321.151L148.275 318.846C145.966 318.82 144.132 317.334 144.192 315.553C144.249 313.813 146.08 312.425 148.315 312.425L352.132 312.655C355.722 312.655 358.343 315.314 357.409 318.013Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M357.245 354.674C356.598 356.549 354.395 357.841 351.894 357.813L148.11 355.508C145.801 355.484 143.967 353.999 144.027 352.216C144.084 350.477 145.915 349.087 148.15 349.087L351.95 349.316C355.551 349.323 358.171 351.977 357.245 354.674Z"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M357.07 391.336C356.423 393.212 354.22 394.504 351.719 394.476L147.936 392.171C145.627 392.145 143.792 390.66 143.852 388.878C143.91 387.138 145.74 385.75 147.975 385.75L351.775 385.979C355.379 385.985 358 388.639 357.07 391.336Z"
              fill="white"
            />
          </g>
          <path
            d="M242.112 39.4134L269.377 36.9981L271.668 12.0317C271.896 9.59505 271.27 7.15487 269.898 5.12693C268.526 3.09899 266.491 1.60875 264.142 0.910119C258.828 -0.653983 251.277 -0.774171 242.602 5.25339C225.577 17.0747 242.112 39.4134 242.112 39.4134ZM247.792 18.6042C247.792 17.262 248.191 15.9499 248.938 14.8338C249.685 13.7178 250.747 12.8479 251.989 12.3343C253.231 11.8206 254.598 11.6862 255.917 11.9481C257.236 12.2099 258.447 12.8563 259.398 13.8054C260.349 14.7545 260.996 15.9638 261.259 17.2802C261.521 18.5967 261.386 19.9613 260.872 21.2013C260.357 22.4414 259.486 23.5013 258.368 24.2471C257.25 24.9928 255.935 25.3908 254.591 25.3908C252.788 25.3904 251.059 24.6752 249.784 23.4026C248.509 22.1299 247.793 20.404 247.792 18.6042Z"
            fill="#9239C8"
          />
          <path
            d="M266.593 26.4925C266.419 26.4762 266.259 26.3921 266.148 26.2586C266.036 26.125 265.982 25.9529 265.997 25.7796L266.364 21.8035C266.37 21.7173 266.394 21.6333 266.434 21.5565C266.474 21.4797 266.529 21.4116 266.595 21.3562C266.662 21.3008 266.739 21.2593 266.822 21.2341C266.904 21.2088 266.992 21.2003 267.078 21.2092C267.251 21.2255 267.411 21.3096 267.523 21.4431C267.634 21.5767 267.688 21.7488 267.673 21.9221L267.309 25.8982C267.293 26.0621 267.217 26.2143 267.095 26.325C266.973 26.4356 266.814 26.4965 266.649 26.4958C266.63 26.4961 266.611 26.495 266.593 26.4925ZM267.309 18.7017C267.135 18.6854 266.975 18.6013 266.864 18.4677C266.752 18.3342 266.698 18.162 266.713 17.9888L266.99 14.9692C267.177 12.9501 266.659 10.9286 265.522 9.24798C264.386 7.56732 262.702 6.3311 260.756 5.74924C257.726 4.85344 254.521 4.72219 251.427 5.36727C251.34 5.39293 251.248 5.40016 251.157 5.38853C251.067 5.37689 250.98 5.34663 250.901 5.29966C250.823 5.25269 250.756 5.19003 250.703 5.11565C250.65 5.04126 250.614 4.95678 250.595 4.86752C250.577 4.77827 250.577 4.68619 250.596 4.59711C250.616 4.50804 250.653 4.4239 250.707 4.35001C250.76 4.27613 250.828 4.21411 250.907 4.16788C250.985 4.12164 251.073 4.0922 251.163 4.08142C254.468 3.39066 257.892 3.53097 261.129 4.48973C263.365 5.15775 265.302 6.5779 266.609 8.50909C267.916 10.4403 268.514 12.7635 268.3 15.0845L268.016 18.1106C268.001 18.2745 267.925 18.4268 267.803 18.5374C267.681 18.648 267.521 18.709 267.356 18.7082L267.309 18.7017Z"
            fill="#F9D7FF"
          />
          <path
            d="M306.589 53.1311V40.5787C306.588 39.5512 306.381 38.5343 305.978 37.5885C305.576 36.6427 304.987 35.7874 304.247 35.0736C303.506 34.3597 302.63 33.8018 301.669 33.4332C300.708 33.0646 299.683 32.8927 298.654 32.9278L211.962 35.8914C210.106 35.9549 208.34 36.7074 207.01 38.0018C205.679 39.2961 204.881 41.0393 204.77 42.8903L204.046 54.9504L306.589 53.1311Z"
            fill="#9239C8"
          />
          <path
            d="M274.264 37.6536H277.993C278.168 37.6536 278.336 37.5842 278.459 37.4607C278.583 37.3372 278.653 37.1697 278.653 36.995C278.653 36.8203 278.583 36.6528 278.459 36.5293C278.336 36.4058 278.168 36.3364 277.993 36.3364H274.264C274.089 36.3364 273.921 36.4058 273.797 36.5293C273.674 36.6528 273.604 36.8203 273.604 36.995C273.604 37.1697 273.674 37.3372 273.797 37.4607C273.921 37.5842 274.089 37.6536 274.264 37.6536Z"
            fill="#F9D7FF"
          />
          <path
            d="M301.897 49.19C302.072 49.19 302.239 49.1206 302.363 48.9971C302.487 48.8736 302.556 48.7061 302.556 48.5314V43.4555C302.555 41.5692 301.803 39.7607 300.467 38.4269C299.131 37.0931 297.319 36.3431 295.43 36.3413H281.722C281.547 36.3413 281.379 36.4107 281.256 36.5342C281.132 36.6577 281.062 36.8252 281.062 36.9999C281.062 37.1745 281.132 37.3421 281.256 37.4656C281.379 37.5891 281.547 37.6584 281.722 37.6584H295.43C296.97 37.6602 298.448 38.2721 299.537 39.3598C300.626 40.4476 301.239 41.9223 301.24 43.4604V48.538C301.242 48.711 301.312 48.8763 301.434 48.9983C301.557 49.1203 301.723 49.1892 301.897 49.19Z"
            fill="#F9D7FF"
          />
          <path
            d="M341.906 56.3073C341.805 54.2149 340.901 52.2414 339.382 50.7961C337.863 49.3508 335.845 48.5446 333.747 48.5444H182.151C179.51 48.5443 176.955 49.475 174.935 51.1724C172.915 52.8698 171.56 55.2249 171.11 57.822L170.982 58.5613C170.742 59.941 170.808 61.3562 171.174 62.7078C171.541 64.0594 172.198 65.3148 173.102 66.386C174.005 67.4572 175.132 68.3184 176.404 68.9092C177.675 69.5 179.061 69.8062 180.464 69.8064H335.995C336.842 69.8062 337.68 69.6344 338.458 69.3015C339.237 68.9685 339.939 68.4812 340.524 67.8692C341.108 67.2571 341.562 66.5331 341.857 65.7408C342.153 64.9486 342.285 64.1046 342.244 63.2602L341.906 56.3073Z"
            fill="#B985DE"
          />
          <path
            d="M181.201 309.625C181.183 309.132 179.035 260.126 157.584 242.479C151.228 237.25 143.718 235.263 135.265 236.569C117.476 239.317 105.172 252.374 98.6967 275.398C93.8312 292.685 94.2848 309.403 94.2897 309.569L92.6536 309.621C92.6536 309.456 92.1852 292.522 97.1068 274.991C100.011 264.655 104.199 256.123 109.536 249.636C116.255 241.472 124.827 236.534 135.013 234.96C143.946 233.58 151.891 235.688 158.623 241.226C167.886 248.848 174.777 262.679 179.104 282.338C182.312 296.915 182.829 309.444 182.834 309.569L181.201 309.625Z"
            fill="#9239C8"
          />
          <path
            d="M25.4783 445.78L47.2213 302.706C47.321 302.044 47.6523 301.439 48.1566 300.997C48.6608 300.556 49.3053 300.307 49.9757 300.295L175.618 298.223L202.795 459.747H36.1741C29.1051 459.747 24.4458 452.766 25.4783 445.78Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M199.845 300.281L175.621 298.223L202.799 459.747L229.628 459.055C230.25 459.039 230.861 458.887 231.417 458.609C231.973 458.332 232.461 457.935 232.847 457.448C233.233 456.961 233.507 456.396 233.65 455.792C233.792 455.187 233.8 454.559 233.672 453.951L201.722 301.822C201.602 301.405 201.358 301.035 201.023 300.76C200.688 300.485 200.277 300.318 199.845 300.281Z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M154.116 309.107C154.097 308.613 151.95 259.606 130.499 241.959C124.142 236.732 116.633 234.743 108.18 236.049C90.3907 238.797 78.0867 251.854 71.6115 274.878C66.746 292.165 67.1996 308.883 67.2045 309.049L65.5684 309.1C65.5684 308.936 65.1 292.004 70.0215 274.473C72.926 264.137 77.1136 255.603 82.4508 249.118C89.1702 240.952 97.7417 236.014 107.928 234.44C116.861 233.06 124.805 235.168 131.538 240.706C140.801 248.328 147.691 262.159 152.019 281.818C155.227 296.395 155.743 308.924 155.748 309.049L154.116 309.107Z"
            fill="#9239C8"
          />
          <path
            d="M342.48 447.26C342.48 447.26 343.356 451.953 343.809 456.6C343.85 457.032 343.802 457.466 343.67 457.879C343.538 458.291 343.323 458.673 343.039 459C342.755 459.327 342.407 459.594 342.017 459.783C341.627 459.972 341.203 460.081 340.769 460.102C334.566 460.418 321.298 461.047 316.524 460.879C316.358 460.873 316.196 460.827 316.051 460.745C315.907 460.663 315.783 460.548 315.692 460.409C315.601 460.271 315.545 460.112 315.527 459.947C315.51 459.782 315.532 459.615 315.593 459.46C315.809 458.901 316.233 458.446 316.777 458.191L338.607 447.922L342.48 447.26Z"
            fill="#1A0F91"
          />
          <path
            d="M343.135 444.493L342.521 450.12C342.521 450.12 338.696 451.941 336.054 449.956L337.585 443.676L343.135 444.493Z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M341.622 338.817C341.926 340.282 343.872 412.057 336.493 444.299C336.433 444.553 336.466 444.821 336.587 445.053C336.708 445.285 336.909 445.466 337.152 445.563C339.159 446.316 341.277 446.725 343.42 446.773C343.837 446.783 344.246 446.654 344.582 446.406C344.917 446.159 345.161 445.807 345.275 445.407C347.401 437.922 357.574 400.425 358.605 364.689C359.76 324.757 358.592 363.329 358.592 363.329L357.456 338.669L341.622 338.817Z"
            fill="#1A0F91"
          />
          <path
            d="M367.728 449.473C367.728 449.473 369.073 451.834 370.416 458.125C370.474 458.393 370.475 458.67 370.419 458.938C370.363 459.207 370.251 459.46 370.091 459.683C369.93 459.905 369.725 460.091 369.488 460.229C369.25 460.368 368.987 460.454 368.714 460.484C366.667 460.705 363.515 460.993 360.987 461C360.712 461.001 360.44 460.945 360.188 460.834C359.936 460.724 359.711 460.561 359.527 460.358C359.342 460.154 359.203 459.914 359.118 459.653C359.032 459.392 359.003 459.116 359.032 458.843C359.291 456.538 360.128 452.751 362.793 449.788L367.728 449.473Z"
            fill="#09005D"
          />
          <path
            d="M368.821 442.229L367.337 450.744C367.337 450.744 365.805 451.905 362.743 451.103L363.403 441.951L368.821 442.229Z"
            fill="url(#paint4_linear)"
          />
          <path
            d="M372.069 340.731C378.717 378.546 371.599 434.355 369.123 445.288C369.05 445.616 368.881 445.914 368.638 446.146C368.396 446.378 368.09 446.534 367.759 446.592C366.604 446.795 364.714 446.968 362.841 446.444C362.526 446.355 362.246 446.17 362.042 445.914C361.839 445.658 361.72 445.345 361.704 445.018C361.274 436.203 361.374 382.054 355.316 360.712C355.009 359.631 354.337 358.69 353.413 358.048L341.967 350.089L340.977 337.054L372.069 340.731Z"
            fill="#1A0F91"
          />
          <path
            d="M331.322 269.355C350.161 268.861 370.998 273.915 370.998 273.915C376.936 306.572 372.648 344.346 372.648 344.346C358.097 348.297 341.586 342.308 341.586 342.308C339.031 328.659 327.352 290.281 327.352 290.281C318.136 289.227 310.529 286.649 304.248 283.201C308.959 277.644 311.138 270.609 312.075 266.454C318.761 268.459 325.513 269.498 331.322 269.355Z"
            fill="url(#paint5_linear)"
          />
          <path
            d="M275.407 242.175C277.236 239.926 279.589 240.959 279.589 240.959C282.472 252.624 297.1 261.965 312.081 266.455C311.144 270.611 308.966 277.651 304.255 283.203C277.717 268.638 275.534 243.064 275.407 242.175Z"
            fill="url(#paint6_linear)"
          />
          <path
            d="M275.862 242.739C275.674 243.354 277.925 244.27 279.222 241.751C279.256 240.547 279.941 235.379 279.21 234.404C275.319 229.213 267.812 222.733 267.812 222.733C266.565 223.984 267.614 225.193 267.614 225.193L271.262 228.914C270.518 230.283 269.613 231.444 269.192 233.096C268.888 234.357 269.081 235.687 269.731 236.81C270.376 237.944 271.257 239.418 272.085 240.57C273.654 242.744 275.862 242.739 275.862 242.739Z"
            fill="url(#paint7_linear)"
          />
          <path
            d="M272.863 231.322L276.182 234.077L277.242 236.841L274.34 240.119C274.34 240.119 273.299 234.625 272.555 233.441C271.811 232.257 272.184 231.683 272.863 231.322Z"
            fill="url(#paint8_linear)"
          />
          <path
            d="M363.946 292.187L370.998 273.912C370.998 273.912 388.71 285.68 391.75 300.786C388.427 301.061 382.383 301.983 376.49 305.024C375.12 297.887 363.946 292.187 363.946 292.187Z"
            fill="url(#paint9_linear)"
          />
          <path
            d="M348.731 309.47C348.731 309.47 368.595 320.428 375.171 310.848C376.537 308.857 376.848 306.896 376.49 305.021C382.383 301.98 388.427 301.058 391.75 300.783C392.665 305.337 392.245 310.193 389.606 315.124C378.132 336.558 346.156 314.358 346.156 314.358L348.731 309.47Z"
            fill="url(#paint10_linear)"
          />
          <path
            d="M350.261 253.896L356.42 271.146C356.47 271.284 356.469 271.435 356.418 271.573C356.367 271.711 356.269 271.827 356.141 271.9C354.517 272.817 348.653 275.479 341.517 271.057C341.148 270.831 340.834 270.528 340.596 270.168C340.358 269.807 340.203 269.399 340.141 268.972L338.056 254.013L350.261 253.896Z"
            fill="url(#paint11_linear)"
          />
          <path
            d="M337.063 237.995C337.063 237.995 330.6 250.427 331.49 255.416C332.381 260.405 340.537 259.968 347.622 255.842L352.473 242.439L337.063 237.995Z"
            fill="url(#paint12_linear)"
          />
          <path
            d="M347.245 249.286C347.176 249.257 347.115 249.213 347.065 249.158C347.016 249.103 346.978 249.038 346.957 248.967C346.935 248.896 346.929 248.821 346.939 248.748C346.949 248.674 346.975 248.604 347.015 248.542C347.535 247.733 348.508 246.082 348.81 244.643C348.81 244.643 349.986 243.876 345.78 242.913C341.574 241.95 335.304 240.455 333.956 236.902C332.637 233.444 335.925 224.665 341.366 231.579C341.414 231.641 341.476 231.691 341.546 231.725C341.616 231.759 341.693 231.777 341.771 231.777C341.85 231.777 341.927 231.759 341.997 231.725C342.067 231.691 342.129 231.641 342.176 231.579C343.045 230.426 345.34 228.027 348.569 229.438C351.591 230.755 351.952 232.957 351.921 234.066C351.918 234.143 351.933 234.219 351.964 234.288C351.995 234.358 352.041 234.42 352.1 234.47C352.158 234.519 352.227 234.555 352.301 234.574C352.375 234.593 352.452 234.595 352.527 234.58C354.046 234.276 357.695 233.992 359.435 237.708C360.868 240.774 359.973 242.526 359.15 243.387C359.088 243.45 359.044 243.528 359.022 243.613C359 243.697 359 243.787 359.023 243.872C359.046 243.956 359.09 244.034 359.152 244.096C359.214 244.159 359.291 244.204 359.375 244.228C360.378 244.525 361.685 245.359 361.571 247.699C361.422 250.934 357.799 250.684 356.766 250.545C356.683 250.534 356.599 250.544 356.52 250.572C356.441 250.601 356.371 250.648 356.314 250.71C355.44 251.698 351.497 255.88 347.19 256.422C347.104 256.431 347.017 256.419 346.937 256.386C346.857 256.353 346.787 256.3 346.732 256.233C346.678 256.166 346.642 256.086 346.626 256.001C346.611 255.916 346.617 255.828 346.644 255.747L348.459 250.313C348.499 250.192 348.493 250.06 348.441 249.942C348.389 249.825 348.294 249.732 348.177 249.681L347.245 249.286Z"
            fill="#1A0F91"
          />
          <path
            d="M346.503 250.061C346.503 250.061 351.159 246.806 351.526 249.506C351.894 252.207 347.344 253.458 345.074 252.47L346.503 250.061Z"
            fill="url(#paint13_linear)"
          />
          <path
            d="M352.791 326.789L341.97 289.066C341.911 288.856 341.79 288.67 341.624 288.529C341.459 288.388 341.255 288.299 341.038 288.274C339.077 288.045 333.397 287.404 326.838 286.851C318.936 286.183 309.761 285.643 304.278 286.102C304.098 286.117 303.925 286.175 303.772 286.272C303.62 286.369 303.494 286.501 303.405 286.658C303.316 286.814 303.267 286.99 303.261 287.17C303.256 287.351 303.295 287.529 303.374 287.691C306.492 293.988 319.317 319.602 325.739 327.992C325.844 328.129 325.979 328.24 326.133 328.316C326.288 328.392 326.459 328.431 326.631 328.43L351.739 328.196C351.909 328.194 352.077 328.153 352.229 328.076C352.38 327.999 352.512 327.888 352.614 327.752C352.716 327.616 352.785 327.458 352.816 327.291C352.846 327.124 352.838 326.952 352.791 326.789Z"
            fill="#1A0F91"
          />
          <path
            d="M350.177 317.676C350.177 317.676 351.685 311.489 351.076 309.292C350.467 307.096 349.29 304.276 345.363 304.903C341.436 305.53 336.804 307.182 336.804 307.182C336.804 307.182 337.039 310.04 340.022 309.118L338.172 310.547C338.172 310.547 338.436 312.837 340.957 311.614L339.236 313.509C339.236 313.509 340.319 315.389 342.173 314.184C344.027 312.979 344.917 318.418 350.177 317.676Z"
            fill="url(#paint14_linear)"
          />
          <path
            d="M327.557 298.111C327.557 298.738 327.37 299.351 327.021 299.873C326.672 300.394 326.176 300.801 325.596 301.041C325.015 301.281 324.377 301.343 323.76 301.221C323.144 301.099 322.578 300.797 322.134 300.353C321.69 299.91 321.387 299.345 321.265 298.73C321.142 298.114 321.205 297.477 321.445 296.897C321.686 296.318 322.093 295.823 322.615 295.474C323.138 295.126 323.752 294.94 324.38 294.94C325.223 294.94 326.031 295.274 326.626 295.869C327.222 296.463 327.557 297.27 327.557 298.111Z"
            fill="#F9D7FF"
          />
          <path
            d="M340.751 300.202L338.543 292.734C338.414 292.288 338.167 291.886 337.828 291.57C337.489 291.254 337.07 291.036 336.616 290.939C329.711 289.5 310.811 289.195 310.621 289.192L310.633 288.349C311.413 288.362 329.805 288.66 336.79 290.114C337.393 290.242 337.95 290.532 338.401 290.951C338.853 291.371 339.181 291.905 339.353 292.496L341.556 299.956L340.751 300.202Z"
            fill="#F9D7FF"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="471.002"
              y1="510.054"
              x2="144.28"
              y2="156.782"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DAE3FE" />
              <stop offset="1" stopColor="#E9EFFD" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="183.581"
              y1="311.108"
              x2="49.6379"
              y2="471.283"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF928E" />
              <stop offset="1" stopColor="#FE7062" />
            </linearGradient>
            <linearGradient
              id="paint2_linear"
              x1="169613"
              y1="297213"
              x2="68519.8"
              y2="325657"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF928E" />
              <stop offset="1" stopColor="#FE7062" />
            </linearGradient>
            <linearGradient
              id="paint3_linear"
              x1="343.415"
              y1="448.48"
              x2="336.284"
              y2="448.552"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint4_linear"
              x1="91249.4"
              y1="29587"
              x2="91476.4"
              y2="29587"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint5_linear"
              x1="160571"
              y1="-452301"
              x2="118945"
              y2="-391232"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF928E" />
              <stop offset="1" stopColor="#FE7062" />
            </linearGradient>
            <linearGradient
              id="paint6_linear"
              x1="87310.2"
              y1="89073.3"
              x2="77467.3"
              y2="85077.6"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint7_linear"
              x1="27032.9"
              y1="35376.5"
              x2="26872.4"
              y2="44096.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint8_linear"
              x1="12643.1"
              y1="11934.8"
              x2="8572.23"
              y2="17050.1"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint9_linear"
              x1="68109.4"
              y1="-175074"
              x2="51845.2"
              y2="-150899"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF928E" />
              <stop offset="1" stopColor="#FE7062" />
            </linearGradient>
            <linearGradient
              id="paint10_linear"
              x1="132737"
              y1="53174.8"
              x2="127125"
              y2="63565.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint11_linear"
              x1="48160.3"
              y1="43080.8"
              x2="48834.1"
              y2="37763.7"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint12_linear"
              x1="-209370"
              y1="58331.1"
              x2="-206823"
              y2="56750.9"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint13_linear"
              x1="-51058.7"
              y1="4237.12"
              x2="-50601.6"
              y2="3891.28"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
            <linearGradient
              id="paint14_linear"
              x1="-210468"
              y1="30638.3"
              x2="-211729"
              y2="30638.3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FEBBBA" />
              <stop offset="1" stopColor="#FF928E" />
            </linearGradient>
          </defs>
        </JournalIllustration>
      </JournalLayout>
    </Container>
  );
};

export default JournalFuture;

const Container = styled.div`
  min-height: calc(100vh - 70px);
`;
const Wrapper = styled.div`
  width: 90%;
  display: flex;
  border-radius: 20px;
  background: #ffffff;
  margin: 0% 5% 5% 5%;
  &:first-child {
    margin-top: 5%;
  }
  flex-direction: column;
  box-sizing: border-box;
  padding: 32px 40px 32px 40px;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
  @media (max-width: 780px) {
    padding: 30px;
  }
`;
const Heading = styled(H1)`
  line-height: 57px;
  margin: 0;
  background: transparent;
  text-transform: uppercase;
`;
const HeadingArea = styled.div`
  display: flex;
  padding: 2% 1% 0% 5%;
  align-items: center;
  margin-left: 200px;
  justify-content: space-between;
  @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 780px) {
    width: 90%;
    margin-top: 20px;
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const Button = styled.button`
  background: #7c40a9;
  border-radius: 10px;
  height: 44px;
  width: 288px;
  outline: 0;
  border: 0;
  &:hover {
    border: 0;
    outline: 0;
    background: #a156db;
    cursor: pointer;
  }
  &:focus {
    border: 0;
    outline: 0;
  }
  margin: 0px 0px 5px 0px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  transition: 0.3s;
  line-height: 19px;
  @media (max-width: 780px) {
    display: none;
  }
`;
const AddButton = styled.button`
  background: #7c40a9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  margin: auto;
  transition: 0.3s;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 20px 31px rgba(124, 64, 169, 0.3);
  @media (min-width: 780px) {
    display: none;
  }
  &:hover {
    border: 0;
    outline: 0;
    background: #a156db;
    cursor: pointer;
  }
  &:focus {
    border: 0;
    outline: 0;
  }
`;
const BackgroundIllustration1 = styled.svg`
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 600px;
  position: fixed;
`;
const BackgroundIllustration2 = styled.svg`
  bottom: -90px;
  right: 0;
  z-index: -1;
  max-width: 600px;
  position: fixed;
  @media (max-width: 780px) {
    max-width: 90%;
  }
`;
const Arrow = styled.div`
  position: absolute;
  top: 16px;
  left: 5%;
`;
const JournalIllustration = styled.svg`
  bottom: -40px;
  left: 0;
  right: 0;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: -1;
  position: absolute;
  @media (max-width: 780px) {
    width: 90%;
    max-width: 300px;
    margin-left: auto;
    margin-bottom: 70px;
  }
`;
