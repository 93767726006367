import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import MorningQuestions from './MorningQuestions';
import EveningQuestions from './EveningQuestions';
import BackArrow from '../ReusableItems/BackArrow';
import MyNotes from './MyNotes';
import JournalLayout from './JournalLayout';
import { Context } from '../Context/globalContextProvider';
import 'firebase/auth';
import 'firebase/firestore';
import { FirebaseUploadJournalEntries } from '../Context/FirebaseContext';

const Wrapper = styled.section`
  display: flex;
  height: max-content;
  width: 90%;
  margin: 4%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 26px 26px 8px 26px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
  @media (max-width: 900px) {
    margin: 4% 0% 4% 0%;
  }
  @media (max-width: 780px) {
    padding: 28px 32px 8px 32px;
    margin: 5%;
  }
`;
const JournalImage = styled.img`
  bottom: 0px;
  left: 0;
  right: 0;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: -1;
  position: absolute;
  @media (max-width: 780px) {
    width: 90%;
    max-width: 300px;
    margin-left: auto;
    margin-bottom: 70px;
  }
`;
const BackgroundIllustration1 = styled.svg`
  top: -100px;
  left: 0;
  z-index: -1;
  max-width: 600px;
  position: fixed;
  @media (max-width: 780px) {
    position: absolute;
    top: -35%;
    width: 100%;
    left: 0;
  }
`;
const BackgroundIllustration2 = styled.svg`
  bottom: -90px;
  right: 0;
  z-index: -1;
  max-width: 600px;
  position: fixed;
  @media (max-width: 780px) {
    max-width: 90%;
  }
`;
const Arrow = styled.div`
  position: absolute;
  top: 16px;
  left: 5%;
`;
// const Datepicker = styled(DatePicker)`
//   outline: 0;
//   border: 0;
//   width: 100%;
//   height: 32px;
//   margin-bottom: 10px;
//   background: #ECECEC;
//   text-align: center;
// `;
// const Illustration = styled.img`
//   bottom: 0;
//   margin-left: auto;
//   position: absolute;
// `;

// function compareDates(date) {
//   const currentDate = new Date();
//   let result;
//   if (date.getFullYear() === currentDate.getFullYear()
//     && date.getMonth() === currentDate.getMonth()
//     && date.getDate() === currentDate.getDate()) {
//     result = 0;
//   } else if (date.getFullYear() < currentDate.getFullYear()) {
//     result = -1;
//   } else if (date.getFullYear() === currentDate.getFullYear()
//     && date.getMonth() < currentDate.getMonth()) {
//     result = -1;
//   } else if (date.getFullYear() === currentDate.getFullYear()
//     && date.getMonth() === currentDate.getMonth()
//     && date.getDate() < currentDate.getDate()) {
//     result = -1;
//   } else if (date.getFullYear() > currentDate.getFullYear()) {
//     result = 1;
//   } else if (date.getFullYear() === currentDate.getFullYear()
//     && date.getMonth() > currentDate.getMonth()) {
//     result = 1;
//   } else if (date.getFullYear() === currentDate.getFullYear()
//     && date.getMonth() === currentDate.getMonth()
//     && date.getDate() > currentDate.getDate()) {
//     result = 1;
//   }
//   return result;
// }

// user, bucketId, entryId, data

const Journal = () => {
  const userData = React.useContext(Context);
  const { user, answers, setAnswers } = React.useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const [keyDate, setKeyDate] = React.useState(
    moment(userData.journalDate).format('DD-MM-YYYY'),
  );
  const [filteredAnswers, setFilteredAnswers] = React.useState({});

  React.useEffect(() => {
    setFilteredAnswers(answers.find((a) => a.keyDate === keyDate) ?? {});
  }, [answers, keyDate]);

  const saveJournal = (answerName, answer) => {
    const currentDateAnswers = {
      ...filteredAnswers,
      keyDate,
      [answerName]: answer,
    };

    const newAnswersList = [
      ...answers.filter((a) => a.keyDate !== keyDate),
      currentDateAnswers,
    ];

    console.log(newAnswersList);
    setAnswers(newAnswersList);
    if (user) {
      FirebaseUploadJournalEntries(user, newAnswersList);
    }
  };

  return (
    <div>
      <BackgroundIllustration1
        width="800"
        height="927"
        viewBox="0 0 800 927"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.7"
          d="M554.604 246.453C140.758 400.268 557.662 749.06 372.152 812.106C186.642 875.152 -32.443 724.12 -117.187 474.765C-201.932 225.41 -120.245 -27.8417 65.2647 -90.8881C250.775 -153.935 831.467 143.551 554.604 246.453Z"
          fill="#E8F2EA"
        />
      </BackgroundIllustration1>
      <BackgroundIllustration2
        width="762"
        height="747"
        viewBox="0 0 762 747"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M467.478 223.672C584.745 772.692 74.658 523.783 131.817 766.323C188.975 1008.86 431.928 1159.14 674.467 1101.98C917.006 1044.83 1067.29 801.872 1010.13 559.333C952.97 316.794 389.026 -143.623 467.478 223.672Z"
          fill="#FCF2E6"
        />
      </BackgroundIllustration2>
      <JournalLayout>
        <Arrow>
          <BackArrow />
        </Arrow>
        <JournalImage src="journal-image.svg" alt="journal-image" />
        <Wrapper>
          <MorningQuestions
            answers={filteredAnswers}
            saveJournal={saveJournal}
          />
          <EveningQuestions
            answers={filteredAnswers}
            saveJournal={saveJournal}
          />
          <MyNotes answers={filteredAnswers} saveJournal={saveJournal} />
        </Wrapper>
      </JournalLayout>
    </div>
  );
};

export default Journal;
