import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import emailjs from 'emailjs-com';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactNotificationComponent from '../Notifications/ReactNotification';
// import swDev from '../../swDev';

const userId = 'user_yaskgZ8UuZ5gfi9ONkUJ1';
const templateId = 'template_yqw1eq8';
const serviceId = 'service_ogzxovj';

emailjs.init(userId);

const translations = [
  {
    English: 'Morning reminder',
    Russian: 'Утреннее напоминание',
    Spanish: 'Recordatorio matutino',
  },
  {
    English: 'Smile and fill in the morning powerful questions',
    Russian: 'Улыбайтесь и отвечайте на важные вопросы утром',
    Spanish: 'Sonríe y llena preguntas importantes por la mañana',
  },
  {
    English: 'Evening reminder',
    Russian: 'Вечернее напоминание',
    Spanish: 'Recordatorio nocturno',
  },
  {
    English: 'Reflect on your day',
    Russian: 'Подумайте о своем дне',
    Spanish: 'Reflexiona sobre tu día',
  },
];

export const SettingsContext = React.createContext();
export const SettingsProvider = ({ children }) => {
  const [swRegistration, setSwRegistration] = React.useState(null);
  const [language, setLanguage] = React.useState('English');
  const [morningReminderOff, toggleMorningReminder] = React.useState(true);
  const [eveningReminderOff, toggleEveningReminder] = React.useState(true);
  const [morningReminderDays, updateMorningReminderDays] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [eveningReminderDays, updateEveningReminderDays] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [morningReminderTime, setMorningReminderTime] = React.useState('06:00');
  const [eveningReminderTime, setEveningReminderTime] = React.useState('18:00');
  const [rating, setRating] = React.useState(0);
  const [feedback, setFeedback] = React.useState('');

  const [showNotification, setShowNotification] = React.useState(false);
  const [notification, setNotification] = React.useState({
    title: '',
    body: '',
  });

  const getDaysNames = (array) => {
    const days = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < array.length; index++) {
      if (array[index]) {
        if (index === 0) days.push('Mon');
        if (index === 1) days.push('Tue');
        if (index === 2) days.push('Wed');
        if (index === 3) days.push('Thu');
        if (index === 4) days.push('Fri');
        if (index === 5) days.push('Sat');
        if (index === 6) days.push('Sun');
      }
    }
    return days;
  };

  const registerServiceWorker = async () => {
    const url = `${window.location.protocol}//${window.location.host}/sw.js`;
    return navigator.serviceWorker.register(url);
  };
  const requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== 'granted') {
      throw new Error('Permission not granted for Notification');
    }
  };
  const showLocalNotification = React.useCallback(
    (title, body) => {
      const options = {
        body,
        // here you can add more properties like icon, image, vibrate, etc.
      };
      swRegistration.showNotification(title, options);
    },
    [swRegistration],
  );

  React.useEffect(() => {
    async function fetchData() {
      const swRegistrationlet = await registerServiceWorker();
      setSwRegistration(swRegistrationlet);
      await requestNotificationPermission();
    }
    fetchData();
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date(Date.now());
      const day = date.toLocaleDateString('en-EN', { weekday: 'short' });
      if (!morningReminderOff) {
        const morningDays = getDaysNames(morningReminderDays);
        if (morningDays.includes(day)) {
          const time = date.toLocaleString('en-EN', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          });
          if (time === morningReminderTime) {
            showLocalNotification(
              translations[0][language],
              translations[1][language],
            );
          }
        }
      }
    }, 60000); //One minute: 60000

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    morningReminderDays,
    morningReminderOff,
    morningReminderTime,
    showLocalNotification,
  ]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date(Date.now());
      const day = date.toLocaleDateString('en-EN', { weekday: 'short' });
      if (!eveningReminderOff) {
        const morningDays = getDaysNames(eveningReminderDays);
        if (morningDays.includes(day)) {
          const time = date.toLocaleString('en-EN', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          });
          if (time === eveningReminderTime) {
            showLocalNotification(
              translations[2][language],
              translations[3][language],
            );
          }
        }
      }
    }, 60000); //One minute: 60000

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eveningReminderDays,
    eveningReminderOff,
    eveningReminderTime,
    showLocalNotification,
  ]);

  const [user] = useAuthState(firebase.auth());
  const [firestoreValue] = useDocumentData(
    user !== null
      ? firebase.firestore().collection('users').doc(user.uid)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  React.useEffect(() => {
    if (firestoreValue?.language !== undefined)
      setLanguage(firestoreValue?.language);
    if (firestoreValue?.morningReminderOff !== undefined) {
      toggleMorningReminder(firestoreValue?.morningReminderOff);
    }
    if (firestoreValue?.eveningReminderOff !== undefined) {
      toggleEveningReminder(firestoreValue?.eveningReminderOff);
    }
    if (firestoreValue?.morningReminderDays !== undefined) {
      updateMorningReminderDays(firestoreValue?.morningReminderDays);
    }
    if (firestoreValue?.eveningReminderDays !== undefined) {
      updateEveningReminderDays(firestoreValue?.eveningReminderDays);
    }
    if (firestoreValue?.morningReminderTime !== undefined) {
      setMorningReminderTime(firestoreValue?.morningReminderTime);
    }
    if (firestoreValue?.eveningReminderTime !== undefined) {
      setEveningReminderTime(firestoreValue?.eveningReminderTime);
    }
    if (firestoreValue?.rating !== undefined) setRating(firestoreValue?.rating);
    if (firestoreValue?.feedback !== undefined)
      setFeedback(firestoreValue?.feedback);
  }, [firestoreValue]);

  React.useEffect(() => {
    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(searchParams.entries());
      const saveSettings = params?.saveSettings === 'true';
      //console.log('NEW REGISTER SAVE SETTINGS', saveSettings, params);
      if (saveSettings) {
        if (user) {
          console.log('SAVE SETTINGS');
          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .set(
              {
                language,
                morningReminderOff,
                eveningReminderOff,
                morningReminderDays,
                eveningReminderDays,
                morningReminderTime,
                eveningReminderTime,
              },
              { merge: true },
            )
            .then(() => {
              console.log('SETTINGS UNSAVED DATA WAS SAVED');
              window.history.pushState(null, '', window.location.pathname);
            })
            .catch((error) => {
              console.error('Error saving settings unsaved data: ', error);
            });
        }
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, firestoreValue]);

  const show = React.useCallback(() => {
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
    //console.log('show');
    return (
      <ReactNotificationComponent
        key="ReactNotificationComponentKey"
        title={notification.title}
        body={notification.body}
      />
    );
  }, [notification.body, notification.title]);

  const sendEmail = ({ feedbackp, ratingp }) => {
    const templateParams = {
      to_name: 'Irina',
      ratingMessage: `Liveweeks has received ${ratingp} stars`,
      feedbackMessage: feedbackp,
    };
    emailjs.send(serviceId, templateId, templateParams).then(
      (response) => {
        //console.log('SUCCESS!', response.status, response.text);
      },
      (err) => {
        //console.log('FAILED...', err);
      },
    );
  };
  return (
    <SettingsContext.Provider
      value={{
        rating,
        setRating,
        feedback,
        setFeedback,
        language,
        setLanguage,
        morningReminderOff,
        toggleMorningReminder,
        eveningReminderOff,
        toggleEveningReminder,
        morningReminderDays,
        updateMorningReminderDays,
        eveningReminderDays,
        updateEveningReminderDays,
        morningReminderTime,
        setMorningReminderTime,
        eveningReminderTime,
        setEveningReminderTime,
        showNotification,
        setShowNotification,
        notification,
        setNotification,
        sendEmail,
      }}
    >
      {showNotification ? show() : <></>}
      {children}
    </SettingsContext.Provider>
  );
};
