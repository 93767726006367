import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { H1, Body1 } from '../Typography/Fonts';
import { Context } from '../Context/globalContextProvider';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: -10%;
  align-items: center;
  @media (max-width: 780px) {
    margin-top: -100px;
    overflow: hidden;
  }
`;
const BackgroundImage = styled.div`
  top: 0;
  right: 0;
  z-index: -1;
  position: fixed;
  max-width: 50vw;
  @media (max-width: 780px) {
    display: none;
  }
`;
const BackgroundImageMobile = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  @media (min-width: 780px) {
    display: none;
  }
`;
const BackgroundIllustration = styled.img`
  bottom: 0;
  left: 0;
  width: 900px;
  min-width: 600px;
  max-width: 100vw;
  padding-left: 5%;
  position: fixed;
  @media (max-width: 780px) {
    padding-right: 10%;
    margin: auto;
    // position: fixed;
    transform: translateX(-10%);
  }
`;
const Title = styled(H1)`
  margin: 0;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 21px;
    line-height: 26px;
  }
`;
const BodyText = styled(Body1)`
  @media (max-width: 780px) {
    font-size: 21px;
    line-height: 26px;
  }
`;

// function useWindowSize() {
//   const [size, setSize] = React.useState([0, 0]);
//   React.useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
// }

const Welcome1 = () => {
  //const [width, height] = useWindowSize();
  const [redirect, setRedirect] = React.useState(false);
  const { firestoreUserDoc } = React.useContext(Context);
  const [redirectLink, setRedirectLink] = React.useState('');
  React.useEffect(() => {
    setTimeout(() => {
      setRedirect(true);
    }, 3000);
  });

  React.useEffect(() => {
    const redirectLinkAux =
      firestoreUserDoc?.lifeExpectency && firestoreUserDoc?.birthday
        ? '/calendar'
        : '/birthdayinfo';
    setRedirectLink(redirectLinkAux);
    console.log(redirectLinkAux);
  }, [firestoreUserDoc, setRedirectLink]);
  return redirect ? (
    <Redirect to={redirectLink} />
  ) : (
    <Wrapper>
      <MetaTags>
        <title>Life Calendar and Journal | Liveweeks</title>
        <meta
          name="description"
          content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
        />
      </MetaTags>
      <BackgroundImage>
        <svg
          width="550"
          height="405"
          viewBox="0 0 800 585"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            d="M531.847 401.117C348.98 118.261 255.064 322.477 156.711 232.907C-6.10585 84.629 245.576 -129.229 439.32 -182.432C633.065 -235.635 865.182 37.2043 871.226 186.556C879.324 386.701 637.652 564.774 531.847 401.117Z"
            fill="#A8D29F"
          />
          <path
            opacity="0.7"
            d="M535.611 283.027C323.202 21.6259 251.942 234.808 144.472 156.409C-33.4375 26.6252 193.619 -213.218 380.466 -287.079C567.313 -360.94 827.599 -114.827 849.772 32.9926C879.486 231.086 658.508 434.27 535.611 283.027Z"
            fill="#E0F5DC"
          />
        </svg>
      </BackgroundImage>
      <BackgroundImageMobile>
        <svg
          width="414"
          height="474"
          viewBox="0 0 414 474"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            d="M256.388 192.262C-9.77592 169.794 72.796 327.772 -31.1069 346.033C-203.112 376.262 -215.208 114.624 -154.611 -32.7376C-94.0152 -180.099 189.754 -193.389 286.389 -124.738C415.889 -32.7377 410.388 205.262 256.388 192.262Z"
            fill="#A8D29F"
          />
          <path
            opacity="0.7"
            d="M184.124 132.621C-82.9083 139.094 16.2778 287.207 -85.0382 316.606C-252.76 365.275 -293.105 106.483 -248.815 -46.5714C-204.524 -199.626 76.1391 -243.553 179.637 -185.764C318.334 -108.321 338.627 128.876 184.124 132.621Z"
            fill="#E0F5DC"
          />
        </svg>
      </BackgroundImageMobile>
      <BackgroundIllustration
        src="welcome-screen-image.png"
        alt="background-illustration"
      />
      <Title>LIVEWEEKS</Title>
      <BodyText>Value life more</BodyText>
    </Wrapper>
  );
};

export default Welcome1;
