const data = [
  {
    id: 1,
    icon: 'insurance-icon-7.png',
    heading: 'Level term insurance',
    paragraph: 'Insurance company pays out a fixed amount of money if the policyholder passes away within the fixed term of the cover. The price depends on the sum of the cover, your age, health condition and lifestyle.',
  },
  {
    id: 2,
    icon: 'insurance-icon-8.png',
    heading: 'Whole-of-life insurance',
    paragraph: 'Your family will receive compensation whenever you die until you pay premiums. Usually more expensive than level term insurance as it lasts the whole life but not fixed term.',
  },
  {
    id: 3,
    icon: 'insurance-icon-9.png',
    heading: 'Decreasing term insurance',
    paragraph: 'The sum of payout decreases till the end of the term. Used to ensure a specific debt is covered – often a mortgage. Usually cheaper than level term insurance.',
  },
  {
    id: 4,
    icon: 'insurance-icon-10.png',
    heading: 'Critical illness cover',
    paragraph: 'If you are diagnosed with a serious illness during the validity period of the policy, this will be paid in one lump sum tax-free. If you have to stop working, it can provide financial support.',
  },
  {
    id: 5,
    icon: 'insurance-icon-11.png',
    heading: 'Over-50s life insurance',
    paragraph: 'This type of insurance works the same as level term insurance, but the procedure is a bit easier as you don’t need to fill long questionnaire about your health condition. The premiums and payouts more often are fixed.',
  },
  {
    id: 6,
    icon: 'insurance-icon-12.png',
    heading: 'Death-in-service cover',
    paragraph: 'Many companies provide a death-in-service. If you die while you’re employed, a lump-sum payment will be given to your dependants. The payout for death in service is tax-free and is usually a multiple of your annual salary.',
  },
  {
    id: 7,
    icon: 'insurance-icon-13.png',
    heading: 'Life insurance investment',
    paragraph: 'We use modern technologies and advanced solutions to develop our products.',
  },
];

export default data;
