import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 12px;
  @media (max-width: 780px) {
    margin-bottom: 7px;
    transform: scale(0.7);
  }
`;
const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;
const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: ${(props) =>
    props.toggled ? 'rgba(179, 179, 179, 0.3);' : 'rgba(42, 117, 186, 0.3);'};
  border-radius: 36px;
`;
const SwitchButton = styled.span`
  content: '';
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: 0.1s;
  background: #2a75ba;
  transform: translateX(0px);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    transform: translateX(-24px);
    background: #b3b3b3;
  }
  ${SwitchLabel}:active & {
    width: 24px;
  }
`;
const SwitchTag = styled.label`
  font-family: Montserrat;
  pointer-events: none;
  font-size: 8px;
  color: #2a75ba;
  margin-top: 1px;
  transform: translateX(6px);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    transform: translateX(26px);
    color: #b3b3b3;
  }
`;

const ToggleSwitch = (
  { id, toggled, onChange }, //onText, offText,
) => (
  <Wrapper>
    <SwitchInput
      className="switch-checkbox"
      id={id}
      type="checkbox"
      checked={toggled}
      onChange={onChange}
    />
    <SwitchLabel toggled={toggled} className="switch-label" htmlFor={id}>
      <SwitchTag>{toggled ? 'OFF' : 'ON'}</SwitchTag>
      {/*  <SwitchTag>{toggled
          ? offText?.toUpperCase() ?? 'OFF'
          : onText?.toUpperCase() ?? 'ON'}</SwitchTag> */}
      <SwitchButton className="switch-button" />
    </SwitchLabel>
  </Wrapper>
);

export default ToggleSwitch;
