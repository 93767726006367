import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/app';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
import JournalMenu from './JournalMenu';
import 'react-datepicker/dist/react-datepicker.css';
import NavigationBar from '../ReusableItems/NavigationBar';
import { H1 } from '../Typography/Fonts';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import UserIcon from '../ReusableItems/UserIcon';
import { SettingsContext } from '../Context/SettingsContext';

const Container = styled.div`
  margin-left: 200px;
  @media (max-width: 900px) {
    margin-left: 0;
  }
`;
const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: auto;
  width: 100%;
  @media (max-width: 780px) {
    flex-direction: column;
    min-height: calc(100% - 210px);
    margin-bottom: 120px;
  }
`;
const JournalContent = styled.div`
  width: 100%;
  margin-left: 250px;
  box-sizing: border-box;
  @media (max-width: 780px) {
    margin: 5% 5% 5% 5%;
    margin: 0;
    width: 100%;
  }
`;
const Heading = styled(H1)`
  line-height: 57px;
  padding: 2% 0% 0% 5%;
  margin: 0;
  background: transparent;
  text-transform: uppercase;
  @media (max-width: 780px) {
    padding-left: 0;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  padding: 0%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 780px) {
    width: 90%;
    margin-top: 20px;
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const UserIconWrapper = styled.div`
  right: 30px;
  position: absolute;
`;

const translations = [
  {
    English: 'PAST',
    Russian: 'ПРОШЛОЕ',
    Spanish: 'PASADO',
  },
  {
    English: 'NOW',
    Russian: 'СЕЙЧАС',
    Spanish: 'PRESENTE',
  },
  {
    English: 'FUTURE',
    Russian: 'БУДУЩЕЕ',
    Spanish: 'FUTURO',
  },
  {
    English: 'Journal',
    Russian: 'Дневник',
    Spanish: 'Diario',
  },
];

function JournalLayout(props) {
  const { children, futureTab } = props;
  const { language } = React.useContext(SettingsContext);
  const [tabLabels, setTabLabels] = React.useState([
    translations[0][language],
    translations[1][language],
    translations[2][language],
  ]);
  React.useEffect(() => {
    setTabLabels([
      translations[0][language],
      translations[1][language],
      translations[2][language],
    ]);
  }, [language]);
  const tabLinks = ['/journal/past', '/journal', '/journal/future'];
  const [userDetails] = useAuthState(firebase.auth());
  return (
    <div>
      <MetaTags>
        <title>Journal</title>
        <meta
          name="description"
          content="Practice gratefulness, focus on your goals and dream big in Journal."
        />
      </MetaTags>
      <Container>
        {futureTab ? null : (
          <HeadingArea>
            <Heading>{translations[3][language]}</Heading>
            <UserIconWrapper>
              <UserIcon userDetails={userDetails} />
            </UserIconWrapper>
          </HeadingArea>
        )}
        <JournalMenu tabLabels={tabLabels} tabLinks={tabLinks} />
        <Wrapper>
          <JournalContent>{children}</JournalContent>
        </Wrapper>
      </Container>
      <NavigationBar />
      <VerticalNavigationBar />
    </div>
  );
}

export default JournalLayout;
