import React from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import MetaTags from 'react-meta-tags';
import CalendarMenu from './CalendarMenu';
import { H1 } from '../Typography/Fonts';
import { Context } from '../Context/globalContextProvider';
import NavigationBar from '../ReusableItems/NavigationBar';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import MobilePaintSelection from '../ReusableItems/MobilePaintSelection';
import UserIcon from '../ReusableItems/UserIcon';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-bottom: 100px;
    min-height: calc(100vh - 160px);
  }
`;
const Content = styled.div`
  margin-left: 200px;
  @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 780px) {
    margin-left: 0;
  }
`;

const Heading = styled(H1)`
  padding: 1% 0% 0% 5%;
  line-height: 57px;
  margin: 0;
  background: transparent;
  text-transform: uppercase;
  @media (max-width: 780px) {
    padding-left: 0;
    font-size: 17px;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  padding: 0%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 780px) {
    width: 90%;
    margin-top: 20px;
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const BrushIcon = styled.svg`
  position: absolute;
  top: 42px;
  left: 5%;
  @media (min-width: 780px) {
    display: none;
  }
`;
const Body = styled.div`
  width: 100%;
  padding: 3% 3% 3% 3%;
  @media (max-width: 780px) {
    box-sizing: border-box;
    width: 100%;
  }
`;
const BackgroundIllustration = styled.svg`
  top: 0;
  right: 0;
  z-index: -1;
  position: absolute;
  @media (max-width: 780px) {
    display: none;
  }
`;
const BackgroundIllustration1 = styled.svg`
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 90%;
  position: absolute;
  @media (min-width: 781px) {
    display: none;
  }
`;
const BackgroundIllustration2 = styled.svg`
  bottom: 0;
  right: 0;
  z-index: -1;
  max-width: 90%;
  position: absolute;
  @media (min-width: 781px) {
    display: none;
  }
`;

const UserIconWrapper = styled.div`
  right: 30px;
  position: absolute;
`;

const translations = [
  {
    English: 'Life Calendar',
    Russian: 'Календарь жизни',
    Spanish: 'Calendario de vida',
  },
  {
    English: 'Life in Years',
    Russian: 'Жизнь в годах',
    Spanish: 'Vida en años',
  },
  {
    English: 'Life in Weeks',
    Russian: 'Жизнь в неделях',
    Spanish: 'Vida en semanas',
  },
  {
    English: 'Years in weeks',
    Russian: 'Года в неделях',
    Spanish: 'Años en semanas',
  },
  {
    English: 'Life in days',
    Russian: 'Жизнь в днях',
    Spanish: 'Vida en días',
  },
];

const CalendarLayout = (props) => {
  const { children } = props;
  const { language } = React.useContext(SettingsContext);
  const user = React.useContext(Context);
  const [userDetails] = useAuthState(firebase.auth());
  const [tabLabels, setTabLabels] = React.useState([
    translations[1][language],
    translations[2][language],
    translations[3][language],
    translations[4][language],
  ]);
  React.useEffect(() => {
    setTabLabels([
      translations[1][language],
      translations[2][language],
      translations[3][language],
      translations[4][language],
    ]);
  }, [language]);
  const tabLinks = [
    '/calendar/lifeinyears',
    '/calendar',
    '/calendar/yearsinweeks',
    '/calendar/days',
  ];

  return (
    <div style={{ position: 'relative' }}>
      <MetaTags>
        <title>Life Calendar</title>
        <meta
          name="description"
          content="Stay conscious with liveweeks. Analyze your life, reflect on your past and keep memories in Life Calendar."
        />
      </MetaTags>
      {user.paintOpen ? <MobilePaintSelection /> : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <BackgroundIllustration
          width="1295"
          height="1080"
          viewBox="0 0 1295 1080"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            d="M600.972 -623.448C841.835 -253.432 1090.66 -736.7 1192.87 -569.541C1295.08 -402.383 1541.23 1131.76 1282.66 1073.57C1150.39 1093.03 1288.88 765.175 1236.41 432.605C1205.24 235.057 1032.05 -18.3464 899.103 -53.2283C783.215 -83.6332 468.497 58.738 278.311 37.1719C23.5379 -22.3835 439.835 -870.99 600.972 -623.448Z"
            fill="#E8F2EA"
            fillOpacity="0.3"
          />
        </BackgroundIllustration>
        <BackgroundIllustration1
          width="414"
          height="519"
          viewBox="0 0 414 519"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M307.469 107.034C57.0924 200.092 309.319 411.111 197.085 449.254C84.8518 487.397 -47.6945 396.022 -98.965 245.163C-150.235 94.3026 -100.815 -58.9146 11.4187 -97.0578C123.652 -135.201 474.972 44.778 307.469 107.034Z"
            fill="#EEF7FF"
            fillOpacity="0.42"
          />
        </BackgroundIllustration1>
        <BrushIcon
          onClick={() => user.togglePaint(true)}
          width="30"
          height="28"
          viewBox="0 0 30 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30 3.47977C30 2.80466 29.7373 2.16968 29.2594 1.69102C28.7893 1.22134 28.1646 0.958543 27.5002 0.951043C27.4904 0.950925 27.4808 0.950867 27.4711 0.950867C26.8174 0.950867 26.1981 1.19901 25.7244 1.65171L10.6468 16.0686C10.5882 16.064 10.5294 16.0601 10.4702 16.0576C9.1759 16.0036 7.85461 16.4121 6.74818 17.2082C5.91621 17.8068 5.44148 18.5464 4.9824 19.2617C4.77574 19.5836 4.58057 19.8877 4.35428 20.1778C3.49922 21.2742 2.07281 21.7835 0.925723 22.1047L0 22.3639L0.340195 23.263C1.28953 25.772 4.57125 26.9829 7.21869 27.0462C7.29996 27.0481 7.38164 27.0491 7.46408 27.0491C10.0359 27.0491 13.1597 26.0912 14.4884 23.3791C14.9637 22.4091 15.1121 21.3042 14.9425 20.2414L29.2994 5.22645C29.7512 4.75384 30 4.13345 30 3.47977ZM12.9085 22.6051C11.9016 24.6604 9.23631 25.3351 7.26076 25.2873C5.86318 25.2539 3.59496 24.7188 2.48467 23.4552C3.63574 23.0377 4.85801 22.3927 5.74166 21.2598C6.01764 20.906 6.2441 20.5532 6.46313 20.2119C6.86707 19.5826 7.21588 19.0391 7.7758 18.6362C8.56389 18.0692 9.49395 17.7775 10.3967 17.8154C11.7324 17.8713 12.4455 18.7429 12.7516 19.2575C13.3594 20.2792 13.4196 21.5619 12.9085 22.6051ZM28.0277 4.01075L14.2806 18.3878C14.2748 18.3779 14.2695 18.3679 14.2635 18.358C13.8319 17.6324 13.2507 17.0578 12.5703 16.6637L26.9401 2.92348C27.2449 2.6321 27.7176 2.63743 28.0151 2.93485C28.1605 3.08046 28.2406 3.27405 28.2406 3.47977C28.2407 3.67852 28.165 3.86708 28.0277 4.01075Z"
            fill="#2A75BA"
          />
        </BrushIcon>
        <Content>
          <HeadingArea>
            <Heading>{translations[0][language]}</Heading>
            <UserIconWrapper>
              <UserIcon userDetails={userDetails} />
            </UserIconWrapper>
          </HeadingArea>
          <Wrapper>
            <CalendarMenu tabLabels={tabLabels} tabLinks={tabLinks} />
            <Body>{children}</Body>
          </Wrapper>
          <BackgroundIllustration2
            width="316"
            height="418"
            viewBox="0 0 316 418"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M238.045 113.936C297.694 393.199 38.2333 266.59 67.3075 389.96C96.3817 513.329 219.962 589.771 343.332 560.697C466.701 531.623 543.143 408.042 514.069 284.673C484.995 161.303 198.139 -72.8924 238.045 113.936Z"
              fill="#EEEEFF"
              fillOpacity="0.42"
            />
          </BackgroundIllustration2>
        </Content>
        <NavigationBar />
        <VerticalNavigationBar showPaint />
      </div>
    </div>
  );
};

export default CalendarLayout;
