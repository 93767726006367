import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';
import BeautyStars from 'beauty-stars';
import { Link, useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import MetaTags from 'react-meta-tags';
//import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
import NavigationBar from '../ReusableItems/NavigationBar';
import { SettingsContext } from '../Context/SettingsContext';
import { Context } from '../Context/globalContextProvider';
import { H1, Body1, Body2, Body3, Body4 } from '../Typography/Fonts';
import BackArrow from '../ReusableItems/BackArrow';
import ToggleSwitch from '../ReusableItems/ToggleSwitch';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import { firebaseLogout } from '../Context/FirebaseContext';
import ShareModalComponent from '../Modals/ShareModal';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0%;
  min-height: 80vh;
  margin-bottom: 100px;
  width: 100%;
  gap: 64px;
  @media (max-width: 780px) {
    margin-top: 20px;
    gap: 0px;
  }
`;
const BackgroundIllustration1 = styled.svg`
  top: 0;
  left: 0
  z-index: -1;
  max-width: 90%;
  position: absolute;
  @media (min-width: 780px) {
    display: none;
  }
`;
const BackgroundIllustration2 = styled.svg`
  bottom: 0;
  right: 0;
  z-index: -1;
  position: fixed;
  @media (max-width: 780px) {
    display: none;
  }
`;
const BackgroundIllustration3 = styled.svg`
  bottom: 0;
  right: 0;
  z-index: -1;
  max-width: 90%;
  position: fixed;
  @media (min-width: 780px) {
    display: none;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  margin-left: 265px;
  padding: 2% 5% 0% 0%;
  justify-content: space-between;
  @media (max-width: 780px) {
    width: 90%;
    margin-left: 0;
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const Heading = styled(H1)`
  margin: 0;
  line-height: 72px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    z-index: 1;
  }
`;
const Icon = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  outline: 0;
  border: 0;
  background: transparent;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  &:foucs {
    outline: 0;
  }
  @media (max-width: 780px) {
    transform: scale(0.6);
    position: absolute;
    right: 0;
    top: 27px;
    &:hover {
      transform: scale(0.7);
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 200px;
  gap: 40px;
  padding: 0% 5% 0% 5%;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-left: 0;
    z-index: 1;
    gap: 0px;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => props.width};
  @media (max-width: 780px) {
    width: 100%;
    gap: 0px;
  }
`;

//min-height: 450px;
const Section = styled.div`
  display: ${(props) => (props.mobileOnly ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  padding-bottom: 75px;
  @media (max-width: 780px) {
    padding-bottom: 0px;
    display: flex;
    min-height: max-content;
  }
`;
const SectionContent = styled.div`
  width: 100%;
  @media (max-width: 780px) {
    padding-bottom: 5%;
    ${(props) => (props.open ? 'height: max-content;' : 'display: none;')};
  }
`;
const SectionHeadingArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  @media (max-width: 780px) {
    padding: 0% 5% 0% 5%;
  }
`;
const HR = styled.div`
  @media (max-width: 780px) {
    height: 1px;
    border: 0;
    outline: 0;
    background: #e3e3e3;
    width: 100vw;
    margin-left: -5%;
  }
`;
// &:last-child {
//   margin-top: -10px;
// }
const LanguageName = styled(Body4)`
  color: ${(props) => (props.active ? '#2A75BA' : '#262626')};
  transition: 0.3s;
  cursor: pointer;
  line-height: 40px;
  width: max-content;
  @media (max-width: 780px) {
    margin-left: 5%;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  &:hover {
    color: #2a75ba;
  }
`;
const B3 = styled(Body3)`
  color: black;
  line-height: 28px;
  opacity: 1;
  @media (max-width: 780px) {
    font-weight: 700;
  }
`;
const Arrow = styled.div`
  position: absolute;
  top: 18px;
  left: 5%;
  @media (max-width: 780px) {
    z-index: 1;
  }
`;
const ExpandIcon = styled.svg`
  @media (min-width: 780px) {
    display: none;
  }
`;
const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: ${(props) => props.margin};
  @media (max-width: 780px) {
    gap: 15px;
    width: 100%;
    height: 100%;
    margin: ${(props) => (props.margin ? '0px 26px 0px 26px' : '5% 0% 0% 5%')};
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
const InputText = styled(Body2)`
  color: ${(props) => (props.focused ? '#2A75BA' : '#8B9583')};
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 780px) {
    display: ${(props) => (props.hidesmall ? 'none' : 'inherit')};
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
`;
const Input = styled.input`
  padding: 0px;
  background: #dfebfd;
  border-radius: 6px;
  color: ${(props) => (props.active ? '#2A75BA' : '#8B9583')};

  width: ${(props) => props.width};
  text-align: ${(props) => props.textAlign || 'center'};
  border: 3px solid ${(props) => (props.invalid ? 'red' : 'transparent')};
  outline: 0;
  height: 38px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  transition: 0.3s;
  white-space: normal !important;
  &:focus {
    color: #262626;
    background: white;
    border: 3px solid #dfebfd;
  }
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
  @media (max-width: 780px) {
    width: ${(props) => (props.type === 'time' ? 'max-content' : '20px')};
    height: ${(props) => (props.type === 'time' ? '24px' : '37px')};
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-bottom: ${(props) => (props.type === 'time' ? '7px' : '0')};
    align-self: ${(props) => (props.type === 'time' ? 'flex-end' : 'auto')};
    padding: ${(props) =>
      props.type === 'time' ? '0px 6px 0px 6px' : '0px 21px 0px 21px'};
  }
`;
const TextArea = styled.textarea`
  box-sizing: border-box;
  border: 0;
  outline: 0;
  width: 100%;
  border-radius: 6px;
  background: #dfebfd;
  color: ${(props) => (props.active ? '#2A75BA' : '#8B9583')};
  border: 3px solid transparent;
  padding: 16px 21px 16px 21px;
  margin: 16px 0px 16px 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  transition: 0.3s;
  &:focus {
    color: #262626;
    background: white;
    border: 3px solid #dfebfd;
  }
  @media (max-width: 780px) {
    width: 90%;
    font-size: 13px;
    line-height: 16px;
    margin: 5% 5% 0% 5%;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    padding: 12px 17px 12px 17px;
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 600;
  font-size: 19px;
  line-height: 19px;
  margin: 25px;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(15px);
  @media (max-width: 780px) {
    margin: 15px 0px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    transform: translateY(0px);
  }
`;

// margin-right: ${(props) => (props.evening ? '8px' : '0')};
const DaytimeText = styled(Body4)`
  color: ${(props) => props.color};
  width: 75px;
  font-size: 16px;
  line-height: 19px;
  @media (max-width: 780px) {
    min-width: 60px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5px;
  }
`;
const ReminderSection = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 780px) {
    gap: 0;
    width: 100%;
    justify-content: center;
  }
`;
const ReminderSubSection1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  @media (max-width: 780px) {
    width: 90%;
    gap: 0;
  }
`;
const ReminderSubSection2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  @media (max-width: 780px) {
    width: 100%;
    justify-content: flex-end;
  }
`;
const ToggleButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 780px) {
    margin: 14px 0px 8px 0px;
    display: ${(props) => (props.display ? 'inherit' : 'none')};
  }
`;
const DayButton = styled.button`
  border: 0;
  outline: 0;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: #ffffff;
  cursor: pointer;
  background: ${(props) => (props.active ? '#2A75BA' : '#94BADC')};
  @media (max-width: 780px) {
    width: 30px;
    height: 30px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  }
`;
// &:hover {
//   transition: 0.6s;
//   background: #2a75ba;
// }
const SVG = styled.svg`
  transform: translateY(-15px);
  margin-right: 16px;
  @media (max-width: 780px) {
    transform: translateY(-10px) scale(2);
  }
`;
const Check = styled.svg`
  ${(props) => (props.active ? 'height: 20px;' : 'display: none;')};
  transition: 0.3s;
  margin-left: 50px;
  @media (max-width: 780px) {
    margin-left: 24px;
    transform: scale(0.6);
  }
`;
const BodyText = styled(Body4)`
  @media (max-width: 780px) {
    letter-spacing: 0.5px;
    margin: 0% 5% 0% 5%;
    line-height: 15px;
    font-size: 11px;
    color: #262626;
  }
`;
const Toggle = styled.div`
  margin-right: 50px;
  @media (max-width: 780px) {
    margin: 0px -10px 0px -14px;
  }
`;
const Button = styled.button`
  width: 100%;
  height: 42px;
  transition: 0.3s;
  text-align: center;
  box-sizing: border-box;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  white-space: normal !important;
  margin-top: ${(props) => (props.send ? '4px' : '30px')};
  border-radius: ${(props) => (props.send ? '10px' : '6px')};
  color: ${(props) => (props.send ? '#FFFFFF' : '#57B010')};
  background: ${(props) => (props.send ? '#2A75BA' : '#FFFFFF')};
  border: ${(props) =>
    props.send ? '2px solid #2A75BA' : '2px solid #57B010'};
  &:hover {
    cursor: pointer;
    border: ${(props) =>
      props.send ? '2px solid #78ACEA' : '2px solid #79C040'};
    background: ${(props) => (props.send ? '#78ACEA' : '#FFFFFF')};
    color: ${(props) => (props.send ? '#FFFFFF' : '#79C040')};
  }
  &:focus {
    outline: 0;
  }
  @media (max-width: 780px) {
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
    text-transform: capitalize;
    box-sizing: border-box;
    border-radius: 10px;
    margin: ${(props) => (props.send ? '5% 10% 0% 10%' : '0')};
    height: ${(props) => (props.send ? '50px' : '40px')};
    font-size: ${(props) => (props.send ? '15px' : '13px')};
    font-weight: ${(props) => (props.send ? '400' : '600')};
    line-height: ${(props) => (props.send ? '18px' : '16px')};
    width: ${(props) => (props.send ? '80%' : 'max-content')};
    letter-spacing: ${(props) => (props.send ? '0.5px' : '1px')};
    border: ${(props) =>
      props.send ? '1px solid #2A75BA' : '1px solid #57B010'};
  }
`;
const Stars = styled.div`
  @media (max-width: 780px) {
    margin-left: 5%;
  }
`;
const BirthdayInput = styled.div`
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  @media (max-width: 780px) {
    margin-left: 5%;
  }
`;
const LoginButton = styled.button`
  font-family: Montserrat;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  background: #57b010;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  color: #ffffff;
  outline: 0;
  border: 0;
  width: 80%;
  z-index: 1;
  height: 50px;
  bottom: 90px;
  margin-left: 10%;
  // position: absolute;
  white-space: normal !important;
  border-radius: 10px;
  &:hover {
    background: #79c040;
    cursor: pointer;
    color: #ffffff;
  }
  &:focus {
    outline: 0;
  }
  @media (min-width: 780px) {
    display: none;
  }
`;
// const LoginAvatar = styled.svg`
//   position: absolute;
//   left: 15%;
// `;

const IconArea = styled.div`
  position: absolute;
  left: 13.5%;
  filter: contrast(300%);
`;

const ReversedContentContainer = styled.div`
  z-index: 1;
  @media (min-width: 780px) {
    flex-direction: column-reverse;
    display: flex;
  }
`;

const languageTranslations = [
  {
    English: 'English',
    Russian: 'Английский',
    Spanish: 'Inglés',
  },
  {
    English: 'Russian',
    Russian: 'Русский',
    Spanish: 'Ruso',
  },
  {
    English: 'Spanish',
    Russian: 'Испанский',
    Spanish: 'Español',
  },
];

const translations = [
  {
    English: 'Settings',
    Russian: 'Настройки',
    Spanish: 'Configuración',
  },
  {
    English: 'Language',
    Russian: 'Язык',
    Spanish: 'Lenguaje',
  },
  {
    English: 'Life expectancy',
    Russian: 'Продолжительность жизни',
    Spanish: 'Expectativa de vida',
  },
  {
    English: 'What is your life expectancy?',
    Russian: 'Какая ваша продолжительность жизни?',
    Spanish: '¿Cuál es tu expectativa de vida?',
  },
  {
    English: 'Years',
    Russian: 'ЛЕТ',
    Spanish: 'Años',
  },
  {
    English: 'Or',
    Russian: 'Или',
    Spanish: 'ó',
  },
  {
    English: 'Calculate',
    Russian: 'Посчитать',
    Spanish: 'Calcular',
  },
  {
    English: 'Your Birthday',
    Russian: 'Твой день рождения',
    Spanish: 'Tu Cumpleaños',
  },
  {
    English: 'day',
    Russian: 'день',
    Spanish: 'día',
  },

  {
    English: 'mo',
    Russian: 'месяц',
    Spanish: 'mes',
  },

  {
    English: 'year', //10
    Russian: 'год',
    Spanish: 'año',
  },
  {
    English: 'Reminders',
    Russian: 'Напоминания',
    Spanish: 'Recordatorios',
  },
  {
    English: 'Morning',
    Russian: 'Утро',
    Spanish: 'Mañana',
  },
  {
    English: 'Evening',
    Russian: 'Вечер',
    Spanish: 'Noche  ',
  },
  {
    English: 'Time',
    Russian: 'Время',
    Spanish: 'Tiempo',
  },
  {
    English: 'Rate Us', //15
    Russian: 'Оставьте свой отзыв',
    Spanish: 'Califíquenos',
  },
  {
    English: 'Slide over the stars to rate us',
    Russian: 'Оцените наше приложение',
    Spanish: 'Desliza sobre las estreas para calificarnos',
  },
  {
    English: 'Feedback',
    Russian: 'Отзыв',
    Spanish: 'Feedback',
  },
  {
    English: 'Send',
    Russian: 'Отправить',
    Spanish: 'Enviar',
  },
  {
    English: 'on', //19
    Russian: 'вкл',
    Spanish: 'on',
  },
  {
    English: 'off',
    Russian: 'выкл',
    Spanish: 'off',
  },
  {
    English: 'About Us',
    Russian: 'О нас',
    Spanish: 'Sobre nosotros',
  },
  {
    English: 'Log out',
    Russian: 'Выйти',
    Spanish: 'Cerrar sesión',
  },
  {
    English: 'Thank you for your feedback',
    Russian: 'Спасибо за ваш отзыв',
    Spanish: 'Gracias por tu feeback',
  },
];

const daysInitialsTranslations = [
  {
    English: 'M',
    Russian: 'П',
    Spanish: 'L',
  },
  {
    English: 'T',
    Russian: 'В',
    Spanish: 'M',
  },
  {
    English: 'W',
    Russian: 'С',
    Spanish: 'M',
  },
  {
    English: 'T',
    Russian: 'Ч',
    Spanish: 'J',
  },
  {
    English: 'F',
    Russian: 'П',
    Spanish: 'V',
  },
  {
    English: 'S',
    Russian: 'С',
    Spanish: 'S',
  },
  {
    English: 'S',
    Russian: 'В',
    Spanish: 'D',
  },
];

const Settings = () => {
  const history = useHistory();
  //const isMobile = useMediaQuery({ query: '(max-width: 780px)' });
  const settings = React.useContext(SettingsContext);
  const globalUserContext = React.useContext(Context);
  const [expandedSetting, setExpandedSetting] = React.useState(-1);
  const [user] = useAuthState(firebase.auth());
  const [focusedInput, setFocusedInput] = React.useState(0);

  const dayInputRef = React.useRef(null);
  const monthInputRef = React.useRef(null);
  const yearInputRef = React.useRef(null);

  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [LE, setLE] = React.useState('');

  const [validDate, setValidDate] = React.useState(false);
  const [validLifeExpectancy, setValidLifeExpectancy] = React.useState(false);
  const [dateModified, setDateModified] = React.useState(false);
  const [lifeExpectencyModified, setLifeExpectencyModified] =
    React.useState(false);

  const [rating, setRating] = React.useState(0);
  const [feedback, setFeedback] = React.useState('');

  const [logoutButtonClicked, setLogoutButtonClicked] = React.useState(false);

  const [languagesLabels, setLanguagesLabels] = React.useState([
    { l: languageTranslations[0][settings.language], k: 'English' },
    { l: languageTranslations[1][settings.language], k: 'Russian' },
    { l: languageTranslations[2][settings.language], k: 'Spanish' },
  ]);
  const [daysInitialsLabels, setDaysInitialsLabels] = React.useState([
    daysInitialsTranslations[0][settings.language],
    daysInitialsTranslations[1][settings.language],
    daysInitialsTranslations[2][settings.language],
    daysInitialsTranslations[3][settings.language],
    daysInitialsTranslations[4][settings.language],
    daysInitialsTranslations[5][settings.language],
    daysInitialsTranslations[6][settings.language],
  ]);
  React.useEffect(() => {
    setLanguagesLabels([
      { l: languageTranslations[0][settings.language], k: 'English' },
      { l: languageTranslations[1][settings.language], k: 'Russian' },
      { l: languageTranslations[2][settings.language], k: 'Spanish' },
    ]);
    setDaysInitialsLabels([
      daysInitialsTranslations[0][settings.language],
      daysInitialsTranslations[1][settings.language],
      daysInitialsTranslations[2][settings.language],
      daysInitialsTranslations[3][settings.language],
      daysInitialsTranslations[4][settings.language],
      daysInitialsTranslations[5][settings.language],
      daysInitialsTranslations[6][settings.language],
    ]);
  }, [settings.language]);

  const handleDayChange = (event) => {
    setDateModified(true);
    setValidDate(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 2;
    const intValue = parseInt(value, 10);
    const validAmount = (intValue <= 31 && intValue >= 0) || !value;
    if (validLength && validAmount) {
      setDay(value);
      if (value.length === 2) {
        setFocusedInput(2);
        monthInputRef.current.focus();
      }
    }
  };

  const handleMonthChange = (event) => {
    setDateModified(true);
    setValidDate(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 2;
    const intValue = parseInt(value, 10);
    const validAmount = (intValue <= 12 && intValue >= 0) || !value;
    if (validLength && validAmount) {
      setMonth(value);
      if (value.length === 2) {
        setFocusedInput(3);
        yearInputRef.current.focus();
      }
    }
  };

  const handleYearChange = (event) => {
    setDateModified(true);
    setValidDate(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 4;
    const intValue = parseInt(value, 10);
    const validAmount = intValue >= 0 || !value;
    if (validLength && validAmount) {
      setYear(value);
    }
  };

  const handleLEChange = (event) => {
    setLifeExpectencyModified(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 3;
    const intValue = parseInt(value, 10);
    const validAmount = (intValue <= 200 && intValue >= 0) || !value;
    if (validLength && validAmount) setLE(value);
  };

  const addZero = (v) => {
    const vString = v.toString();
    return vString && vString?.length === 1 ? `0${vString}` : vString;
  };

  const onBirthdayChangeHandler = () => {
    const newStringDate = `${addZero(month)} ${addZero(day)},${year}`;
    const birthday = new Date(newStringDate);
    const timestamp = Date.parse(birthday);
    const dateInRange = moment(birthday).isBetween(
      new Date('1 1,1800'),
      new Date(Date.now()),
    );

    const tMonth = addZero(birthday.getMonth() + 1);
    const tDay = addZero(birthday.getDate());
    const tYear = birthday.getFullYear();
    const createdStringDate = `${tMonth} ${tDay},${tYear}`;
    // console.log(newStringDate);
    // console.log(createdStringDate);
    const dateExistsInCalendar = newStringDate === createdStringDate;
    if (
      birthday instanceof Date &&
      Number.isNaN(timestamp) === false &&
      // timestamp > 0 &&
      dateInRange &&
      dateExistsInCalendar
    ) {
      setDateModified(false);
      setValidDate(true);
      // setValidDay(true);
      // setValidMonth(true);
      // setValidYear(true);
      globalUserContext.setBirthday(birthday);
      if (user) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .set(
            {
              birthday,
            },
            { merge: true },
          )
          .then(() => {
            console.log('Birhtday and life expectency stored!');
          })
          .catch((error) => {
            console.error(
              'Error writing Birhtday and life expectency: ',
              error,
            );
          });
      }
    } else {
      console.log('NOT VALID DATE');
      setValidDate(false);
      // setValidDay(false);
      // setValidMonth(false);
      // setValidYear(false);
    }
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      const fieldsFilled = day !== '' && month !== '' && year !== '';
      // setValidDay(day !== '');
      // setValidMonth(month !== '');
      // setValidYear(year !== '');
      if (fieldsFilled && dateModified) onBirthdayChangeHandler();
    }, 2000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, year, month]);

  React.useEffect(() => {
    const invalidDate =
      moment(globalUserContext.birthday).format('D') === 'Invalid date';
    // console.log(
    //   moment(globalUserContext.birthday).format('D'),
    //   moment(globalUserContext.birthday).format('M'),
    //   moment(globalUserContext.birthday).format('Y'),
    // );
    if (!invalidDate) {
      setDay(addZero(moment(globalUserContext.birthday).format('D')));
      setMonth(addZero(moment(globalUserContext.birthday).format('M')));
      setYear(addZero(moment(globalUserContext.birthday).format('Y')));
    }
  }, [globalUserContext.birthday]);

  const handleMorningReminderToggle = (index) => {
    const newReminders = settings.morningReminderDays;
    newReminders[index] = !settings.morningReminderDays[index];
    settings.updateMorningReminderDays([...newReminders]);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            morningReminderDays: [...newReminders],
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };
  const handleEveningReminderToggle = (index) => {
    const newReminders = settings.eveningReminderDays;
    newReminders[index] = !settings.eveningReminderDays[index];
    settings.updateEveningReminderDays([...newReminders]);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            eveningReminderDays: [...newReminders],
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const onChangeHandlerMorningReminder = (value) => {
    settings.toggleMorningReminder(value);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            morningReminderOff: value,
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const onChangeHandlerEveningReminder = (value) => {
    settings.toggleEveningReminder(value);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            eveningReminderOff: value,
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const oNMorningToggleTurnOn = () => {
    const newReminders = settings.morningReminderDays;
    console.log(newReminders);
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < newReminders.length; index++) {
      newReminders[index] = true;
    }
    console.log(newReminders);
    settings.updateMorningReminderDays([...newReminders]);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            morningReminderDays: [...newReminders],
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const oNEveningToggleTurnOn = () => {
    const newReminders = settings.eveningReminderDays;

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < newReminders.length; index++) {
      newReminders[index] = true;
    }

    settings.updateEveningReminderDays([...newReminders]);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            eveningReminderDays: [...newReminders],
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const onChangeHandlerMorningReminderTime = (value) => {
    settings.setMorningReminderTime(value);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            morningReminderTime: value,
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const onChangeHandlerEveningReminderTime = (value) => {
    settings.setEveningReminderTime(value);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            eveningReminderTime: value,
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const onChangeHandlerLifeExpectancy = (valid) => {
    if (valid) {
      setLifeExpectencyModified(false);
      globalUserContext.setLifeExpectency(LE);
      if (user) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .set(
            {
              lifeExpectency: LE,
            },
            { merge: true },
          )
          .then(() => {
            // // console.log("Life expectency stored!");
          })
          .catch((error) => {
            console.error('Error writing Life expectency: ', error);
          });
      }
    }
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      const valid = LE > 0 && LE <= 200;
      setValidLifeExpectancy(valid);
      if (valid) onChangeHandlerLifeExpectancy(valid);
    }, 1500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LE]);

  React.useEffect(() => {
    setLE(globalUserContext.lifeExpectency);
  }, [globalUserContext.lifeExpectency]);

  const onChangeHandlerLanguage = (value) => {
    settings.setLanguage(value);
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            language: value,
          },
          { merge: true },
        )
        .then(() => {
          // // console.log("Life expectency stored!");
        })
        .catch((error) => {
          console.error('Error writing settings: ', error);
        });
    }
  };

  const sendEmail = async () => {
    settings.setShowNotification(true);
    settings.setNotification({
      title: 'title',
      body: translations[23][settings.language],
    });
    settings.sendEmail({
      ratingp: rating,
      feedbackp: feedback,
    });
  };

  const onChangeHandlerFeedback = () => {
    sendEmail();
    if (user) {
      firebase.firestore().collection('feedback').doc().set({
        feedback,
        rating,
        email: user.email,
      });
    }
    setRating(0);
    setFeedback('');
  };

  const openShareModal = () => {
    toast(<ShareModalComponent />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <Wrapper>
      <MetaTags>
        <title>Life Calendar and Journal | Liveweeks</title>
        <meta
          name="description"
          content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
        />
      </MetaTags>
      <div>
        <BackgroundIllustration1
          width="414"
          height="519"
          viewBox="0 0 414 519"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M307.469 107.034C57.0924 200.092 309.319 411.111 197.085 449.254C84.8518 487.397 -47.6945 396.022 -98.965 245.163C-150.235 94.3026 -100.815 -58.9146 11.4187 -97.0578C123.652 -135.201 474.972 44.778 307.469 107.034Z"
            fill="#EEF7FF"
            fillOpacity="0.42"
          />
        </BackgroundIllustration1>
        <BackgroundIllustration2
          width="678"
          height="745"
          viewBox="0 0 678 745"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            d="M149.807 511.638C722.794 629.159 499.022 -55.3172 751.727 3.99552C1004.43 63.3082 1144.66 386.752 1064.93 726.428C985.208 1066.1 715.72 1293.38 463.015 1234.07C210.309 1174.76 -233.522 433.016 149.807 511.638Z"
            fill="#78ACEA"
            fillOpacity="0.1"
          />
        </BackgroundIllustration2>
        <BackgroundIllustration3
          width="316"
          height="418"
          viewBox="0 0 316 418"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M238.045 113.936C297.694 393.199 38.2333 266.59 67.3075 389.96C96.3817 513.329 219.962 589.771 343.332 560.697C466.701 531.623 543.143 408.042 514.069 284.673C484.995 161.303 198.139 -72.8924 238.045 113.936Z"
            fill="#EEEEFF"
            fillOpacity="0.42"
          />
        </BackgroundIllustration3>
        <Arrow>
          <BackArrow />
        </Arrow>
        <HeadingArea>
          <Heading>{translations[0][settings.language]}</Heading>
          <Icon variant="share" onClick={openShareModal}>
            <img src="share-icon.png" alt="share-icon" />
          </Icon>
        </HeadingArea>
        <Container>
          <Column width="30%">
            <HR />
            <Section>
              <SectionHeadingArea
                onClick={() =>
                  expandedSetting === 0
                    ? setExpandedSetting(-1)
                    : setExpandedSetting(0)
                }
              >
                <B3>{translations[1][settings.language]}</B3>
                {expandedSetting === 0 ? (
                  <ExpandIcon
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 7L7 1L1 7"
                      stroke="#2A75BA"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </ExpandIcon>
                ) : (
                  <ExpandIcon
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#2A75BA"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </ExpandIcon>
                )}
              </SectionHeadingArea>
              <SectionContent open={expandedSetting === 0}>
                {languagesLabels.map((l) => (
                  <LanguageName
                    key={l.k}
                    active={settings.language === l.k}
                    onClick={() => onChangeHandlerLanguage(l.k)}
                  >
                    {l.l}
                    <Check
                      active={settings.language === l.k}
                      width="24"
                      height="24"
                      viewBox="0 0 24 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="#2A75BA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Check>
                  </LanguageName>
                ))}
              </SectionContent>
            </Section>
            <HR />
            <Section>
              <SectionHeadingArea
                onClick={() =>
                  expandedSetting === 1
                    ? setExpandedSetting(-1)
                    : setExpandedSetting(1)
                }
              >
                <B3>{translations[2][settings.language]}</B3>
                {expandedSetting === 1 ? (
                  <ExpandIcon
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 7L7 1L1 7"
                      stroke="#2A75BA"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </ExpandIcon>
                ) : (
                  <ExpandIcon
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#2A75BA"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </ExpandIcon>
                )}
              </SectionHeadingArea>
              <SectionContent open={expandedSetting === 1}>
                <BodyText style={{ marginTop: '10px' }}>
                  {translations[3][settings.language]}
                </BodyText>
                <InputArea>
                  <InputText focused={focusedInput === 3}>
                    {translations[4][settings.language]}
                  </InputText>
                  <Input
                    type="number"
                    value={LE}
                    invalid={lifeExpectencyModified && !validLifeExpectancy}
                    width="96%"
                    min="1"
                    max="200"
                    onFocus={() => setFocusedInput(3)}
                    onBlur={() => setFocusedInput(0)}
                    onChange={handleLEChange}
                    active={LE === null}
                  />
                  <Subheading>{translations[5][settings.language]}</Subheading>
                  <Link to="calculateyears">
                    <Button>{translations[6][settings.language]}</Button>
                  </Link>
                </InputArea>
              </SectionContent>
            </Section>
            <HR />
          </Column>
          <Column width="60%">
            <ReversedContentContainer>
              <Section style={{ marginTop: '10px' }}>
                <SectionHeadingArea
                  onClick={() =>
                    expandedSetting === 2
                      ? setExpandedSetting(-1)
                      : setExpandedSetting(2)
                  }
                >
                  <B3>{translations[11][settings.language]}</B3>
                  {expandedSetting === 2 ? (
                    <ExpandIcon
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 7L7 1L1 7"
                        stroke="#2A75BA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </ExpandIcon>
                  ) : (
                    <ExpandIcon
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L7 7L13 1"
                        stroke="#2A75BA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </ExpandIcon>
                  )}
                </SectionHeadingArea>
                <SectionContent open={expandedSetting === 2}>
                  <ReminderSection>
                    <ReminderSubSection1>
                      <ReminderSubSection2>
                        <SVG
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 1V3"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 21V23"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.21997 4.21997L5.63997 5.63997"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.3601 18.36L19.7801 19.78"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 12H3"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21 12H23"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.21997 19.78L5.63997 18.36"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.3601 5.63997L19.7801 4.21997"
                            stroke="#FFA54B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </SVG>
                        <DaytimeText color="#FFA54B">
                          {translations[12][settings.language]}
                        </DaytimeText>
                        <InputArea margin="0px 36px 0px 36px">
                          <InputText hidesmall focused={focusedInput === 1}>
                            {translations[14][settings.language]}
                          </InputText>
                          <Input
                            width="240px"
                            type="time"
                            value={settings.morningReminderTime}
                            active={!settings.morningReminderOff}
                            onFocus={() => setFocusedInput(1)}
                            onBlur={() => setFocusedInput(0)}
                            onChange={(e) =>
                              onChangeHandlerMorningReminderTime(e.target.value)
                            }
                          />
                        </InputArea>
                        <Toggle>
                          <ToggleSwitch
                            id="morning-reminder-toggle"
                            toggled={settings.morningReminderOff}
                            onText={translations[19][settings.language]}
                            offText={translations[20][settings.language]}
                            onChange={(e) => {
                              //console.log(e.target.checked);
                              if (!e.target.checked) oNMorningToggleTurnOn();
                              onChangeHandlerMorningReminder(e.target.checked);
                            }}
                          />
                        </Toggle>
                      </ReminderSubSection2>
                      <ToggleButtonRow
                        display={(!settings.morningReminderOff).toString()}
                      >
                        {daysInitialsLabels.map((day, index) => (
                          <DayButton
                            active={
                              !settings.morningReminderOff &&
                              settings.morningReminderDays[index]
                            }
                            key={Math.random()}
                            onClick={() => {
                              if (!settings.morningReminderOff)
                                handleMorningReminderToggle(index);
                            }}
                          >
                            {day}
                          </DayButton>
                        ))}
                      </ToggleButtonRow>
                    </ReminderSubSection1>
                    <ReminderSubSection1>
                      <ReminderSubSection2>
                        <SVG
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 10.79C18.8427 12.4922 18.2039 14.1144 17.1583 15.4668C16.1127 16.8192 14.7035 17.8458 13.0957 18.4265C11.4879 19.0073 9.74801 19.1181 8.07952 18.7461C6.41104 18.3741 4.88302 17.5345 3.67425 16.3258C2.46548 15.117 1.62596 13.589 1.25393 11.9205C0.881899 10.252 0.992739 8.51208 1.57348 6.9043C2.15423 5.29651 3.18085 3.88737 4.53324 2.84175C5.88562 1.79614 7.50782 1.15731 9.21002 1C8.21344 2.34827 7.73387 4.00945 7.85856 5.68141C7.98324 7.35338 8.70388 8.92506 9.88943 10.1106C11.075 11.2961 12.6466 12.0168 14.3186 12.1415C15.9906 12.2662 17.6518 11.7866 19 10.79V10.79Z"
                            stroke="#2A75BA"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </SVG>
                        <DaytimeText color="#2466A3">
                          {translations[13][settings.language]}
                        </DaytimeText>
                        <InputArea margin="0px 36px 0px 36px">
                          <InputText hidesmall focused={focusedInput === 2}>
                            {translations[14][settings.language]}
                          </InputText>
                          <Input
                            width="240px"
                            type="time"
                            value={settings.eveningReminderTime}
                            active={!settings.eveningReminderOff}
                            onFocus={() => setFocusedInput(2)}
                            onBlur={() => setFocusedInput(0)}
                            onChange={(e) =>
                              onChangeHandlerEveningReminderTime(e.target.value)
                            }
                          />
                        </InputArea>
                        <Toggle>
                          <ToggleSwitch
                            id="evening-reminder-toggle"
                            toggled={settings.eveningReminderOff}
                            onText={translations[19][settings.language]}
                            offText={translations[20][settings.language]}
                            onChange={(e) => {
                              if (!e.target.checked) oNEveningToggleTurnOn();
                              onChangeHandlerEveningReminder(e.target.checked);
                            }}
                          />
                        </Toggle>
                      </ReminderSubSection2>
                      <ToggleButtonRow
                        display={(!settings.eveningReminderOff).toString()}
                      >
                        {daysInitialsLabels.map((day, index) => (
                          <DayButton
                            active={
                              !settings.eveningReminderOff &&
                              settings.eveningReminderDays[index]
                            }
                            key={Math.random()}
                            onClick={() => {
                              if (!settings.eveningReminderOff)
                                handleEveningReminderToggle(index);
                            }}
                          >
                            {day}
                          </DayButton>
                        ))}
                      </ToggleButtonRow>
                    </ReminderSubSection1>
                  </ReminderSection>
                </SectionContent>
              </Section>
              <HR />
              <Section>
                <SectionHeadingArea
                  onClick={() =>
                    expandedSetting === 4
                      ? setExpandedSetting(-1)
                      : setExpandedSetting(4)
                  }
                >
                  <B3>{translations[7][settings.language]}</B3>
                  {expandedSetting === 4 ? (
                    <ExpandIcon
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 7L7 1L1 7"
                        stroke="#2A75BA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </ExpandIcon>
                  ) : (
                    <ExpandIcon
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L7 7L13 1"
                        stroke="#2A75BA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </ExpandIcon>
                  )}
                </SectionHeadingArea>
                <SectionContent open={expandedSetting === 4}>
                  <BirthdayInput>
                    <div>
                      <BodyText>{translations[8][settings.language]}</BodyText>
                      <Input
                        style={{ padding: '0', width: '38px', height: '34px' }}
                        value={day}
                        type="number"
                        placeholder="DD"
                        invalid={dateModified && !validDate}
                        filled={day.length > 0}
                        ref={dayInputRef}
                        min="1"
                        max="31"
                        onChange={handleDayChange}
                      />
                    </div>
                    <div>
                      <BodyText>{translations[9][settings.language]}</BodyText>
                      <Input
                        style={{ padding: '0', width: '40px', height: '34px' }}
                        value={month}
                        type="number"
                        placeholder="MM"
                        invalid={dateModified && !validDate}
                        filled={month.length > 0}
                        ref={monthInputRef}
                        min="1"
                        max="12"
                        onChange={handleMonthChange}
                      />
                    </div>
                    <div>
                      <BodyText>{translations[10][settings.language]}</BodyText>
                      <Input
                        style={{ padding: '0', width: '38px', height: '34px' }}
                        value={year}
                        placeholder="YYYY"
                        type="number"
                        invalid={dateModified && !validDate}
                        filled={year.length > 0}
                        ref={yearInputRef}
                        min="1"
                        onChange={handleYearChange}
                      />
                    </div>
                  </BirthdayInput>
                </SectionContent>
                <HR />
              </Section>
            </ReversedContentContainer>
            <Section>
              <SectionHeadingArea
                onClick={() =>
                  expandedSetting === 3
                    ? setExpandedSetting(-1)
                    : setExpandedSetting(3)
                }
              >
                <B3>{translations[15][settings.language]}</B3>
                {expandedSetting === 3 ? (
                  <ExpandIcon
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 7L7 1L1 7"
                      stroke="#2A75BA"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </ExpandIcon>
                ) : (
                  <ExpandIcon
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#2A75BA"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </ExpandIcon>
                )}
              </SectionHeadingArea>
              <SectionContent open={expandedSetting === 3}>
                <BodyText style={{ marginTop: '10px', marginBottom: '10px' }}>
                  {translations[16][settings.language]}
                </BodyText>
                <Stars>
                  <BeautyStars
                    value={rating}
                    maxStars={5}
                    inactiveColor="#C5DCF1"
                    activeColor="#2A75BA"
                    onChange={(value) => setRating(value)}
                  />
                </Stars>
                <TextArea
                  placeholder={translations[17][settings.language]}
                  name="text"
                  // cols="40"
                  rows="4"
                  value={feedback}
                  active={feedback}
                  focused={focusedInput === 4}
                  onFocus={() => setFocusedInput(4)}
                  onBlur={() => setFocusedInput(0)}
                  onChange={(event) => setFeedback(event.target.value)}
                />
                <Button
                  send
                  onClick={() => {
                    onChangeHandlerFeedback();
                  }}
                  disabled={!feedback || !rating}
                >
                  {translations[18][settings.language]}
                </Button>
              </SectionContent>
            </Section>
            <HR />
            <Section
              mobileOnly
              onClick={() => {
                // window.location = '/about';
                history.push({
                  pathname: '/about',
                });
              }}
            >
              <SectionHeadingArea>
                <B3>{translations[21][settings.language]}</B3>
                <ExpandIcon
                  style={{ transform: 'rotate(90deg)' }}
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 7L7 1L1 7"
                    stroke="#2A75BA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </ExpandIcon>
              </SectionHeadingArea>
            </Section>
          </Column>
          <HR />
        </Container>
      </div>
      {user || logoutButtonClicked ? (
        <LoginButton
          onClick={() => {
            setLogoutButtonClicked(true);
            firebaseLogout(history);
          }}
        >
          <IconArea>
            <img src="/logout.png" alt="logout" style={{ width: '75%' }} />
          </IconArea>
          {translations[22][settings.language]}
        </LoginButton>
      ) : null}
      <NavigationBar />
      <VerticalNavigationBar />

      <ToastContainer />
    </Wrapper>
  );
};

export default Settings;
