import React from 'react';
import styled from 'styled-components';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import MetaTags from 'react-meta-tags';
import { useHistory } from 'react-router-dom';
// import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
import { H1, Body1, Body2 } from '../Typography/Fonts';
import BackArrow from '../ReusableItems/BackArrow';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import {
  firebaseLoginGoogle,
  firebaseLoginFacebook,
  firebaseLoginTwitter,
} from '../Context/FirebaseContext';
import ShareModalComponent from '../Modals/ShareModal';
import { SettingsContext } from '../Context/SettingsContext';
import { Context } from '../Context/globalContextProvider';

const Wrapper = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 780px) {
    padding: 0%;
    padding-top: 22px;
    box-sizing: border-box;
  }
`;
const Content = styled.div`
  margin-left: 200px;
  @media (max-width: 780px) {
    margin-left: 0;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  width: 90%;
  padding: 2% 5% 0% 5%;
  justify-content: space-between;
  @media (max-width: 780px) {
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const Container = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -3%;
  @media (max-width: 780px) {
    gap: 34px;
    margin-top: -10%;
    align-items: center;
    justify-content: center;
  }
`;
const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  width: 40%;
  width: 100%;
  max-width: 360px;
  padding: 3% 0% 5% 0%;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const IconsRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 780px) {
    width: 80%;
  }
`;
const Icon = styled.button`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  outline: 0;
  border: 0;
  border-radius: 10px;
  ${(props) => {
    switch (props.variant) {
      case 'google':
        return 'background: #E16752;';
      case 'facebook':
        return 'background: #3B5598;';
      case 'twitter':
        return 'background: #08A0E9;';
      case 'apple':
        return 'background: #000000;';
      case 'share':
        return 'background: #FFFFFF;';
      default:
        return null;
    }
  }}
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    @media (max-width: 780px) {
      transform: ${(props) =>
        props.variant === 'share' ? 'scale(0.7)' : 'scale(1.1)'};
    }
  }
  &:foucs {
    outline: 0;
  }
  @media (max-width: 780px) {
    ${(props) =>
      props.variant === 'share'
        ? 'position: absolute; right: 14px; top: 31px; transform: scale(0.6); background: transparent'
        : null}
  }
`;
const ImageArea = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: auto;
  @media (max-width: 780px) {
    display: none;
  }
`;
const BackgroundIllustration1 = styled.svg`
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 90%;
  position: absolute;
`;
const BackgroundIllustration2 = styled.svg`
  bottom: 0;
  right: 0;
  z-index: -1;
  max-width: 90%;
  position: absolute;
`;
const Illustration = styled.img`
  max-width: 650px;
`;
const Heading = styled(H1)`
  line-height: 72px;
  margin: 0;
  margin-left: -5px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    margin: 0;
    text-align: center;
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 700;
  font-size: 19px;
  line-height: 70px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    color: black;
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0.5px;
    margin: 30px 0px 30px 0px;
  }
`;
const BodyText = styled(Body2)`
  color: #262626;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 80px;
  @media (max-width: 780px) {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    margin-bottom: 50px;
    letter-spacing: 0.5px;
  }
`;
const Hr = styled.div`
  width: 30vw;
  height: 1px;
  max-width: 110px;
  background: #e3e3e3;
  @media (min-width: 780px) {
    display: none;
  }
`;
const HrArea = styled.div`
  gap: 28px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const translations = [
  {
    English: 'or',
    Russian: 'ИЛИ',
    Spanish: 'ó',
  },
];

const AuthenticationLayout = ({ children, pageType, pageHeader, pageText }) => {
  const history = useHistory();
  const { language } = React.useContext(SettingsContext);
  const { birthday, lifeExpectency } = React.useContext(Context);
  //const isMobile = useMediaQuery({ query: '(max-width: 780px)' });
  const [user] = useAuthState(firebase.auth());
  if (user?.displayName) {
    console.log('USER ALREADY LOGGED IN');
    // window.location = 'birthdayinfo';
  }

  const openShareModal = () => {
    toast(<ShareModalComponent />, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <Wrapper>
      <ToastContainer />
      <MetaTags>
        <title>Life Calendar and Journal | Liveweeks</title>
        <meta
          name="description"
          content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
        />
      </MetaTags>
      <BackgroundIllustration1
        width="414"
        height="519"
        viewBox="0 0 414 519"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M307.469 107.034C57.0924 200.092 309.319 411.111 197.085 449.254C84.8518 487.397 -47.6945 396.022 -98.965 245.163C-150.235 94.3026 -100.815 -58.9146 11.4187 -97.0578C123.652 -135.201 474.972 44.778 307.469 107.034Z"
          fill="#EEF7FF"
          fillOpacity="0.42"
        />
      </BackgroundIllustration1>
      <BackgroundIllustration2
        width="316"
        height="418"
        viewBox="0 0 316 418"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M238.045 113.936C297.694 393.199 38.2333 266.59 67.3075 389.96C96.3817 513.329 219.962 589.771 343.332 560.697C466.701 531.623 543.143 408.042 514.069 284.673C484.995 161.303 198.139 -72.8924 238.045 113.936Z"
          fill="#EEEEFF"
          fillOpacity="0.42"
        />
      </BackgroundIllustration2>
      <BackArrow />
      <Content>
        <HeadingArea>
          <div>
            <Heading>{pageHeader}</Heading>
            <BodyText>{pageText}</BodyText>
          </div>
          <Icon variant="share" onClick={openShareModal}>
            <img src="share-icon.png" alt="share-icon" />
          </Icon>
        </HeadingArea>
        <Container>
          <TextArea pageType={pageType}>
            {pageType !== 'Forgot Password' && pageType !== 'Verify Email' ? (
              <IconsRow>
                <Icon
                  variant="google"
                  onClick={() =>
                    firebaseLoginGoogle(history, birthday && lifeExpectency > 0)
                  }
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M27.4258 14.2515C27.4258 13.1502 27.3343 12.3466 27.1364 11.5132H14.2677V16.4837H21.8214C21.6691 17.719 20.8467 19.5792 19.0192 20.8293L18.9936 20.9957L23.0624 24.0759L23.3443 24.1034C25.9333 21.7669 27.4258 18.3291 27.4258 14.2515Z"
                        fill="white"
                      />
                      <path
                        d="M14.2679 27.3479C17.9686 27.3479 21.0753 26.1572 23.3446 24.1035L19.0194 20.8294C17.862 21.6181 16.3086 22.1688 14.2679 22.1688C10.6433 22.1688 7.56701 19.8323 6.47039 16.6029L6.30965 16.6162L2.07877 19.8159L2.02344 19.9662C4.27736 24.3416 8.9071 27.3479 14.2679 27.3479Z"
                        fill="white"
                      />
                      <path
                        d="M6.47023 16.6029C6.18088 15.7695 6.01343 14.8765 6.01343 13.9539C6.01343 13.0311 6.18088 12.1383 6.45501 11.3049L6.44735 11.1274L2.16347 7.87634L2.02331 7.94149C1.09437 9.75712 0.56134 11.796 0.56134 13.9539C0.56134 16.1118 1.09437 18.1506 2.02331 19.9662L6.47023 16.6029Z"
                        fill="white"
                      />
                      <path
                        d="M14.2678 5.73897C16.8415 5.73897 18.5776 6.82536 19.5675 7.73322L23.4358 4.04245C21.0601 1.88457 17.9685 0.560059 14.2678 0.560059C8.90705 0.560059 4.27734 3.56621 2.02344 7.94149L6.45513 11.3049C7.56697 8.07548 10.6433 5.73897 14.2678 5.73897Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width="26.88"
                          height="26.88"
                          fill="white"
                          transform="translate(0.559998 0.560059)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Icon>
                <Icon
                  variant="facebook"
                  onClick={() => {
                    firebaseLoginFacebook(
                      history,
                      birthday && lifeExpectency > 0,
                    );
                  }}
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M18.4768 5.02326H20.9307V0.749339C20.5073 0.691099 19.0513 0.560059 17.3556 0.560059C13.8176 0.560059 11.3939 2.7855 11.3939 6.87574V10.6401H7.48956V15.418H11.3939V27.4401H16.1808V15.4191H19.9272L20.5219 10.6412H16.1796V7.3495C16.1808 5.96854 16.5526 5.02326 18.4768 5.02326Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width="26.88"
                          height="26.88"
                          fill="white"
                          transform="translate(0.559998 0.560059)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Icon>
                <Icon
                  variant="twitter"
                  onClick={() => {
                    firebaseLoginTwitter(
                      history,
                      birthday && lifeExpectency > 0,
                    );
                  }}
                >
                  <svg
                    width="28"
                    height="22"
                    viewBox="0 0 28 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.44 2.6656C26.4404 3.10408 25.3753 3.39472 24.2648 3.53584C25.4072 2.85376 26.2791 1.78192 26.689 0.489998C25.6239 1.12504 24.4479 1.5736 23.1946 1.82392C22.1833 0.747038 20.7418 0.0800781 19.1694 0.0800781C16.1185 0.0800781 13.6623 2.5564 13.6623 5.59216C13.6623 6.02896 13.6993 6.44896 13.79 6.8488C9.20864 6.62536 5.1548 4.4296 2.43152 1.08472C1.95608 1.9096 1.6772 2.85376 1.6772 3.87016C1.6772 5.77864 2.66 7.4704 4.12496 8.44984C3.2396 8.43304 2.37104 8.176 1.6352 7.77112C1.6352 7.78792 1.6352 7.80976 1.6352 7.8316C1.6352 10.5095 3.54536 12.7338 6.05024 13.2462C5.60168 13.3689 5.1128 13.4277 4.60544 13.4277C4.25264 13.4277 3.89648 13.4075 3.56216 13.3336C4.27616 15.5159 6.30224 17.1203 8.71136 17.1724C6.83648 18.639 4.45592 19.5227 1.8788 19.5227C1.42688 19.5227 0.993438 19.5026 0.559998 19.4471C3.00104 21.0213 5.894 21.9201 9.01376 21.9201C19.1542 21.9201 24.6982 13.5201 24.6982 6.23896C24.6982 5.99536 24.6898 5.76016 24.6781 5.52664C25.7718 4.75048 26.6907 3.78112 27.44 2.6656Z"
                      fill="white"
                    />
                  </svg>
                </Icon>
              </IconsRow>
            ) : null}
            {pageType !== 'Forgot Password' && pageType !== 'Verify Email' ? (
              <HrArea>
                <Hr />
                <Subheading>{translations[0][language]}</Subheading>
                <Hr />
              </HrArea>
            ) : null}
            {children}
          </TextArea>
          <ImageArea>
            <Illustration
              src="login-image.png"
              alt="login-screen-illustration"
              width="100%"
            />
          </ImageArea>
        </Container>
      </Content>
      <VerticalNavigationBar />
    </Wrapper>
  );
};

export default AuthenticationLayout;
