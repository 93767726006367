import React from 'react';
import styled from 'styled-components';
import Slider from '../ReusableItems/Slider';
import { Body1 } from '../Typography/Fonts';
import { CalculateYearsContext } from '../Context/CalculateYearsContext';
import Switch from '../ReusableItems/Switch';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 780px) {
    padding: 25px;
    width: auto;
    margin-top: 5%;
    margin-bottom: 00px;
    padding-bottom: 10px;
    background: rgba(239, 246, 241, 0.8);
    border-radius: 21px;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 36px 0px;
  @media (max-width: 780px) {
    width: 100%;
    text-align: left;
    flex-direction: ${(props) => props.flexDirection};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px 0px 20px 0px;
  }
`;
const TextArea = styled.div`
  width: 35%;
  @media (max-width: 780px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 10px 10px 0px;
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const InputArea = styled.div`
  width: 65%;
  @media (max-width: 780px) {
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 700;
  line-height: 26px;
  font-size: 19px;
  margin: 0px 0px 36px 0px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    margin: 0px 0px 20px 0px;
    text-transform: none;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    font-size: 16px;
  }
`;
const Body3 = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  @media (max-width: 780px) {
    font-weight: bold;
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  }
`;

const translations = [
  {
    English: 'Physique',
    Russian: 'Физические данные',
    Spanish: 'Físico',
  },
  {
    English: 'US',
    Russian: 'US',
    Spanish: 'US',
  },
  {
    English: 'Metric',
    Russian: 'Величина',
    Spanish: 'Métrica',
  },
  {
    English: 'Weight',
    Russian: 'Вес',
    Spanish: 'Peso',
  },
  {
    English: 'Height',
    Russian: 'Рост',
    Spanish: 'Altura',
  },
];

function Physique() {
  const { language } = React.useContext(SettingsContext);
  const calculateYearsContext = React.useContext(CalculateYearsContext);
  //
  const [weight, setWeight] = React.useState(70);
  const [height, setHeight] = React.useState(1.7);
  const [converted, setConverted] = React.useState(true);

  React.useEffect(() => {
    if (typeof weight[0] !== 'undefined')
      calculateYearsContext.setWeight(weight[0]);
    else calculateYearsContext.setWeight(weight);
    if (typeof height[0] !== 'undefined')
      calculateYearsContext.setHeight(height[0]);
    else calculateYearsContext.setHeight(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weight, height]);

  React.useEffect(() => {
    if (!converted) {
      if (calculateYearsContext.usUnits) {
        const newWeight = weight * 2.205;
        const newHeight = height * 3.281;
        setWeight(newWeight);
        setHeight(newHeight);
      } else {
        const newWeight = weight / 2.205;
        const newHeight = height / 3.281;
        setWeight(newWeight);
        setHeight(newHeight);
      }
      setConverted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [converted]);

  return (
    <Wrapper>
      <Subheading>{translations[0][language]}</Subheading>
      <Switch
        checkedText={translations[1][language]}
        uncheckedText={translations[2][language]}
        checked={calculateYearsContext.usUnits}
        setChecked={(checked) => {
          setConverted(false);
          calculateYearsContext.setUsUnits(checked);
        }}
      />
      <Section>
        <TextArea>
          <Body3>{translations[3][language]}</Body3>
        </TextArea>
        <InputArea>
          <Slider
            unit={calculateYearsContext.usUnits ? ' lb' : ' kg'}
            start={calculateYearsContext.usUnits ? 75 : 50}
            end={calculateYearsContext.usUnits ? 399 : 181}
            step={1}
            toggle={setWeight}
            value={weight}
          />
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[4][language]}</Body3>
        </TextArea>
        <InputArea>
          <Slider
            unit={calculateYearsContext.usUnits ? 'feet' : ' m'}
            start={calculateYearsContext.usUnits ? 4.7 : 1.2}
            end={calculateYearsContext.usUnits ? 6.11 : 2.4}
            step={0.01}
            toggle={setHeight}
            value={height}
          />
        </InputArea>
      </Section>
      {/* <br />Score: {weight} + {height} */}
    </Wrapper>
  );
}

export default Physique;
