import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: Montserrat;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 2px;
  text-align: left;
  white-space: normal !important;
  @media (max-width: 780px) {
    font-size: 21px;
    line-height: 26px;
    letter-spacing: 1px;
  }
`;
export const Body1 = styled.p`
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 1.6px;
  text-align: left;
  white-space: normal !important;
  @media (max-width: 780px) {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
  }
`;
export const Body2 = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  white-space: normal !important;
`;
export const Body3 = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: 1.6px;
  white-space: normal !important;
  @media (max-width: 780px) {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #000000;
  }
`;
export const Body4 = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  color: #262626;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 1.8px;
  white-space: normal !important;
`;
