import React from 'react';
import styled from 'styled-components';
import RadioButton from '../ReusableItems/RadioButton';
import Slider from '../ReusableItems/Slider';
import { Body1 } from '../Typography/Fonts';
import { CalculateYearsContext } from '../Context/CalculateYearsContext';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 780px) {
    padding: 25px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: auto;
    margin-top: 5%;
    background: rgba(239, 246, 241, 0.8);
    border-radius: 21px;
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 700;
  line-height: 26px;
  font-size: 19px;
  margin: 0px 0px 32px 0px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    margin: 0px 0px 10px 0px;
    text-transform: none;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    font-size: 16px;
  }
`;
const RadioButtonRow = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 780px) {
    flex-wrap: wrap;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 36px 0px;
  @media (max-width: 780px) {
    width: 100%;
    text-align: left;
    flex-direction: ${(props) => props.flexDirection};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px 0px 20px 0px;
  }
`;
const TextArea = styled.div`
  width: 35%;
  @media (max-width: 780px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 10px 10px 0px;
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const InputArea = styled.div`
  width: 65%;
  @media (max-width: 780px) {
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const Body3 = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  @media (max-width: 780px) {
    font-weight: bold;
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  }
`;

const translations = [
  {
    English: 'Fitness',
    Russian: 'Фитнес',
    Spanish: 'Aptitud física',
  },
  {
    English: 'Exercise',
    Russian: 'Упражнения',
    Spanish: 'Ejercicio',
  },
  {
    English: 'Never',
    Russian: 'Никогда',
    Spanish: 'Nunca',
  },
  {
    English: 'Rarely',
    Russian: 'Редко',
    Spanish: 'Rara vez',
  },
  {
    English: '2x/week',
    Russian: '2 раза в неделю',
    Spanish: '2x/semana',
  },
  {
    English: '3x/week',
    Russian: '3 раза в неделю',
    Spanish: '3x/semana',
  },
  {
    English: 'Everyday',
    Russian: 'Каждый день',
    Spanish: 'Todos los días',
  },
  {
    English: 'General health',
    Russian: 'Общее состояние здоровья',
    Spanish: 'Salud general',
  },
  {
    English: 'Poor',
    Russian: 'Плохое',
    Spanish: 'Pobre',
  },
  {
    English: 'Fair',
    Russian: 'Слабое',
    Spanish: 'Regular',
  },
  {
    English: 'Good', //10
    Russian: 'Хорошее',
    Spanish: 'Buena',
  },
  {
    English: 'Very good',
    Russian: 'Очень хорошее',
    Spanish: 'Muy buena',
  },
  {
    English: 'Excellent',
    Russian: 'Отличное',
    Spanish: 'Excelente',
  },
  {
    English: 'Do you have diabetes?',
    Russian: 'У Вас есть диабет?',
    Spanish: '¿Tienes diabates?',
  },
  {
    English: 'No',
    Russian: 'Нет',
    Spanish: 'No',
  },
  {
    English: 'Yes',
    Russian: 'Да',
    Spanish: 'Sí',
  },
];

function Fitness() {
  const { language } = React.useContext(SettingsContext);
  const calculateYearsContext = React.useContext(CalculateYearsContext);

  const [excerciseLabels, setExcerciseLabels] = React.useState([
    { l: translations[2][language], k: translations[2].English },
    { l: translations[3][language], k: translations[3].English },
    { l: translations[4][language], k: translations[4].English },
    { l: translations[5][language], k: translations[5].English },
    { l: translations[6][language], k: translations[6].English },
  ]);
  const [healthLabels, setHealthLabels] = React.useState([
    { l: translations[8][language], k: translations[8].English },
    { l: translations[9][language], k: translations[9].English },
    { l: translations[10][language], k: translations[10].English },
    { l: translations[11][language], k: translations[11].English },
    { l: translations[12][language], k: translations[12].English },
  ]);
  const [diabetesLabels, setDiabetesLabels] = React.useState([
    { l: translations[14][language], k: translations[14].English },
    { l: translations[15][language], k: translations[15].English },
  ]);

  const [exerciseStatus, setExerciseState] = React.useState(1);
  const [healthStatus, setHealth] = React.useState(1);
  const [diabetesStatus, toggleStatus] = React.useState(
    translations[14][language],
  );

  const [diabetesRow, setDiabetesRow] = React.useState([]);

  React.useEffect(() => {
    setExcerciseLabels([
      { l: translations[2][language], k: translations[2].English },
      { l: translations[3][language], k: translations[3].English },
      { l: translations[4][language], k: translations[4].English },
      { l: translations[5][language], k: translations[5].English },
      { l: translations[6][language], k: translations[6].English },
    ]);
    setHealthLabels([
      { l: translations[8][language], k: translations[8].English },
      { l: translations[9][language], k: translations[9].English },
      { l: translations[10][language], k: translations[10].English },
      { l: translations[11][language], k: translations[11].English },
      { l: translations[12][language], k: translations[12].English },
    ]);
    setDiabetesLabels([
      { l: translations[14][language], k: translations[14].English },
      { l: translations[15][language], k: translations[15].English },
    ]);
  }, [language]);
  React.useEffect(() => {
    const { k } = excerciseLabels[exerciseStatus];
    calculateYearsContext.setExercise(
      k === translations[2].English
        ? 'NEVER'
        : k === translations[3].English
        ? 'RARELY'
        : k === translations[4].English
        ? '_1_TO_2_PER_WEEK'
        : k === translations[5].English
        ? '_3_TO_4_PER_WEEK'
        : k === translations[6].English
        ? '_5_PLUS_PER_WEEK'
        : null,
    );
  }, [calculateYearsContext, excerciseLabels, exerciseStatus]);

  React.useEffect(() => {
    const { k } = healthLabels[healthStatus];
    calculateYearsContext.setGeneralHealth(
      k === translations[11].English ? 'VERY_GOOD' : k.toUpperCase(),
    );
  }, [calculateYearsContext, healthStatus, healthLabels]);

  React.useEffect(() => {
    const auxDiabetesRow = diabetesLabels.map((label, i) => {
      return (
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`auxDiabetesRow-${i}`}
          tag={label.l}
          value={label.k}
          active={diabetesStatus === label.k ? 1 : 0}
          toggle={(e) => {
            console.log(e);
            toggleStatus(e);
            calculateYearsContext.setDiabetes(e === translations[15].English);
          }}
        />
      );
    });
    setDiabetesRow(auxDiabetesRow);
  }, [calculateYearsContext, diabetesLabels, diabetesStatus, language]);

  return (
    <Wrapper>
      <Subheading>{translations[0][language]}</Subheading>
      <Section>
        <TextArea>
          <Body3>{translations[1][language]}</Body3>
        </TextArea>
        <InputArea>
          <Slider
            unit={excerciseLabels.map((a) => a.l)}
            start={0}
            end={4}
            step={1}
            toggle={setExerciseState}
          />
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[7][language]}</Body3>
        </TextArea>
        <InputArea>
          <Slider
            unit={healthLabels.map((a) => a.l)}
            start={0}
            end={4}
            step={1}
            toggle={setHealth}
          />
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[13][language]}</Body3>
          <br />
        </TextArea>
        <InputArea>
          <RadioButtonRow>{diabetesRow}</RadioButtonRow>
        </InputArea>
      </Section>
    </Wrapper>
  );
}

export default Fitness;
