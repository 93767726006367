/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

// justify-content: space-between;
const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 78px;
  height: 26px;
  background: #57b010;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

const SwitchButton = styled.span`
  position: absolute;
  top: 1px;
  left: 1px;
  width: 24px;
  height: 24px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  left: ${(props) => (props.checked ? 'calc(100% - 1px)' : '')};
  transform: ${(props) => (props.checked ? 'translateX(-100%)' : '')};
`;

const SwitchText = styled.p`
  color: white;
  font-size: 11px;
  position: absolute;
  left: ${(props) => {
    const px = props.uncheckedTextLength * 6.6;
    return props.checked ? 'calc(100% - 70px)' : `calc(100% - ${px}px)`;
  }};
`;

const Switch = ({ checked, setChecked, checkedText, uncheckedText }) => {
  return (
    <Wrapper>
      <SwitchLabel htmlFor="react-switch-new">
        <SwitchText
          checked={checked}
          uncheckedTextLength={uncheckedText.length ?? 0}
        >
          {checked ? checkedText : uncheckedText}
        </SwitchText>
        <SwitchButton checked={checked} />
      </SwitchLabel>

      <SwitchInput
        id="react-switch-new"
        type="checkbox"
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
    </Wrapper>
  );
};

export default Switch;
