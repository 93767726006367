const data = [
  {
    id: 1,
    icon: 'insurance-icon-14.png',
    heading: 'Single policies',
    paragraph: 'A single life insurance policy protects only one person. When this person dies, his or her loved ones will receive a sum of money which was defined in the policy. If one partner from the couple passes away, another one still has his own policy if they chose two single policies.',
    list: [
      'You can choose individual level and duration of cover for both policyholders',
      'Each partner has individual cover and payouts will be made twice',
      'If you break up with your partner you have your own policy',
    ],
  },
  {
    id: 2,
    icon: 'insurance-icon-15.png',
    heading: 'A joint policy',
    paragraph: 'A "joint" life insurance policy protects two people. Payout is made and the insurance terminates when one of them dies. The compensation is paid only once after the first death of one of the partners. This option makes sense when a couple doesn’t have kids or any other dependants.',
    list: [
      'Less expensive than two single policies',
      'One application needed for both policyholders',
      'Payout irrespective of who dies first',
    ],
  },
];

export default data;
