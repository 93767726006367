const data = [
  {
    id: 1,
    heading: 'Direct Insurers',
    paragraph: 'The price of premiums you will pay to an insurance company depends on your age, occupation, habits, health conditions and being a smoker. So if you are 92-years-old smoking granny who likes hiking and surfing on waves you probably will never receive a low price for your policy as the risk of your death is high.',
  },
  {
    id: 2,
    heading: 'Discount Brokers',
    paragraph: 'You may get life insurance through discount brokers and receive a good deal through their websites. They give you pleasant offers and free competitors comparison as they usually take $25 fee for this but then return all commissions to you.',
  },
  {
    id: 3,
    heading: 'Advisory Brokers',
    paragraph: 'If you are unsure which policy is most appropriate in your case, you can go to an advisory broker and they will give you consultation and answer all your questions about the specifics of all insurance types for some commission.',
  },
  {
    id: 4,
    heading: 'Cashback Sites',
    paragraph: 'A good win for you may be buying insurance through a cashback website. Check thoroughly the rules the cashback sites have and choose the most appealing to you.',
  },
];

export default data;
