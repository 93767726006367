import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  postion: absolute;
  width: 24px;
  height: auto;
  border: 0;
  outline: 0;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '5%')};
  background: transparent;
  transform: translateY(38px);
  @media (min-width: 780px) {
    display: none;
  }
`;

const BackArrow = ({ marginLeft }) => {
  const history = useHistory();
  return (
    <Wrapper marginLeft={marginLeft} onClick={() => history.goBack()}>
      <img
        style={{ position: 'absolute' }}
        src="/back-arrow.png"
        alt="back-arrow"
        width="14px"
        height="14px"
      />
    </Wrapper>
  );
};

export default BackArrow;
