import React from 'react';
import 'firebase/firestore';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import MorningQuestions from './MorningQuestions';
import EveningQuestions from './EveningQuestions';
import MyNotes from './MyNotes';
import { Context } from '../Context/globalContextProvider';
import BackArrow from '../ReusableItems/BackArrow';
import { H1, Body4 } from '../Typography/Fonts';
import JournalLayout from './JournalLayout';
import { FirebaseUploadJournalEntries } from '../Context/FirebaseContext';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.div`
  width: 90%;
  display: flex;
  border-radius: 20px;
  background: #ffffff;
  margin: 10% 5% 5% 5%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 26px 8px 26px;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
  &:first-child {
    margin-top: 5%;
  }
  @media (max-width: 780px) {
    margin-top: 25px;
    margin-bottom: 80px;
    box-sizing: border-box;
    box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
    &:first-child {
      margin-top: 50px;
    }
  }
`;
const Container = styled.div`
  margin-top: 80px;
`;
const Datepicker = styled(DatePicker)`
  outline: 0;
  border: 0;
  width: 26px;
  height: 28px;
  background: #ececec;
  text-align: center;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  left: 0;
  top: -18px;
`;
const BackgroundIllustration1 = styled.svg`
  top: -100px;
  left: 0;
  z-index: -1;
  max-width: 600px;
  position: fixed;
  @media (max-width: 780px) {
    position: absolute;
    top: -35%;
    width: 100%;
    left: 0;
  }
`;
const BackgroundIllustration2 = styled.svg`
  bottom: -90px;
  right: 0;
  z-index: -1;
  max-width: 600px;
  position: fixed;
  @media (max-width: 780px) {
    max-width: 90%;
  }
`;
const CalendarIcon = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
`;
const IconArea = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  margin: 3px 0px 0px 12px;
`;
const DateStyle = styled(H1)`
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #57b010;
  margin: 0;
  padding: 0;
  @media (max-width: 780px) {
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
  }
`;
const DateMonthStyle = styled(Body4)`
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #333333;
  }
`;
const DayStyle = styled.div`
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #333333;
  margin: 0;
  padding: 0;
  @media (max-width: 780px) {
    font-weight: 700;
  }
`;
const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: max-content;
  margin: 3% 5% 0% 5%;
  margin-bottom: 32px;
  @media (max-width: 780px) {
    margin: 26px 52px 26px 52px;
  }
`;
const HR = styled.div`
  width: 90%;
  height: 0.8px;
  background: #d6d6d6;
  border: 0;
  margin: 0% 5% 5% 5%;
  @media (min-width: 780px) {
    display: none;
  }
`;
const DateRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.5px 0px 0px 7px;
  @media (max-width: 780px) {
    padding: 5px 0px 0px 7px;
  }
`;
const JournalDataDate = styled.div`
  z-index: -1;
  height: 52px;
  width: max-content;
  display: flex;
  background: white;
  border-radius: 20px;
  justify-content: center;
  padding: 10px 16px 10px 16px;
  margin: -58px 0px 0px -26px;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
  @media (max-width: 780px) {
    padding: 12px 34px 0px 34px;
  }
`;
const Arrow = styled.div`
  position: absolute;
  top: 16px;
  left: 5%;
`;

const monthsTranslations = [
  {
    English: 'January',
    Russian: 'Январь',
    Spanish: 'Enero',
  },
  {
    English: 'February',
    Russian: 'Февраль',
    Spanish: 'Febrero',
  },
  {
    English: 'March',
    Russian: 'Март',
    Spanish: 'Marzo',
  },
  {
    English: 'April',
    Russian: 'апрель',
    Spanish: 'Abril',
  },
  {
    English: 'May',
    Russian: 'Может',
    Spanish: 'Mayo',
  },
  {
    English: 'June',
    Russian: 'июнь',
    Spanish: 'Junio',
  },
  {
    English: 'July',
    Russian: 'июль',
    Spanish: 'Julio',
  },
  {
    English: 'August',
    Russian: 'август',
    Spanish: 'Agosto',
  },
  {
    English: 'September',
    Russian: 'сентябрь',
    Spanish: 'Septiembre',
  },
  {
    English: 'October',
    Russian: 'Октябрь',
    Spanish: 'Octubre',
  },
  {
    English: 'November',
    Russian: 'Ноябрь',
    Spanish: 'Noviembre',
  },
  {
    English: 'December',
    Russian: 'Декабрь',
    Spanish: 'Diciembre',
  },
];

const daysTranslations = [
  {
    English: 'Monday',
    Russian: 'Понедельник',
    Spanish: 'Lunes',
  },
  {
    English: 'Tuesday',
    Russian: 'Вторник',
    Spanish: 'Martes',
  },
  {
    English: 'Wednesday',
    Russian: 'Среда',
    Spanish: 'Miércoles',
  },
  {
    English: 'Thursday',
    Russian: 'Четверг',
    Spanish: 'Jueves',
  },
  {
    English: 'Friday',
    Russian: 'Пятница',
    Spanish: 'Viernes',
  },
  {
    English: 'Saturday',
    Russian: 'Суббота',
    Spanish: 'Sábado',
  },
  {
    English: 'Sunday',
    Russian: 'Воскресенье',
    Spanish: 'Domingo',
  },
];

function JournalPast(props) {
  const history = useHistory();
  const userData = React.useContext(Context);
  const { user, answers, setAnswers } = React.useContext(Context);
  const { language } = React.useContext(SettingsContext);
  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [keyDate, setKeyDate] = React.useState(
    moment(userData.journalDate).subtract(1, 'days').format('DD-MM-YYYY'),
  );
  const [date, setDate] = React.useState(
    moment(userData.journalDate).subtract(1, 'days').toDate(),
  );
  const [filteredAnswers, setFilteredAnswers] = React.useState({});

  React.useEffect(() => {
    setFilteredAnswers(answers.find((a) => a.keyDate === keyDate) ?? {});
  }, [answers, keyDate]);

  const saveJournal = (answerName, answer) => {
    const currentDateAnswers = {
      ...filteredAnswers,
      keyDate,
      [answerName]: answer,
    };

    const newAnswersList = [
      ...answers.filter((a) => a.keyDate !== keyDate),
      currentDateAnswers,
    ];

    setAnswers(newAnswersList);
    if (user) {
      FirebaseUploadJournalEntries(user, newAnswersList);
    }
  };

  // React.useEffect(() => {
  //   if (props?.location?.dateForwarded !== undefined) {
  //     userData.setJournalDate(props?.location?.dateForwarded);
  //   } else {
  //     const tempDate = userData.journalDate;
  //     const today = new Date();
  //     if (tempDate.getDate() === today.getDate()) {
  //       tempDate.setDate(tempDate.getDate() - 1);
  //       userData.setJournalDate(tempDate);
  //     }
  //   }
  // });

  const redirectOnDate = (d) => {
    const today = moment(new Date());
    const dateToCheck = moment(d);

    const data1 = [
      parseInt(today.format('D'), 10),
      parseInt(today.format('M'), 10),
      parseInt(today.format('YYYY'), 10),
    ];
    const data2 = [
      parseInt(dateToCheck.format('D'), 10),
      parseInt(dateToCheck.format('M'), 10),
      parseInt(dateToCheck.format('YYYY'), 10),
    ];
    if (data1[2] < data2[2]) {
      history.push({
        pathname: '/journal/future',
      });
      //window.location = '/journal/future';
    } else if (data1[1] < data2[1]) {
      history.push({
        pathname: '/journal/future',
      });
      //window.location = '/journal/future';
    } else if (data1[0] < data2[0]) {
      history.push({
        pathname: '/journal/future',
      });
      //window.location = '/journal/future';
    } else if (
      data1[2] === data2[2] &&
      data1[1] === data2[1] &&
      data1[0] === data2[0]
    ) {
      history.push({
        pathname: '/journal',
      });
      //window.location = '/journal';
    }
  };

  React.useEffect(() => {
    const d = moment(date).format('dddd').toUpperCase();
    const index = daysTranslations.findIndex(
      (t) => t.English.toUpperCase() === d,
    );
    if (index > -1) setDay(daysTranslations[index][language].toUpperCase());
  }, [date, language, setDay]);

  React.useEffect(() => {
    const d = moment(date).format('MMMM').toUpperCase();
    const index = monthsTranslations.findIndex(
      (t) => t.English.toUpperCase() === d,
    );
    if (index > -1) setMonth(monthsTranslations[index][language].toUpperCase());
  }, [date, language, setMonth]);

  return (
    <div>
      <BackgroundIllustration1
        width="800"
        height="927"
        viewBox="0 0 800 927"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.7"
          d="M554.604 246.453C140.758 400.268 557.662 749.06 372.152 812.106C186.642 875.152 -32.443 724.12 -117.187 474.765C-201.932 225.41 -120.245 -27.8417 65.2647 -90.8881C250.775 -153.935 831.467 143.551 554.604 246.453Z"
          fill="#E8F2EA"
        />
      </BackgroundIllustration1>
      <BackgroundIllustration2
        width="762"
        height="747"
        viewBox="0 0 762 747"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M467.478 223.672C584.745 772.692 74.658 523.783 131.817 766.323C188.975 1008.86 431.928 1159.14 674.467 1101.98C917.006 1044.83 1067.29 801.872 1010.13 559.333C952.97 316.794 389.026 -143.623 467.478 223.672Z"
          fill="#FCF2E6"
        />
      </BackgroundIllustration2>
      <Arrow>
        <BackArrow />
      </Arrow>
      <JournalLayout>
        <DateDiv>
          <DateStyle>{moment(date).format('DD')}</DateStyle>
          <DateRow>
            <DateMonthStyle>
              {month} {moment(date).format('YYYY')}
            </DateMonthStyle>
            <DayStyle>{day}</DayStyle>
          </DateRow>
          <IconArea>
            <CalendarIcon
              width="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4H7V2H9V4H15V2H17V4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22ZM5 10V20H19V10H5ZM5 6V8H19V6H5ZM17 18H15V16H17V18ZM13 18H11V16H13V18ZM9 18H7V16H9V18ZM17 14H15V12H17V14ZM13 14H11V12H13V14ZM9 14H7V12H9V14Z"
                fill="#ADADAD"
              />
            </CalendarIcon>
            <Datepicker
              calendarClassName="mobile-calendar-style"
              selected={date}
              dateFormat="MMM dd, yyyy"
              showYearDropdown
              onChange={(datePickerDate) => {
                const formatedDatePickerDate =
                  moment(datePickerDate).format('DD-MM-YYYY');
                setKeyDate(formatedDatePickerDate);
                setDate(datePickerDate);
                redirectOnDate(datePickerDate);
              }}
            />
          </IconArea>
        </DateDiv>
        <HR />
        <Container>
          <Wrapper>
            <JournalDataDate>
              <DateMonthStyle>
                {moment(date).format('DD')} {month}{' '}
                {moment(date).format('YYYY')}
              </DateMonthStyle>
            </JournalDataDate>
            <MorningQuestions
              answers={filteredAnswers}
              saveJournal={saveJournal}
            />
            <EveningQuestions
              answers={filteredAnswers}
              saveJournal={saveJournal}
            />
            <MyNotes answers={filteredAnswers} saveJournal={saveJournal} />
          </Wrapper>
        </Container>
      </JournalLayout>
    </div>
  );
}

export default JournalPast;
