import React from 'react';
import styled from 'styled-components';
import AutoHeightTextarea from './TextArea';
import { Body4 } from '../Typography/Fonts';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 10px;
  padding: 16px 26px 16px 26px;
  transform: translateY(-20px);
  background: #f0f7f1;
  border-radius: 10px;
  @media (max-width: 780px) {
    padding: 20px;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 780px) {
    justify-content: space-between;
  }
`;
const Arrow = styled.svg`
  justify-self: flex-end;
  margin-left: auto;
  cursor: pointer;
  padding: 12px;
  width: 15px;
  height: 8px;
  @media (max-width: 780px) {
    padding: 0;
  }
`;
const BodyText = styled(Body4)`
  @media (max-width: 780px) {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #333333;
  }
`;
const BodyHeading = styled(BodyText)`
  padding-left: 33px;
  cursor: pointer;
  @media (max-width: 780px) {
    padding: 0px 10px 0px 11px;
  }
`;

const translations = [
  {
    English: 'My notes',
    Russian: 'Мои заметки',
    Spanish: 'Mis notas',
  },
];

function MyNotes({ answers, saveJournal }) {
  const [expanded, toggleExpand] = React.useState(false);
  const { language } = React.useContext(SettingsContext);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const onChangeHandler = (text, name) => {
    saveJournal(name, text);
  };
  const optimisedHandleChange = debounce(onChangeHandler, 25);

  const onEnterKeyHandler = (event, name) => {
    if (event.key === 'Enter') {
      console.log('onEnterKeyHandler: ', name);
    }
  };

  let expandedContent = null;
  if (expanded) {
    expandedContent = (
      <div>
        <AutoHeightTextarea
          name="a7"
          fieldValue={answers?.a7 ?? ''}
          onChangeHandler={optimisedHandleChange}
          onEnterKeyHandler={onEnterKeyHandler}
          bgColor="#F0F7F1"
        />
      </div>
    );
  }

  return (
    <Wrapper>
      <HeadingArea>
        <svg
          width="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M12.527 23.9999C12.33 23.9999 12.138 23.9219 11.997 23.7799C11.824 23.6069 11.746 23.3599 11.789 23.1199L12.319 20.1149C12.346 19.9629 12.419 19.8229 12.527 19.7149L19.952 12.2899C20.268 11.9739 20.603 11.7759 20.948 11.7049C21.424 11.6049 21.887 11.7499 22.25 12.1129L23.487 13.3499C24.169 14.0329 24.169 15.1419 23.487 15.8249L16.063 23.2499C15.954 23.3579 15.814 23.4319 15.663 23.4579L12.658 23.9879C12.614 23.9959 12.571 23.9999 12.527 23.9999ZM15.533 22.7199H15.543H15.533ZM13.755 20.6079L13.452 22.3249L15.169 22.0219L22.426 14.7639C22.523 14.6659 22.523 14.5079 22.426 14.4099L21.198 13.1819C21.188 13.2009 21.113 13.2479 21.012 13.3489L13.755 20.6079Z"
              fill="#57B010"
            />
            <path
              d="M10.01 21H2.75C1.233 21 0 19.767 0 18.25V4.75C0 3.233 1.233 2 2.75 2H14.25C15.767 2 17 3.233 17 4.75V12.81C17 13.224 16.664 13.56 16.25 13.56C15.836 13.56 15.5 13.224 15.5 12.81V4.75C15.5 4.061 14.939 3.5 14.25 3.5H2.75C2.061 3.5 1.5 4.061 1.5 4.75V18.25C1.5 18.939 2.061 19.5 2.75 19.5H10.01C10.424 19.5 10.76 19.836 10.76 20.25C10.76 20.664 10.424 21 10.01 21Z"
              fill="#57B010"
            />
            <path
              d="M3.75 5C3.336 5 3 4.664 3 4.25V0.75C3 0.336 3.336 0 3.75 0C4.164 0 4.5 0.336 4.5 0.75V4.25C4.5 4.664 4.164 5 3.75 5Z"
              fill="#57B010"
            />
            <path
              d="M8.5 5C8.086 5 7.75 4.664 7.75 4.25V0.75C7.75 0.336 8.086 0 8.5 0C8.914 0 9.25 0.336 9.25 0.75V4.25C9.25 4.664 8.914 5 8.5 5Z"
              fill="#57B010"
            />
            <path
              d="M13.25 5C12.836 5 12.5 4.664 12.5 4.25V0.75C12.5 0.336 12.836 0 13.25 0C13.664 0 14 0.336 14 0.75V4.25C14 4.664 13.664 5 13.25 5Z"
              fill="#57B010"
            />
            <path
              d="M11.25 13.5H3.75C3.336 13.5 3 13.164 3 12.75C3 12.336 3.336 12 3.75 12H11.25C11.664 12 12 12.336 12 12.75C12 13.164 11.664 13.5 11.25 13.5Z"
              fill="#57B010"
            />
            <path
              d="M8.25 9.5H3.75C3.336 9.5 3 9.164 3 8.75C3 8.336 3.336 8 3.75 8H8.25C8.664 8 9 8.336 9 8.75C9 9.164 8.664 9.5 8.25 9.5Z"
              fill="#57B010"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <BodyHeading onClick={() => toggleExpand(!expanded)}>
          {translations[0][language]}
        </BodyHeading>
        <Arrow
          onClick={() => toggleExpand(!expanded)}
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.514893 1.46495L8.99989 9.94995L17.4849 1.46495L16.0709 0.0499516L8.99989 7.12195L1.92889 0.0499516L0.514893 1.46495Z"
            fill="#57B010"
          />
        </Arrow>
      </HeadingArea>
      {expandedContent}
    </Wrapper>
  );
}

export default MyNotes;
