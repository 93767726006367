import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router';

const Wrapper = styled.div`
  gap: 40px;
  width: 200px;
  display: flex;
  min-width: 200px;
  margin-top: 50px;
  height: 100%;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  border-right: 1px solid #dfebfd;
  @media (max-width: 780px) {
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;
    box-sizing: border-box;
    flex-direction: row;
    margin: 0% 5% 0% 5%;
    border-right: 0;
    width: 90%;
    gap: 0;
  }
`;
const Button = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  height: 118px;
  width: 100%;
  padding: 20%;
  line-height: 25px;
  text-align: center;
  border-radius: 0px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  text-decoration: none;
  border-right: ${(props) =>
    props.active ? '2px solid #2A75BA' : '2px solid white'};
  color: ${(props) => (props.active ? '#2A75BA' : '#6A9FCF')};
  @media (max-width: 780px) {
    border-bottom: ${(props) =>
      props.active ? '2px solid #2A75BA' : '2px solid white'};
    padding: 7px 0px 7px 0px;
    height: max-content;
    width: max-content;
    max-width: 20vw;
    border-right: 0;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
  }
`;

function Menubar(props) {
  const { tabLabels, tabLinks } = props;
  const location = useLocation();

  const menubuttons = [];
  for (let i = 0; i < tabLabels.length; i += 1) {
    menubuttons.push(
      <Link to={tabLinks[i]} key={i}>
        <Button to={undefined} active={location.pathname === tabLinks[i]}>
          {tabLabels[i]}
        </Button>
      </Link>,
    );
  }

  return <Wrapper>{menubuttons}</Wrapper>;
}

export default Menubar;
