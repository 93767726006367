import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
// import { Context } from '../Context/globalContextProvider2';

const Wrapper = styled.div`
  gap: 40px;
  width: 200px;
  display: flex;
  min-width: 200px;
  margin-top: 50px;
  max-height: 930px;
  position: fixed;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  border-right: 1px solid #DFEBFD;
  @media (max-width: 900px) {
    margin-left: 0;
    margin-top: 30px;
  }
  @media (max-width: 780px) {
    justify-content: space-evenly;
    border-bottom: 1px solid #DFEBFD;
    box-sizing: border-box;
    flex-direction: row;
    position: relative;
    margin: 0% 5% 0% 5%;
    border-right: 0;
    width: 90%;
    gap: 0;
  }
`;
const Button = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  height: 138px;
  width: 100%;
  line-height: 25px;
  text-align: center;
  border-radius: 0px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  border-right: ${(props) => (props.active ? `1px solid ${props.activeColor}` : '0')};
  color: ${(props) => (props.active ? props.activeColor : props.color)};
  @media (max-width: 780px) {
    border-bottom: ${(props) => (props.active ? `2px solid ${props.activeColor}` : '0')};
    padding: 7px 0px 7px 0px;
    height: max-content;
    width: max-content;
    max-width: 20vw;
    border-right: 0;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
  }
`;

const JournalMenu = (props) => {
//  const user = React.useContext(Context);
  const { tabLabels, tabLinks } = props;
  const location = useLocation();
  // function handleTabSwitch(tabLink, index) {
  //   user.setJournalTab(index);
  //   const date = new Date();
  //   if (tabLinks === '/journal/past') date.setDate(date.getDate() - 1);
  //   user.setJournalDate(date);
  // }

  const color = location.pathname === '/journal/future' ? '#B08CCB' : '#ADADAD';
  const activeColor = location.pathname === '/journal/future' ? '#7C40A9' : '#57B010';

  return (
    <Wrapper>
      {tabLabels.map((label, index) => (
        <Link key={label} to={tabLinks[index]}>
          <Button
            key={label}
            color={color}
            activeColor={activeColor}
            active={location.pathname === tabLinks[index]}
          >
            {label}
          </Button>
        </Link>
      ))}
    </Wrapper>
  );
};

export default JournalMenu;
