import React from 'react';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

export const Context = React.createContext();
export const StateProvider = ({ children }) => {
  const [navigationTab, setNavigationTab] = React.useState('');
  const [renderDaysOfYear, setRenderYear] = React.useState(moment().year());
  const [lifeExpectency, setLifeExpectency] = React.useState(0);
  const [birthday, setBirthday] = React.useState(null);
  const [journalDate, setJournalDate] = React.useState(
    new Date(moment().format('MMM D, YYYY')),
  );
  const [scrollYear, setScrollYear] = React.useState(0);
  const [scrollWeek, setScrollWeek] = React.useState(1);
  const [paintOpen, togglePaint] = React.useState(false);

  const [activeColor, setActiveColor] = React.useState('#7CA9D2');
  const [recentColors, setRecentColors] = React.useState([]);

  const [defaultColor, setDefaultColor] = React.useState('#7CA9D2');
  const [lifeInYearsColors, setLifeInYearsColors] = React.useState([]);
  const [lifeInWeeksColors, setLifeInWeeksColors] = React.useState([]);
  const [yearsInWeeksColors, setYearsInWeeksColors] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);

  const [bucketsLists, setBucketsLists] = React.useState([]);

  const [user] = useAuthState(firebase.auth());
  const [firestoreUserDoc] = useDocumentData(
    user !== null
      ? firebase.firestore().collection('users').doc(user.uid)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  // User data
  React.useEffect(() => {
    if (
      firestoreUserDoc?.lifeExpectency !== undefined &&
      firestoreUserDoc?.lifeExpectency !== null
    ) {
      setLifeExpectency(firestoreUserDoc?.lifeExpectency);
    }
    if (
      firestoreUserDoc?.birthday !== undefined &&
      firestoreUserDoc?.birthday !== null
    ) {
      setBirthday(firestoreUserDoc?.birthday?.toDate());
    }
    if (firestoreUserDoc?.activeColor !== undefined) {
      setActiveColor(firestoreUserDoc?.activeColor);
    }
    if (firestoreUserDoc?.recentColors !== undefined) {
      setRecentColors(firestoreUserDoc?.recentColors);
    }
    if (firestoreUserDoc?.lifeInYearsColors !== undefined) {
      setLifeInYearsColors(firestoreUserDoc?.lifeInYearsColors);
    }
    if (firestoreUserDoc?.lifeInWeeksColors !== undefined) {
      setLifeInWeeksColors(firestoreUserDoc?.lifeInWeeksColors);
    }
    if (firestoreUserDoc?.yearsInWeeksColors !== undefined) {
      setYearsInWeeksColors(firestoreUserDoc?.yearsInWeeksColors);
    }
    if (firestoreUserDoc?.bucketsLists !== undefined) {
      setBucketsLists(firestoreUserDoc?.bucketsLists);
    }
    if (firestoreUserDoc?.pastAnswers !== undefined) {
      setBucketsLists(firestoreUserDoc?.pastAnswers);
    }
    if (firestoreUserDoc?.answers !== undefined) {
      setAnswers(firestoreUserDoc?.answers);
    }
  }, [firestoreUserDoc]);

  React.useEffect(() => {
    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(searchParams.entries());
      const saveGlobal = params?.saveGlobal === 'true';
      //console.log('NEW REGISTER SAVE GLOBAL', saveGlobal, params);
      if (saveGlobal) {
        if (user) {
          console.log('SAVE GLOBAL');
          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .set(
              {
                lifeExpectency,
                birthday,
                activeColor,
                recentColors,
                lifeInYearsColors,
                lifeInWeeksColors,
                yearsInWeeksColors,
                bucketsLists,
                answers,
              },
              { merge: true },
            )
            .then(() => {
              console.log('GLOBAL UNSAVED DATA WAS SAVED');
              window.history.pushState(null, '', window.location.pathname);
            })
            .catch((error) => {
              console.error('Error saving global unsaved data: ', error);
            });
        }
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, firestoreUserDoc]);

  return (
    <Context.Provider
      key="Context-Provider-Key"
      value={{
        user,
        firestoreUserDoc,
        birthday,
        setBirthday,
        lifeExpectency,
        setLifeExpectency,
        // calendarTab,
        // setCalendarTab,
        // journalTab,
        // setJournalTab,
        navigationTab,
        setNavigationTab,
        renderDaysOfYear,
        setRenderYear,
        journalDate,
        setJournalDate,
        scrollYear,
        setScrollYear,
        scrollWeek,
        setScrollWeek,
        recentColors,
        setRecentColors,
        activeColor,
        setActiveColor,
        paintOpen,
        togglePaint,
        lifeInYearsColors,
        setLifeInYearsColors,
        defaultColor,
        setDefaultColor,
        lifeInWeeksColors,
        setLifeInWeeksColors,
        yearsInWeeksColors,
        setYearsInWeeksColors,
        bucketsLists,
        setBucketsLists,
        answers,
        setAnswers,
      }}
    >
      {children}
    </Context.Provider>
  );
};
