import React from 'react';
import styled from 'styled-components';
import RadioButton from '../ReusableItems/RadioButton';
import { Body1 } from '../Typography/Fonts';
import { CalculateYearsContext } from '../Context/CalculateYearsContext';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 780px) {
    padding: 25px;
    width: auto;
    margin-top: 5%;
    margin-bottom: 0px;
    padding-bottom: 10px;
    background: rgba(239, 246, 241, 0.8);
    border-radius: 21px;
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 700;
  line-height: 26px;
  font-size: 19px;
  margin: 0px 0px 32px 0px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    margin: 0px 0px 20px 0px;
    text-transform: none;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    font-size: 16px;
  }
`;
// justify-content: ${(props) =>
//   props.breakLine ? 'space-around' : 'flex-start'};
const RadioButtonRow = styled.section`
  justify-content: flex-start;
  flex-flow: row wrap;
  display: flex;
  flex-direction: row;
  @media (max-width: 780px) {
    flex-wrap: wrap;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 36px 0px;
  @media (max-width: 780px) {
    width: 100%;
    text-align: left;
    flex-direction: ${(props) => props.flexDirection};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px 0px 20px 0px;
  }
`;
const TextArea = styled.div`
  width: 35%;
  @media (max-width: 780px) {
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const InputArea = styled.div`
  width: 65%;
  @media (max-width: 780px) {
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const Body3 = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  @media (max-width: 780px) {
    font-weight: bold;
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  }
`;

const translations = [
  {
    English: 'Life Events',
    Russian: 'Жизненные события',
    Spanish: 'Eventos de la vida',
  },
  {
    English: 'Education',
    Russian: 'Образование',
    Spanish: 'Educación',
  },
  {
    English: 'Less than 12 years',
    Russian: 'Среднее',
    Spanish: 'Menos de 12 años',
  },
  {
    English: 'High School graduate',
    Russian: 'Среднее техническое / специальное',
    Spanish: 'Graduado/a de secundaria',
  },
  {
    English: 'Some College',
    Russian: 'Незаконченное высшее',
    Spanish: 'Un poco de Universidad',
  },
  {
    English: 'College Graduate',
    Russian: 'Высшее',
    Spanish: 'Graduado/a de la Universidad',
  },
  {
    English: 'Marital Status',
    Russian: 'Семейное положение',
    Spanish: 'Estado Civil',
  },
  {
    English: 'Married',
    Russian: 'Женат/Замужем',
    Spanish: 'Casado/a',
  },
  {
    English: 'Separated',
    Russian: 'Расстался',
    Spanish: 'Separado/a',
  },
  {
    English: 'Widowed',
    Russian: 'Вдова/Вдовец',
    Spanish: 'Viudo/a',
  },
  {
    English: 'Never married', //10
    Russian: 'Не состоял/а в браке',
    Spanish: 'Nunca casado/a',
  },
  {
    English: 'Divorced',
    Russian: 'Разведен/а',
    Spanish: 'Divorciado/a',
  },
  {
    English: 'Work Status',
    Russian: 'Рабочий Cтатус',
    Spanish: 'Situación Laboral',
  },
  {
    English: 'Currently Working',
    Russian: 'Работаю',
    Spanish: 'Actualmente Trabajando',
  },
  {
    English: 'Retired',
    Russian: 'На пенсии',
    Spanish: 'Retirado',
  },
  {
    English: 'Annual Income',
    Russian: 'Ежегодный доход',
    Spanish: 'Ingreso Anual',
  },
];

function LifeEvents() {
  const { language } = React.useContext(SettingsContext);
  const calculateYearsContext = React.useContext(CalculateYearsContext);
  const [educationLabels, setEducationLabels] = React.useState([
    { l: translations[2][language], k: translations[2].English },
    { l: translations[3][language], k: translations[3].English },
    { l: translations[4][language], k: translations[4].English },
    { l: translations[5][language], k: translations[5].English },
  ]);
  const [maritalLabels, setMaritalLabels] = React.useState([
    { l: translations[7][language], k: translations[7].English },
    { l: translations[8][language], k: translations[8].English },
    { l: translations[9][language], k: translations[9].English },
    { l: translations[10][language], k: translations[10].English },
    { l: translations[11][language], k: translations[11].English },
  ]);
  const [workStatusLabels, setWorkStatusLabels] = React.useState([
    { l: translations[13][language], k: translations[13].English },
    { l: translations[14][language], k: translations[14].English },
  ]);
  const [activeEducation, toggleEducation] = React.useState(
    translations[3].English,
  );
  const [activeMaritalStatus, toggleStatus] = React.useState(
    translations[10].English,
  );
  const [activeWorkStatus, toggleWork] = React.useState(
    translations[13].English,
  );
  const [educationRow, setEducationRow] = React.useState([]);
  const [maritalRow, setMaritalRow] = React.useState([]);
  const [workRow, setWorkRow] = React.useState([]);
  const [incomeRow, setIncomeRow] = React.useState([]);

  React.useEffect(() => {
    setEducationLabels([
      { l: translations[2][language], k: translations[2].English },
      { l: translations[3][language], k: translations[3].English },
      { l: translations[4][language], k: translations[4].English },
      { l: translations[5][language], k: translations[5].English },
    ]);
    setMaritalLabels([
      { l: translations[7][language], k: translations[7].English },
      { l: translations[8][language], k: translations[8].English },
      { l: translations[9][language], k: translations[9].English },
      { l: translations[10][language], k: translations[10].English },
      { l: translations[11][language], k: translations[11].English },
    ]);
    setWorkStatusLabels([
      { l: translations[13][language], k: translations[13].English },
      { l: translations[14][language], k: translations[14].English },
    ]);
  }, [language]);

  React.useEffect(() => {
    const auxEducationRow = educationLabels.map((label, i) => {
      return (
        <>
          {language === 'Russian' && (i + 1) % 2 === 0 ? (
            <div style={{ width: '50px' }} />
          ) : null}
          <RadioButton
            // eslint-disable-next-line react/no-array-index-key
            key={`auxEducationRow-${i}`}
            tag={label.l}
            value={label.k}
            active={activeEducation === label.k ? 1 : 0}
            toggle={(e) => {
              console.log(e);
              toggleEducation(e);
              calculateYearsContext.setEducation(
                e === translations[2].English
                  ? '_8_TO_11'
                  : e === translations[3].English
                  ? 'HIGH_SCHOOL'
                  : e === translations[4].English
                  ? 'SOME_COLLEGE'
                  : e === translations[5].English
                  ? 'COLLEGE_GRAD'
                  : null,
              );
            }}
          />
          {language === 'Russian' && (i + 1) % 2 === 0 ? (
            <div style={{ width: '100%', height: '12px' }} />
          ) : null}
        </>
      );
    });
    setEducationRow(auxEducationRow);
  }, [activeEducation, calculateYearsContext, educationLabels, language]);

  React.useEffect(() => {
    const auxMaritalRow = maritalLabels.map((label, i) => {
      return (
        <>
          <RadioButton
            // eslint-disable-next-line react/no-array-index-key
            key={`auxMaritalRow-${i}`}
            tag={label.l}
            value={label.k}
            active={activeMaritalStatus === label.k ? 1 : 0}
            toggle={(e) => {
              console.log(e);
              toggleStatus(e);
              calculateYearsContext.setMaritalStatus(
                e === translations[7].English
                  ? 'MARRIED'
                  : e === translations[8].English
                  ? 'SEPARATED'
                  : e === translations[9].English
                  ? 'WIDOWED'
                  : e === translations[10].English
                  ? 'NEVER_MARRIED'
                  : e === translations[11].English
                  ? 'DIVORCED'
                  : null,
              );
            }}
          />
          {(i + 1) % 4 === 0 ? (
            <div style={{ width: '100%', height: '12px' }} />
          ) : null}
        </>
      );
    });
    setMaritalRow(auxMaritalRow);
  }, [activeMaritalStatus, calculateYearsContext, maritalLabels]);

  React.useEffect(() => {
    const auxWorkStatusRow = workStatusLabels.map((label, i) => {
      return (
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`auxWorkStatusRow-${i}`}
          tag={label.l}
          value={label.k}
          active={activeWorkStatus === label.k ? 1 : 0}
          toggle={(e) => {
            console.log(e);
            toggleWork(e);
            calculateYearsContext.setWorkStatus(
              e === translations[13].English
                ? 'WORKING'
                : e === translations[14].English
                ? 'RETIRED'
                : null,
            );
          }}
        />
      );
    });
    setWorkRow(auxWorkStatusRow);
  }, [activeWorkStatus, calculateYearsContext, workStatusLabels]);

  const income = ['<$40K', '$40-60K', '$60-80K', '>$80K'];
  const [activeIncome, toggleIncome] = React.useState('<$40K');

  React.useEffect(() => {
    const auxIncomeRow = income.map((label, i) => {
      return (
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`auxWorkStatusRow-${i}`}
          tag={label}
          active={activeIncome === label ? 1 : 0}
          toggle={(e) => {
            console.log(e);
            toggleIncome(e);
            calculateYearsContext.setAnnualIncome(
              e === '<$40K'
                ? '40MINUS'
                : e === '$40-60K'
                ? '40-60'
                : e === '$60-80K'
                ? '60-80'
                : e === '>$80K'
                ? '80PLUS'
                : null,
            );
          }}
        />
      );
    });
    setIncomeRow(auxIncomeRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIncome, calculateYearsContext]);

  return (
    <Wrapper>
      <Subheading>{translations[0][language]}</Subheading>
      <Section>
        <TextArea>
          <Body3>{translations[1][language]}</Body3>
        </TextArea>
        <InputArea>
          <RadioButtonRow breakLine={language === 'Russian'}>
            {educationRow}
          </RadioButtonRow>
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[6][language]}</Body3>
        </TextArea>
        <InputArea>
          <RadioButtonRow>{maritalRow}</RadioButtonRow>
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[12][language]}</Body3>
        </TextArea>
        <InputArea>
          <RadioButtonRow>{workRow}</RadioButtonRow>
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[15][language]}</Body3>
        </TextArea>
        <InputArea>
          <RadioButtonRow>{incomeRow}</RadioButtonRow>
        </InputArea>
      </Section>
    </Wrapper>
  );
}

export default LifeEvents;
