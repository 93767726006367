import React from 'react';
import 'firebase/auth';
import 'firebase/firestore';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import BackArrow from '../ReusableItems/BackArrow';
import { Context } from '../Context/globalContextProvider';
import { CalculateYearsContext } from '../Context/CalculateYearsContext';
import {
  FirebaseContext,
  FirebaseGetLifeExpectencyQuery,
  FirestoreSaveUserLifeExpectency,
  FirestoreSaveLifeExpectency,
} from '../Context/FirebaseContext';
import { H1, Body2 } from '../Typography/Fonts';
import PersonalInfo from './PersonalInfo';
import Physique from './Physique';
import LifeEvents from './LifeEvents';
import Fitness from './Fitness';
import Lifestyle from './Lifestyle';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4% 0% 5% 5%;
  margin-left: 200px;
  @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 780px) {
    padding: 0% 5% 0% 5%;
    padding-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;
const Container = styled.div`
  padding-left: 30px;
  @media (max-width: 780px) {
    padding-left: 0px;
  }
`;
const Heading = styled(H1)`
  line-height: 72px;
  margin: 0;
  @media (max-width: 780px) {
    text-align: center;
  }
`;
const BackgroundImage1 = styled.img`
  top: 0;
  left: 0;
  z-index: -1;
  max-width: 800px;
  position: absolute;
  @media (max-width: 780px) {
    display: none;
  }
`;
const BackgroundImage2 = styled.img`
  right: 0;
  z-index: -1;
  position: absolute;
  max-width: 1000px;
  transform: translateY(60%);
  @media (max-width: 780px) {
    display: none;
  }
`;
const Illustration = styled.img`
  width: 100%;
  max-width: 570px;
`;
const BodyText = styled(Body2)`
  color: #262626;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 64px;
  @media (max-width: 780px) {
    line-height: 20px;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 16px;
    opacity: 0.7;
    margin: -10px 0px 0px 0px;
  }
`;
const TextArea = styled.div`
  width: 70%;
  @media (max-width: 1080px) {
    width: 80%;
  }
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const ImageArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20% 0% 0% 0%;
  width: 30%;
  @media (max-width: 1080px) {
    display: none;
  }
`;
const Button = styled.button`
  height: 48px;
  width: 496px;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  transition: 0.3s;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: normal !important;
  color: #ffffff;
  margin: 5% 5% 5% 20%;
  background: #57b010;
  border: 2px solid #57b010;
  &:hover {
    border: 2px solid #79c040;
    cursor: pointer;
    background: #79c040;
    color: #ffffff;
  }
  &:focus {
    outline: 0;
  }
  @media (max-width: 780px) {
    width: 80%;
    height: 50px;
    border-radius: 10px;
    letter-spacing: 0.5px;
    font-size: 15px;
    line-height: 19px;
    margin: 5% 0% 10% 0%;
  }
`;

const translations = [
  {
    English: 'Calculator',
    Russian: 'Калькулятор',
    Spanish: 'Calculadora',
  },
  {
    English: 'Calculate your life expectency',
    Russian: 'Подсчитай ожидаемую продолжительность жизни',
    Spanish: 'Calcula tu expectativa de vida',
  },
  {
    English: 'Calculate',
    Russian: 'Посчитать',
    Spanish: 'Calcular',
  },
];

function CalculateYears() {
  const history = useHistory();
  const { language } = React.useContext(SettingsContext);
  const calculateYearsContext = React.useContext(CalculateYearsContext);
  const user = React.useContext(Context);
  const [validDay, setValidDay] = React.useState(false);
  const [validMonth, setValidMonth] = React.useState(false);
  const [validYear, setValidYear] = React.useState(false);
  const [continueClicked, setContinueClicked] = React.useState(false);
  const authentication = React.useContext(FirebaseContext);

  const onChangeHandlerCalculate = async () => {
    const isQuit = calculateYearsContext.smokingStatus === 'QUIT';
    const smoking = `${
      isQuit ? calculateYearsContext.smokingWhenQuit ?? '' : ''
    }${calculateYearsContext.smoking ?? ''}`;
    const smokingAmount = calculateYearsContext.smokingAmount ?? '';
    const metricType = calculateYearsContext.usUnits ? 'IMPERIAL' : 'METRIC';

    const URL = `https://us-central1-oorganised.cloudfunctions.net/longevity?alcohol=${
      calculateYearsContext.alcohol
    }&current_age=${calculateYearsContext.age}&diabetes=${
      calculateYearsContext.diabetes
    }&education=${calculateYearsContext.education}&exercise=${
      calculateYearsContext.exercise
    }&gender=${calculateYearsContext.Gender}&health=${
      calculateYearsContext.generalHealth
    }&height=${calculateYearsContext.Height}&height_type=${metricType}&income=${
      calculateYearsContext.annualIncome
    }&incomeStatus=${calculateYearsContext.workStatus}&marital_status=${
      calculateYearsContext.maritalStatus
    }&optIn=false&race=${
      calculateYearsContext.race === 'Asian'
        ? 'OTHER'
        : calculateYearsContext.race
    }${smoking ? `&smoking=${smoking}` : ''}${
      smokingAmount ? `&smokingAmount=${smokingAmount}` : ''
    }&smokingQuit=${calculateYearsContext.smokingQuit}&smokingStatus=${
      calculateYearsContext.smokingStatus
    }&us_units=${calculateYearsContext.usUnits}&weight=${
      calculateYearsContext.weight
    }&weight_type=${metricType}`;
    console.log(URL);
    console.log(URL.split('&'));
    const params = {
      alcohol: calculateYearsContext.alcohol,
      age: calculateYearsContext.age,
      diabetes: calculateYearsContext.diabetes,
      education: calculateYearsContext.education,
      exercise: calculateYearsContext.exercise,
      gender: calculateYearsContext.Gender,
      generalHealth: calculateYearsContext.generalHealth,
      height: calculateYearsContext.Height,
      annualIncome: calculateYearsContext.annualIncome,
      workStatus: calculateYearsContext.workStatus,
      maritalStatus: calculateYearsContext.maritalStatus,
      race: calculateYearsContext.race,
      weight: calculateYearsContext.weight,
      smoking,
      smokingAmount,
      smokingQuit: calculateYearsContext.smokingQuit,
      smokingStatus: calculateYearsContext.smokingStatus,
      metricType,
      country: calculateYearsContext.residenceCountry,
    };
    //console.log(params);

    let data = {};
    if (authentication.user) {
      const result = await FirebaseGetLifeExpectencyQuery(params);
      result.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data = doc.data();
      });
    }

    if (data?.lifeExpectency) {
      user.setLifeExpectency(data?.lifeExpectency);
      user.setBirthday(calculateYearsContext.birth);
      if (authentication.user) {
        await FirestoreSaveUserLifeExpectency(
          authentication.user.uid,
          data?.lifeExpectency,
          calculateYearsContext.birth,
        );
      }
      console.log('roundedA ', data?.lifeExpectency);
      //window.location = '/success';
      history.push({
        pathname: '/success',
      });
    } else {
      axios
        .get(URL)
        .then(async (response) => {
          const calc = response.data.mean;
          if (response?.data?.mean) {
            const rounded = Math.round(calc);
            user.setLifeExpectency(rounded);
            user.setBirthday(calculateYearsContext.birth);
            if (authentication.user) {
              await FirestoreSaveUserLifeExpectency(
                authentication.user.uid,
                rounded,
                calculateYearsContext.birth,
              );
              await FirestoreSaveLifeExpectency({
                ...params,
                lifeExpectency: rounded,
              });
            }
            console.log('rounded ', rounded);
            //window.location = '/success';
            history.push({
              pathname: '/success',
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching Life expectency: ', error);
        });
    }
  };

  return (
    <div>
      <Wrapper>
        {/* <Header2 to="/birthdayinfo" /> */}
        <BackgroundImage1
          src="birthday-info-illustration-1.png"
          alt="background-illustration1"
          width="30%"
        />
        <TextArea>
          <BackArrow link="/birthdayinfo" marginLeft="-8px" />
          <Heading>{translations[0][language]}</Heading>
          <BodyText>{translations[1][language]}</BodyText>
          <Container>
            <PersonalInfo
              validDay={validDay}
              setValidDay={setValidDay}
              validMonth={validMonth}
              setValidMonth={setValidMonth}
              validYear={validYear}
              setValidYear={setValidYear}
              continueClicked={continueClicked}
            />
            <Physique />
            <LifeEvents />
            <Fitness />
            <Lifestyle />
            {validDay === true && validYear === true && validMonth === true ? (
              // /success
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link to="#">
                <Button
                  onClick={() => {
                    setContinueClicked(true);
                    onChangeHandlerCalculate();
                  }}
                >
                  {translations[2][language]}
                </Button>
              </Link>
            ) : (
              <Button
                onClick={() => {
                  setContinueClicked(true);
                  setValidDay(false);
                  setValidMonth(false);
                  setValidYear(false);
                }}
              >
                {translations[2][language]}
              </Button>
            )}
          </Container>
        </TextArea>
        <ImageArea>
          <BackgroundImage2
            src="calculate-years-illustration.png"
            alt="background-illustration2"
            width="30%"
          />
          <Illustration
            src="calculate-years-image.png"
            alt="background-illustration2"
            width="100%"
          />
        </ImageArea>
      </Wrapper>
      <VerticalNavigationBar />
    </div>
  );
}

export default CalculateYears;
