import React from 'react';
import styled from 'styled-components';
import { SliderPicker, HuePicker } from 'react-color';
import { Context } from '../Context/globalContextProvider';
import 'firebase/auth';
import 'firebase/firestore';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

const SliderPickerWrapper = styled.div`
  position: absolute;
  left: -18px;
  width: 250px;
`;

const HuePickerWrapper = styled.div`
  position: relative;
  left: -32px;
  z-index: 99999;
`;

const PickerArea = styled.div`
  transform: rotate(270deg);
  width: 100%;
`;

const Recents = styled.div`
  height: 16px;
  display: flex;
  flex-direction: row;
  gap: 9.9px;
  margin-top: 20px;
`;

const Color = styled.div`
  background: ${(props) => props.shade};
  border-radius: 50%;
  height: 16px;
  width: 16px;
`;

const PaintSelection = ({ circleColor, setCircleColor }) => {
  const { setActiveColor, recentColors } = React.useContext(Context);

  /**
   What was done below is using two pickers (slider and hue) and 
   show hue picker OVER the hue bar of the slider picker.

   WHY?: Hue picker of sliderpicker does not work when is rotated veritically
   */
  return (
    <Wrapper>
      <HuePickerWrapper className="HuePickerWrapper">
        <HuePicker
          width={13}
          height={250}
          direction="vertical"
          color={circleColor}
          onChange={(color) => {
            setCircleColor(color.hex);
            setActiveColor(color.hex);
          }}
        />
      </HuePickerWrapper>
      <SliderPickerWrapper className="SliderPickerWrapper">
        <PickerArea>
          <SliderPicker
            color={circleColor}
            onChange={(color) => {
              setCircleColor(color.hex);
              setActiveColor(color.hex);
            }}
          />
          <Recents className="Recents">
            {recentColors.map((color) => (
              <Color
                shade={color}
                key={color}
                onClick={() => {
                  setCircleColor(color);
                  setActiveColor(color);
                }}
              />
            ))}
          </Recents>
        </PickerArea>
      </SliderPickerWrapper>
    </Wrapper>
  );
};

export default PaintSelection;
