import React from 'react';
import styled from 'styled-components';

const Button = styled.div`
  min-width: 19px;
  height: 19px;
  border: 1.6px solid #adadad;
  box-sizing: border-box;
  border-radius: 50%;
  transition: 0.3s;
  margin: 5px;
  &:hover {
    cursor: pointer;
    border: 2px solid #57b010;
  }
  ${(props) => {
    if (props.active === 1) {
      return 'background-clip: content-box; background-color: #57B010; box-shadow: inset 0 0 0 3px white; border: 2px solid #57B010;';
    }
    return 'background: #FFFFFF;';
  }}
  @media(max-width: 780px) {
    box-shadow: inset 0 0 0 2px rgba(239, 246, 241, 0.8);
    min-width: 20px;
    height: 20px;
    ${(props) => {
      if (props.active === 1) {
        return 'background-clip: content-box; background-color: #57B010; box-shadow: inset 0 0 0 3px white; border: 2px solid #57B010;';
      }
      return 'background: transparent;';
    }}
  }
`;
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 12px;
  min-width: 25%;
  max-width: 25%;
  color: #8b9583;
  &:hover {
    color: #262626;
  }
  &:hover ${Button} {
    border: 2px solid #57b010;
  }
  @media (max-width: 780px) {
    min-width: 50%;
    max-width: 50%;
    flex-wrap: wrap;
    gap: 9px;
    margin: 10px 0px 0px 0px;
    flex-direction: row;
  }
`;
const Tag = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  font-size: 13px;
  transition: 0.3s;
  text-align: center;
  ${(props) => {
    if (props.active === 1) {
      return 'color: #262626;';
    }
    return null;
  }}
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 780px) {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.5px;
    max-width: 70%;
    text-align: left;
  }
`;

function RadioButton(props) {
  const { active, tag, value, toggle } = props;
  return (
    <Wrapper onClick={() => toggle(value ?? tag)}>
      <Button active={active} />
      <Tag active={active}>{tag}</Tag>
    </Wrapper>
  );
}

export default RadioButton;
