import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  ViberShareButton,
  ViberIcon,
  EmailShareButton,
  EmailIcon,
  //
  HatenaShareButton,
  HatenaIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LineShareButton,
  LineIcon,
  LivejournalShareButton,
  LivejournalIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  VKShareButton,
  VKIcon,
  WeiboShareButton,
  WeiboIcon,
  WorkplaceShareButton,
  WorkplaceIcon,
} from 'react-share';

const ShareModalComponent = () => {
  const shareUrl = 'https://liveweeks.com/';
  const appId = '989453541584412';
  const iconSize = '30';
  return (
    // style={{ display: 'flex', justifyContent: 'space-around' }}
    <div>
      <FacebookShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <FacebookIcon round size={iconSize} />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        url={shareUrl}
        style={{ padding: '0px 5px' }}
        appId={appId}
      >
        <FacebookMessengerIcon round size={iconSize} />
      </FacebookMessengerShareButton>
      <TwitterShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <TwitterIcon round size={iconSize} />
      </TwitterShareButton>
      <TelegramShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <TelegramIcon round size={iconSize} />
      </TelegramShareButton>
      <WhatsappShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <WhatsappIcon round size={iconSize} />
      </WhatsappShareButton>
      <LinkedinShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <LinkedinIcon round size={iconSize} />
      </LinkedinShareButton>
      <ViberShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <ViberIcon round size={iconSize} />
      </ViberShareButton>
      <EmailShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <EmailIcon round size={iconSize} />
      </EmailShareButton>
      {/* -- */}
      <HatenaShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <HatenaIcon round size={iconSize} />
      </HatenaShareButton>
      <InstapaperShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <InstapaperIcon round size={iconSize} />
      </InstapaperShareButton>
      <LineShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <LineIcon round size={iconSize} />
      </LineShareButton>
      <LivejournalShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <LivejournalIcon round size={iconSize} />
      </LivejournalShareButton>
      <MailruShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <MailruIcon round size={iconSize} />
      </MailruShareButton>
      <OKShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <OKIcon round size={iconSize} />
      </OKShareButton>
      <PinterestShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <PinterestIcon round size={iconSize} />
      </PinterestShareButton>
      <PocketShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <PocketIcon round size={iconSize} />
      </PocketShareButton>
      <RedditShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <RedditIcon round size={iconSize} />
      </RedditShareButton>
      <TumblrShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <TumblrIcon round size={iconSize} />
      </TumblrShareButton>
      <VKShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <VKIcon round size={iconSize} />
      </VKShareButton>
      <WeiboShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <WeiboIcon round size={iconSize} />
      </WeiboShareButton>
      <WorkplaceShareButton url={shareUrl} style={{ padding: '0px 5px' }}>
        <WorkplaceIcon round size={iconSize} />
      </WorkplaceShareButton>
    </div>
  );
};

export default ShareModalComponent;
