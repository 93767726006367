import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { Body2 } from '../Typography/Fonts';
import { countriesList } from './countriesArray';

const ListItem = styled(Body2)`
  box-sizing: border-box;
  padding-left: 24px;
  transition: 0.3s;
  &:hover {
    color: #2a75ba;
    cursor: pointer;
  }
  @media (max-width: 780px) {
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    letter-spacing: 1px;
  }
`;

// const countriesList = [
//   'Afghanistan',
//   'Albania',
//   'Algeria',
//   'American Samoa',
//   'Andorra',
//   'Angola',
//   'Anguilla',
//   'Antarctica',
//   'Antigua and Barbuda',
//   'Argentina',
//   'Armenia',
//   'Aruba',
//   'Australia',
//   'Austria',
//   'Azerbaijan',
//   'Bahamas',
//   'Bahrain',
//   'Bangladesh',
//   'Barbados',
//   'Belarus',
//   'Belgium',
//   'Belize',
//   'Benin',
//   'Bermuda',
//   'Bhutan',
//   'Bolivia (Plurinational State of)',
//   'Bonaire, Sint Eustatius and Saba',
//   'Bosnia and Herzegovina',
//   'Botswana',
//   'Bouvet Island',
//   'Brazil',
//   'British Indian Ocean Territory',
//   'Brunei Darussalam',
//   'Bulgaria',
//   'Burkina Faso',
//   'Burundi',
//   'Cabo Verde',
//   'Cambodia',
//   'Cameroon',
//   'Canada',
//   'Cayman Islands',
//   'Central African Republic',
//   'Chad',
//   'Chile',
//   'China',
//   'Christmas Island',
//   'Cocos (Keeling) Islands',
//   'Colombia',
//   'Comoros',
//   'Congo (the Democratic Republic of the)',
//   'Congo',
//   'Cook Islands',
//   'Costa Rica',
//   'Croatia',
//   'Cuba',
//   'Curaçao',
//   'Cyprus',
//   'Czechia',
//   "Côte d'Ivoire",
//   'Denmark',
//   'Djibouti',
//   'Dominica',
//   'Dominican Republic',
//   'Ecuador',
//   'Egypt',
//   'El Salvador',
//   'Equatorial Guinea',
//   'Eritrea',
//   'Estonia',
//   'Eswatini',
//   'Ethiopia',
//   'Falkland Islands [Malvinas]',
//   'Faroe Islands',
//   'Fiji',
//   'Finland',
//   'France',
//   'French Guiana',
//   'French Polynesia',
//   'French Southern Territories',
//   'Gabon',
//   'Gambia',
//   'Georgia',
//   'Germany',
//   'Ghana',
//   'Gibraltar',
//   'Greece',
//   'Greenland',
//   'Grenada',
//   'Guadeloupe',
//   'Guam',
//   'Guatemala',
//   'Guernsey',
//   'Guinea',
//   'Guinea-Bissau',
//   'Guyana',
//   'Haiti',
//   'Heard Island and McDonald Islands',
//   'Holy See',
//   'Honduras',
//   'Hong Kong',
//   'Hungary',
//   'Iceland',
//   'India',
//   'Indonesia',
//   'Iran (Islamic Republic of)',
//   'Iraq',
//   'Ireland',
//   'Isle of Man',
//   'Israel',
//   'Italy',
//   'Jamaica',
//   'Japan',
//   'Jersey',
//   'Jordan',
//   'Kazakhstan',
//   'Kenya',
//   'Kiribati',
//   "Korea (the Democratic People's Republic of)",
//   'Korea (the Republic of)',
//   'Kuwait',
//   'Kyrgyzstan',
//   "Lao People's Democratic Republic",
//   'Latvia',
//   'Lebanon',
//   'Lesotho',
//   'Liberia',
//   'Libya',
//   'Liechtenstein',
//   'Lithuania',
//   'Luxembourg',
//   'Macao',
//   'Madagascar',
//   'Malawi',
//   'Malaysia',
//   'Maldives',
//   'Mali',
//   'Malta',
//   'Marshall Islands',
//   'Martinique',
//   'Mauritania',
//   'Mauritius',
//   'Mayotte',
//   'Mexico',
//   'Micronesia (Federated States of)',
//   'Moldova (the Republic of)',
//   'Monaco',
//   'Mongolia',
//   'Montenegro',
//   'Montserrat',
//   'Morocco',
//   'Mozambique',
//   'Myanmar',
//   'Namibia',
//   'Nauru',
//   'Nepal',
//   'Netherlands',
//   'New Caledonia',
//   'New Zealand',
//   'Nicaragua',
//   'Niger',
//   'Nigeria',
//   'Niue',
//   'Norfolk Island',
//   'Northern Mariana Islands',
//   'Norway',
//   'Oman',
//   'Pakistan',
//   'Palau',
//   'Palestine, State of',
//   'Panama',
//   'Papua New Guinea',
//   'Paraguay',
//   'Peru',
//   'Philippines',
//   'Pitcairn',
//   'Poland',
//   'Portugal',
//   'Puerto Rico',
//   'Qatar',
//   'Republic of North Macedonia',
//   'Romania',
//   'Russian Federation',
//   'Rwanda',
//   'Réunion',
//   'Saint Barthélemy',
//   'Saint Helena, Ascension and Tristan da Cunha',
//   'Saint Kitts and Nevis',
//   'Saint Lucia',
//   'Saint Martin (French part)',
//   'Saint Pierre and Miquelon',
//   'Saint Vincent and the Grenadines',
//   'Samoa',
//   'San Marino',
//   'Sao Tome and Principe',
//   'Saudi Arabia',
//   'Senegal',
//   'Serbia',
//   'Seychelles',
//   'Sierra Leone',
//   'Singapore',
//   'Sint Maarten (Dutch part)',
//   'Slovakia',
//   'Slovenia',
//   'Solomon Islands',
//   'Somalia',
//   'South Africa',
//   'South Georgia and the South Sandwich Islands',
//   'South Sudan',
//   'Spain',
//   'Sri Lanka',
//   'Sudan',
//   'Suriname',
//   'Svalbard and Jan Mayen',
//   'Sweden',
//   'Switzerland',
//   'Syrian Arab Republic',
//   'Taiwan',
//   'Tajikistan',
//   'Tanzania, United Republic of',
//   'Thailand',
//   'Timor-Leste',
//   'Togo',
//   'Tokelau',
//   'Tonga',
//   'Trinidad and Tobago',
//   'Tunisia',
//   'Turkey',
//   'Turkmenistan',
//   'Turks and Caicos Islands',
//   'Tuvalu',
//   'Uganda',
//   'Ukraine',
//   'United Arab Emirates',
//   'United Kingdom of Great Britain and Northern Ireland',
//   'United States Minor Outlying Islands',
//   'United States of America',
//   'Uruguay',
//   'Uzbekistan',
//   'Vanuatu',
//   'Venezuela (Bolivarian Republic of)',
//   'Viet Nam',
//   'Virgin Islands (British)',
//   'Virgin Islands (US)',
//   'Wallis and Futuna',
//   'Western Sahara',
//   'Yemen',
//   'Zambia',
//   'Zimbabwe',
// ];

const dynamicSearch = (country, language) =>
  countriesList.filter((term) =>
    term[language].toLocaleLowerCase().includes(country.toLocaleLowerCase()),
  );

const SearchResults = ({ country, setCountry, language }) => (
  <div className="">
    <Scrollbars
      autoHeight
      style={{
        zIndex: 1000,
        maxHeight: 300,
        height: 'max-content',
        position: 'absolute',
        background: '#FFFFFF',
        width:
          window.innerWidth < 780 ? 'calc(90% + 15px)' : 'calc(90% + 25px)',
        border: '3px solid #F0F7F1',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        boxShadow: '0px 9px 10px 0px rgba(0,0,0,0.1)',
      }}
    >
      {country !== ''
        ? dynamicSearch(country, language).map((item) => (
            <ListItem
              key={item[language]}
              onClick={() => {
                console.log(item);
                setCountry({ v: item.English, t: item[language] });
              }}
            >
              {item[language]}
            </ListItem>
          ))
        : null}
    </Scrollbars>
  </div>
);

export default SearchResults;
