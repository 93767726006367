import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { firebaseLogin } from '../Context/FirebaseContext';
import AuthenticationLayout from './AuthenticationLayout';
import 'firebase/auth';
import 'firebase/firestore';
import { SettingsContext } from '../Context/SettingsContext';
import { Context } from '../Context/globalContextProvider';

const Input = styled.input`
  background: #dfebfd;
  border-radius: 6px;
  padding-left: 4%;
  padding-right: 4%;
  box-sizing: border-box;
  color: ${(props) => (props.filled ? '#2A75BA' : '#8B9583')};
  width: 100%;
  outline: 0;
  height: 48px;
  border: ${(props) =>
    !props.invalidHighlight ? '2px solid #ff5c33' : '3px solid transparent'};
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  transition: 0.3s;
  white-space: normal !important;
  &:focus {
    color: #262626;
    background: #ffffff;
    border: ${(props) =>
      !props.invalidHighlight ? '3px solid #ff5c33' : '3px solid #DFEBFD'};
  }
  @media (max-width: 780px) {
    height: 47px;
    max-width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 10px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    padding: 16px 21px 16px 21px;
  }
`;
const BodyText = styled.div`
  color: #262626;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 80px;
  @media (max-width: 780px) {
    opacity: 0.6;
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
    letter-spacing: 0.5px;
  }
`;
const Form = styled.form`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 45px;
  @media (max-width: 780px) {
    gap: 20px;
    width: 80%;
    margin: 0% 10% 0% 10%;
  }
`;
const SubText = styled(BodyText)`
  display: inline;
  align-self: ${(props) => (props.nodecor ? 'center' : 'flex-start')};
  letter-spacing: 0px;
  font-size: 12px;
  line-height: 14px;
  transition: 0.3s;
  margin-bottom: ${(props) => (props.nodecor ? '0' : '80px')};
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: ${(props) =>
      props.nodecor ? '1px solid transparent' : '1px solid #000000'};
  }
  @media (max-width: 780px) {
    opacity: ${(props) => (props.nodecor ? 1 : 0.6)};
  }
`;
const Text = styled(SubText)`
  font-weight: 700;
  color: #57b010;
  border-bottom: 2px solid transparent;
  &:hover {
    color: #79c040;
    cursor: pointer;
    border-bottom: 2px solid #79c040;
  }
`;
const Button = styled.button`
  height: 42px;
  width: 100%;
  min-width: 360px;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  transition: 0.3s;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  white-space: normal !important;
  color: #ffffff;
  background: #57b010;
  border: 2px solid #57b010;
  &:hover {
    border: 2px solid #79c040;
    cursor: pointer;
    background: #79c040;
    color: #ffffff;
  }
  &:focus {
    outline: 0;
  }
  @media (max-width: 780px) {
    height: 50px;
    min-width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    border-radius: 10px;
    text-transform: none;
    letter-spacing: 0.5px;
  }
`;

const ErrorText = styled(BodyText)`
  color: #ff0000;
  display: inline;
  letter-spacing: 0px;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
  align-self: center;
  text-align: center;
  @media (max-width: 780px) {
    opacity: 1;
  }
`;

const translations = [
  {
    English: 'Log in',
    Russian: 'Войти',
    Spanish: 'Iniciar sesión',
  },
  {
    English: 'Sync and see your life on any device!',
    Russian: 'Войдите в аккаунт, чтобы не потерять данные!',
    Spanish: '¡Sincroniza y mira tu vida en cualquier dispositivo!',
  },
  {
    English: 'or',
    Russian: 'ИЛИ',
    Spanish: 'ó',
  },
  {
    English: 'Email',
    Russian: 'Имейл',
    Spanish: 'Email',
  },
  {
    English: 'Password',
    Russian: 'Пароль',
    Spanish: 'Contraseña',
  },
  {
    English: 'Forgot your password?',
    Russian: 'Забыли свой пароль?',
    Spanish: '¿Olvidaste tu contraseña?',
  },
  {
    English: "Don't have an account yet?",
    Russian: 'Еще нет аккаунта?',
    Spanish: '¿No tienes una cuenta todavía?',
  },
  {
    English: 'Register',
    Russian: 'Зарегистрироваться',
    Spanish: 'Registrarse',
  },
];

const Login = () => {
  const history = useHistory();
  const { language } = React.useContext(SettingsContext);
  const { birthday, lifeExpectency } = React.useContext(Context);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [focusedInput, setFocusedInput] = React.useState(0);
  const [passwordValidation, setPasswordValidation] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const signInWithEmailAndPasswordHandler = (event) => {
    event.preventDefault();
    setPasswordValidation(false);
    firebaseLogin(
      email,
      password,
      setPasswordValidation,
      setErrorMessage,
      birthday && lifeExpectency > 0,
      history,
    );
  };
  const onChangeHandler = (event) => {
    setPasswordValidation(false);
    const { name, value } = event.currentTarget;
    if (name === 'userEmail') {
      setEmail(value);
    } else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  return (
    <AuthenticationLayout
      pageType={translations[0].English}
      pageText={translations[1][language]}
      pageHeader={translations[0][language]}
    >
      <Form>
        <Input
          required
          type="email"
          name="userEmail"
          value={email}
          invalidHighlight
          placeholder={translations[3][language]}
          id="userEmail"
          filled={email !== '' || email !== null}
          focused={focusedInput === 1}
          onFocus={() => setFocusedInput(1)}
          onBlur={() => setFocusedInput(0)}
          onChange={(event) => onChangeHandler(event)}
        />
        <div>
          <Input
            required
            type="password"
            name="userPassword"
            value={password}
            placeholder={translations[4][language]}
            id="userPassword"
            invalidHighlight={!passwordValidation}
            filled={password !== '' || password !== null}
            focused={focusedInput === 2}
            onFocus={() => setFocusedInput(2)}
            onBlur={() => setFocusedInput(0)}
            onChange={(event) => {
              onChangeHandler(event);
            }}
          />
          <Link to="/password-reset" style={{ textDecoration: 'none' }}>
            <SubText>{translations[5][language]}</SubText>
          </Link>
        </div>

        {passwordValidation ? <ErrorText>{errorMessage}</ErrorText> : null}
        <Button
          type="submit"
          onClick={(event) => {
            signInWithEmailAndPasswordHandler(event);
          }}
        >
          {translations[0][language]}
        </Button>
        <SubText nodecor>
          {translations[6][language]}&nbsp;
          <Link style={{ textDecoration: 'none' }} to="register">
            <Text>{translations[7][language]}</Text>
          </Link>
        </SubText>
      </Form>
    </AuthenticationLayout>
  );
};

export default Login;
