import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import CalendarLayout from './CalendarLayout';
import { Context } from '../Context/globalContextProvider';
import { SettingsContext } from '../Context/SettingsContext';

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 780px) {
    box-sizing: border-box;
    margin-left: 6%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: ${({ desktop }) => (desktop === true ? 'none' : 'default')};
`;
const LittleBox = styled.div`
  width: 6px;
  height: 6px;
  margin: 2px;
  border: 0;
  outline: 0;
  cursor: pointer;
  @media (max-width: 780px) {
    width: 5px;
    height: 5px;
    margin: 1px;
  }
  @media (max-width: 420px) {
    width: 4px;
    height: 4px;
    margin: 1px;
  }
  ${(props) => `background: ${props.activeColor};`}
`;
const Label = styled.label`
  margin: ${(props) => props.margin};
  line-height: 44px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 2px;
  color: #262626;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 780px) {
    margin: 9.5px;
    margin-top: ${(props) => (props.name ? '8px' : '0')};
    margin-bottom: 0;
    font-size: ${(props) => (props.name ? '10px' : '7px')};
    letter-spacing: ${(props) => (props.name ? '3px' : '2px')};
    font-weight: ${(props) => (props.name ? '600' : '400')};
    line-height: 9px;
    text-transform: uppercase;
    color: #333333;
  }
`;
const Arrow = styled.svg`
  transform: translate(20px, 8px);
  @media (max-width: 780px) {
    display: ${(props) => (props.mobile ? 'none' : 'auto')};
    transform: translate(6px, 3px);
    width: 12px;
    height: 12px;
  }
`;
const YearsArrow = styled.svg`
  position: absolute;
  transform: rotate(90deg);
  top: 12px;
  right: 70px;
  @media (max-width: 780px) {
    display: none;
  }
`;
const YearsColumn = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 150px 0px 0px 60px;
  @media (max-width: 780px) {
    width: 20px;
  }
`;
const YearLabel = styled(Label)`
  transform: ${(props) => props.transform || null};
  position: absolute;
  right: -25px;
  top: ${(props) => props.top || '-22px'};
  @media (max-width: 780px) {
    right: -18px;
    top: ${(props) => (props.top ? '-5px' : '-12px')};
  }
`;
const L = styled.div`
  transform: rotate(180deg);
  @media (max-width: 780px) {
    display: none;
  }
`;
const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const WhiteSpace = styled.div`
  height: 4px;
  @media (max-width: 780px) {
    display: none;
  }
`;
const MobileYearLabel = styled.label`
  position: absolute;
  top: -310px;
  transform: rotate(90deg) scale(0.9);
  right: 6px;
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: 600;
  line-height: 9px;
  text-transform: uppercase;
  color: #333333;
  @media (min-width: 780px) {
    display: none;
  }
`;
const translations = [
  {
    English: 'Years',
    Russian: 'Года',
    Spanish: 'Años',
  },
  {
    English: 'Weeks',
    Russian: 'Недели',
    Spanish: 'Semanas',
  },
];
function LifeInWeeks() {
  const {
    birthday,
    activeColor,
    // setCalendarTab,
    setScrollYear,
    lifeExpectency,
    defaultColor,
    paintOpen,
    recentColors,
    setRecentColors,
    lifeInWeeksColors,
    setLifeInWeeksColors,
  } = React.useContext(Context);
  const { language } = React.useContext(SettingsContext);

  const age = moment().year() - moment(birthday).year();
  const boxesArray = [];

  const boxCallback = React.useCallback(
    (key) => {
      if (paintOpen) {
        const newArray = lifeInWeeksColors.filter((l) => l.key !== key);
        setLifeInWeeksColors([...newArray, { key, color: activeColor }]);

        const newArray2 = [
          ...recentColors.filter((rc) => rc !== activeColor),
          activeColor,
        ];
        if (newArray2.length > 10) newArray2.shift();
        setRecentColors(newArray2);
      }
    },
    [
      paintOpen,
      lifeInWeeksColors,
      setLifeInWeeksColors,
      activeColor,
      recentColors,
      setRecentColors,
    ],
  );

  const littleBoxCreator = (year, key, color) => (
    <LittleBox
      activeColor={color}
      key={`LittleBox-${key}`}
      onClick={() => {
        // setCalendarTab(2);
        setScrollYear(year);
        boxCallback(key);
      }}
    />
  );

  // 52 weeks by year
  const weeksLabels = [];
  for (let x = 0; x < 52; x += 5) {
    weeksLabels.push(x);
  }

  const getWeek = (currentdate) => {
    const oneJan = new Date(currentdate.getFullYear(), 0, 1);
    const numberOfDays = Math.floor(
      (currentdate - oneJan) / (24 * 60 * 60 * 1000),
    );
    return Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  };

  const calculateLifeInWeeksMatrix = () => {
    const birthdayWeek = getWeek(new Date(birthday));
    const actualWeek = getWeek(new Date(Date.now()));
    //if (actualWeek < birthdayWeek) age -= 1;
    const lifeWeeks = age * 52 + actualWeek - birthdayWeek;
    //console.log(lifeWeeks);
    const l = lifeExpectency === 0 ? 90 : lifeExpectency;
    for (let x = 1; x <= l; x += 1) {
      const boxRow = [];
      for (let y = 1; y <= 52; y += 1) {
        const key = `${x}-${y}`;

        const actualColor = lifeInWeeksColors.find((c) => c.key === key)?.color;
        const inRangeColor = actualColor ?? defaultColor;
        const notInRangeColor = actualColor ?? '#BECAD6';

        const inRange = (x - 1) * 52 + y <= lifeWeeks;

        if (inRange) {
          boxRow.push(littleBoxCreator(x, key, inRangeColor));
        } else {
          boxRow.push(littleBoxCreator(x, key, notInRangeColor));
        }
      }
      boxesArray.push(boxRow);
      if (x % 10 === 0) {
        boxesArray.push(
          <div key={`div-${x.toString()}`}>
            <WhiteSpace key={`WhiteSpace-${x.toString()}`} />
            <YearLabel key={`YearLabel-${x.toString()}`}>{x}</YearLabel>
          </div>,
        );
      }
      if (x === 1) {
        boxesArray.push(
          <div key={`div2-${x.toString()}`}>
            <YearLabel top="-25px" key={`YearLabel2-${x.toString()}`}>
              1
            </YearLabel>
          </div>,
        );
      }
    }
  };

  calculateLifeInWeeksMatrix();
  React.useEffect(() => {
    calculateLifeInWeeksMatrix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeInWeeksColors]);

  return (
    <div>
      <CalendarLayout>
        <Container>
          <Wrapper>
            <Label name="">
              {translations[1][language]}
              <Arrow
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18 8V11H2V13H18V16L22 12L18 8Z" fill="#2E3A59" />
              </Arrow>
            </Label>
            <div>
              {weeksLabels.map((label) => (
                <Label key={label} margin="16px">
                  {label === 0 ? '1' : label}
                </Label>
              ))}
            </div>
            {boxesArray.map((row, i) => (
              <div
                key={`BoxRowParent-${i.toString()}`}
                style={{ position: 'relative' }}
              >
                <BoxRow key={`BoxRow-${i.toString()}`}>{row}</BoxRow>
              </div>
            ))}
          </Wrapper>
          <YearsColumn>
            <YearLabel transform="rotate(270deg)">
              <L>{translations[0][language]}</L>
              <YearsArrow
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16 18H13V2L11 2V18H8L12 22L16 18Z" fill="#2E3A59" />
              </YearsArrow>
            </YearLabel>
            <MobileYearLabel>
              {translations[0][language]}
              <Arrow
                style={{ position: 'absolute', bottom: '2px', right: '-15px' }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18 8V11H2V13H18V16L22 12L18 8Z" fill="#2E3A59" />
              </Arrow>
            </MobileYearLabel>
          </YearsColumn>
        </Container>
      </CalendarLayout>
    </div>
  );
}

export default LifeInWeeks;
