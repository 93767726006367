import React from 'react';
import styled from 'styled-components';
import { H1, Body4 } from '../Typography/Fonts';

const Wrapper = styled.div`
  display: flex;
  background: #FFFFFF;
  border-radius: 20px;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
  margin: ${(props) => (props.variant === 'top' ? '8% 2% 0% 0%' : '0% 2% 2% 0%')};
  @media (min-width: 780px) {
    ${(props) => {
    switch (props.size) {
      case 'small': return 'width: 376px; max-width: 23%; padding: 24px;';
      case 'large': return 'width: 648px; max-width: 40%; padding: 32px;';
      default: return 'width: 512px; max-width: 31.3333%; padding: 32px;';
    }
  }}}
  @media (max-width: 780px) {
    box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.05);
    ${(props) => {
    switch (props.size) {
      case 'small': return 'width: 100%; max-width: 100%; margin: 5%; padding: 24px; box-sizing: border-box;';
      case 'large': return 'width: 90%; max-width: 90%; margin: 5%; padding: 32px;';
      default: return 'width: 90%; max-width: 90%; margin: 5%; padding: 15px 24px 10px 24px;';
    }
  }}}
  }
`;
const Heading = styled(H1)`
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #262626;
  }
`;
const BodyText = styled(Body4)`
  font-weight: 400;
  @media (max-width: 780px) {
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #262626;
  }
`;
const Image = styled.div`
  width: 144px;
  height: 144px;
  display: flex;
  border-radius: 50%;
  align-self: center;
  background: #FFFFFF;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (props.variant === 'top' ? '-102px' : 'none')};
  margin-bottom: ${(props) => (props.variant === 'top' ? '32px' : '16px')};
  box-shadow: ${(props) => (props.variant === 'top' ? '0px 2px 10px rgba(0, 0, 0, 0.1)' : '0')};
  @media (max-width: 780px) {
    display: none;
  }
`;
const List = styled.div`
  width: 100%;
  margin-top: 24px;
`;
const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: flex-start;
`;
const SVG = styled.div`
  width: 40px;
`;
const ListText = styled.div`
  width: 90%;
  margin-top: -20.5px;
  align-self: flex-start;
  justify-self: flex-start;
  padding-bottom: 24px;
`;

const InsuranceCard = (props) => {
  const { variant, size, cardData } = props;
  return (
    <Wrapper variant={variant} size={size}>
      <Image variant={variant}>
        <img src={cardData.icon} alt="card-illustration" />
      </Image>
      <Heading>{cardData.heading}</Heading>
      <BodyText>{cardData.paragraph}</BodyText>
      {size === 'large'
        ? (
          <List>
            {cardData.list.map((listitem) => (
              <ListItem key={listitem}>
                <SVG>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM5.41 9.59002L4 11L8 15L16 7.00002L14.59 5.58002L8 12.17L5.41 9.59002Z" fill="#7C40A9" />
                  </svg>
                </SVG>
                <ListText>
                  <BodyText>{listitem}</BodyText>
                </ListText>
              </ListItem>
            ))}
          </List>
        )
        : null}
    </Wrapper>
  );
};

export default InsuranceCard;
