import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Body2 } from '../Typography/Fonts';
import AuthenticationLayout from './AuthenticationLayout';
import { SettingsContext } from '../Context/SettingsContext';

// const Input = styled.input`
//   background: #dfebfd;
//   border-radius: 6px;
//   color: ${(props) => (props.filled ? '#2A75BA' : '#8B9583')};
//   outline: 0;
//   height: 40px;
//   width: 40px;
//   max-height: 40px;
//   max-width: 40px;
//   text-align: center;
//   font-family: Montserrat;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 19px;
//   letter-spacing: 1.6px;
//   transition: 0.3s;
//   border: 3px solid transparent;
//   white-space: normal !important;
//   &:focus {
//     color: #262626;
//     background: #ffffff;
//     border: 3px solid #dfebfd;
//   }
//   @media (max-width: 780px) {
//     max-width: 100%;
//     font-size: 13px;
//     font-weight: 400;
//     line-height: 16px;
//     border-radius: 10px;
//     letter-spacing: 0.5px;
//     box-sizing: border-box;
//   }
// `;
// const InputRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   justify-content: space-evenly;
// `;
// const BodyText = styled(Body2)`
//   color: #262626;
//   font-size: 18px;
//   font-weight: 400;
//   line-height: 24px;
//   margin-bottom: 80px;
//   @media (max-width: 780px) {
//     opacity: 0.6;
//     font-size: 12px;
//     line-height: 15px;
//     font-weight: normal;
//     letter-spacing: 0.5px;
//   }
// `;
const Form = styled.form`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 45px;
  @media (max-width: 780px) {
    gap: 20px;
    width: 80%;
    margin: 0% 10% 0% 10%;
  }
`;
// const SubText = styled(BodyText)`
//   display: inline;
//   align-self: ${(props) => (props.nodecor ? 'center' : 'flex-start')};
//   letter-spacing: 0px;
//   font-size: 12px;
//   line-height: 14px;
//   margin-bottom: ${(props) => (props.nodecor ? '0' : '80px')};
//   border-bottom: 1px solid #ffffff;
//   &:hover {
//     cursor: ${(props) => (props.nodecor ? '0' : '1px solid #000000')};
//     border-bottom: ${(props) => (props.nodecor ? '0' : '1px solid #000000')};
//   }
//   @media (max-width: 780px) {
//     opacity: 1;
//   }
// `;
// const Text = styled(SubText)`
//   font-weight: 700;
//   color: #57b010;
//   transition: 0.3s;
//   border-bottom: 2px solid transparent;
//   &:hover {
//     color: #79c040;
//     cursor: pointer;
//     text-align: center;
//     border-bottom: 2px solid #79c040;
//   }
// `;
const Button = styled.button`
  height: 42px;
  width: 100%;
  min-width: 360px;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  transition: 0.3s;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  white-space: normal !important;
  color: #ffffff;
  background: #57b010;
  border: 2px solid #57b010;
  &:hover {
    border: 2px solid #79c040;
    cursor: pointer;
    background: #79c040;
    color: #ffffff;
  }
  &:focus {
    outline: 0;
  }
  @media (max-width: 780px) {
    height: 50px;
    min-width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    border-radius: 10px;
    text-transform: none;
    letter-spacing: 0.5px;
  }
`;

// const signInWithEmailAndPasswordHandler = (event, email, password) => {
//   event.preventDefault();
//   firebaseLogin(email, password);
// };

const translations = [
  {
    English: 'Please check your email to reset your password',
    Russian: 'Проверьте почтовый ящик для восстановления пароля',
    Spanish: 'Por favor revisa tu correo para reiniciar tu contraseña',
  },
  {
    English: 'Confirm',
    Russian: 'Подтвердить',
    Spanish: 'Confirmar',
  },
  {
    English: 'Verify Email',
    Russian: 'Проверить электронную почту',
    Spanish: 'Verificar Email',
  },
];

const VerifyEmail = () => {
  const { language } = React.useContext(SettingsContext);

  return (
    <AuthenticationLayout
      pageType={translations[2].English}
      pageText={translations[0][language]}
      pageHeader={translations[2][language]}
    >
      <Form>
        {/* <InputRow>
          <Input
            type="text"
            maxLength="1"
            name="verificationCode0"
            id="verificationCode0"
            filled={code[0] !== '' || code[0] !== null}
            focused={focusedInput === 1}
            onFocus={(event) => { setFocusedInput(1); event.target.select(); }}
            onBlur={() => setFocusedInput(0)}
            onKeyUp={(event) => handleChange(event, 0)}
          />
          <Input
            type="text"
            maxLength="1"
            name="verificationCode1"
            id="verificationCode1"
            filled={code[1] !== '' || code[1] !== null}
            focused={focusedInput === 2}
            onFocus={(event) => { setFocusedInput(2); event.target.select(); }}
            onBlur={() => setFocusedInput(0)}
            onKeyUp={(event) => handleChange(event, 1)}
          />
          <Input
            type="text"
            maxLength="1"
            name="verificationCode2"
            id="verificationCode2"
            filled={code[2] !== '' || code[2] !== null}
            focused={focusedInput === 3}
            onFocus={(event) => { setFocusedInput(3); event.target.select(); }}
            onBlur={() => setFocusedInput(0)}
            onKeyUp={(event) => handleChange(event, 2)}
          />
          <Input
            type="text"
            maxLength="1"
            name="verificationCode3"
            id="verificationCode3"
            filled={code[3] !== '' || code[3] !== null}
            focused={focusedInput === 4}
            onFocus={(event) => { setFocusedInput(4); event.target.select(); }}
            onBlur={() => setFocusedInput(0)}
            onKeyUp={(event) => handleChange(event, 3)}
          />
        </InputRow>
        <Text nodecor style={{ textDecoration: 'underline' }}>Resend Code</Text> */}
        <Link to="/birthdayinfo">
          <Button type="submit">{translations[1][language]}</Button>
        </Link>
      </Form>
    </AuthenticationLayout>
  );
};

export default VerifyEmail;
