import React from 'react';
import styled from 'styled-components';
import RadioButton from '../ReusableItems/RadioButton';
import Slider from '../ReusableItems/Slider';
import { Body1 } from '../Typography/Fonts';
import { CalculateYearsContext } from '../Context/CalculateYearsContext';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: 780px) {
    padding: 25px;
    padding-bottom: 0px;
    width: auto;
    margin-top: 5%;
    background: rgba(239, 246, 241, 0.8);
    border-radius: 21px;
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 700;
  line-height: 26px;
  font-size: 19px;
  margin: 0px 0px 32px 0px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    margin: 0px 0px 10px 0px;
    text-transform: none;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    font-size: 16px;
  }
`;
const RadioButtonRow = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: 780px) {
    flex-wrap: wrap;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 36px 0px;
  @media (max-width: 780px) {
    width: 100%;
    text-align: left;
    flex-direction: ${(props) => props.flexDirection};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px 0px 20px 0px;
  }
`;
const TextArea = styled.div`
  width: 35%;
  @media (max-width: 780px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 10px 10px 0px;
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const InputArea = styled.div`
  width: 65%;
  @media (max-width: 780px) {
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const Body3 = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  @media (max-width: 780px) {
    font-weight: bold;
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  }
`;

const translations = [
  {
    English: 'Lifestyle',
    Russian: 'Образ жизни',
    Spanish: 'Estilo de vida',
  },
  {
    English: 'Alcohol',
    Russian: 'Алкоголь',
    Spanish: 'Alcohol',
  },
  {
    English: 'Zero',
    Russian: 'Не пью',
    Spanish: 'Cero',
  },
  {
    English: 'Rare',
    Russian: 'Редко',
    Spanish: 'Rara vez',
  },
  {
    English: 'Casual',
    Russian: 'Регулярно',
    Spanish: 'Casualmente',
  },
  {
    English: 'Often',
    Russian: 'Часто',
    Spanish: 'A menudo',
  },
  {
    English: 'Addiction',
    Russian: 'Зависимость',
    Spanish: 'Adicto/a',
  },
  {
    English: 'Smoking',
    Russian: 'Курение',
    Spanish: 'Fumar',
  },
  {
    English: 'Never Smoked',
    Russian: 'Никогда не курил/а',
    Spanish: 'Nunca',
  },
  {
    English: 'Quit',
    Russian: 'Бросил/а',
    Spanish: 'Dejado',
  },
  {
    English: 'When you quit?', //10
    Russian: 'Когда бросили?',
    Spanish: '¿Cuándo lo dejaste?',
  },
  {
    English: 'This year',
    Russian: 'В этом году',
    Spanish: 'Este año',
  },
  {
    English: '1-9 years ago',
    Russian: '1-9 лет назад',
    Spanish: 'Hace 1-9 años',
  },
  {
    English: 'More than 10 years ago',
    Russian: 'Больше 10 лет назад',
    Spanish: 'Hace más de 10 años',
  },
  {
    English: 'Smoking frequency',
    Russian: 'Частота курения',
    Spanish: 'Frecuencia',
  },
  {
    English: '1/2 pack a Day', //15
    Russian: '1/2 пачки в день',
    Spanish: '1/2 caja al día',
  },
  {
    English: '1 pack a Day',
    Russian: '1 пачка в день',
    Spanish: '1 caja al día',
  },
  {
    English: '2 packs a Day',
    Russian: '2 пачки в день',
    Spanish: '2 cajas al día',
  },
  {
    English: 'Still smoking',
    Russian: 'Всё ещё курю',
    Spanish: 'Sigo fumando',
  },
];

function Lifestyle() {
  const { language } = React.useContext(SettingsContext);
  const calculateYearsContext = React.useContext(CalculateYearsContext);

  const [alcoholLabels, setAlcoholLabels] = React.useState([
    { l: translations[2][language], k: translations[2].English },
    { l: translations[3][language], k: translations[3].English },
    { l: translations[4][language], k: translations[4].English },
    { l: translations[5][language], k: translations[5].English },
    { l: translations[6][language], k: translations[6].English },
  ]);
  const [smokingLabels, setSmokingLabels] = React.useState([
    { l: translations[8][language], k: translations[8].English },
    { l: translations[9][language], k: translations[9].English },
    { l: translations[18][language], k: translations[18].English },
  ]);
  const [whenQuitLabels, setWhenQuitLabels] = React.useState([
    { l: translations[11][language], k: translations[11].English },
    { l: translations[12][language], k: translations[12].English },
    { l: translations[13][language], k: translations[13].English },
  ]);
  const [smokingFrequencyLabels, setSmokingFrequencyLabels] = React.useState([
    { l: translations[15][language], k: translations[15].English },
    { l: translations[16][language], k: translations[16].English },
    { l: translations[17][language], k: translations[17].English },
  ]);

  const [alcoholStatus, setAlcohol] = React.useState(0);
  const [smokingStatus, setSmokingStatus] = React.useState(
    translations[8].English,
  );
  const [smokingQuit, setSmokingQuit] = React.useState('');
  const [smokingAmount, setFrequency] = React.useState('');

  const [smokingRow, setSmokingRow] = React.useState([]);
  const [whenQuitRow, setWhenQuitRow] = React.useState([]);
  const [frequencyRow, setFrequencyRowRow] = React.useState([]);

  // const getSmokingString = React.useCallback(
  //   (e) => {
  //     switch (
  //       e //smokingStatus
  //     ) {
  //       case translations[8].English:
  //         return 'NON_SMOKER';
  //       case translations[9].English:
  //         if (smokingAmount === translations[15].English) return 'DOSE_1_TO_10';
  //         if (smokingAmount === translations[16].English)
  //           return 'DOSE_11_TO_20';
  //         if (smokingAmount === translations[17].English)
  //           return 'DOSE_31_TO_40';
  //         return '';
  //       case translations[18].English:
  //         if (smokingAmount === translations[15].English)
  //           return 'CURRENT_1_TO_10';
  //         if (smokingAmount === translations[16].English)
  //           return 'CURRENT_11_TO_20';
  //         if (smokingAmount === translations[17].English)
  //           return 'CURRENT_31_TO_40';
  //         return '';
  //       default:
  //         return '';
  //     }
  //   },
  //   [smokingAmount],
  // );

  React.useEffect(() => {
    switch (smokingStatus) {
      case translations[8].English:
        calculateYearsContext.setSmoking('NON_SMOKER');
        break;
      case translations[9].English:
        if (smokingAmount === translations[15].English)
          calculateYearsContext.setSmoking('DOSE_1_TO_10');
        if (smokingAmount === translations[16].English)
          calculateYearsContext.setSmoking('DOSE_11_TO_20');
        if (smokingAmount === translations[17].English)
          calculateYearsContext.setSmoking('DOSE_31_TO_40');
        break;
      case translations[18].English:
        if (smokingAmount === translations[15].English)
          calculateYearsContext.setSmoking('CURRENT_1_TO_10');
        if (smokingAmount === translations[16].English)
          calculateYearsContext.setSmoking('CURRENT_11_TO_20');
        if (smokingAmount === translations[17].English)
          calculateYearsContext.setSmoking('CURRENT_31_TO_40');
        break;
      default:
        break;
    }
  }, [calculateYearsContext, smokingAmount, smokingStatus]);

  React.useEffect(() => {
    setAlcoholLabels([
      { l: translations[2][language], k: translations[2].English },
      { l: translations[3][language], k: translations[3].English },
      { l: translations[4][language], k: translations[4].English },
      { l: translations[5][language], k: translations[5].English },
      { l: translations[6][language], k: translations[6].English },
    ]);
    setSmokingLabels([
      { l: translations[8][language], k: translations[8].English },
      { l: translations[9][language], k: translations[9].English },
      { l: translations[18][language], k: translations[18].English },
    ]);
    setWhenQuitLabels([
      { l: translations[11][language], k: translations[11].English },
      { l: translations[12][language], k: translations[12].English },
      { l: translations[13][language], k: translations[13].English },
    ]);
    setSmokingFrequencyLabels([
      { l: translations[15][language], k: translations[15].English },
      { l: translations[16][language], k: translations[16].English },
      { l: translations[17][language], k: translations[17].English },
    ]);
  }, [language]);
  React.useEffect(() => {
    const { k } = alcoholLabels[alcoholStatus];
    calculateYearsContext.setAlcohol(
      k === translations[2].English
        ? 'ZERO'
        : k === translations[3].English
        ? '_MT_1_PER_DAY'
        : k === translations[4].English
        ? '_LT_1_PER_DAY'
        : k === translations[5].English
        ? '_MT_1_PER_DAY'
        : k === translations[6].English
        ? '_LT_1_PER_DAY'
        : null,
    );
  }, [alcoholLabels, alcoholStatus, calculateYearsContext]);
  React.useEffect(() => {
    const auxSmokingRow = smokingLabels.map((label, i) => {
      return (
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`auxSmokingRow-${i}`}
          tag={label.l}
          value={label.k}
          active={smokingStatus === label.k ? 1 : 0}
          toggle={(e) => {
            console.log(e);
            setSmokingStatus(e);
            //calculateYearsContext.setSmoking(getSmokingString(e));
            calculateYearsContext.setSmokingQuit(e === translations[9].English);
            calculateYearsContext.setSmokingNow(e === translations[18].English);
            calculateYearsContext.setSmokingStatus(
              e === translations[8].English
                ? 'NON_SMOKER'
                : e === translations[9].English
                ? 'QUIT'
                : 'STILL_SMOKE',
            );
          }}
        />
      );
    });
    setSmokingRow(auxSmokingRow);
  }, [
    calculateYearsContext,
    //getSmokingString,
    language,
    smokingLabels,
    smokingStatus,
  ]);
  React.useEffect(() => {
    const auxWhenQuitRow = whenQuitLabels.map((label, i) => {
      return (
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`auxWhenQuitRow-${i}`}
          tag={label.l}
          value={label.k}
          active={smokingQuit === label.k ? 1 : 0}
          toggle={(e) => {
            console.log(e);
            setSmokingQuit(e);
            calculateYearsContext.setSmokingWhenQuit(
              e === translations[11].English
                ? 'QUIT_LT_1_'
                : e === translations[12].English
                ? 'QUIT_1_TO_9_'
                : e === translations[13].English
                ? 'QUIT_10_PLUS_'
                : null,
            );
          }}
        />
      );
    });
    setWhenQuitRow(
      smokingStatus === translations[9].English ? auxWhenQuitRow : null,
    );
  }, [
    calculateYearsContext,
    language,
    smokingQuit,
    smokingStatus,
    whenQuitLabels,
  ]);
  React.useEffect(() => {
    const auxSmokingFrequencyRow = smokingFrequencyLabels.map((label, i) => {
      return (
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`auxSmokingFrequencyRow-${i}`}
          tag={label.l}
          value={label.k}
          active={smokingAmount === label.k ? 1 : 0}
          toggle={(e) => {
            console.log(e);
            setFrequency(e);
            //calculateYearsContext.setSmoking(getSmokingString(e));
            calculateYearsContext.setSmokingAmount(
              e === translations[15].English
                ? '1_TO_10'
                : e === translations[16].English
                ? '11_TO_20'
                : e === translations[17].English
                ? '31_TO_40'
                : null,
            );
          }}
        />
      );
    });
    const c = [translations[9].English, translations[18].English];
    setFrequencyRowRow(
      c.includes(smokingStatus) ? auxSmokingFrequencyRow : null,
    );
  }, [
    calculateYearsContext,
    //getSmokingString,
    language,
    smokingAmount,
    smokingFrequencyLabels,
    smokingStatus,
  ]);

  return (
    <Wrapper>
      <Subheading>{translations[0][language]}</Subheading>
      <Section>
        <TextArea>
          <Body3>{translations[1][language]}</Body3>
        </TextArea>
        <InputArea>
          <Slider
            unit={alcoholLabels.map((a) => a.l)}
            start={0}
            end={4}
            step={1}
            toggle={setAlcohol}
          />
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[7][language]}</Body3>
          <br />
        </TextArea>
        <InputArea>
          <RadioButtonRow>{smokingRow}</RadioButtonRow>
        </InputArea>
        {/* <br />Score: { alcoholStatus } */}
      </Section>
      {smokingStatus === translations[9].English ? (
        <Section>
          <TextArea>
            <Body3>{translations[10][language]}</Body3>
            <br />
          </TextArea>
          <InputArea>
            <RadioButtonRow>{whenQuitRow}</RadioButtonRow>
          </InputArea>
        </Section>
      ) : null}
      {[translations[9].English, translations[18].English].includes(
        smokingStatus,
      ) ? (
        <Section>
          <TextArea>
            <Body3>{translations[14][language]}</Body3>
            <br />
          </TextArea>
          <InputArea>
            <RadioButtonRow>{frequencyRow}</RadioButtonRow>
          </InputArea>
        </Section>
      ) : null}
    </Wrapper>
  );
}

export default Lifestyle;
