import React from 'react';
import 'firebase/auth';
import 'firebase/firestore';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import firebase from 'firebase/app';
import moment from 'moment';
import { useAuthState } from 'react-firebase-hooks/auth';
import NavigationBar from '../ReusableItems/NavigationBar';
import { Context } from '../Context/globalContextProvider';
import { FirebaseContext } from '../Context/FirebaseContext';
import { H1, Body1, Body2 } from '../Typography/Fonts';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';
import UserIcon from '../ReusableItems/UserIcon';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.div`
  height: ${({ desktop }) => (desktop === true ? '100%' : '100vh')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Body = styled.section`
  width: 80%;
  padding: 5% 0% 0% 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  @media (max-width: 1180px) {
    margin-left: 200px;
  }
  @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 780px) {
    justify-content: flex-start;
    margin-left: 0;
    width: 80%;
    height: 100%;
    padding-top: 45px;
  }
`;
const Button = styled.button`
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  transition: 0.3s;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: normal !important;
  &:focus {
    outline: 0;
  }
  ${(props) => {
    switch (props.size) {
      case 'small':
        return 'width: 210px; color: #57B010; background: #FFFFFF; border: 2px solid #57B010;';
      case 'large':
        return 'width: 440px; height: 50px; color: #FFFFFF; background: #57B010; border: 2px solid #57B010;';
      default:
        return null;
    }
  }}
  @media (max-width: 780px) {
    letter-spacing: 1px;
    border-radius: 10px;
    font-size: 13px;
    ${(props) => {
      switch (props.size) {
        case 'small':
          return 'width: 120px; font-weight: 700; filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));';
        case 'large':
          return 'width: 312px; font-weight: 600;';
        default:
          return null;
      }
    }}
  }
  &:hover {
    border: 2px solid #79c040;
    cursor: pointer;
    ${(props) => {
      switch (props.size) {
        case 'small':
          return 'background: #FFFFFF; color: #79C040;';
        case 'large':
          return 'background: #79C040; color: #FFFFFF;';
        default:
          return null;
      }
    }}
  }
`;
const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  text-align: center;
`;
const Title = styled(H1)`
  margin: auto;
  align-self: center;
  text-transform: uppercase;
`;
const BackgroundIllustration1 = styled.img`
  top: 0;
  left: 0;
  z-index: -1;
  min-width: 350px;
  position: absolute;
`;
const BackgroundIllustration2 = styled.img`
  bottom: 0;
  right: 0;
  z-index: -1;
  min-width: 350px;
  position: fixed;
  @media (max-width: 780px) {
    margin-right: -25%;
  }
`;
const Illustration = styled.img`
  bottom: 0;
  z-index: -1;
  @media (max-width: 780px) {
    bottom: 70px;
    width: 100vw;
    position: absolute;
    max-width: 500px;
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 600;
  text-transform: uppercase;
  padding: ${(props) => props.padding || '1% 0% 1% 0%'};
  transform: translateY(12px);
  @media (max-width: 780px) {
    padding: 12% 0% 0% 0%;
    transform: translateY(-4px);
    width: max-content;
    font-weight: 700;
  }
`;
const BodyText = styled(Body2)`
  color: ${(props) => (props.focused ? '#2A75BA' : '#8B9583')};
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
  }
`;
const FieldsArea = styled.div`
  display: flex;
  width: 700px;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 780px) {
    justify-content: space-evenly;
    width: 100%;
  }
`;
const InputArea = styled.div`
  flex-direction: column;
  @media (min-width: 781px) {
    width: 232px;
  }
  @media (max-width: 780px) {
    width: ${(props) => (props.dInputArea ? '130px' : '')};
  }
`;
const InputAreaText = styled(InputArea)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  minwidth: 240px;
  width: 100px;
`;

//min-width: 20%;
const Input = styled.input`
  background: #dfebfd;
  border-radius: 6px;
  width: 96%;

  border: 0;
  outline: 0;
  height: 42px;
  border: 3px solid ${(props) => (props.invalid ? 'red' : 'transparent')};
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  transition: 0.3s;
  white-space: normal !important;
  color: ${(props) => (props.filled ? '#2A75BA' : '#8B9583')};
  @media (max-width: 780px) {
    width: ${(props) => (props.wide ? '120px' : '71px')};
    border-radius: 10px;
    box-sizing: ${(props) => (props.wide ? 'auto' : 'border-box')};
    height: 41px;
  }
  &:focus {
    color: #262626;
    background: white;
    border: 3px solid #dfebfd;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const UserIconWrapper = styled.div`
  right: 0px;
  position: absolute;
`;

const translations = [
  {
    English: 'YOUR BIRTHDAY',
    Russian: 'ДАТА РОЖДЕНИЯ',
    Spanish: 'TU CUMPLEAÑOS',
  },
  {
    English: 'DAY',
    Russian: 'ДЕНЬ',
    Spanish: 'DÍA',
  },
  {
    English: 'DD',
    Russian: 'дд',
    Spanish: 'DD',
  },
  {
    English: 'MONTH',
    Russian: 'МЕСЯЦ',
    Spanish: 'MES',
  },
  {
    English: 'MM',
    Russian: 'мм',
    Spanish: 'MM',
  },
  {
    English: 'YEAR',
    Russian: 'ГОД',
    Spanish: 'AÑO',
  },
  {
    English: 'YYYY',
    Russian: 'гггг',
    Spanish: 'AAAA',
  },
  {
    English: 'LIFE EXPECTANCY',
    Russian: 'ПРОДОЛЖИТЕЛЬНОСТЬ ЖИЗНИ',
    Spanish: 'EXPECTATIVA DE VIDA',
  },
  {
    English: 'YEARS',
    Russian: 'ЛЕТ',
    Spanish: 'AÑOS',
  },
  {
    English: 'YY',
    Russian: 'гг',
    Spanish: 'AA',
  },
  {
    English: 'OR',
    Russian: 'ИЛИ',
    Spanish: 'Ó',
  },
  {
    English: 'CALCULATE',
    Russian: 'ПОСЧИТАТЬ',
    Spanish: 'CALCULAR',
  },
  {
    English: 'CONTINUE',
    Russian: 'Продолжить',
    Spanish: 'CONTINUAR',
  },
  {
    English: 'LIVEWEEKS',
    Russian: 'LIVEWEEKS',
    Spanish: 'LIVEWEEKS',
  },
];

function BirthdayInfo(props) {
  const { desktop } = props;
  const { language } = React.useContext(SettingsContext);
  const user = React.useContext(Context);
  const authentication = React.useContext(FirebaseContext);

  const dayInputRef = React.useRef(null);
  const monthInputRef = React.useRef(null);
  const yearInputRef = React.useRef(null);

  const [focusedInput, setFocusedInput] = React.useState(0);

  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [age, setAge] = React.useState('');

  const [validDate, setValidDate] = React.useState(true);
  const [validAge, setValidAge] = React.useState(true);
  const [dateModified, setDateModified] = React.useState(false);
  const [ageModified, setAageModified] = React.useState(false);

  const [userDetails] = useAuthState(firebase.auth());

  const addZero = (v) => {
    const vString = v.toString();
    return vString && vString?.length === 1 ? `0${vString}` : vString;
  };

  const onBirthdayChangeHandler = () => {
    const newStringDate = `${addZero(month)} ${addZero(day)},${year}`;
    const birthday = new Date(newStringDate);
    const timestamp = Date.parse(birthday);
    const dateInRange = moment(birthday).isBetween(
      new Date('1 1,1800'),
      new Date(Date.now()),
    );

    const tMonth = addZero(birthday.getMonth() + 1);
    const tDay = addZero(birthday.getDate());
    const tYear = birthday.getFullYear();
    const createdStringDate = `${tMonth} ${tDay},${tYear}`;

    // console.log(newStringDate);
    // console.log(createdStringDate);
    const dateExistsInCalendar = newStringDate === createdStringDate;
    if (
      birthday instanceof Date &&
      Number.isNaN(timestamp) === false &&
      // timestamp > 0 &&
      dateInRange &&
      dateExistsInCalendar
    ) {
      setDateModified(false);
      setValidDate(true);
    } else {
      console.log('INVALID DATE');
      setValidDate(false);
    }
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      const fieldsFilled = day && month && year;
      if (fieldsFilled && dateModified) {
        onBirthdayChangeHandler();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, year, month]);

  React.useEffect(() => {
    const isValid = age > 0 && age <= 200;
    setValidAge(isValid);
    if (isValid) {
      setAageModified(false);
    }
  }, [age]);

  const handleDayChange = (event) => {
    setDateModified(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 2;
    const intValue = parseInt(value, 10);
    const validAmount = (intValue <= 31 && intValue >= 0) || !value;
    if (validLength && validAmount) {
      setDay(value);
      if (value.length === 2) {
        setFocusedInput(2);
        monthInputRef.current.focus();
      }
    }
  };

  const handleMonthChange = (event) => {
    setDateModified(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 2;
    const intValue = parseInt(value, 10);
    const validAmount = (intValue <= 12 && intValue >= 0) || !value;
    if (validLength && validAmount) {
      setMonth(value);
      if (value.length === 2) {
        setFocusedInput(3);
        yearInputRef.current.focus();
      }
    }
  };

  const handleYearChange = (event) => {
    setDateModified(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 4;
    const intValue = parseInt(value, 10);
    const validAmount = intValue >= 0 || !value;
    if (validLength && validAmount) {
      setYear(value);
    }
  };

  const handleAgeChange = (event) => {
    setAageModified(true);
    const { value } = event.target;
    const validLength = value.toString().length <= 3;
    const intValue = parseInt(value, 10);
    const validAmount = (intValue <= 200 && intValue >= 0) || !value;
    if (validLength && validAmount) setAge(value);
  };

  const onChangeHandlerContinue = () => {
    if (validDate && validAge) {
      user.setLifeExpectency(age);
      user.setBirthday(new Date(`${month} ${day},${year}`));
      if (authentication.user) {
        firebase
          .firestore()
          .collection('users')
          .doc(authentication.user.uid)
          .set(
            {
              birthday: new Date(`${month} ${day},${year}`),
              lifeExpectency: age,
            },
            { merge: true },
          )
          .then(() => {
            console.log('Birhtday and life expectency stored!');
          })
          .catch((error) => {
            console.error(
              'Error writing Birhtday and life expectency: ',
              error,
            );
          });
      }
    }
  };
  const onChangeHandlerCalculate = () => {
    if (validDate) {
      user.setBirthday(new Date(`${month} ${day},${year}`));
    }
  };
  return (
    <Wrapper desktop={desktop}>
      <MetaTags>
        <title>Life Calendar and Journal | Liveweeks</title>
        <meta
          name="description"
          content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
        />
      </MetaTags>
      <BackgroundIllustration1
        src="birthday-info-illustration-1.png"
        alt="background-illustration1"
        width="30%"
      />
      <BackgroundIllustration2
        src="birthday-info-illustration-2.png"
        alt="background-illustration2"
        width="30%"
      />
      <Body>
        <TitleArea>
          <Title>{translations[13][language]}</Title>
          <UserIconWrapper>
            <UserIcon userDetails={userDetails} />
          </UserIconWrapper>
        </TitleArea>
        <Subheading>{translations[0][language]}</Subheading>
        <FieldsArea>
          <InputArea>
            <BodyText focused={focusedInput === 1}>
              {translations[1][language]}
            </BodyText>
            <Input
              type="number"
              placeholder={translations[2][language]}
              ref={dayInputRef}
              invalid={dateModified && !validDate}
              filled={day.length > 0}
              value={day}
              min="1"
              max="31"
              onFocus={() => setFocusedInput(1)}
              onBlur={() => setFocusedInput(0)}
              onChange={handleDayChange}
            />
          </InputArea>
          <InputArea>
            <BodyText focused={focusedInput === 2}>
              {translations[3][language]}
            </BodyText>
            <Input
              type="number"
              placeholder={translations[4][language]}
              ref={monthInputRef}
              invalid={dateModified && !validDate}
              filled={month.length > 0}
              value={month}
              min="1"
              max="12"
              onFocus={() => setFocusedInput(2)}
              onBlur={() => setFocusedInput(0)}
              onChange={handleMonthChange}
            />
          </InputArea>
          <InputArea>
            <BodyText focused={focusedInput === 3}>
              {translations[5][language]}
            </BodyText>
            <Input
              type="number"
              placeholder={translations[6][language]}
              ref={yearInputRef}
              invalid={dateModified && !validDate}
              filled={year.length > 0}
              value={year}
              min="1"
              onFocus={() => setFocusedInput(3)}
              onBlur={() => setFocusedInput(0)}
              onChange={handleYearChange}
            />
          </InputArea>
        </FieldsArea>
        <Subheading padding="1% 0% 0% 0%">
          {translations[7][language]}
        </Subheading>
        <FieldsArea>
          <InputArea dInputArea>
            <BodyText focused={focusedInput === 4}>
              {translations[8][language]}
            </BodyText>
            <Input
              wide
              type="number"
              invalid={ageModified && !validAge}
              placeholder={translations[9][language]}
              filled={age > 0}
              value={age}
              min="1"
              max="200"
              onFocus={() => setFocusedInput(4)}
              onBlur={() => setFocusedInput(0)}
              onChange={handleAgeChange}
            />
          </InputArea>
          <InputAreaText>
            <Subheading translate>{translations[10][language]}</Subheading>
          </InputAreaText>
          <InputArea
            dInputArea
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Link to="/calculateyears" style={{ width: '100%' }}>
              <Button
                size="small"
                onClick={(event) => onChangeHandlerCalculate(event)}
                style={{ width: '100%' }}
              >
                {translations[11][language]}
              </Button>
            </Link>
          </InputArea>
        </FieldsArea>
        {validDate && validAge ? (
          <Link to="/calendar" style={{ marginTop: '80px' }}>
            <Button
              size="large"
              onClick={(event) => onChangeHandlerContinue(event)}
            >
              {translations[12][language]}
            </Button>
          </Link>
        ) : (
          <Button
            style={{ marginTop: '80px' }}
            size="large"
            onClick={(event) => onChangeHandlerContinue(event)}
          >
            {translations[12][language]}
          </Button>
        )}
        <Illustration src="birthday-info-image.png" alt="illustration" />
      </Body>
      <NavigationBar />
      <VerticalNavigationBar />
    </Wrapper>
  );
}

export default BirthdayInfo;
