import React from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import CheckBox from '../ReusableItems/CheckBox';
import 'firebase/auth';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;
const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 62px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  @media (max-width 780px) {
  }
`;
const ListItemRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  width: inherit;
  @media (max-width 780px) {
    gap: 0px;
  }
`;
const FooterArea = styled.div`
  align-items: center;
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width 780px) {
  }
`;

const AddItem = styled.div`
  display: flex;
  align-items: center;
  color: #b08ccb;
  cursor: pointer;
`;

// &:focus {
//   color: #7c40a9;
// }
const Input = styled.input`
  border: 0;
  outline: 0;
  color: #7c40a9;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  letter-spacing: 1.6px;
  background: transparent;
  font-family: Montserrat;
  white-space: normal !important;

  @media (max-width: 780px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    padding: 0;
  }
`;
const ListItemInput = styled(Input)`
  color: ${(props) => (props.checked ? '#7C40A9' : '#8B9583')};
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  width: 100%;
  @media (max-width: 780px) {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 0;
    margin-top: 1px;
  }
`;
const Arrow = styled.svg`
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
    transition: 0.3s;
  }
  @media (max-width: 780px) {
    margin-top: 6px;
  }
`;
const Plus = styled.svg`
  margin-top: 7px;
  padding-right: 7px;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
    transition: 0.3s;
  }
  @media (max-width: 780px) {
  }
`;
const Cross = styled.svg`
  cursor: pointer;
  transform: rotate(45deg);
  &:hover {
    transform: rotate(45deg) scale(1.2);
    transition: 0.3s;
  }
`;

// bottom: -68px;
// box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.1);
// padding: 12px 18px 12px 18px;
const DeleteButton = styled.button`
  position: absolute;
  padding: 0px;
  right: 0;
  width: max-content;
  background: #ffffff;
  outline: 0;
  border: 0;
  border-radius: 14px;
  color: #f47059;
  cursor: pointer;
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.6px;
  padding-bottom: 3px;
  &:hover {
    transition: 0.3s;
    background: #f6f6f4;
  }
`;

const DragHandle = SortableHandle(() => (
  <div style={{ cursor: 'grab' }}>
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M16 2.4C16 3.72548 14.9254 4.8 13.6 4.8C12.2745 4.8 11.2 3.72548 11.2 2.4C11.2 1.07452 12.2745 0 13.6 0C14.9254 0 16 1.07452 16 2.4Z"
          fill="black"
        />
        <path
          d="M16 12C16 13.3255 14.9254 14.4 13.6 14.4C12.2745 14.4 11.2 13.3255 11.2 12C11.2 10.6745 12.2745 9.6 13.6 9.6C14.9254 9.6 16 10.6745 16 12Z"
          fill="black"
        />
        <path
          d="M16 21.6C16 22.9255 14.9254 24 13.6 24C12.2745 24 11.2 22.9255 11.2 21.6C11.2 20.2745 12.2745 19.2 13.6 19.2C14.9254 19.2 16 20.2745 16 21.6Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M4.8 2.4C4.8 3.72548 3.72548 4.8 2.4 4.8C1.07452 4.8 0 3.72548 0 2.4C0 1.07452 1.07452 0 2.4 0C3.72548 0 4.8 1.07452 4.8 2.4Z"
          fill="black"
        />
        <path
          d="M4.8 12C4.8 13.3255 3.72548 14.4 2.4 14.4C1.07452 14.4 0 13.3255 0 12C0 10.6745 1.07452 9.6 2.4 9.6C3.72548 9.6 4.8 10.6745 4.8 12Z"
          fill="black"
        />
        <path
          d="M4.8 21.6C4.8 22.9255 3.72548 24 2.4 24C1.07452 24 0 22.9255 0 21.6C0 20.2745 1.07452 19.2 2.4 19.2C3.72548 19.2 4.8 20.2745 4.8 21.6Z"
          fill="black"
        />
      </g>
    </svg>
  </div>
));

const SortableItem = SortableElement(
  ({
    value,
    listId,
    setBucketListCheckedHandler,
    setBucketListTextHandler,
    handleDelete,
  }) => {
    return (
      <ListItem key={`ListItemKey-${value.id}-${listId}`}>
        <ListItemRow key={`ListItemRowKey-${value.id}-${listId}`}>
          <DragHandle />
          <CheckBox
            checked={value.checked}
            setCheckedByIndex={setBucketListCheckedHandler}
            index={value.id}
          />
          <ListItemInput
            autoFocus
            key={`ListItemInputKey-${value.id}-${listId}`}
            checked={value.checked}
            type="text"
            value={value.text}
            onChange={(e) => {
              console.log(e.target.value);
              setBucketListTextHandler(value.id, e.target.value);
            }}
            onBlur={() => {
              console.log('ONBLUR: ', value);
            }}
          />
        </ListItemRow>
        <div
          aria-hidden="true"
          style={{ zIndex: 10000, padding: '10px 0px 0px 15px' }}
          onClick={() => handleDelete(value.id)}
        >
          <Cross
            name={value.value}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => handleDelete(value.id)}
          >
            <path
              d="M7 0C6.72385 0 6.5 0.22385 6.5 0.5V6.5H0.5C0.22385 6.5 0 6.72385 0 7C0 7.27615 0.22385 7.5 0.5 7.5H6.5V13.5C6.5 13.7761 6.72385 14 7 14C7.27615 14 7.5 13.7761 7.5 13.5V7.5H13.5C13.7761 7.5 14 7.27615 14 7C14 6.72385 13.7761 6.5 13.5 6.5H7.5V0.5C7.5 0.22385 7.27615 0 7 0Z"
              fill="#B08CCB"
            />
          </Cross>
        </div>
      </ListItem>
    );
  },
);

const SortableList = SortableContainer(
  ({
    items,
    listId,
    setBucketListCheckedHandler,
    setBucketListTextHandler,
    handleDelete,
  }) => (
    <ul style={{ paddingLeft: '0px' }} key={`UlKey-${listId}`}>
      {items.map((value, index) => (
        <SortableItem
          key={`SortableItemKey-${value.id}-${listId}`}
          index={index}
          value={value}
          listId={listId}
          setBucketListCheckedHandler={setBucketListCheckedHandler}
          setBucketListTextHandler={setBucketListTextHandler}
          handleDelete={handleDelete}
        />
      ))}
    </ul>
  ),
);

const translations = [
  {
    English: 'Enter list name',
    Russian: 'Ввести имя списка',
    Spanish: 'Ingresar nombre',
  },
  {
    English: 'List item',
    Russian: 'Добавить пункт',
    Spanish: 'Elemento de lista',
  },
];

function BucketList(props) {
  const {
    listId,
    listName,
    listValues,
    saveEntries,
    deleteBucketsList,
    editBucketsListName,
    addEntry,
    editEntryChecked,
    editEntryText,
  } = props;
  const [listExpanded, toggleListExpand] = React.useState(true);
  const { language } = React.useContext(SettingsContext);

  function handleInsert(text) {
    const newEntry = {
      id: Date.now(),
      text,
      checked: false,
    };
    addEntry(listId, newEntry);
  }

  const setBucketListCheckedHandler = (entryId, checked) => {
    editEntryChecked(listId, entryId, checked);
  };

  const setBucketListTextHandler = (entryId, text) => {
    editEntryText(listId, entryId, text);
  };

  const onSortStart = ({ node, index, collection, isKeySorting }, event) => {
    document.body.style.overflow = 'hidden';
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    document.body.style.overflow = 'auto';
    const newEntriesArray = arrayMove(listValues, oldIndex, newIndex);
    saveEntries(listId, newEntriesArray);
  };

  const handleDelete = (entryId) => {
    const newEntriesArray = listValues.filter((l) => l.id !== entryId);
    saveEntries(listId, newEntriesArray);
  };

  return (
    <Wrapper key={`WrapperKey2-${listId}`}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Input
          type="text"
          placeholder={translations[0][language]}
          value={listName}
          onChange={(e) => {
            editBucketsListName(listId, e.target.value);
          }}
        />
        {listExpanded ? (
          <Arrow
            onClick={() => toggleListExpand(!listExpanded)}
            width="20"
            height="11"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.3132 11.7132L11.9999 0.399902L0.686565 11.7132L2.5719 13.5999L11.9999 4.17057L21.4279 13.5999L23.3132 11.7132Z"
              fill="#B08CCB"
            />
          </Arrow>
        ) : (
          <Arrow
            onClick={() => toggleListExpand(!listExpanded)}
            width="20"
            height="11"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.686646 2.28677L12 13.6001L23.3133 2.28677L21.428 0.400099L12 9.82943L2.57198 0.400099L0.686646 2.28677Z"
              fill="#B08CCB"
            />
          </Arrow>
        )}
      </div>
      {listExpanded ? (
        <div
          style={{ margin: '20px 0px 0px 0px' }}
          key={`SortableListDivKey-${listId}`}
        >
          <SortableList
            key={`SortableListKey-${listId}`}
            items={listValues}
            onSortStart={onSortStart}
            onSortEnd={onSortEnd}
            distance={10}
            useDragHandle
            listId={listId}
            setBucketListCheckedHandler={setBucketListCheckedHandler}
            setBucketListTextHandler={setBucketListTextHandler}
            handleDelete={handleDelete}
          />
        </div>
      ) : null}
      {listExpanded ? (
        <FooterArea>
          <AddItem onClick={() => handleInsert('')}>
            <Plus
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 0C6.72385 0 6.5 0.22385 6.5 0.5V6.5H0.5C0.22385 6.5 0 6.72385 0 7C0 7.27615 0.22385 7.5 0.5 7.5H6.5V13.5C6.5 13.7761 6.72385 14 7 14C7.27615 14 7.5 13.7761 7.5 13.5V7.5H13.5C13.7761 7.5 14 7.27615 14 7C14 6.72385 13.7761 6.5 13.5 6.5H7.5V0.5C7.5 0.22385 7.27615 0 7 0Z"
                fill="#B08CCB"
              />
            </Plus>
            {translations[1][language]}
          </AddItem>
          <DeleteButton onClick={() => deleteBucketsList(listId)}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 22H7C5.89543 22 5 21.1046 5 20V7H3V5H7V4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V5H21V7H19V20C19 21.1046 18.1046 22 17 22ZM7 7V20H17V7H7ZM9 4V5H15V4H9Z"
                fill="#F47059"
              />
            </svg>
          </DeleteButton>
        </FooterArea>
      ) : null}
    </Wrapper>
  );
}

export default BucketList;
