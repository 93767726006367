import React from 'react';
import styled from 'styled-components';
import AutoHeightTextarea from './TextArea';
import { Body4 } from '../Typography/Fonts';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 10px;
  padding: 18px 26px 18px 26px;
  background: #fcf2e6;
  border-radius: 10px;
  @media (max-width: 780px) {
    padding: 20px;
  }
`;
const HeadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 780px) {
    justify-content: space-between;
  }
`;
// const Input = styled.input`
//   border: 0;
//   outline: 0;
//   width: 95%;
//   background: transparent;
//   border-bottom: 1px solid #d6d6d6;
//   font-family: Montserrat;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 24px;
//   color: #262626;
// `;
const Arrow = styled.svg`
  justify-self: flex-end;
  margin-left: auto;
  cursor: pointer;
  padding: 12px;
  width: 15px;
  height: 8px;
  @media (max-width: 780px) {
    width: 26px;
    padding: 0;
  }
`;
const BodyText = styled(Body4)`
  @media (max-width: 780px) {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #333333;
  }
`;
const BodyHeading = styled(BodyText)`
  padding-left: 30px;
  cursor: pointer;
  @media (max-width: 780px) {
    padding: 0px 10px 0px 15px;
  }
`;
const Icon = styled.svg``;

const translations = [
  {
    English: 'What am I happy about in my life now?',
    Russian: 'Почему я счастлив/а сейчас?',
    Spanish: '¿De qué estoy feliz en mi vida ahora?',
  },
  {
    English: 'What am I committed to in my life now?',
    Russian: 'Чему я посвящаю время в данный момент?',
    Spanish: '¿A qué estoy comprometido en mi vida ahora?',
  },
  {
    English: 'What am I grateful about in my life now?',
    Russian: 'К чему/кому я чувствую благодарность в жизни?',
    Spanish: '¿De qué estoy agradecido en mi vida ahora?',
  },
];

function MorningQuestions({ answers, saveJournal }) {
  const [expanded, toggleExpand] = React.useState(false);
  const { language } = React.useContext(SettingsContext);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const onChangeHandler = (text, name) => {
    saveJournal(name, text);
  };
  const optimisedHandleChange = debounce(onChangeHandler, 25);

  const onEnterKeyHandler = (event, name) => {
    if (event.key === 'Enter') {
      console.log('onEnterKeyHandler: ', name);
    }
  };

  let expandedContent = null;
  if (expanded) {
    expandedContent = (
      <div>
        <AutoHeightTextarea
          name="a1"
          fieldValue={answers?.a1 ?? ''}
          onChangeHandler={optimisedHandleChange}
          onEnterKeyHandler={onEnterKeyHandler}
          bgColor="#FCF2E6"
        />
        <BodyText>{translations[1][language]}</BodyText>
        <AutoHeightTextarea
          name="a2"
          fieldValue={answers?.a2 ?? ''}
          onChangeHandler={optimisedHandleChange}
          onEnterKeyHandler={onEnterKeyHandler}
          bgColor="#FCF2E6"
        />
        <BodyText>{translations[2][language]}</BodyText>
        <AutoHeightTextarea
          name="a3"
          fieldValue={answers?.a3 ?? ''}
          onChangeHandler={optimisedHandleChange}
          onEnterKeyHandler={onEnterKeyHandler}
          bgColor="#FCF2E6"
        />
      </div>
    );
  }

  return (
    <Wrapper>
      <HeadingArea>
        <Icon
          width="24px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12.6754 0H11.3254V4.2H12.6754V0Z" fill="#F9BB1E" />
          <path d="M12.6754 19.5H11.3254V24H12.6754V19.5Z" fill="#F9BB1E" />
          <path
            d="M23.9996 11.3254H19.7996V12.6754H23.9996V11.3254Z"
            fill="#F9BB1E"
          />
          <path d="M4.5 11.3254H0V12.6754H4.5V11.3254Z" fill="#F9BB1E" />
          <path
            d="M19.6781 20.1121L20.5691 19.2212L18.0341 16.6862L17.1432 17.5772L19.6781 20.1121Z"
            fill="#F9BB1E"
          />
          <path
            d="M6.42473 6.85188L7.31567 5.96094L4.77542 3.42068L3.88447 4.31163L6.42473 6.85188Z"
            fill="#F9BB1E"
          />
          <path
            d="M20.566 4.3221L19.675 3.43115L17.1401 5.96611L18.031 6.85705L20.566 4.3221Z"
            fill="#F9BB1E"
          />
          <path
            d="M7.30757 17.5715L6.41675 16.6804L3.87613 19.2203L4.76695 20.1114L7.30757 17.5715Z"
            fill="#F9BB1E"
          />
          <path
            d="M12.0002 17.6852C8.86519 17.6852 6.31519 15.1352 6.31519 12.0002C6.31519 8.86519 8.86519 6.31519 12.0002 6.31519C15.1352 6.31519 17.6852 8.86519 17.6852 12.0002C17.6852 15.1352 15.1352 17.6852 12.0002 17.6852Z"
            fill="#F9BB1E"
          />
        </Icon>
        <BodyHeading onClick={() => toggleExpand(!expanded)}>
          {translations[0][language]}
        </BodyHeading>
        <Arrow
          onClick={() => toggleExpand(!expanded)}
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.514893 1.46495L8.99989 9.94995L17.4849 1.46495L16.0709 0.0499516L8.99989 7.12195L1.92889 0.0499516L0.514893 1.46495Z"
            fill="#57B010"
          />
        </Arrow>
      </HeadingArea>
      {expandedContent}
    </Wrapper>
  );
}

export default MorningQuestions;
