import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 5px;
  z-index:10000;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    transform: scale(1.05);
  }
  @media (max-width: 780px) {
    margin-top: 4px;
    transform: scale(0.8);
  }
`;

const CheckBox = ({ checked, setCheckedByIndex, index }) => {
  const [check, setCheck] = React.useState(checked);
  return (
    <Wrapper onClick={() => { setCheckedByIndex(index, !check); setCheck(!check); }}>
      {check
        ? (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 19.3334H3.33341C1.86066 19.3334 0.666748 18.1395 0.666748 16.6667V3.33341C0.666748 1.86066 1.86066 0.666748 3.33341 0.666748H16.6667C18.1395 0.666748 19.3334 1.86066 19.3334 3.33341V16.6667C19.3334 18.1395 18.1395 19.3334 16.6667 19.3334ZM3.33341 3.33341V16.6667H16.6667V3.33341H3.33341ZM8.66675 14.4827L5.06675 10.9534L6.93341 9.04675L8.66675 10.7414L13.0667 6.38542L14.9334 8.28141L8.66675 14.4814V14.4827Z" fill="#7C40A9" />
          </svg>
        )
        : (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 19.3332H3.33341C1.86066 19.3332 0.666748 18.1393 0.666748 16.6665V3.33317C0.666748 1.86041 1.86066 0.666504 3.33341 0.666504H16.6667C18.1395 0.666504 19.3334 1.86041 19.3334 3.33317V16.6665C19.3334 18.1393 18.1395 19.3332 16.6667 19.3332ZM3.33341 3.33317V16.6665H16.6667V3.33317H3.33341Z" fill="#ADADAD" />
          </svg>
        )}
    </Wrapper>
  );
};

export default CheckBox;
