import React from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { H1, Body4 } from '../Typography/Fonts';
import BackArrow from '../ReusableItems/BackArrow';
import NavigationBar from '../ReusableItems/NavigationBar';
import VerticalNavigationBar from '../ReusableItems/VerticalNavigation';

const Wrapper = styled.div`
  padding: 0% 5% 0% 5%;
  margin-left: 200px;
  @media (max-width: 780px) {
    padding: 0%;
    margin-left: 0;
    padding-top: 20px;
  }
`;
const Heading = styled(H1)`
  margin: 0;
  line-height: 72px;
  background: transparent;
  text-transform: uppercase;
`;
const HeadingArea = styled.div`
  display: flex;
  width: 100%;
  padding: 3% 0% 0% 0%;
  justify-content: space-between;
  @media (max-width: 780px) {
    width: 90%;
    padding: 0% 5% 0% 5%;
    justify-content: center;
    text-align: center;
  }
`;
const BodyText = styled(Body4)`
  @media (max-width: 780px) {
    letter-spacing: 0.5px;
    margin: 0% 5% 0% 5%;
    line-height: 15px;
    font-size: 11px;
    color: #262626;
  }
`;
const List = styled.ol`
  padding: 0px 0px 0px 18px;
`;
const ListItem = styled.li`
  color: #57b010;
  @media (max-width: 780px) {
    margin: 0% 5% 0% 5%;
  }
`;
const BackgroundIllustration1 = styled.svg`
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  @media (max-width: 780px) {
    max-width: 90%;
  }
`;
const BackgroundIllustration2 = styled.svg`
  right: 0;
  z-index: -1;
  position: absolute;
  transform: translateY(150px);
  @media (max-width: 780px) {
    transform: translateY(70px);
    max-width: 90%;
  }
`;

const DataDeletion = () => (
  <div>
    <MetaTags>
      <title>Life Calendar and Journal | Liveweeks</title>
      <meta
        name="description"
        content="Fill your Life calendar with colorful events and note every cherish memory in Journal. Live meaningful and plan you future with liveweeks."
      />
    </MetaTags>
    <Wrapper>
      <BackArrow />
      <BackgroundIllustration1
        width="389"
        height="827"
        viewBox="0 0 389 827"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M314.604 246.453C-99.2418 400.268 317.662 749.06 132.152 812.106C-53.3585 875.152 -272.443 724.12 -357.187 474.765C-441.932 225.41 -360.245 -27.8417 -174.735 -90.8881C10.7747 -153.935 591.467 143.551 314.604 246.453Z"
          fill="#E8F2EA"
        />
      </BackgroundIllustration1>

      <HeadingArea>
        <Heading>Data Deletion Instructions </Heading>
      </HeadingArea>
      <BodyText>
        LIVEWEEKS does not store your personal data; it is used only for login.
        According to the Facebook Platform rules, we have to provide{' '}
        <b>User Data Deletion Callback URL</b> or{' '}
        <b>Data Deletion Instructions URL</b>. If you want to delete your
        activities for the LIVEWEEKS, following these instructions:
      </BodyText>
      <List>
        <ListItem>
          <BodyText>
            Go to Your Facebook Account’s Setting & Privacy. Click ”Setting“.
          </BodyText>
        </ListItem>
        <ListItem>
          <BodyText>
            Then, go to ”Apps and Websites” and you will see all of your Apps
            activities.
          </BodyText>
        </ListItem>
        <ListItem>
          <BodyText>Select the option box for LIVEWEEKS.</BodyText>
        </ListItem>
        <ListItem>
          <BodyText>Click ”Remove” button.</BodyText>
        </ListItem>
      </List>

      <BackgroundIllustration2
        width="565"
        height="990"
        viewBox="0 0 565 990"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M460.927 185.914C619.74 929.44 -71.0606 592.349 6.34805 920.815C83.7567 1249.28 412.783 1452.8 741.249 1375.39C1069.71 1297.99 1273.24 968.959 1195.83 640.493C1118.42 312.027 354.682 -311.506 460.927 185.914Z"
          fill="#FCF2E6"
        />
      </BackgroundIllustration2>
    </Wrapper>
    <NavigationBar />
    <VerticalNavigationBar />
  </div>
);

export default DataDeletion;
