import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
  display: block;
  overflow: hidden;
  outline: 0;
  width: 95%;
  background: transparent;
  margin-bottom: 25px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: #262626;
  resize: none;
  border: 0;
  min-height: 84px;
  color: ${(props) => props.color}px;
  background-image: -webkit-linear-gradient(
      left,
      ${(props) => props.bgColor} 1px,
      transparent 1px
    ),
    -webkit-linear-gradient(right, ${(props) => props.bgColor} 1px, transparent
          1px),
    -webkit-linear-gradient(${(props) => props.bgColor} 26px, #d6d6d6 26px, ${(
            props,
          ) => props.bgColor}
          27px, ${(props) => props.bgColor} 27px);
  background-size: 100% 100%, 100% 100%, 100% 28px;
`;

const AutoHeightTextarea = ({ bgColor, onChangeHandler, name, fieldValue }) => {
  const textareaRef = React.useRef(null);
  // const [currentValue, setCurrentValue] = React.useState('');// you can manage data with it

  React.useEffect(() => {
    textareaRef.current.style.height = '0px';
    const { scrollHeight } = textareaRef.current;
    textareaRef.current.style.height = `${scrollHeight}px`;
  }, [fieldValue]);

  return (
    <TextArea
      ref={textareaRef}
      value={fieldValue}
      bgColor={bgColor}
      onChange={(e) => {
        onChangeHandler(e.target.value, name);
      }}
      // onBlur={(e) => { onChangeHandler(e, name); }}
      // eslint-disable-next-line no-unused-vars
      onKeyPress={(event) => {}}
    />
  );
};

export default AutoHeightTextarea;
