/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Context } from '../Context/globalContextProvider';
import { SettingsContext } from '../Context/SettingsContext';

const handleColorType = (tabName, active) => {
  if (
    active &&
    (tabName.includes('calendar') ||
      tabName.includes('settings') ||
      tabName.includes('lifeinsurance'))
  ) {
    return '#2A75BA';
  }
  if (tabName.includes('journal/future') && active) {
    return '#7C40A9;';
  }
  if (tabName.includes('journal') && active) {
    return '#57B010';
  }
  return '#000000';
};
const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  left: 0px;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  padding: 1% 6% 0% 6%;
  box-sizing: border-box;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  background: ${(props) => (props.shaded ? 'transparent' : 'transparent')};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  @media (min-width: 900px) {
    display: none;
  }
`;
const IconButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  width: 40px;
  border: 0;
  gap: 10px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;
const ButtonText = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  color: ${(props) => handleColorType(props.tabName, props.active)};
`;

const translations = [
  {
    English: 'Calendar',
    Russian: 'Календарь',
    Spanish: 'Calendario',
  },
  {
    English: 'Journal',
    Russian: 'Дневник',
    Spanish: 'Diario',
  },
  {
    English: 'Settings',
    Russian: 'Настройки',
    Spanish: 'Configuración',
  },
];

function NavigationBar(props) {
  const { shaded } = props;
  const user = React.useContext(Context);
  const { language } = React.useContext(SettingsContext);
  const routes = ['/calendar', '/journal', '/lifeinsurance', '/settings'];
  const location = useLocation();

  return (
    <Wrapper shaded={shaded}>
      <Link
        to={routes[0]}
        onClick={() => user.setNavigationTab('/calendar')}
        style={{ textDecoration: 'none' }}
      >
        <IconButton key="calendar">
          {location.pathname.includes('calendar') ? (
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
                stroke="#2A75BA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 1V5"
                stroke="#2A75BA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 1V5"
                stroke="#2A75BA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 9H19"
                stroke="#2A75BA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <path
                  d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 1V5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 1V5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 9H19"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          )}
          <ButtonText
            tab={
              user.navigationTab === '/calendar' ? user.navigationTab : 'null'
            }
            active={location.pathname.includes('calendar')}
            tabName={location.pathname}
          >
            {translations[0][language]}
          </ButtonText>
        </IconButton>
      </Link>
      <Link
        to={routes[1]}
        onClick={() => user.setNavigationTab('/journal')}
        style={{ textDecoration: 'none' }}
      >
        <IconButton key="journal">
          {location.pathname === '/journal' ||
          location.pathname === '/journal/past' ? (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.2096 0H3.79666C2.98318 0 2.3457 0.637474 2.3457 1.45096V2.61169H1.18497C0.784261 2.61169 0.459473 2.93648 0.459473 3.33719C0.459473 3.7379 0.784261 4.06265 1.18497 4.06265H2.3457V5.97314H1.18497C0.784261 5.97314 0.459473 6.29793 0.459473 6.69864C0.459473 7.09935 0.784261 7.42414 1.18497 7.42414H2.3457V9.33459H1.18497C0.784261 9.33459 0.459473 9.65938 0.459473 10.0601C0.459473 10.4608 0.784261 10.7856 1.18497 10.7856H2.3457V12.6959H1.18497C0.784261 12.6959 0.459473 13.0207 0.459473 13.4214C0.459473 13.8221 0.784261 14.1469 1.18497 14.1469H2.3457V16.0573H1.18497C0.784261 16.0573 0.459473 16.382 0.459473 16.7827C0.459473 17.1835 0.784261 17.5082 1.18497 17.5082H2.3457V18.669C2.3457 19.4826 2.98314 20.12 3.79666 20.12H18.2095C19.0233 20.12 19.6605 19.4826 19.6605 18.669V1.45096C19.6605 0.637474 19.0231 0 18.2096 0ZM3.79672 16.7827C3.79672 16.7972 3.7963 16.8115 3.79547 16.8257L3.79666 18.669H5.99731V1.45096H3.79666C3.78892 1.45096 3.78554 1.45194 3.78554 1.45194L3.78668 3.21626C3.79328 3.25559 3.79672 3.29599 3.79672 3.33719C3.79672 3.37807 3.79334 3.41816 3.78684 3.4572L3.78886 6.59146C3.79404 6.62644 3.79672 6.66223 3.79672 6.69864C3.79672 6.73472 3.79409 6.77019 3.789 6.80485L3.79104 9.96893C3.79479 9.99879 3.79672 10.0292 3.79672 10.0601C3.79672 10.0906 3.79483 10.1207 3.79116 10.1503L3.79323 13.3498C3.79554 13.3734 3.79672 13.3973 3.79672 13.4214C3.79672 13.4453 3.79557 13.4689 3.79332 13.4921L3.79541 16.7389C3.79628 16.7534 3.79672 16.768 3.79672 16.7827ZM7.44827 1.45096V18.669H18.2095V1.45096H7.44827Z"
                  fill="#57B010"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="20.12" height="20.12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : location.pathname === '/journal/future' ? (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M18.2096 0H3.79666C2.98318 0 2.3457 0.637474 2.3457 1.45096V18.669C2.3457 19.4826 2.98314 20.12 3.79666 20.12H18.2095C19.0233 20.12 19.6605 19.4826 19.6605 18.669V1.45096C19.6605 0.637474 19.0231 0 18.2096 0ZM18.2096 18.669H3.79666L3.78554 1.45194C3.78554 1.45194 3.78892 1.45096 3.79666 1.45096H18.2095V18.669H18.2096Z"
                  fill="#7C40A9"
                />
                <path
                  d="M3.07122 9.33447H1.18497C0.784261 9.33447 0.459473 9.65926 0.459473 10.06C0.459473 10.4607 0.784261 10.7855 1.18497 10.7855H3.07122C3.4717 10.7855 3.79668 10.4607 3.79672 10.06C3.79668 9.65926 3.47193 9.33447 3.07122 9.33447Z"
                  fill="#7C40A9"
                />
                <path
                  d="M3.07122 5.97314H1.18497C0.784261 5.97314 0.459473 6.29793 0.459473 6.69864C0.459473 7.09935 0.784261 7.42414 1.18497 7.42414H3.07122C3.4717 7.42414 3.79672 7.09935 3.79672 6.69864C3.79672 6.29793 3.47193 5.97314 3.07122 5.97314Z"
                  fill="#7C40A9"
                />
                <path
                  d="M3.07122 2.61182H1.18497C0.784261 2.61182 0.459473 2.93661 0.459473 3.33732C0.459473 3.73803 0.784261 4.06277 1.18497 4.06277H3.07122C3.4717 4.06277 3.79672 3.73799 3.79672 3.33732C3.79668 2.93657 3.47193 2.61182 3.07122 2.61182Z"
                  fill="#7C40A9"
                />
                <path
                  d="M3.07122 12.6958H1.18497C0.784261 12.6958 0.459473 13.0206 0.459473 13.4213C0.459473 13.822 0.784261 14.1468 1.18497 14.1468H3.07122C3.4717 14.1468 3.79672 13.822 3.79672 13.4213C3.79668 13.0206 3.47193 12.6958 3.07122 12.6958Z"
                  fill="#7C40A9"
                />
                <path
                  d="M3.07122 16.0571H1.18497C0.784261 16.0571 0.459473 16.3819 0.459473 16.7826C0.459473 17.1833 0.784261 17.5081 1.18497 17.5081H3.07122C3.4717 17.5081 3.79672 17.1833 3.79672 16.7826C3.79668 16.3819 3.47193 16.0571 3.07122 16.0571Z"
                  fill="#7C40A9"
                />
                <path
                  d="M7.44827 0.725586H5.99731V19.3946H7.44827V0.725586Z"
                  fill="#7C40A9"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="20.12" height="20.12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3" clipPath="url(#clip0)">
                <path
                  d="M18.2096 0H3.79666C2.98318 0 2.3457 0.637474 2.3457 1.45096V18.669C2.3457 19.4826 2.98314 20.12 3.79666 20.12H18.2095C19.0233 20.12 19.6605 19.4826 19.6605 18.669V1.45096C19.6605 0.637474 19.0231 0 18.2096 0ZM18.2096 18.669H3.79666L3.78554 1.45194C3.78554 1.45194 3.78892 1.45096 3.79666 1.45096H18.2095V18.669H18.2096Z"
                  fill="black"
                />
                <path
                  d="M3.07122 9.33447H1.18497C0.784261 9.33447 0.459473 9.65926 0.459473 10.06C0.459473 10.4607 0.784261 10.7855 1.18497 10.7855H3.07122C3.4717 10.7855 3.79668 10.4607 3.79672 10.06C3.79668 9.65926 3.47193 9.33447 3.07122 9.33447Z"
                  fill="black"
                />
                <path
                  d="M3.07122 5.97314H1.18497C0.784261 5.97314 0.459473 6.29793 0.459473 6.69864C0.459473 7.09935 0.784261 7.42414 1.18497 7.42414H3.07122C3.4717 7.42414 3.79672 7.09935 3.79672 6.69864C3.79672 6.29793 3.47193 5.97314 3.07122 5.97314Z"
                  fill="black"
                />
                <path
                  d="M3.07122 2.61182H1.18497C0.784261 2.61182 0.459473 2.93661 0.459473 3.33732C0.459473 3.73803 0.784261 4.06277 1.18497 4.06277H3.07122C3.4717 4.06277 3.79672 3.73799 3.79672 3.33732C3.79668 2.93657 3.47193 2.61182 3.07122 2.61182Z"
                  fill="black"
                />
                <path
                  d="M3.07122 12.6958H1.18497C0.784261 12.6958 0.459473 13.0206 0.459473 13.4213C0.459473 13.822 0.784261 14.1468 1.18497 14.1468H3.07122C3.4717 14.1468 3.79672 13.822 3.79672 13.4213C3.79668 13.0206 3.47193 12.6958 3.07122 12.6958Z"
                  fill="black"
                />
                <path
                  d="M3.07122 16.0574H1.18497C0.784261 16.0574 0.459473 16.3822 0.459473 16.7829C0.459473 17.1836 0.784261 17.5083 1.18497 17.5083H3.07122C3.4717 17.5083 3.79672 17.1835 3.79672 16.7829C3.79668 16.3822 3.47193 16.0574 3.07122 16.0574Z"
                  fill="black"
                />
                <path
                  d="M7.44827 0.725586H5.99731V19.3946H7.44827V0.725586Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="20.12" height="20.12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
          <ButtonText
            tab={
              user.navigationTab === '/journal' ||
              user.navigationTab === '/journal/past' ||
              user.navigationTab === '/journal/future'
                ? user.navigationTab
                : 'null'
            }
            active={location.pathname.includes('journal')}
            tabName={location.pathname}
          >
            {translations[1][language]}
          </ButtonText>
        </IconButton>
      </Link>
      {/* <Link
        to={routes[2]}
        onClick={() => user.setNavigationTab('/lifeinsurance')}
        style={{ textDecoration: 'none' }}
      >
        <IconButton key="lifeinsurance">
        
          {location.pathname.includes('lifeinsurance') ? (
            <img
              src="/protection-active.png"
              alt="protection"
              style={{ width: '20px' }}
            />
          ) : (
            <img
              src="/protection.png"
              alt="protection"
              style={{ width: '20px' }}
            />
          )}
          <ButtonText
            tab={
              user.navigationTab === '/lifeinsurance'
                ? user.navigationTab
                : 'null'
            }
            active={location.pathname.includes('lifeinsurance')}
            tabName={location.pathname}
          >
            Protection
          </ButtonText>
        </IconButton>
      </Link> */}
      <Link
        to={routes[3]}
        onClick={() => user.setNavigationTab('/settings')}
        style={{ textDecoration: 'none' }}
      >
        <IconButton key="settings">
          {location.pathname.includes('settings') ? (
            <svg
              width="21"
              height="14"
              viewBox="0 0 21 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.12134 7H19.1213"
                stroke="#2A75BA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.12134 1H19.1213"
                stroke="#2A75BA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.12134 13H19.1213"
                stroke="#2A75BA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="21"
              height="14"
              viewBox="0 0 21 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <path
                  d="M1.12134 7H19.1213"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.12134 1H19.1213"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.12134 13H19.1213"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          )}
          <ButtonText
            tab={
              user.navigationTab === '/settings' ? user.navigationTab : 'null'
            }
            active={location.pathname.includes('settings')}
            tabName={location.pathname}
          >
            {translations[2][language]}
          </ButtonText>
        </IconButton>
      </Link>
    </Wrapper>
  );
}

export default NavigationBar;
