import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import SearchResults from './SearchResults';
import RadioButton from '../ReusableItems/RadioButton';
import { Body1, Body2 } from '../Typography/Fonts';
import { CalculateYearsContext } from '../Context/CalculateYearsContext';
import { Context } from '../Context/globalContextProvider';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 780px) {
    padding: 25px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: auto;
    margin-top: 5%;
    background: rgba(239, 246, 241, 0.8);
    border-radius: 21px;
  }
`;
const SearchIcon = styled.svg`
  pointer-events: none;
  position: absolute;
  opacity: 1;
  right: calc(10% - 12px);
  top: 9px;
  @media (max-width: 780px) {
    top: 8px;
  }
`;
// justify-content: ${(props) =>
//   props.breakLine ? 'space-around' : 'flex-start'};
const RadioButtonRow = styled.section`
  justify-content: flex-start;
  flex-flow: row wrap;
  display: flex;
  flex-direction: row;
  @media (max-width: 780px) {
    flex-wrap: wrap;
  }
`;
const Subheading = styled(Body1)`
  color: #57b010;
  font-weight: 700;
  line-height: 26px;
  font-size: 19px;
  margin: 0px 0px 20px 0px;
  text-transform: uppercase;
  @media (max-width: 780px) {
    margin: 0px 0px 10px 0px;
    text-transform: none;
    letter-spacing: 1px;
    line-height: 20px;
    font-weight: 600;
    font-size: 16px;
  }
`;
const Body3 = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #262626;
  @media (max-width: 780px) {
    font-weight: bold;
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 30px 0px;
  @media (max-width: 780px) {
    width: 100%;
    text-align: left;
    flex-direction: ${(props) => props.flexDirection};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px 0px 20px 0px;
  }
`;
const TextArea = styled.div`
  width: 35%;
  @media (max-width: 780px) {
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const InputArea = styled.div`
  width: 65%;
  position: relative;
  @media (max-width: 780px) {
    width: ${(props) => (props.flexDirection === 'row' ? '50%' : '100%')};
  }
`;
const Input = styled.input`
  background: #dfebfd;
  border-radius: 6px;
  border: 0;
  outline: 0;
  height: 34px;
  border: ${(props) =>
    !props.invalid ? '1px solid #ff5c33' : '3px solid transparent'};
  width: 100%;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.6px;
  transition: 0.3s;
  white-space: normal !important;
  color: ${(props) => (props.filled ? '#2A75BA' : '#8B9583')};
  @media (max-width: 780px) {
    width: 38px;
    height: 34px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
  &:focus {
    color: #262626;
    background: white;
    border: 3px solid #dfebfd;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const InputBox = styled(Input)`
  width: 90%;
  text-align: left;
  padding-left: 19px;
  @media (max-width: 780px) {
    padding-left: 15px;
    width: 90%;
  }
`;
const InputsFieldsArea = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 780px) {
    justify-content: space-evenly;
    width: 30px;
  }
`;
const BirthdayInput = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  @media (max-width: 780px) {
    width: 50%;
  }
`;
const BodyText = styled(Body2)`
  color: ${(props) => (props.focused ? '#2A75BA' : '#8B9583')};
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 780px) {
    font-size: 12px;
    line-height: 13px;
    margin: 0px 0px 5px 0px;
    text-transform: none;
    letter-spacing: 0.5px;
    width: max-content;
  }
`;

const translations = [
  {
    English: 'Personal Info',
    Russian: 'Личная информация',
    Spanish: 'Información personal',
  },
  {
    English: 'Your birthday',
    Russian: 'День рождения',
    Spanish: 'Tu cumpleaños',
  },
  {
    English: 'day',
    Russian: 'день',
    Spanish: 'día',
  },
  {
    English: 'month',
    Russian: 'месяц',
    Spanish: 'mes',
  },
  {
    English: 'year',
    Russian: 'год',
    Spanish: 'año',
  },
  {
    English: 'DD',
    Russian: 'дд',
    Spanish: 'DD',
  },
  {
    English: 'MM',
    Russian: 'мм',
    Spanish: 'MM',
  },
  {
    English: 'YYYY',
    Russian: 'гггг',
    Spanish: 'YYYY',
  },
  {
    English: 'Country of origin',
    Russian: 'Страна проживания',
    Spanish: 'País de origen',
  },
  {
    English: 'Search Country',
    Russian: 'Поиск страны',
    Spanish: 'Seleccionar país',
  },
  {
    English: 'Gender', //10
    Russian: 'Пол',
    Spanish: 'Género',
  },
  {
    English: 'Male',
    Russian: 'Мужской',
    Spanish: 'Hombre',
  },
  {
    English: 'Female',
    Russian: 'Женский',
    Spanish: 'Mujer',
  },
  {
    English: 'Race',
    Russian: 'Раса',
    Spanish: 'Raza',
  },
  {
    English: 'White',
    Russian: 'Европеоидная',
    Spanish: 'Blanca',
  },
  {
    English: 'Black',
    Russian: 'Негроидная',
    Spanish: 'Negra',
  },
  {
    English: 'Hispanic',
    Russian: 'Латиноамериканец',
    Spanish: 'Hispana',
  },
  {
    English: 'Asian',
    Russian: 'Монголоидная',
    Spanish: 'Asiática',
  },
];

function PersonalInfo({
  validDay,
  setValidDay,
  validMonth,
  setValidMonth,
  validYear,
  setValidYear,
  continueClicked,
}) {
  const { language } = React.useContext(SettingsContext);
  const calculateYearsContext = React.useContext(CalculateYearsContext);
  const globalUserContext = React.useContext(Context);
  const [focusedInput, setFocusedInput] = React.useState(0);

  const [genderLabels, setGenderLabels] = React.useState([
    { l: translations[11][language], k: 'Male' },
    { l: translations[12][language], k: 'Female' },
  ]);
  const [raceLabels, setRaceLabels] = React.useState([
    { l: translations[14][language], k: 'White' },
    { l: translations[15][language], k: 'Black' },
    { l: translations[16][language], k: 'Hispanic' },
    { l: translations[17][language], k: 'Asian' },
  ]);

  const [genderRow, setGenderRow] = React.useState([]);
  const [raceRow, setRaceRow] = React.useState([]);

  const [activeGender, setActiveGenger] = React.useState('Male');
  const [activeRace, setRace] = React.useState('White');

  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [country, setCountry] = React.useState({ v: '', t: '' });

  React.useEffect(() => {
    setGenderLabels([
      { l: translations[11][language], k: 'Male' },
      { l: translations[12][language], k: 'Female' },
    ]);
    setRaceLabels([
      { l: translations[14][language], k: 'White' },
      { l: translations[15][language], k: 'Black' },
      { l: translations[16][language], k: 'Hispanic' },
      { l: translations[17][language], k: 'Asian' },
    ]);
  }, [language]);

  React.useEffect(() => {
    const auxGenderRow = genderLabels.map((label, index) => (
      <RadioButton
        // eslint-disable-next-line react/no-array-index-key
        key={`genderRow-${index}`}
        tag={label.l}
        value={label.k}
        active={activeGender === label.k ? 1 : 0}
        toggle={(e) => {
          setActiveGenger(e);
          calculateYearsContext.setGender(e);
        }}
      />
    ));
    setGenderRow(auxGenderRow);
  }, [activeGender, calculateYearsContext, genderLabels]);

  React.useEffect(() => {
    const auxRaceRow = raceLabels.map((label, index) => (
      <>
        {language === 'Russian' && (index + 1) % 2 === 0 ? (
          <div style={{ width: '50px' }} />
        ) : null}
        <RadioButton
          // eslint-disable-next-line react/no-array-index-key
          key={`raceRow-${index}`}
          tag={label.l}
          value={label.k}
          active={activeRace === label.k ? 1 : 0}
          toggle={(e) => {
            setRace(e);
            calculateYearsContext.setRace(e);
          }}
        />
        {language === 'Russian' && (index + 1) % 2 === 0 ? (
          <div style={{ width: '100%', height: '12px' }} />
        ) : null}
      </>
    ));
    setRaceRow(auxRaceRow);
  }, [activeRace, calculateYearsContext, language, raceLabels]);

  React.useEffect(() => {
    setDay(moment(globalUserContext.birthday).format('D'));
    setMonth(moment(globalUserContext.birthday).format('M'));
    setYear(moment(globalUserContext.birthday).format('Y'));
  }, [globalUserContext.birthday]);

  const [openDropdown, setDropdown] = React.useState(false);
  // console.log("age", age, "day", day, "month", month, "year", year);
  React.useEffect(() => {
    if (day !== '' && month !== '' && year !== '')
      calculateYearsContext.setBirth(new Date(`${month} ${day},${year}`));
    calculateYearsContext.setAge(new Date().getFullYear() - year);
    // console.log(new Date().getFullYear()-year)
    // console.log(calculateYearsContext.age)
    if (day !== '') {
      setValidDay(true);
    }
    if (month !== '') {
      setValidMonth(true);
    }
    if (year !== '') {
      setValidYear(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, year, month]);

  React.useEffect(() => {
    if (country.v && country.t) {
      calculateYearsContext.setResidenceCountry(country.v);
    }
  }, [calculateYearsContext, country]);

  const handleOnBlur = () => {
    const timer = setTimeout(() => {
      setDropdown(false);
    }, 150);
    return () => clearTimeout(timer);
  };
  return (
    <Wrapper>
      <Subheading>{translations[0][language]}</Subheading>
      <Section flexDirection="row">
        <TextArea flexDirection="row">
          <Body3>{translations[1][language]}</Body3>
        </TextArea>
        <BirthdayInput>
          <InputsFieldsArea>
            <BodyText focused={focusedInput === 1}>
              {translations[2][language]}
            </BodyText>
            <Input
              type="number"
              value={day}
              placeholder={translations[5][language]}
              invalid={continueClicked ? validDay : true}
              filled={day.length > 0}
              onFocus={() => setFocusedInput(1)}
              onBlur={() => setFocusedInput(0)}
              onChange={(event) => setDay(event.target.value)}
            />
          </InputsFieldsArea>
          <InputsFieldsArea>
            <BodyText focused={focusedInput === 2}>
              {translations[3][language]}
            </BodyText>
            <Input
              type="number"
              value={month}
              placeholder={translations[6][language]}
              invalid={continueClicked ? validMonth : true}
              filled={month.length > 0}
              onFocus={() => setFocusedInput(2)}
              onBlur={() => setFocusedInput(0)}
              onChange={(event) => setMonth(event.target.value)}
            />
          </InputsFieldsArea>
          <InputsFieldsArea>
            <BodyText focused={focusedInput === 3}>
              {translations[4][language]}
            </BodyText>
            <Input
              type="number"
              value={year}
              placeholder={translations[7][language]}
              invalid={continueClicked ? validYear : true}
              filled={year.length > 0}
              onFocus={() => setFocusedInput(3)}
              onBlur={() => setFocusedInput(0)}
              onChange={(event) => setYear(event.target.value)}
            />
          </InputsFieldsArea>
        </BirthdayInput>
      </Section>
      <Section flexDirection="row">
        <TextArea flexDirection="row">
          <Body3>{translations[8][language]}</Body3>
        </TextArea>
        <InputArea flexDirection="row">
          <InputBox
            type="country"
            placeholder={translations[9][language]}
            value={country.t}
            filled={!openDropdown}
            invalid
            onFocus={() => setDropdown(true)}
            onBlur={() => handleOnBlur()}
            onClick={() => setDropdown(true)}
            onChange={(e) => {
              setCountry({ v: '', t: e.target.value });
              setDropdown(true);
            }}
          />
          <SearchIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
              stroke="#2A75BA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.9999 20.9999L16.6499 16.6499"
              stroke="#2A75BA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </SearchIcon>
          {/* && openDropdown */}
          {country.t && openDropdown ? (
            <SearchResults
              country={country.t}
              setCountry={setCountry}
              language={language}
            />
          ) : null}
        </InputArea>
      </Section>
      <Section flexDirection="column">
        <TextArea>
          <Body3>{translations[10][language]}</Body3>
        </TextArea>
        <InputArea>
          <RadioButtonRow>{genderRow}</RadioButtonRow>
        </InputArea>
      </Section>
      <Section>
        <TextArea>
          <Body3>{translations[13][language]}</Body3>
        </TextArea>
        <InputArea>
          <RadioButtonRow breakLine={language === 'Russian'}>
            {raceRow}
          </RadioButtonRow>
        </InputArea>
      </Section>
    </Wrapper>
  );
}

export default PersonalInfo;
