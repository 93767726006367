import './App.css';
import React from 'react';
import { useGA4React } from 'ga-4-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/app';
import { StateProvider } from './components/Context/globalContextProvider';
import { FirebaseProvider } from './components/Context/FirebaseContext';
import { SettingsProvider } from './components/Context/SettingsContext';
import { CalculateYearsProvider } from './components/Context/CalculateYearsContext';
import Welcome from './components/WelcomeScreens/Welcome';
import BirthdayInfo from './components/BirthdayInfo/BirthdayInfo';
import CalculateYears from './components/CalculateYears/CalculateYears';
import Success from './components/CalculateYears/Success';
import Settings from './components/Settings/Settings';
import Journal from './components/Journal/Journal';
import JournalPast from './components/Journal/JournalPast';
import JournalFuture from './components/Journal/JournalFuture';
import LifeInYears from './components/Calendar/LifeInYears';
import YearsInWeeks from './components/Calendar/YearsInWeeks';
import LifeInDays from './components/Calendar/LifeInDays';
import LifeInWeeks from './components/Calendar/LifeInWeeks';
import Login from './components/Authentication/Login';
import Register from './components/Authentication/Register';
import Logout from './components/Authentication/Logout';
import PasswordReset from './components/Authentication/ForgotPassword';
import VerifyEmail from './components/Authentication/VerifyEmail';
import LifeInsurance from './components/Insurance/LifeInsurance';
import About from './components/About/About';
import DataDeletion from './components/DataDeletion/DataDeletion';

import 'firebase/auth';
import 'firebase/firestore';

const FirebaseAnonymousLogin = () => {
  firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      // Signed in..
    })
    .catch((error) => {
      console.error(error);
    });
};

function App() {
  // const [user, loading, error] = useAuthState(firebase.auth());
  const [user] = useAuthState(firebase.auth());
  React.useEffect(() => {
    if (user === null && window.location.pathname === '/login')
      FirebaseAnonymousLogin();
  }, [user]);

  useGA4React('UA-162697431-2'); // GA CODE, optional, if empty try to get from globals
  return (
    <div className="App">
      <FirebaseProvider>
        <SettingsProvider>
          <CalculateYearsProvider>
            <StateProvider key="StateProviderKey">
              <Router>
                <Switch>
                  <Route path="/" exact component={Welcome} />
                  <Route path="/birthdayinfo" exact component={BirthdayInfo} />
                  <Route
                    path="/calculateyears"
                    exact
                    component={CalculateYears}
                  />
                  <Route path="/success" exact component={Success} />
                  <Route path="/settings" exact component={Settings} />
                  <Route path="/calendar" exact component={LifeInWeeks} />
                  <Route
                    exact
                    path="/calendar/lifeinyears"
                    component={LifeInYears}
                  />
                  <Route
                    exact
                    path="/calendar/yearsinweeks"
                    component={YearsInWeeks}
                  />
                  <Route exact path="/calendar/days" component={LifeInDays} />
                  <Route path="/journal" exact component={Journal} />
                  <Route path="/journal/past" exact component={JournalPast} />
                  <Route
                    path="/journal/future"
                    exact
                    component={JournalFuture}
                  />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/logout" component={Logout} />
                  <Route
                    exact
                    path="/password-reset"
                    component={PasswordReset}
                  />
                  <Route exact path="/verify-email" component={VerifyEmail} />
                  <Route
                    exact
                    path="/lifeinsurance"
                    component={LifeInsurance}
                  />
                  <Route exact path="/about" component={About} />
                  <Route
                    exact
                    path="/datadeletioninstructions"
                    component={DataDeletion}
                  />
                </Switch>
              </Router>
            </StateProvider>
          </CalculateYearsProvider>
        </SettingsProvider>
      </FirebaseProvider>
    </div>
  );
}

export default App;
