/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { useRanger } from 'react-ranger';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  margin: 0px 0px 0px 16px;
  @media (max-width: 780px) {
    margin: 0px 0px 0px 10px;
  }
  // width: 100%;
  // margin-bottom: 21px;
  // margin-top: 5px;
`;
const Track = styled.div`
  display: inline-block;
  border-radius: 50px;
  height: 26px;
  min-width: 70%;
  background: rgba(87, 176, 16, 0.4);
  @media (max-width: 780px) {
    height: 18px;
    min-width: 98%;
  }
`;
const Segment = styled.div`
  //  background: ${(prop) => (prop.index === 0 ? '#000000' : '#B9B9B9')};
  background: #bcdf9f;
  height: 100%;
  border-radius: 50px;
`;
const Handle = styled.div`
  background: #57b010;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  @media (max-width: 780px) {
    width: 24px;
    height: 24px;
  }
`;
const Button = styled.button`
  appearance: none;
  border: 0;
  background: transparent;
  outline: 0;
`;
const Label = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #262626;
  @media (max-width: 780px) {
    display: none;
  }
`;

function roundToTwo(num) {
  return +`${Math.round(`${num}e+2`)}e-2`;
}

function Slider(props) {
  const { unit, start, end, step, toggle, value } = props;

  //if (value) console.log('SliderProps: ', unit, value);
  //  const [values, setValues] = React.useState([start + ((end - start) / 2)]);
  const [values, setValues] = React.useState([value ?? start]);

  //if (value) console.log('SliderProps: ', unit, values);
  const { getTrackProps, segments, handles } = useRanger({
    min: start,
    max: end,
    stepSize: step,
    values,
    onDrag: setValues,
    onChange: () => toggle(values),
  });
  //if (value) console.log('SliderProps: ', unit, segments, handles);

  React.useEffect(() => {
    setValues([value ?? start]);
  }, [start, value]);

  let val = roundToTwo(values);
  let label = (
    <Label>
      {val}
      {unit}
    </Label>
  );

  if (unit === ' Week') {
    val = Math.floor(val);
    label =
      val === 0 ? (
        <Label>Never</Label>
      ) : (
        <Label>
          {val}x per {unit}
        </Label>
      );
  } else if (unit === 'feet') {
    label = <Label>{val.toString().replace('.', `' `)} "</Label>;
  } else if (Array.isArray(unit)) {
    label = <Label>{unit[val]}</Label>;
  }
  return (
    <Wrapper>
      <Track {...getTrackProps()}>
        {segments.map(({ getSegmentProps }, i) => (
          <Segment {...getSegmentProps()} index={i} />
        ))}
        {handles.map(({ getHandleProps }) => (
          <Button {...getHandleProps({})}>
            <Handle />
          </Button>
        ))}
      </Track>
      {label}
    </Wrapper>
  );
}

export default Slider;
