import React from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Body2 } from '../Typography/Fonts';
import { Context } from '../Context/globalContextProvider';
import { firebaseLogout, FirebaseSaveColors } from '../Context/FirebaseContext';
import PaintSelection from './PaintSelection';
import { SettingsContext } from '../Context/SettingsContext';

const Wrapper = styled.section`
  height: 100vh;
  padding: 0;
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 210px;
  box-sizing: border-box;
  @media (max-width: 900px) {
    display: none;
  }
`;
const Background = styled.svg`
  position: absolute;
  left: 0;
  width: 250px;
  z-index: -1;
`;
const Container = styled.div`
  display: flex;
  margin-top: 15vh;
  margin-bottom: 20px;
  height: calc(100% - 15vh - 20px);
  flex-direction: column;
  justify-content: space-between;
`;
const Section = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Avatar = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dfebfd;
  margin: auto;
  width: 75px;
  height: 75px;
  z-index: 1;
`;

const AvatarImage = styled.img`
  border-radius: 50%;
  margin: auto;
  width: 75px;
  height: 75px;
`;

const BodyText = styled(Body2)`
  font-weight: 400;
  color: ${(props) => (props.paintOpen ? '#2A75BA' : props.color)};
`;
const UsernameText = styled(BodyText)`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'unset')};
  text-align: center;
`;
// const TextLink = styled(Link)`
//   text-decoration: none;
//   color: #000000;
// `;

const Hr = styled.div`
  background: #adadad;
  height: 1px;
  width: 40%;
  margin: 10px 0px 10px 0px;
`;
const IconButton = styled(Link)`
  height: max-content;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2.5% 0% 2.5% 0%;
  padding-left: 24px;
  box-sizing: border-box;
  gap: 20px;
  width: 100%;
  border: 0;
  outline: 0;
  color: ${(props) => props.color || '#ADADAD'};
  &:focus {
    outline: 0;
    color: ${(props) => props.color || '#ADADAD'};
  }
  &:hover {
    color: ${(props) => props.color || '#ADADAD'};
    cursor: pointer;
    text-decoration: underline;
  }
`;
const IconArea = styled.div`
  width: 40px;
`;

const translations = [
  {
    English: 'Paint',
    Russian: 'Покрасить',
    Spanish: 'Pintura',
  },
  {
    English: 'Calendar',
    Russian: 'Календарь',
    Spanish: 'Calendario',
  },
  {
    English: 'Journal',
    Russian: 'Дневник',
    Spanish: 'Diario',
  },
  {
    English: 'Protection',
    Russian: 'Защита',
    Spanish: 'Protección',
  },
  {
    English: 'Settings',
    Russian: 'Настройки',
    Spanish: 'Configuración',
  },
  {
    English: 'About Us',
    Russian: 'О нас',
    Spanish: 'Sobre nosotros',
  },
  {
    English: 'Log in',
    Russian: 'Войти',
    Spanish: 'Iniciar sesión',
  },
  {
    English: 'Log out',
    Russian: 'Выйти',
    Spanish: 'Cerrar sesión',
  },
];

const VerticalNavigationBar = ({ showPaint }) => {
  const {
    activeColor,
    paintOpen,
    togglePaint,
    user,
    recentColors,
    lifeInYearsColors,
    lifeInWeeksColors,
    yearsInWeeksColors,
  } = React.useContext(Context);
  const history = useHistory();
  const { language } = React.useContext(SettingsContext);
  const location = useLocation().pathname;
  const [userDetails] = useAuthState(firebase.auth());
  const [circleColor, setCircleColor] = React.useState(activeColor);

  const saveColors = React.useCallback(
    () => {
      if (user?.uid) {
        FirebaseSaveColors(
          user,
          recentColors,
          lifeInYearsColors,
          lifeInWeeksColors,
          yearsInWeeksColors,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lifeInWeeksColors, lifeInYearsColors, recentColors, yearsInWeeksColors],
  );

  return (
    <Wrapper>
      <Background fill="none" height="300vh" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path
            d="M208 1080V137.5C208 40.1268 0 0 0 0V1080H208Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="-16"
            y="-20"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="4" />
            <feGaussianBlur stdDeviation="10" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.14902 0 0 0 0 0.14902 0 0 0 0 0.14902 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </Background>
      <Container>
        <Section>
          <div>
            <Avatar>
              {userDetails?.photoURL ? (
                <AvatarImage
                  width="40"
                  height="40"
                  src={userDetails?.photoURL}
                />
              ) : (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34.1422 25.8578C31.9638 23.6795 29.371 22.067 26.5421 21.0908C29.572 19.004 31.5625 15.5116 31.5625 11.5625C31.5625 5.18695 26.3755 0 20 0C13.6245 0 8.4375 5.18695 8.4375 11.5625C8.4375 15.5116 10.428 19.004 13.458 21.0908C10.6291 22.067 8.03625 23.6795 5.85789 25.8578C2.08039 29.6354 0 34.6578 0 40H3.125C3.125 30.6951 10.6951 23.125 20 23.125C29.3049 23.125 36.875 30.6951 36.875 40H40C40 34.6578 37.9196 29.6354 34.1422 25.8578ZM20 20C15.3476 20 11.5625 16.215 11.5625 11.5625C11.5625 6.91 15.3476 3.125 20 3.125C24.6524 3.125 28.4375 6.91 28.4375 11.5625C28.4375 16.215 24.6524 20 20 20Z"
                    fill="#2A75BA"
                  />
                </svg>
              )}
            </Avatar>

            {userDetails?.displayName ? (
              <UsernameText>{userDetails.displayName}</UsernameText>
            ) : (
              // <TextLink to="/login">
              //   <UsernameText >{translations[6][language]}</UsernameText>
              // </TextLink>
              <UsernameText
                pointer
                onClick={() => {
                  if (!paintOpen === false) {
                    saveColors();
                    togglePaint(false);
                  }
                  history.push({
                    pathname: '/login',
                  });
                }}
              >
                {translations[6][language]}
              </UsernameText>
            )}
          </div>
          <Hr />
          {showPaint ? (
            <Section>
              <IconButton
                to="#"
                color="#57B010"
                onClick={() => {
                  if (!paintOpen === false) saveColors();
                  togglePaint(!paintOpen);
                }}
              >
                <IconArea>
                  {paintOpen ? (
                    <img src="/paintbrush-active.png" alt="paintbrush" />
                  ) : (
                    <img src="/paintbrush.png" alt="paintbrush" />
                  )}
                </IconArea>
                <BodyText paintOpen={paintOpen} color="#57B010">
                  {translations[0][language]}
                </BodyText>
                {paintOpen ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                      fill="#ADADAD"
                    />
                  </svg>
                ) : null}
              </IconButton>
              {!paintOpen ? <Hr /> : null}
            </Section>
          ) : null}
          {paintOpen ? (
            <>
              <PaintSelection
                circleColor={circleColor}
                setCircleColor={setCircleColor}
              />
              <Hr />
            </>
          ) : (
            <>
              <IconButton
                to="/calendar"
                // onClick={() => { user.setNavigationTab("/calendar"); user.setCalendarTab(1); user.setJournalTab(1); }}
              >
                <IconArea>
                  {location.includes('calendar') ? (
                    <img src="/calendar-active.png" alt="calendar" />
                  ) : (
                    <img src="/calendar.png" alt="calendar" />
                  )}
                </IconArea>
                <BodyText
                  color={
                    location.includes() === '/calendar' ? '#2A75BA' : '#ADADAD'
                  }
                >
                  {translations[1][language]}
                </BodyText>
              </IconButton>
              <IconButton
                to="/journal"
                // onClick={() => { user.setNavigationTab("/journal"); user.setCalendarTab(1); user.setJournalTab(1); }}
              >
                <IconArea>
                  {location.includes('journal') ? (
                    <img src="/notebook-active.png" alt="notebook" />
                  ) : (
                    <img src="/notebook.png" alt="notebook" />
                  )}
                </IconArea>
                <BodyText
                  color={location.includes('/journal') ? '#2A75BA' : '#ADADAD'}
                >
                  {translations[2][language]}
                </BodyText>
              </IconButton>
              <IconButton to="/settings">
                <IconArea>
                  {location.includes('settings') ? (
                    <img src="/settings-active.png" alt="settings" />
                  ) : (
                    <img src="/settings.png" alt="settings" />
                  )}
                </IconArea>
                <BodyText
                  color={location.includes('/settings') ? '#2A75BA' : '#ADADAD'}
                >
                  {translations[4][language]}
                </BodyText>
              </IconButton>
            </>
          )}
        </Section>
        {paintOpen ? null : (
          <Section>
            <IconButton to="/about">
              <IconArea>
                {location.includes('about') ? (
                  <img src="/about-active.png" alt="information" />
                ) : (
                  <img src="/information.png" alt="information" />
                )}
              </IconArea>
              <BodyText
                color={location.includes('/about') ? '#57B010' : '#ADADAD'}
              >
                {translations[5][language]}
              </BodyText>
            </IconButton>

            {user ? (
              <IconButton to="#">
                <IconArea>
                  <img src="/logout.png" alt="logout" />
                </IconArea>
                <BodyText
                  onClick={() => {
                    firebaseLogout(history);
                  }}
                >
                  {translations[7][language]}
                </BodyText>
              </IconButton>
            ) : null}
          </Section>
        )}
      </Container>
    </Wrapper>
  );
};

export default VerticalNavigationBar;
