import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import CalendarLayout from './CalendarLayout';
import { Context } from '../Context/globalContextProvider';
import { SettingsContext } from '../Context/SettingsContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const YearsColumn = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  @media (max-width: 780px) {
    display: none;
  }
`;
const Label = styled.label`
  margin: 16.2px 0px;
  line-height: 44px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 2px;
  color: #262626;
  width: 76px;
`;
const YearLabel = styled(Label)`
  transform: rotate(90deg);
  position: absolute;
  top: 30px;
  right: -60px;
  width: 71px;
`;
const Arrow = styled.svg`
  transform: rotate(270deg) translate(-8px, 16px);
`;
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 760px;
  height: max-content;
  @media (max-width: 780px) {
    justify-content: center;
    margin: 5% 0% 0% 0%;
    max-width: 100%;
  }
`;
const LittleBox = styled.button`
  height: 44px;
  width: 44px;
  border: 0;
  outline: 0;
  margin: 16px;
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 780px) {
    width: 25px;
    height: 25px;
    margin: 3px;
  }
  &:hover {
    transition: 0.3s;
    transform: scale(1.1);
  }
  ${(props) => {
    const backgroundColor = `background: ${props.activeColor};`;
    switch (props.trigger) {
      case true:
        return `${backgroundColor} animation: current 4s infinite;`;
      case false:
        return `${backgroundColor}`;
      default:
        return `${backgroundColor} border: 1px solid #B2B2B2; box-sizing: border-box;`;
    }
  }}

  @keyframes current {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }
`;
const translations = [
  {
    English: 'Years',
    Russian: 'Года',
    Spanish: 'Años',
  },
];
function LifeInYears() {
  const {
    birthday,
    //setCalendarTab,
    setScrollYear,
    lifeExpectency,
    lifeInYearsColors,
    setLifeInYearsColors,
    defaultColor,
    activeColor,
    paintOpen,
    recentColors,
    setRecentColors,
  } = React.useContext(Context);
  const { language } = React.useContext(SettingsContext);
  const age = moment().year() - moment(birthday).year();
  const boxesArray = [];
  const labelsArray = [];

  const circleCallback = React.useCallback(
    (year) => {
      if (paintOpen) {
        const newArray = lifeInYearsColors.filter((l) => l.year !== year);
        setLifeInYearsColors([...newArray, { year, color: activeColor }]);

        const newArray2 = [
          ...recentColors.filter((rc) => rc !== activeColor),
          activeColor,
        ];
        if (newArray2.length > 10) newArray2.shift();
        setRecentColors(newArray2);
      }
    },
    [
      activeColor,
      paintOpen,
      recentColors,
      setRecentColors,
      lifeInYearsColors,
      setLifeInYearsColors,
    ],
  );

  const circleCreator = (year, color, trigger) => (
    <LittleBox
      key={`LittleBox-${year}`}
      trigger={trigger}
      activeColor={color}
      onClick={() => {
        //setCalendarTab(2);
        setScrollYear(year);
        circleCallback(year);
      }}
    />
  );

  const calculateLifeInYearsMatrix = () => {
    const l = lifeExpectency === 0 ? 90 : lifeExpectency;
    for (let i = 1; i <= l; i += 1) {
      const actualCircleColor = lifeInYearsColors.find(
        (c) => c.year === i,
      )?.color;
      const yearCircleColor = actualCircleColor ?? defaultColor;
      const notYearCircleColor = actualCircleColor ?? 'transparent';

      let box = <LittleBox />;
      if (i < age) box = circleCreator(i, yearCircleColor, false);
      else if (i === age) box = circleCreator(i, yearCircleColor, true);
      else box = circleCreator(i, notYearCircleColor, undefined);
      boxesArray.push(box);
    }
  };

  const calculateLabels = () => {
    for (let i = 1; i <= lifeExpectency; i += 10) {
      const rangeTop =
        i + 9 <= lifeExpectency ? i + 9 : i + (lifeExpectency - i);
      const labelText = `${i} - ${rangeTop}`;
      labelsArray.push(<Label>{labelText}</Label>);
    }
  };

  calculateLifeInYearsMatrix();
  calculateLabels();
  React.useEffect(() => {
    calculateLifeInYearsMatrix();
    calculateLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeInYearsColors]);

  return (
    <CalendarLayout>
      <Container>
        <Wrapper>{boxesArray}</Wrapper>
        <YearsColumn>
          <YearLabel>
            {translations[0][language]}
            <Arrow
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16 18H13V2L11 2V18H8L12 22L16 18Z" fill="#2E3A59" />
            </Arrow>
          </YearLabel>
          {labelsArray}
        </YearsColumn>
      </Container>
    </CalendarLayout>
  );
}

export default LifeInYears;
