import React from 'react';
import { useMediaQuery } from 'react-responsive';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Body4 } from '../Typography/Fonts';
import 'firebase/auth';
import { Context } from '../Context/globalContextProvider';
import { SettingsContext } from '../Context/SettingsContext';

const LabelDiv = styled.div`
  padding: 0;
  &:first-child {
    margin-top: 32px;
  }
  @media (max-width: 780px) {
    &:first-child {
      margin-top: 8px;
    }
  }
`;
const Label = styled.label`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 780px) {
  }
`;
const DateDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  @media (max-width: 780px) {
    justify-content: flex-start;
    width: max-content;
    gap: 15px;
  }
`;
const DateTag = styled.h6`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  display: inline;
  font-size: 21px;
  line-height: 100%;
  letter-spacing: 1.2px;
  color: ${(props) => (props.blue ? '#3E8FDA' : '#000000')};
  @media (max-width: 780px) {
    width: auto;
    font-size: 26px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
`;
const BodyText = styled(Body4)`
  font-size: 15px;
  line-height: 16px;
  text-align: left;
  width: 170px;
  margin: 0;
  padding: 0;
  letter-spacing: 1.6px;
  @media (max-width: 780px) {
    width: auto;
    font-size: 10px;
    line-height: 100%;
    margin-bottom: 3px;
    letter-spacing: 1px;
    align-self: flex-end;
    text-transform: lowercase;
  }
`;
const SVGArea = styled.div`
  @media (max-width: 780px) {
    display: none;
  }
`;

const validity = (startDate, linkIndex) => startDate + linkIndex;

const translations = [
  {
    English: 'Monday',
    Russian: 'Понедельник',
    Spanish: 'Lunes',
  },
  {
    English: 'Tuesday',
    Russian: 'Вторник',
    Spanish: 'Martes',
  },
  {
    English: 'Wednesday',
    Russian: 'Среда',
    Spanish: 'Miércoles',
  },
  {
    English: 'Thursday',
    Russian: 'Четверг',
    Spanish: 'Jueves',
  },
  {
    English: 'Friday',
    Russian: 'Пятница',
    Spanish: 'Viernes',
  },
  {
    English: 'Saturday',
    Russian: 'Суббота',
    Spanish: 'Sábado',
  },
  {
    English: 'Sunday',
    Russian: 'Воскресенье',
    Spanish: 'Domingo',
  },
];

const monthsTranslations = [
  {
    English: 'Jan',
    Russian: 'Янв',
    Spanish: 'Ene',
  },
  {
    English: 'Feb',
    Russian: 'Фев',
    Spanish: 'Feb',
  },
  {
    English: 'Mar',
    Russian: 'Марта',
    Spanish: 'Mar',
  },
  {
    English: 'Apr',
    Russian: 'Апр',
    Spanish: 'Abr',
  },
  {
    English: 'May',
    Russian: 'Мая',
    Spanish: 'May',
  },
  {
    English: 'Jun',
    Russian: 'Июня',
    Spanish: 'Jun',
  },
  {
    English: 'Jul',
    Russian: 'Июля',
    Spanish: 'Jul',
  },
  {
    English: 'Aug',
    Russian: 'Август',
    Spanish: 'Ago',
  },
  {
    English: 'Sep',
    Russian: 'Сент',
    Spanish: 'Sep',
  },
  {
    English: 'Oct',
    Russian: 'Окт',
    Spanish: 'Oct',
  },
  {
    English: 'Nov',
    Russian: 'Нояб',
    Spanish: 'Nov',
  },
  {
    English: 'Dec',
    Russian: 'Дек',
    Spanish: 'Dic',
  },
];

const ExpandableDayBlock = ({
  scrollReference,
  label,
  secondLabel,
  expandedContent,
  startDate,
  linkForward,
  linkIndex,
  dateToSet,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 780px)' });
  const history = useHistory();
  const [showExpanded, toggleExpanded] = React.useState(false);
  const { setJournalDate, togglePaint } = React.useContext(Context);
  const [day, setDay] = React.useState('');
  const { language } = React.useContext(SettingsContext);
  React.useEffect(() => {
    const d = label.format('dddd').toUpperCase();
    const index = translations.findIndex((t) => t.English.toUpperCase() === d);
    if (index > -1) setDay(translations[index][language].toUpperCase());
  }, [label, language, setDay]);
  validity(startDate, linkIndex);

  const handleLabelClick = () => {
    if (isMobile) {
      setJournalDate(dateToSet.toDate());
      history.push(linkForward, label.toDate());
    } else toggleExpanded(!showExpanded);
  };
  return (
    <LabelDiv>
      <LazyLoad>
        <Label ref={scrollReference} id={label} onClick={handleLabelClick}>
          <DateDiv>
            <div>
              <DateTag blue>
                {label.format('D')}{' '}
                <DateTag>
                  {
                    monthsTranslations.find(
                      (t) => t.English === label.format('MMM'),
                    )?.[language]
                  }
                </DateTag>
              </DateTag>
            </div>
            <BodyText>
              {label.format('YYYY')}, {day}
            </BodyText>
          </DateDiv>
          <BodyText>{secondLabel}</BodyText>
          <SVGArea>
            {showExpanded ? (
              <svg
                style={{ marginRight: '100px' }}
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4854 8.53495L9.00035 0.0499516L0.51535 8.53495L1.92935 9.94995L9.00035 2.87795L16.0714 9.94995L17.4854 8.53495Z"
                  fill="#2A75BA"
                />
              </svg>
            ) : (
              <svg
                style={{ marginRight: '100px' }}
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.514648 1.46498L8.99965 9.94998L17.4846 1.46498L16.0706 0.0499821L8.99965 7.12198L1.92865 0.0499821L0.514648 1.46498Z"
                  fill="#6A9FCF"
                />
              </svg>
            )}
            <Link
              to={{
                pathname: `${linkForward}`,
                dateForwarded: label.toDate(),
              }}
              style={{ textDecoration: 'none' }}
              onClick={() => {
                setJournalDate(dateToSet.toDate());
                togglePaint(false);
              }}
            >
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4652 17.485L9.9502 9L1.4652 0.514999L0.0501957 1.929L7.1222 9L0.0501957 16.071L1.4652 17.485Z"
                  fill="#57B010"
                />
              </svg>
            </Link>
          </SVGArea>
        </Label>
        {showExpanded ? expandedContent : null}
      </LazyLoad>
    </LabelDiv>
    // <Hr />
  );
};

export default ExpandableDayBlock;
